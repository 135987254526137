import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux/es/exports';
import BackOfficeRoutes from '../routes/backOffice-routes/BackOfficeRoutes';
import BackOfficeNavBar from './BackOfficeNavBar';
import MainHeader from '../shared-components/MainHeader';
import Footer from '../shared-components/Footer';
import TopHeaderBackoffice from './TopHeaderBackoffice';
import { backOfficeCommunication } from '../communication/backOfficeCommunication';
import swal from 'sweetalert';


const BackOfficeDashboard = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const backOfficeUser = useSelector((state) => state.backOfficeUserDetails)
    const [countHandler, setCountHandler] = useState(true);
    const [showLoginBox, setShowLoginBox] = useState(false);

    //get current url
    const fullUrl = window.location.href;
    const splitUrl = fullUrl.split('#');
    const signInUrl = splitUrl.includes("/back-office/login");


    return (
        <div className="dashboard_main">
            <TopHeaderBackoffice showLoginBox={showLoginBox} setShowLoginBox={setShowLoginBox} />
            <MainHeader />
            {signInUrl === true ?
                <span></span>
                :
                <>
                    {
                        !([undefined, null, ""].includes(backOfficeUser.name)) &&
                        <BackOfficeNavBar countHandler={countHandler} setShowLoginBox={setShowLoginBox} />
                    }
                </>
            }

            <div className="dashboard" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }} onClick={() => setShowLoginBox(false)}>
                <BackOfficeRoutes setCountHandler={setCountHandler} />
            </div>
            <Footer />
        </div>
    )
}

export default BackOfficeDashboard