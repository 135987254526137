import React from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from "react-router-dom";
import AdminRoutes from '../routes/admin-routes/AdminRoutes'
import DesignationList from './designation-maintenance/DesignationList'
import SideNav from './SideNav'

const Dashboard = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <div className="admin_dashboard" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
            <div className="side_nav">
                <SideNav />
            </div>
            <div className="dashboard_view">
                <Outlet></Outlet>
            </div>
        </div>
    )
}

export default Dashboard