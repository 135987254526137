import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import SignIn from '../../user-portal/SignIn';
import Registration from '../../user-portal/Registration';
import ForgotPassword from '../../user-portal/ForgotPassword';
import LatestNotifications from '../../user-portal/latest-notification/LatestNotifications';
import MyApplication from '../../user-portal/my-application/MyApplication';
import Alerts from '../../user-portal/alert/Alerts';
import Grievance from '../../user-portal/grievance/Grievance';
import Remittance from '../../user-portal/remittance/Remittance';
import Downloads from '../../user-portal/downloads/Downloads';
import { UserPrivateRoute } from '../privateRoute';
import UserDashboard from '../../user-portal/UserDashboard';
import RaiseGrievance from '../../user-portal/grievance/RaiseGrievance';
import UserChatbot from '../../user-portal/grievance/UserChatbot';
import NewApplication from '../../user-portal/latest-notification/NewApplication';
import RepairAndMaintenance from '../../user-portal/latest-notification/RepairAndMaintenance';
import SecondInstallment from '../../user-portal/my-application/SecondInstallment';
import PreviewNewApplication from '../../user-portal/my-application/PreviewNewApplication';
import PreviewRepairAndMaintenance from '../../user-portal/my-application/PreviewRepairAndMaintenance';
import RemittancePreview from '../../user-portal/remittance/RemittancePreview';
import PreviewSecondInstallment from '../../user-portal/my-application/PreviewSecondInstallment';

const UserRoutes = () => {
  return (
    <>
      <Routes>
        {/* <Route path='/user' element={<UserPrivateRoute><UserDashboard></UserDashboard></UserPrivateRoute>}> */}
        <Route path='/latest-notification' element={<LatestNotifications />} />
        <Route path='/latest-notification/new-application' element={<NewApplication />} />
        <Route path='/latest-notification/repair-and-maintenance' element={<RepairAndMaintenance />} />
        <Route path='/my-application' element={<MyApplication />} />
        <Route path='/my-application/preview-new-application' element={<PreviewNewApplication />} />
        <Route path='/my-application/preview-repair-and-maintenance' element={<PreviewRepairAndMaintenance />} />
        <Route path='/my-application/second-installment' element={<SecondInstallment />} />
        <Route path='/my-application/preview-second-installment' element={<PreviewSecondInstallment />} />
        <Route path='/alerts' element={<Alerts />} />
        <Route path='/grievance' element={<Grievance />} />
        <Route path='/grievance/raise-grievance' element={<RaiseGrievance />} />
        <Route path='/grievance/chatbot' element={<UserChatbot />} />
        <Route path='/remittance' element={<Remittance />} />
        <Route path='/remittance/remittance-preview' element={<RemittancePreview />} />
        <Route path='/downloads' element={<Downloads />} />
        <Route path="*" element={<Navigate to="/latest-notification" replace />} />
      </Routes>
    </>
  )
}

export default UserRoutes