import { createSlice } from '@reduxjs/toolkit';

const darkWhiteThemeToggle = createSlice({
    name: "darkwhitethemeSlice",
    initialState: {
        theme: "WHITE"
    },

    reducers: {
        darkTheme: (state, action) => {
            if (state.theme === "WHITE") {
                state.theme = state.theme = "DARK"
                return state
            } else {
                return state
            }
        },
        whiteTheme: (state, action) => {
            if (state.theme === "DARK") {
                state.theme = state.theme = "WHITE"
                return state
            } else {
                return state
            }
        },
    }
})
export const { darkTheme, whiteTheme } = darkWhiteThemeToggle.actions;
export default darkWhiteThemeToggle.reducer;