import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import useValidator from '../../form-validation/useValidatior';
import { adminCommunication } from '../../communication/adminCommunication';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import H5 from "../../text-components/H5";
import swal from 'sweetalert';

const AddNotification = () => {
  //get value from redux store
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);

  const [validator, showValidationMessage] = useValidator();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notificationDetail, setNotificationDetail] = useState({
    portal: "",
    notificationType: "",
    formType: "",
    startDate: "",
    endDate: "",
    subject: "",
    description: "",
    attachment: "",
  })

  //Get all inputs value
  const getNotificationDetails = (event) => {
    setNotificationDetail({ ...notificationDetail, [event.target.name]: event.target.value });
  }

  //set file
  const onFileSelect = (event) => {
    //convert file into fromData
    const formData = new FormData();
    formData.append('attachment', event.target.files[0], event.target.files[0].name);
    setNotificationDetail({ ...notificationDetail, attachment: formData })
  }

  const addNotification = async () => {
    try {
      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }


      dispatch(showLoader()); //show loader
      //call api according to portal

      //public portal notification
      if (notificationDetail.portal === "PUBLIC") {
        const serverResponse = await adminCommunication.uploadPublicNotification(notificationDetail.portal, notificationDetail.subject, notificationDetail.description, notificationDetail.attachment);
        if (serverResponse.data.status === "SUCCESS") {
          dispatch(hideLoader());
          swal({ text: serverResponse?.data?.message, icon: "success" });
          navigate("/admin/dashboard/notification-list");
        } else if (serverResponse.data.status === "JWT_INVALID") {
          navigate("/admin/login");
          swal({ text: serverResponse?.data?.message, icon: "error" });
          dispatch(hideLoader());
        } else {
          swal({ text: serverResponse?.data?.message, icon: "error" });
          dispatch(hideLoader());
        }
      }

      //user portal notification
      if (notificationDetail.portal === "USER") {
        const serverResponse = await adminCommunication.uploadUserNotification(notificationDetail.portal, notificationDetail.notificationType, notificationDetail.subject, notificationDetail.description, notificationDetail.attachment, notificationDetail.formType, notificationDetail.startDate, notificationDetail.endDate);
        if (serverResponse.data.status === "SUCCESS") {
          dispatch(hideLoader());
          swal({ text: serverResponse?.data?.message, icon: "success" });
          navigate("/admin/dashboard/notification-list");
        } else if (serverResponse.data.status === "JWT_INVALID") {
          navigate("/admin/login");
          swal({ text: serverResponse?.data?.message, icon: "error" });
          dispatch(hideLoader());
        } else {
          swal({ text: serverResponse?.data?.message, icon: "error" });
          dispatch(hideLoader());
        }
      }

    } catch (error) {
      swal({ text: error?.message, icon: "error" });
      dispatch(hideLoader());
    }

  }

  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="form_section">
            <div className="add_notification_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Add Notification</H5>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <select className="form-select" name="portal" onChange={(event) => getNotificationDetails(event)}>
                      <option value="">Select Portal</option>
                      <option value="PUBLIC">Public</option>
                      <option value="USER">User</option>
                    </select>
                    {validator.message("Portal", notificationDetail?.portal, "required|alpha_space", {
                      messages: {
                        required: "Portal is required"
                      }
                    })}
                  </div>
                  {notificationDetail.portal === "PUBLIC" ?
                    <span></span>
                    :
                    <div className="col-lg-6 col-md-6">
                      <select className="form-select" name="notificationType" onChange={(event) => getNotificationDetails(event)}>
                        <option value="">Select Notification Type</option>
                        <option value="GENERAL">General</option>
                        <option value="APPLY">Apply</option>
                      </select>
                      {validator.message("notification Type", notificationDetail?.notificationType, "required|alpha_space", {
                        messages: {
                          required: "notification Type is required"
                        }
                      })}
                    </div>
                  }
                </div>
                {(notificationDetail?.portal === "PUBLIC") || (notificationDetail?.portal === "USER" && notificationDetail.notificationType === "GENERAL") ?
                  <span></span>
                  :
                  <div className="row mb-3">
                    <div className="col-lg-6 col-md-6">
                      <input type="date" name="startDate" className="form-control" placeholder="Start Date" onChange={(event) => getNotificationDetails(event)} />
                      {validator.message("start Date", notificationDetail?.startDate, "required", {
                        messages: {
                          required: "start Date is required"
                        }
                      })}
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <input type="date" name="endDate" className="form-control" placeholder="End Date" onChange={(event) => getNotificationDetails(event)} />
                      {validator.message("end Date", notificationDetail?.endDate, "required", {
                        messages: {
                          required: "end Date is required"
                        }
                      })}
                    </div>
                  </div>
                }
                <div className="row mb-3">
                  {(notificationDetail.portal === "PUBLIC") || (notificationDetail.portal === "USER" && notificationDetail.notificationType === "GENERAL") ?
                    <span></span>
                    :
                    <div className="col-lg-6 col-md-6">
                      <select className="form-select" name="formType" onChange={(event) => getNotificationDetails(event)}>
                        <option value="">Select Form Type</option>
                        <option value="NEW APPLICATION">New Application</option>
                        <option value="REPAIR AND MAINTENANCE">Repair and Maintenance</option>
                      </select>
                      {validator.message("form Type", notificationDetail?.formType, "required", {
                        messages: {
                          required: "form Type is required"
                        }
                      })}
                    </div>
                  }
                  <div className="col-lg-6 col-md-6">
                    <input type="text" name="subject" className="form-control" placeholder="Enter Subject" onChange={(event) => getNotificationDetails(event)} />
                    {validator.message("subject", notificationDetail?.subject, "required", {
                      messages: {
                        required: "subject is required"
                      }
                    })}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <textarea className="form-control" name='description' placeholder="Enter Description" onChange={(event) => getNotificationDetails(event)}></textarea>
                    {validator.message("description", notificationDetail?.description, "required", {
                      messages: {
                        required: "description is required"
                      }
                    })}
                  </div>
                  {(notificationDetail.portal === "USER" && notificationDetail.notificationType === "APPLY") ?
                    <span></span>
                    :
                    < div className="col-lg-6 col-md-6">
                      <div>
                        <input type="file" name="file" className="form-control" onChange={(event) => onFileSelect(event)} />
                        {validator.message("file", notificationDetail?.attachment, "required", {
                          messages: {
                            required: "file is required"
                          }
                        })}
                      </div>

                      <span className="document_attachement_note" style={{color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "rgb(173, 83, 137)", marginTop: "4px" }}>*Only pdf is allowed</span>
                    </div>
                  }
                </div>

                <div className="button_group mt-3">
                  <button onClick={() => addNotification()} >Add</button>
                  <NavLink to="/admin/dashboard/notification-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </div>
            </div >
          </div >
      }
    </>
  )
}

export default AddNotification