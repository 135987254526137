import React from 'react'
import { Route, Routes } from "react-router-dom";
import NewApplications from '../../backOffice-portal/new-application/NewApplications';
import StateSelectedApplications from '../../backOffice-portal/state-selected-applications/StateSelectedApplications';
import CentralAcknowledgementForm from '../../backOffice-portal/state-selected-applications/CentralAcknowledgementForm';
import FinalSelectedApplications from '../../backOffice-portal/final-selected-applications/FinalSelectedApplications';
import AddReviewForm from '../../backOffice-portal/final-selected-applications/AddReviewForm';
import Notification from '../../backOffice-portal/notification/Notification';
import Grievance from '../../backOffice-portal/grievance/Grievance';
import Remittance from '../../backOffice-portal/remittance/Remittance';
import RemittanceForm from '../../backOffice-portal/remittance/RemittanceForm';
import BackOfficeChatbot from '../../backOffice-portal/grievance/BackOfficeChatbot';
import DistrictSelectedApplications from '../../backOffice-portal/district-selected-applications/DistrictSelectedApplications';
import DivisionSelectedApplications from '../../backOffice-portal/division-selected-applications/DivisionSelectedApplications';
import ViewReviews from '../../backOffice-portal/final-selected-applications/ViewReviews';
import UpdateReview from '../../backOffice-portal/final-selected-applications/UpdateReview';
import BackOfficeSignIn from '../../backOffice-portal/BackOfficeSignIn';
import PreviewNewApplication from '../../user-portal/my-application/PreviewNewApplication';
import PreviewRepairAndMaintenance from '../../user-portal/my-application/PreviewRepairAndMaintenance';
import PreviewSecondInstallment from '../../user-portal/my-application/PreviewSecondInstallment';

const BackOfficeRoutes = ({ setCountHandler }) => {
  return (
    <>
      <Routes>
        <Route path='/login' element={<BackOfficeSignIn />} />
        <Route path='/new-applications' element={<NewApplications />} />
        <Route path='/new-applications/new-application-preview' element={<PreviewNewApplication />} />
        <Route path='/new-applications/repair-and-maintenance-preview' element={<PreviewRepairAndMaintenance />} />
        <Route path='/new-applications/second-installment-preview' element={<PreviewSecondInstallment />} />
        <Route path='/district-selected-applications' element={<DistrictSelectedApplications />} />
        <Route path='/district-selected-applications/cental-acknowledgement' element={<CentralAcknowledgementForm />} />
        <Route path='/district-selected-applications/new-application-preview' element={<PreviewNewApplication />} />
        <Route path='/district-selected-applications/repair-and-maintenance-preview' element={<PreviewRepairAndMaintenance />} />
        <Route path='/district-selected-applications/second-installment-preview' element={<PreviewSecondInstallment />} />
        <Route path='/division-selected-applications' element={<DivisionSelectedApplications />} />
        <Route path='/division-selected-applications/cental-acknowledgement' element={<CentralAcknowledgementForm />} />
        <Route path='/division-selected-applications/new-application-preview' element={<PreviewNewApplication />} />
        <Route path='/division-selected-applications/repair-and-maintenance-preview' element={<PreviewRepairAndMaintenance />} />
        <Route path='/division-selected-applications/second-installment-preview' element={<PreviewSecondInstallment />} />
        <Route path='/state-selected-applications' element={<StateSelectedApplications />} />
        <Route path='/state-selected-applications/new-application-preview' element={<PreviewNewApplication />} />
        <Route path='/state-selected-applications/repair-and-maintenance-preview' element={<PreviewRepairAndMaintenance />} />
        <Route path='/state-selected-applications/second-installment-preview' element={<PreviewSecondInstallment />} />
        <Route path='/state-selected-applications/cental-acknowledgement' element={<CentralAcknowledgementForm />} />
        <Route path='/final-selected-applications' element={<FinalSelectedApplications />} />
        <Route path='/final-selected-applications/new-application-preview' element={<PreviewNewApplication />} />
        <Route path='/final-selected-applications/repair-and-maintenance-preview' element={<PreviewRepairAndMaintenance />} />
        <Route path='/final-selected-applications/second-installment-preview' element={<PreviewSecondInstallment />} />
        <Route path='/final-selected-applications/add-review' element={<AddReviewForm />} />
        <Route path='/final-selected-applications/view-review' element={<ViewReviews />} />
        <Route path='/final-selected-applications/update-review' element={<UpdateReview />} />
        <Route path='/notification' element={<Notification setCountHandler={setCountHandler} />} />
        <Route path='/grievance' element={<Grievance />} />
        <Route path='/grievance/chatbot' element={<BackOfficeChatbot />} />
        <Route path='/remittance' element={<Remittance />} />
        <Route path='/remittance/remittance-form' element={<RemittanceForm />} />
      </Routes>
    </>
  )
}

export default BackOfficeRoutes