import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import H6 from '../text-components/H6';

const SideNav = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <ul className="tab_list">
            <NavLink to="/admin/dashboard/designation-list">
                <li>
                    <div><i className="fa fa-star me-2" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i></div>
                    <H6>Designation Maintenance</H6>
                </li>
            </NavLink>
            <NavLink to="/admin/dashboard/department-list">
                <li>
                    <div><i className="fa fa-calendar-minus-o me-2" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i></div>
                    <H6>Department Maintenance</H6>
                </li>
            </NavLink>
            <NavLink to="/admin/dashboard/user-list">
                <li>
                    <div><i className="fa fa-user-circle-o me-2" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i></div>
                    <H6>User Maintenance</H6>
                </li>
            </NavLink>
            <NavLink to="/admin/dashboard/notification-list">
                <li>
                    <div><i className="fa fa-check me-2" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i></div>
                    <H6>Notification Maintenance</H6>
                </li>
            </NavLink>
            <NavLink to="/admin/dashboard/upload-data-list">
                <li>
                    <div><i className="fa fa-cloud-upload me-2" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i></div>
                    <H6>Document</H6>
                </li>
            </NavLink>
            {/* <NavLink to="/admin/dashboard/feedback">
                <li>
                    <div><i className="fa fa-comments me-2" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i></div>
                    <H6>Feedback</H6>
                </li>
            </NavLink> */}
            {/* <NavLink to="/admin/dashboard/statistics">
                <li>
                    <div><i className="fa fa-bar-chart me-2" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i></div>
                    <H6>Update Statistics</H6>
                </li>
            </NavLink> */}
        </ul>
    )
}

export default SideNav