import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

const H4 = ({ whiteThemeColor, darkThemeColor, children }) => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier.fontSize);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <>
            <h4 style={{ fontSize: `${20 * fontSizeMultiplier / 100}px`, color: `${darkWhiteThemeToggle === "WHITE" ? whiteThemeColor="#000" : darkThemeColor="#fff"}` }}>{children}</h4>
        </>
    )
}

export default H4