import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import { adminCommunication } from '../../communication/adminCommunication';
import H4 from '../../text-components/H4'
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const NotificationList = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageCount, setPageCount] = useState(10);
  const limit = process.env.REACT_APP_LIMIT



  //onclick set Current page
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage)
    if (searchValue) {
      fetchNotificationListBySearch(searchValue, selectedPage + 1);
    } else {
      fetchAllNotifications(selectedPage + 1);
    }
  };

  //filter data according to notification
  const searchFilter = async (searchString) => {
    if (searchString === "") {
      const serverResponse = await adminCommunication.getAllNotifications();
      if (serverResponse?.data?.status === "SUCCESS") {
        setNotifications(serverResponse?.data?.notifications);
        setPageCount(serverResponse?.data?.totalPages)
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "warning" });
        dispatch(hideLoader());
      } else {
        setNotifications([]);
        setPageCount(0);
        swal({ text: serverResponse?.data?.message, icon: "warning" });
      }
    } else {
      fetchNotificationListBySearch(searchString, currentPage + 1)
    }
  }

  //fetch all notifications on initial load
  const fetchAllNotifications = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await adminCommunication.getAllNotifications(page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setNotifications(responseFromServer?.data?.notifications);
        setPageCount(responseFromServer?.data?.totalPages);
        dispatch(hideLoader());
      } else if (responseFromServer.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        dispatch(hideLoader());
      } else {
        setNotifications([]);
        dispatch(hideLoader());
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
      dispatch(hideLoader());
    }
  };

  //get notification list by search
  const fetchNotificationListBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await adminCommunication.getNotificationListBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setNotifications(responseFromServer?.data?.notifications);
        setPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/admin/login");
      } else {
        setNotifications([]);
        setPageCount(0);
      }
    } catch (error) {
      swal({ text: error.message, icon: "warning" });
    }
  };

  useEffect(() => {
    fetchAllNotifications();
  }, []);


  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :

          <div className="tab_content_main">
            <div className='top_search_header'>
              <div className='title'>
                <H4 whiteThemeColor darkThemColor>Notification Details List</H4>
              </div>
              <div className="search">
                <input type="text" className="search-input" placeholder="Search .."
                  onChange={(e) => { searchFilter(e.target.value.trim()); setSearchValue(e.target.value.trim()) }} />
                <i className=" search-icon fa-solid fa-magnifying-glass"></i>

              </div>
              <div className='button_group add_button'>
                <NavLink to="/admin/dashboard/notification-list/add-notification">
                  <button><i className="fa fa-plus me-2"></i>Add New</button>
                </NavLink>
              </div>
              <div className="pagination_div">
                {
                  pageCount>1 &&
                  <div className="pagination_main">
                    <ReactPaginate
                      previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                      nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount} shape="rounded"
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      forcePage={currentPage}
                      activeClassName={"active"} />
                  </div>
                }
              </div>
            </div>

            <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
              <div className="row m-0">
                <div className="col-2 text_align">
                  <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                </div>
                <div className="col-3 text_align">
                  <H5 whiteThemeColor darkThemeColor>Subject</H5>
                </div>
                <div className="col-1 text_align">
                  <H5 whiteThemeColor darkThemeColor>Type</H5>
                </div>
                <div className="col-2 text_align">
                  <H5 whiteThemeColor darkThemeColor>Form Type</H5>
                </div>
                <div className="col-1 text_align">
                  <H5 whiteThemeColor darkThemeColor>Portal</H5>
                </div>
                <div className="col-2 text_align">
                  <H5 whiteThemeColor darkThemeColor>Creation Date</H5>
                </div>
                <div className="col-1 align">
                  <H5 whiteThemeColor darkThemeColor>Action</H5>
                </div>
              </div>
            </div>
            {notifications.length > 0 ? (
              <>
                {notifications?.map((notificationDetail, index) => (
                  <div className="table_data" key={index}>
                    <div className="row m-0">
                      <div className="col-2 text_align">
                        <H6 whiteThemeColor darkThemeColor>{limit * currentPage + (index + 1)}</H6>
                      </div>
                      <div className="col-3 text_align">
                        <H6 whiteThemeColor darkThemeColor>{notificationDetail?.subject}</H6>
                      </div>
                      <div className="col-1 text_align">
                        <H6 whiteThemeColor darkThemeColor>{[undefined, null, ""].includes(notificationDetail?.notificationType) ? "-" : notificationDetail?.notificationType}</H6>
                      </div>
                      <div className="col-2 text_align">
                        <H6 whiteThemeColor darkThemeColor>{[undefined, null, ""].includes(notificationDetail?.formType) ? "-" : notificationDetail?.formType}</H6>
                      </div>
                      <div className="col-1 text_align" >
                        <H6 whiteThemeColor darkThemeColor  >{notificationDetail?.portal}</H6>
                      </div>
                      <div className="col-2 text_align">
                        <H6 whiteThemeColor darkThemeColor>{new Date(notificationDetail?.createdDate).toLocaleDateString()}</H6>
                      </div>
                      <div className="col-1 align p-0 action">
                        <NavLink to={`/admin/dashboard/notification-list/update-notification?notificationId=${notificationDetail?._id}`}>
                          <i style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "rgb(173, 83, 137)" }} className="fa-solid fa-pen-to-square" title="Update"></i>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="data_not_available_message">
                <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Notification Not Available</span>
              </div>
            )}
          </div>
      }
    </>
  )
}

export default NotificationList