import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import H4 from '../../text-components/H4'
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import { adminCommunication } from '../../communication/adminCommunication';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const DesignationList = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const [designations, setDesignations] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const limit = process.env.REACT_APP_LIMIT
  const [filterDesignation, setFilterDesignation] = useState([]);


  //Onclick set current page
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
      fetchDesignationListBySearch(searchValue, selectedPage + 1);
    } else {
      fetchAllDesignations(selectedPage + 1);
    }
  }

  //filter data according to designation
  const searchFilter = async (searchString) => {
    if (searchString === "") {
      const serverResponse = await adminCommunication.getAllDesignations();
      if (serverResponse?.data?.status === "SUCCESS") {
        setDesignations(serverResponse?.data?.designation);
        setPageCount(serverResponse?.data?.totalPages);
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "warning" });
      } else {
        setDesignations([]);
        setPageCount(0);
        swal({ text: serverResponse?.data?.message, icon: "warning" });
      }
    } else {
      fetchDesignationListBySearch(searchString, currentPage + 1);
    }
  };

  //get designation list by search
  const fetchDesignationListBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await adminCommunication.getDesignationListBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setDesignations(responseFromServer?.data?.designation);
        setPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/admin/login");
      } else {
        setDesignations([]);
        setPageCount(0);
      }
    } catch (error) {
      swal({ text: error.message, icon: "warning" });
    }
  };

  //fetch all designations on initial load
  const fetchAllDesignations = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await adminCommunication.getAllDesignations(page);
      if (responseFromServer.data.status === "SUCCESS") {
        dispatch(hideLoader());
        setDesignations(responseFromServer?.data?.designation);
        setFilterDesignation(responseFromServer?.data?.designation);
        setPageCount(responseFromServer?.data?.totalPages)
      } else if (responseFromServer.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        dispatch(hideLoader());
      } else {
        setDesignations([]);
        setFilterDesignation([]);
        dispatch(hideLoader());
      }

    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error?.message, icon: "error" });
    }

  }



  useEffect(() => {
    fetchAllDesignations();
  }, []);

  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="tab_content_main">
            <div className='top_search_header'>
              <div className='title'>
                <H4 whiteThemeColor darkThemColor>Designation Details List</H4>
              </div>
              <div className='button_group add_button'>
                <NavLink to="/admin/dashboard/designation-list/add-designation">
                  <button><i className="fa fa-plus"></i>Add New</button>
                </NavLink>
              </div>
              <div className="search">
                <input type="text" className="search-input" placeholder="Search .."
                  onChange={(e) => { searchFilter(e.target.value); setSearchValue(e.target.value) }} />
                <i className=" search-icon fa-solid fa-magnifying-glass"></i>

              </div>

              <div className="">
                {
                  pageCount>1 &&
                  <div className="pagination_main">
                    <ReactPaginate
                      previousLabel={currentPage === 0 ? "First" : "Prev"}
                      nextLabel={(currentPage + 1) === pageCount ? "Last" : "Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      forcePage={currentPage}
                      activeClassName={"active"} />
                  </div>
                }
              </div>

            </div>
            { /* <div className="row m-0 mb-3">
              <div className="col-12 button_group">
                
              </div>
      </div>*/}

            <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
              <div className="row m-0">
                <div className="col-2 text_align">
                  <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                </div>
                <div className="col-6 text_align">
                  <H5 whiteThemeColor darkThemeColor>Designation</H5>
                </div>
                <div className="col-3 text_align">
                  <H5 whiteThemeColor darkThemeColor>Creation Date</H5>
                </div>
                <div className="col-1 align">
                  <H5 whiteThemeColor darkThemeColor>Action</H5>
                </div>
              </div>
            </div>
            {designations.length > 0 ? (
              <>
                {designations?.map((userDesignation, index) => (
                  <div className="table_data" key={index}>
                    <div className="row m-0">
                      <div className="col-2 text_align">
                        <H6 whiteThemeColor darkThemeColor>{limit * currentPage + (index + 1)}</H6>
                      </div>
                      <div className="col-6 text_align">
                        <H6 whiteThemeColor darkThemeColor>{userDesignation?.designation}</H6>
                      </div>
                      <div className="col-3 text_align">
                        <H6 whiteThemeColor darkThemeColor>{new Date(userDesignation?.creationDate).toLocaleDateString()}</H6>
                      </div>
                      <div className="col-1 align p-0 action">
                        <NavLink to={`/admin/dashboard/designation-list/update-designation?designationId=${userDesignation?._id}`}>
                          <i style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "rgb(173, 83, 137)" }} className="fa-solid fa-pen-to-square" title="Update"></i>
                        </NavLink>
                      </div>
                    </div>

                  </div>
                ))}
              </>
            ) : (
              <div className="data_not_available_message">
                <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Designation Not Available</span>
              </div>
            )}
          </div>
      }
    </>
  )
}

export default DesignationList