import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";
import H5 from '../../text-components/H5';
import useValidator from '../../form-validation/useValidatior';
import { adminCommunication } from '../../communication/adminCommunication';
import { useNavigate } from 'react-router-dom';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';


const AddData = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const [validator, showValidationMessage] = useValidator();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [documentDetails, setDocumentDetails] = useState({
    portal: "USER",
    title: "",
    description: "",
    attachment: ""
  });

  const addDownloadDocument = async () => {
    try {
      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }
      dispatch(showLoader());
      //send data to backend
      const serverResponse = await adminCommunication.addDownloadDocument(documentDetails.portal, documentDetails.title, documentDetails.description, documentDetails?.attachment);
      if (serverResponse.data.status === "SUCCESS") {
        swal({ text: serverResponse?.data?.message, icon: "success" });
        dispatch(hideLoader());
        navigate("/admin/dashboard/upload-data-list");
      } else if (serverResponse.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "error" });
        dispatch(hideLoader());
      } else {
        swal({ text: serverResponse?.data?.message, icon: "error" });
        dispatch(hideLoader());
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
      dispatch(hideLoader());
    }
  }

  //set file
  const onFileSelect = (event) => {
    //convert file into fromData
    const formData = new FormData();
    formData.append('attachment', event.target.files[0], event.target.files[0].name);
    setDocumentDetails({ ...documentDetails, attachment: formData })
  }

  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :

          <div className="form_section">
            <div className="form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Add Data</H5>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 col-md-12">
                    <input type="text" name="portal" value="User" className="form-control" placeholder="Enter Designation" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 col-md-12">
                    <input type="text" name="title" className="form-control" placeholder="Enter Title" onChange={(event) => { setDocumentDetails({ ...documentDetails, title: event.target.value }) }} />
                    {validator.message("Title", documentDetails.title, "required|alpha_space", {
                      messages: {
                        required: "Title is required"
                      }
                    })}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 col-md-12">
                    <textarea name="description" className="form-control" placeholder="Enter Description" onChange={(event) => { setDocumentDetails({ ...documentDetails, description: event.target.value }) }} ></textarea>
                    {validator.message("Description", documentDetails.description, "required|alpha_num_dash_space", {
                      messages: {
                        required: "Description is required"
                      }
                    })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <input type="file" name="file" className="form-control" onChange={(event) => { onFileSelect(event) }} />
                    {validator.message("file", documentDetails.attachment, "required", {
                      messages: {
                        required: "file is required"
                      }
                    })}
                    <span className="document_attachement_note" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "rgb(173, 83, 137)", marginTop: "4px" }}>*Only pdf is allowed</span>
                  </div>
                </div>
                <div className="button_group mt-3">
                  <button onClick={() => addDownloadDocument()} >Add</button>
                  <NavLink to="/admin/dashboard/upload-data-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default AddData