import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import { userCommunication } from '../../communication/userCommunication';
import useValidator from '../../form-validation/useValidatior';
import swal from 'sweetalert';

const BackOfficeChatbot = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [validator, showValidationMessage] = useValidator();
    const lastElementOfChat = useRef();
    const [chatMessages, setChatMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [title, setTitle] = useState("");

    //getting ticket id from query params
    const params = new URLSearchParams(useLocation().search);
    const ticketId = params.get("ticketId");

    //Scroll to last chat
    const scrollToLastChild = () => {
        lastElementOfChat?.current?.lastChild?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    //Fetch Grievance chat
    const fetchGrievanceChat = async () => {
        try {
            const responseFromServer = await backOfficeCommunication.getGrievanceChatByTicketId(ticketId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setChatMessages(responseFromServer?.data?.chat);
                setTitle(responseFromServer?.data?.title);
                scrollToLastChild();
            }
        } catch (error) {
            swal({text: error.message, icon: "error"});
        }
    }

    //send message
    const sendMessage = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            //send values to the server
            let isFileAttached = false;
            let formData = new FormData();
            if (fileUrl) {
                formData.append("grievanceFile", fileUrl);
                isFileAttached = true;
            }

            const serverResponse = await backOfficeCommunication.replyGrievance(ticketId, isFileAttached, message, formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                swal({text: serverResponse?.data?.message, icon: "success"});
                fetchGrievanceChat();
                scrollToLastChild();
                window.location.reload(false);
            } else {
                swal({text: serverResponse?.data?.message, icon: "error"});
            }
        } catch (error) {
            swal({text: error.message, icon: "error"});
            
        }
    }

    //Preview uploaded document
    const previewUploadedDocument = async (fileUrl) => {
        if (fileUrl) {
            await userCommunication.getGrievanceDocument(fileUrl);
        }
    }

    useEffect(() => {
        fetchGrievanceChat();
    }, []);


    return (
        <div className="container p-1">
            <div className="chatbot_main" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
                <div className="chatbot_header">
                    <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{title}</span>
                    <NavLink to="/back-office/grievance">
                        <i className="fa-solid fa-arrow-left-long chatbot_back_btn" title="Go back" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}></i>
                    </NavLink>
                </div>
                <div className="message_section" ref={lastElementOfChat}>
                    {chatMessages?.map((chat, index) => {
                        const splitUrl = chat?.fileUrl.split(".");
                        let extension = "";
                        if (chat?.fileUrl) {
                            extension = splitUrl[splitUrl?.length - 1];
                        }
                        return (
                            <>
                                {chat?.createdBy === "USER" &&
                                    <>
                                        {chat?.fileUrl === "" ?
                                            <div className="row m-0 user_message" key={index}>
                                                <div className="col-12">
                                                    <div className="message_box_main" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                                                        <div className="message">
                                                            <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{chat?.message}</span>
                                                        </div>
                                                        <div className="detail">
                                                            <div className="reply_by" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{chat?.userId}</div>
                                                            <div className="date" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(chat?.date).toLocaleDateString()}</div>
                                                            <div className="time" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(chat?.date).toLocaleTimeString()}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="row m-0">
                                                <div className="col-12" style={{ display: "flex", justifyContent: "flex-start" }}>
                                                    <div className="attached_dacument_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                                                        <div className="attached_file">
                                                            {extension === "pdf" ?
                                                                < i className="fa fa-file-pdf" onClick={() => previewUploadedDocument(chat?.fileUrl)}></i>
                                                                :
                                                                <i className="fa fa-picture-o" style={{ color: "green" }} onClick={() => previewUploadedDocument(chat?.fileUrl)}></i>
                                                            }
                                                        </div>
                                                        <div className="message">
                                                            <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{chat?.message}</span>
                                                        </div>
                                                        <div className="detail">
                                                            <div className="reply_by" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{chat?.userId}</div>
                                                            <div className="date" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(chat?.date).toLocaleDateString()}</div>
                                                            <div className="time" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(chat?.date).toLocaleTimeString()}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                {chat?.createdBy === "BACKOFFICE" &&
                                    <>
                                        {chat?.fileUrl === "" ?
                                            <div className="row m-0 back_office_message" key={index}>
                                                <div className="col-12" style={{ display: "flex", justifyContent: "end" }}>
                                                    <div className="message_box_main" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                                                        <div className="message">
                                                            <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{chat?.message}</span>
                                                        </div>
                                                        <div className="detail">
                                                            <div className="reply_by" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{chat?.name}</div>
                                                            <div className="date" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(chat?.date).toLocaleDateString()}</div>
                                                            <div className="time" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(chat?.date).toLocaleTimeString()}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="row m-0">
                                                <div className="col-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <div className="attached_dacument_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                                                        <div className="attached_file">
                                                            {extension === "pdf" ?
                                                                < i className="fa fa-file-pdf" onClick={() => previewUploadedDocument(chat?.fileUrl)}></i>
                                                                :
                                                                <i className="fa fa-picture-o" style={{ color: "green" }} onClick={() => previewUploadedDocument(chat?.fileUrl)}></i>
                                                            }
                                                        </div>
                                                        <div className="message">
                                                            <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{chat?.message}</span>
                                                        </div>
                                                        <div className="detail">
                                                            <div className="reply_by" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{chat?.name}</div>
                                                            <div className="date" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(chat?.date).toLocaleDateString()}</div>
                                                            <div className="time" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(chat?.date).toLocaleTimeString()}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                            </>
                        );
                    })
                    }
                </div>
                <div className="reply_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                    <div className="input-group grievance_attach_file">
                        <input type="file" id="file" name="file" onChange={(event) => setFileUrl(event.target.files[0])} style={{ display: "none" }} />
                        <label for="file"><i className="fa-solid fa-paperclip" title="Click to attach file" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "skyblue" }}></i></label>
                    </div>
                    <div className="type_message">
                        {validator.message("message", message, "required", {
                            messages: {
                                required: "Message is required"
                            }
                        })}
                        <span className="mb-1" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "skyblue" }}>{fileUrl?.name}</span>
                        <input type="text" name="message" onChange={(event) => setMessage(event.target.value)} className="form-control" placeholder="Type a message..." />
                    </div>
                    <div className="send_btn">
                        <i className="fa-solid fa-paper-plane" title="Send message" onClick={() => sendMessage()} style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "skyblue" }}></i>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BackOfficeChatbot