import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import { userCommunication } from '../../communication/userCommunication';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { removeUnderscoreFromText } from '../../shared-components/removeUnderScore';
import swal from 'sweetalert';

const ViewReviews = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const backOfficeUser = useSelector((state) => state.backOfficeUserDetails);
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const [reviewList, setReviewList] = useState([]);

    //get id, userId and applicationId from params
    const params = new URLSearchParams(useLocation().search);
    const id = params.get("id")
    const applicationId = params.get("applicationId")
    const userId = params.get("userId")
    const currentForm = params.get("currentForm")

    //fetch all reviews on initial load
    const fetchAllReview = async () => {
        try {

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.getAllReview(id, applicationId, userId, currentForm);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setReviewList(responseFromServer?.data?.review);
            } else {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    //Preview uploaded document
    const previewUploadedDocument = async (fileUrl) => {
        if (fileUrl) {
            await userCommunication.getUserDocument(fileUrl);
        }
    }

    useEffect(() => {
        fetchAllReview();
    }, []);


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <CommonLoader />
                    :
                    <div className="view_review_section">
                        <div className="back_button">
                            <NavLink to="/back-office/final-selected-applications">
                                <i className="fa-solid fa-arrow-left-long" title="Go Back" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}></i>
                            </NavLink>
                        </div>
                        <div className="row table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                            <div className="col-1 text_align">
                                <H5>Sr. No</H5>
                            </div>
                            <div className="col-2 text_align">
                                <H5>Title</H5>
                            </div>
                            <div className="col-3 text_align">
                                <H5>Installment</H5>
                            </div>
                            <div className="col-3 text_align">
                                <H5>Comment</H5>
                            </div>
                            <div className="col-2 text_align">
                                <H5>Status</H5>
                            </div>
                            <div className="col-1 align">
                                <H5>Preview</H5>
                            </div>
                        </div>
                        {reviewList?.length > 0 ?
                            <>
                                {reviewList?.map((review, index) => (
                                    <div className="table_data_main" key={index}>
                                        <div className="row table_data">
                                            <div className="col-1 text_align">
                                                <H6>{index + 1}</H6>
                                            </div>
                                            <div className="col-2 text_align">
                                                <H6>{review?.reviewName}</H6>
                                            </div>
                                            <div className="col-3 text_align" style={{ textTransform: "lowercase" }}>
                                                <H6>{removeUnderscoreFromText(review?.currentForm)}</H6>
                                            </div>
                                            <div className="col-3 text_align">
                                                <H6>{[undefined, null, ""].includes(review?.comment) ? "-" : review?.comment}</H6>
                                            </div>
                                            <div className="col-2 text_align">
                                                <H6>{removeUnderscoreFromText(review?.status)}</H6>
                                            </div>
                                            <div className="col-1 align">
                                                <i className="fa-solid fa-file-pdf" style={{ color: (darkWhiteThemeToggle === "WHITE") ? "red" : "rgb(173, 83, 137)", cursor: "pointer" }} onClick={() => previewUploadedDocument(review?.reviewDocument)} title="Review File"></i>
                                            </div>
                                        </div>
                                        {!["Division", "District"].includes(backOfficeUser.userType) &&
                                            <div className="review_button">
                                                {review?.status === "Under_Review" &&
                                                    <NavLink to={`/back-office/final-selected-applications/update-review?reviewId=${review?._id}&userId=${review?.userId}`}>
                                                        <button>Approve</button>
                                                    </NavLink>
                                                }
                                            </div>
                                        }
                                    </div>
                                ))}
                            </>
                            :
                            <div className="data_not_available_message">
                                <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Review Not Available</span>
                            </div>
                        }
                    </div>
            }
        </>
    )
}

export default ViewReviews