import React from 'react'

const CommonLoader = () => {
    return (
        <div className="common_loader_main">
            <div className="loader"></div>
        </div>
    )
}

export default CommonLoader