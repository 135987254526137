import React, { useEffect, useState } from "react";
import './PublicCSS.css';
import { publicCommunication } from "../communication/public-communication";
import { useSelector } from "react-redux";
import H6 from "../text-components/H6";
import H4 from "../text-components/H4";
import H5 from "../text-components/H5";
import swal from "sweetalert";

const Notification = () => {
  const [notificationData, setNotificationData] = useState([]);
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  //get notification on initial load
  const getAllNotifications = async () => {
    try {
      const serverResponse = await publicCommunication.getPublicNotification();
      if (serverResponse.data.status === "SUCCESS") {
        setNotificationData(serverResponse.data.notifications);
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }

  //DOWNLOAD NOTIFICATION
  const downloadNotification = async (fileUrl) => {
    try {
      await publicCommunication.getDocument(fileUrl)
    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }

  useEffect(() => {
    getAllNotifications();
  }, [])

  return (
    <div className="public_notification_main" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
      <div className="container pt-2">
        <div className="row text-center">
          <div className=" col-lg-6 col-md-6 mt-2  note_scroll">
          
            <H4 className="notification">Notification</H4>
            <div className="">
            <div className="table_heading mt-2" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
              <div className="row p-1">
                <div className="col-3 text_align">
                  <H5>Serial Number</H5>
                </div>
                <div className="col-6 text_align">
                  <H5>Title</H5>
                </div>
                <div className="col-3 align">
                  <H5>Downloads</H5>
                </div>
              </div>
            </div>
            {notificationData.length > 0 ?
              <>
                {notificationData.map((notification, index) => {
                  return (
                    <div className="table_data">
                      <div className="row">
                        <div className="col-3 text_align">
                          <H6 whiteThemeColor darkThemeColor>{index + 1}</H6>
                        </div>
                        <div className="col-6 text_align">
                          <H6 whiteThemeColor darkThemeColor>{notification.subject}</H6>
                        </div>
                        <div className="col-3 align">
                          <i className="fa-solid fa-download note_pointer" onClick={() => downloadNotification(notification.attachment)} style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "rgb(173, 83, 137)" }} ></i>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
              :
              <div className="mt-5">
                <H6>Notification Not Available</H6>
              </div>
            }
            </div>
          </div>
          <div className="col-lg-6 col-md-6  mt-2">
            <H4 whiteThemeColor darkThemeColor className="gallery font_class">Our Gallery</H4>
            <div className="our_gallery mq_our_gallery">
            <div className="row m-2">
              <div className="col-3 gallery_grid ms-3">
                <img src="/images/gallery_1.png" />
              </div>
              <div className="col-3 gallery_grid ms-3 ">
                <img src="/images/gallery_2.png" />
              </div>
              <div className="col-3 gallery_grid ms-3">
                <img src="/images/gallery_5.png" />
              </div>
              <div className="col-3 gallery_grid ms-3">
                <img src="/images/gallery_5.png" />
              </div>
            </div>
            <div className="row m-2">
              <div className="col-3 gallery_grid ms-3">
                <img src="/images/gallery_5.png" />
              </div>
              <div className="col-3 gallery_grid ms-3 ">
                <img src="/images/gallery_6.png" />
              </div>
              <div className="col-3 gallery_grid ms-3">
                <img src="/images/gallery_5.png" />
              </div>
              <div className="col-3 gallery_grid ms-3">
                <img src="/images/gallery_8.png" />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
