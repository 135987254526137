import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import useValidator from '../../form-validation/useValidatior';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import swal from 'sweetalert';

const CentralAcknowledgementForm = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const backOfficeUser = useSelector((state) => state.backOfficeUserDetails)
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [validator, showValidationMessage] = useValidator();
  const [centralAcknowledgedCertificate, setCentralAcknowledgedCertificate] = useState("");

  //get id from params
  const params = new URLSearchParams(useLocation().search);
  const id = params.get("id");
  const userId = params.get("userId");

  //Upload central acknowledgment
  const uploadCentralAcknowledment = async () => {

    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const formData = new FormData();
      formData.append("uploadCentralAcknowledgementCertificate", centralAcknowledgedCertificate);
      dispatch(showLoader());
      const serverResponse = await backOfficeCommunication.uploadCentralAcknowledgementCertificate(formData, userId, id);
      if (serverResponse?.data.status === "SUCCESS") {
        dispatch(hideLoader());
        swal({text: serverResponse?.data?.message, icon: "success"});
        navigation("/back-office/state-selected-applications");
      } else {
        dispatch(hideLoader());
        swal({text: serverResponse?.data?.message, icon: "error"});
      }

    } catch (error) {
      dispatch(hideLoader());
      swal({text: error.message, icon: "error"});
    }

  }

  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="acknowledgement_form_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
            <div className="acknowledgement_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div className="form_title" style={{ textAlign: "left" }}>
                <H5 whiteThemeColor darkThemeColor>Upload Central Acknowledgement</H5>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-12">
                  <H6 whiteThemeColor darkThemeColor>Attach File</H6>
                  <input type="file" name="centralAcknowledgedCertificate" onChange={(event) => setCentralAcknowledgedCertificate(event.target.files[0])} className="form-control mt-1" />
                  {validator.message("centralAcknowledgedCertificate", centralAcknowledgedCertificate, "required", {
                    messages: {
                      required: "File is required"
                    }
                  })}
                  <span className="document_attachement_note" style={{ marginTop: "4px" }}>*Only pdf is allowed</span>
                </div>
              </div>
              <div className="button_group">
                <button className="me-3" onClick={() => uploadCentralAcknowledment()}>Upload</button>
                <button onClick={() => navigation(["Division"].includes(backOfficeUser.userType) ? `/back-office/division-selected-applications` :
                  ["District"].includes(backOfficeUser.userType) ? `/back-office/district-selected-applications` : `/back-office/state-selected-applications`)}>Cancel</button>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default CentralAcknowledgementForm