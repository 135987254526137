import React from 'react'
import { NavLink } from 'react-router-dom';

const UserNavBar = ({ counts, getAlertCounts, setShowLoginBox }) => {

    return (
        <ul className="user_navbar" onClick={() => setShowLoginBox(false)}>
            <NavLink to="/user/latest-notification" >
                <li onClick={() => { getAlertCounts() }}><span>Latest Notification</span></li>
            </NavLink>
            <NavLink to="/user/my-application">
                <li onClick={() => { getAlertCounts() }}><span>My Application</span></li>
            </NavLink>
            <NavLink to="/user/alerts">
                <li onClick={() => { getAlertCounts() }}><span>Alert</span>
                    {counts ?
                        <div className="badge"><span>{counts}</span></div>
                        :
                        <></>
                    }

                </li>
            </NavLink>
            <NavLink to="/user/remittance" >
                <li onClick={() => { getAlertCounts() }}><span>Remittance</span></li>
            </NavLink>
            <NavLink to="/user/grievance">
                <li onClick={() => { getAlertCounts() }}><span>Grievance</span></li>
            </NavLink>
            <NavLink to="/user/downloads">
                <li onClick={() => { getAlertCounts() }}><span>Downloads</span></li>
            </NavLink>
        </ul>
    )
}

export default UserNavBar