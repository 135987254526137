import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { userCommunication } from '../communication/userCommunication';
import swal from 'sweetalert';

const PrintRepairAndMaintenance = () => {
    const componentRef = useRef();
    const [repairAndMaintenanceData, setRepairMaintenanceData] = useState({});
    //get notification id from params
    const params = new URLSearchParams(useLocation().search);
    const id = params.get("id");


    //Get user Application Detail by id on initial load
    const fetchUserApplicationById = async () => {
        try {
            const responseFromServer = await userCommunication.getUserApplicationById(id);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setRepairMaintenanceData(responseFromServer?.data?.applications);
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchUserApplicationById();
    }, []);


    return (
        <div className="print_table_main">
            <div className="print_table_section" ref={componentRef}>
                {/* <div className="file_number">
                    <h5><b>File No. 11024/1/2016-SCD-I-Vikyne(3)</b></h5>
                </div>
                <div className="annexure_no">
                    <h5>ANNEXURE - III</h5>
                </div> */}
                <div className="annexure_header">
                    <h5>Format for seeking 'Repair and Maintenance cost' under the Scheme of Babu jagjivan Ram Chhatrawas Yojna
                        (BJRCY)</h5>
                </div>

                <div>
                    <table className="annexure_table">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Item/Particulars</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={2} style={{ textAlign: "center" }}>1</td>
                                <td>Whether the hostel has been constructed under the Scheme, BJRCY of Ministry of Social
                                    Justice & Empowerment? P1. also mention date of sanction of the hostel by the Ministry of
                                    SJ&E</td>
                                <td>{repairAndMaintenanceData?.isHostelConstructedByBJRCY}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "10px" }}>Date of Sanction</td>
                                <td>{(repairAndMaintenanceData?.sanctionDate === "") ? "-" : repairAndMaintenanceData?.sanctionDate}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>2</td>
                                <td>Category(girl or boys) & location of the hostel<br />(Please give detailed address)</td>
                                <td>{repairAndMaintenanceData?.category}</td>
                            </tr>

                            <tr>

                                <td style={{ textAlign: "center" }}>3</td>
                                <td>Total number of inmates/seats <b>approved</b> by the Ministry of SJ&E while sanctioning the
                                    hostel<br />(Please indicate the number)</td>
                                <td>{repairAndMaintenanceData?.totalNoOfInmates}</td>
                            </tr>

                            <tr>

                                <td style={{ textAlign: "center" }} rowSpan="4">4</td>
                                <td rowSpan="4">Total number of students admitted/residing in the hostel at present, including
                                    those
                                    belonging
                                    to other categories<br />(Please indicate the number and attach list of students with contact
                                    number)</td>
                                <td>SC : {repairAndMaintenanceData?.admittedInmatesDetails?.SC}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                                    Others : {repairAndMaintenanceData?.admittedInmatesDetails?.other}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "10px" }}>Total : {repairAndMaintenanceData?.admittedInmatesDetails?.total}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                                    Percentage of SCs : {repairAndMaintenanceData?.admittedInmatesDetails?.percentageOfSC}
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }} rowSpan="11">5</td>
                                <td colSpan="2">Whether , the State Government/ UT Administration / University /Institution have
                                    provided
                                    following facilities<br />(Please specify clearly for a unit of 100 children)</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(a) Appointment/availability of a Warden (in case of girls' hostel)</td>
                                <td>{repairAndMaintenanceData?.facilities?.appointmentOfWarder}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(b) Availability of Chowkidars/Watchmen (in case of girls' hostel)</td>
                                <td>{repairAndMaintenanceData?.facilities?.availabilityOfWatchmen}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(c) In-house coaching</td>
                                <td>{repairAndMaintenanceData?.facilities?.coaching}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(d) Medical care</td>
                                <td>{repairAndMaintenanceData?.facilities?.medicalCare}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(e)Appointment of cook, helpers,etc</td>
                                <td>{repairAndMaintenanceData?.facilities?.appointmentOfCook}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(f) Games & Sports and recreational facilities</td>
                                <td>{repairAndMaintenanceData?.facilities?.gameAndSport}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(g) Drinking Water</td>
                                <td>{repairAndMaintenanceData?.facilities?.drinkingWater}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(h) Bathing & Washing</td>
                                <td>{repairAndMaintenanceData?.facilities?.bathingAndWashing}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(i) Sanitation & Cleaning</td>
                                <td>{repairAndMaintenanceData?.facilities?.cleaning}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "6px" }}>(j) Others</td>
                                <td>{(repairAndMaintenanceData?.facilities?.other === "") ? "-" : repairAndMaintenanceData?.facilities?.other}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>6</td>
                                <td>Estimated cost of 'Repair and Maintenance' of hostel(s)<br />(Please attach detailed cost
                                    estimate determined by PWD/PAR)</td>
                                <td>{repairAndMaintenanceData?.estimatedCost}</td>
                            </tr>

                            <tr>
                                <td rowSpan={2} style={{ textAlign: "center" }}>7</td>
                                <td>Whether the implementing agency is willing to bear any additional cost incurred on repair
                                    and maintenance of the hostel?</td>
                                <td>{repairAndMaintenanceData?.isAgencyAgreeToBearCost}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "10px" }}>Bear Cost Details</td>
                                <td>{(repairAndMaintenanceData?.bearCostDetails === "") ? "-" : repairAndMaintenanceData?.bearCostDetails}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>8</td>
                                <td>Name of Implementing Agency</td>
                                <td>{repairAndMaintenanceData?.agency}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>9</td>
                                <td>Remark</td>
                                <td>{(repairAndMaintenanceData?.remark === "") ? "-" : repairAndMaintenanceData?.remark}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="row mt-4">
                    <div className="col-6 mt-5">
                        <h6 className="mb-3">Place:.........................</h6>
                        <h6>Date:..........................</h6>
                    </div>
                    <div className="col-6 Authorization_detail">
                        <h6>Signature of the Authorized Representative of the Implementing Agency</h6>
                        <div className="mt-3" style={{ marginLeft: "50%", textAlign: "left" }}>
                            <h6>Name:..................................</h6>
                            <h6 className="mt-3 mb-3">Designation:...........................</h6>
                            <h6>Full address:..........................</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="print_icon">
                <ReactToPrint
                    trigger={() => <i className="fa fa-print" aria-hidden="true" title="Print Document"></i>}
                    content={() => componentRef.current}
                />
            </div>
        </div>
    )
}

export default PrintRepairAndMaintenance;