import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import H5 from "../../text-components/H5";
import useValidator from '../../form-validation/useValidatior';
import { adminCommunication } from '../../communication/adminCommunication';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import divisionArray from '../../shared-components/divisionArray';
import swal from 'sweetalert';

const AddUser = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const [validator, showValidationMessage] = useValidator();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [district, setDistrict] = useState([]);
  const [userDetail, setUserDetail] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    alternateNumber: "",
    password: "",
    confirmPassword: "",
    department: "",
    designation: "",
    zone: "",
    division: "",
    district: ""
  })

  //Get all inputs value
  const getUserDetails = (event) => {
    if (event.department) {
      setUserDetail({ ...userDetail, "department": event });
    } else if (event.designation) {
      setUserDetail({ ...userDetail, "designation": event });
    } else {
      setUserDetail({ ...userDetail, [event.target.name]: event.target.value });
    }
  }

  //fetch all departments on initial load
  const fetchAllDepartments = async () => {
    try {
      const responseFromServer = await adminCommunication.getAllDepartments();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setDepartments(responseFromServer?.data?.department);
      } else {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }

  //Department wise designations
  const departmentWiseDesignations = async (department) => {
    try {
      const getDesignations = await adminCommunication.getDepartmentWiseDesignation(department?._id);
      if (getDesignations?.data?.status === "SUCCESS") {
        setDesignations(getDesignations?.data?.designation)
      } else {
        swal({ text: getDesignations?.data?.message, icon: "error" });
      }

    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }

  //get division wise districts
  const divisionWiseDistricts = (event) => {
    for (let i = 0; i < divisionArray.length; i++) {
      if (divisionArray[i].division === event.target.value) {
        setDistrict(divisionArray[i].district);
      }
    }
  }

  useEffect(() => {
    fetchAllDepartments();
  }, []);

  //Add user
  const addUser = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const dataToSend = {
        fullName: userDetail.fullName,
        email: userDetail.email,
        mobileNo: Number(userDetail.mobileNumber),
        alternateMobileNo: Number(userDetail.alternateNumber),
        password: userDetail.password,
        department: userDetail.department,
        designation: userDetail.designation,
        zone: userDetail.zone,
        division: userDetail.division,
        district: userDetail.district
      }

      dispatch(showLoader());
      const serverResponse = await adminCommunication.addUser(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        navigation("/admin/dashboard/user-list");
        swal({ text: serverResponse?.data?.message, icon: "success" });
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="form_section">
            <div className="add_user_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Add User</H5>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <input type="text" name="fullName" onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Enter Full Name" />
                    {validator.message("fullName", userDetail.fullName, "required|alpha_space", {
                      messages: {
                        required: "Full Name is required"
                      }
                    })}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <input type="email" name="email" onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Enter Email" />
                    {validator.message("email", userDetail.email, "required|email", {
                      messages: {
                        required: "Email is required"
                      }
                    })}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <input type="number" name="mobileNumber" onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Enter Mobile Number" />
                    {validator.message("mobileNumber", userDetail.mobileNumber, "required|numeric|min:10|max:10", {
                      messages: {
                        required: "Mobile Number is required"
                      }
                    })}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <input type="number" name="alternateNumber" onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Enter Alternate Number" />
                    {validator.message("alternateNumber", userDetail.alternateNumber, "numeric|min:10|max:10", {
                      messages: {
                        required: "Alternate Number is required"
                      }
                    })}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <input type="password" name="password" onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Enter Password" />
                    {validator.message("password", userDetail.password, "required|min:8", {
                      messages: {
                        required: "Password is required"
                      }
                    })}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <input type="password" name="confirmPassword" onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Confirm Password" />
                    {validator.message("confirmPassword", userDetail.confirmPassword, `required|in:${userDetail.password}`, {
                      messages: {
                        required: "Confirm Password is required",
                        in: "Password need to match"
                      }
                    })}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <select name="department" onChange={(event) => { getUserDetails(JSON.parse(event.target.value)); departmentWiseDesignations(JSON.parse(event.target.value)) }} className="form-select">
                      <option value={JSON.stringify({})}>Select Department</option>
                      {departments?.map((departmentDetail, index) => (
                        <option value={JSON.stringify(departmentDetail)} key={index}>{departmentDetail?.department}</option>
                      ))}
                    </select>
                    {validator.message("department", userDetail.department, "required", {
                      messages: {
                        required: "Department is required"
                      }
                    })}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <select name="designation" onChange={(event) => getUserDetails(JSON.parse(event.target.value))} className="form-select">
                      <option value={JSON.stringify({})}>Select Designation</option>
                      {designations?.map((designationDetail, index) => (
                        <option value={JSON.stringify(designationDetail)} key={index}>{designationDetail?.designation}</option>
                      ))}
                    </select>
                    {validator.message("designation", userDetail.designation, "required", {
                      messages: {
                        required: "Designation is required"
                      }
                    })}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <select name="zone" onChange={(event) => getUserDetails(event)} className="form-select">
                      <option value="">Select Zone</option>
                      <option value="District">District</option>
                      <option value="Division">Division</option>
                      <option value="State">State</option>
                    </select>
                    {validator.message("zone", userDetail.zone, "required", {
                      messages: {
                        required: "Zone is required"
                      }
                    })}
                  </div>
                  {userDetail.zone === "State" ?
                    <span></span>
                    :
                    <div className="col-lg-6 col-md-6">
                      <select name="division" onChange={(event) => { getUserDetails(event); divisionWiseDistricts(event) }} className="form-select">
                        <option value="">Select Division</option>
                        {divisionArray?.map((divisionList, index) => (
                          <option key={index}>{divisionList?.division}</option>
                        ))}
                      </select>
                      {validator.message("division", userDetail.division, "required", {
                        messages: {
                          required: "Division is required"
                        }
                      })}
                    </div>
                  }
                </div>
                {userDetail.zone === "Division" || userDetail.zone === "State" ?
                  <span></span>
                  :
                  <div className="row mb-3">
                    <div className="col-lg-6 col-md-6">
                      <select name="district" onChange={(event) => getUserDetails(event)} className="form-select">
                        <option value="">Select District</option>
                        {district?.map((districtList, index) => (
                          <option key={index}>{districtList}</option>
                        ))}
                      </select>
                      {validator.message("district", userDetail.district, "required", {
                        messages: {
                          required: "District is required"
                        }
                      })}
                    </div>
                  </div>
                }
                <div className="button_group mt-3">
                  <button onClick={() => addUser()}>Add</button>
                  <NavLink to="/admin/dashboard/user-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default AddUser