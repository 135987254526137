import axios from 'axios';
import swal from 'sweetalert';

const developmentURL = "http://localhost:3012";
const contoboURL = "https://bjrcybackend.mattedemolitions.com";
const machineIp = "http://192.168.29.169:3012";


function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if ("bjrcyUserToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}

const getServerUrl = () => {
    const nodeEnvironment = process.env.REACT_APP_NODE_ENV;
    const serverUrl = process.env.REACT_APP_BACKEND_URL;


    if (nodeEnvironment === "development") {
        return developmentURL;
    }

    if (nodeEnvironment === "contobo_dev") {
        return contoboURL;
    }

    if (nodeEnvironment === "production") {
        return serverUrl;
    }

    if (nodeEnvironment === "machine_ip") {
        return machineIp;
    }

    return serverUrl;
}

export const backOfficeCommunication = {
    //?---------------------------------Login API-------------------------------------------------------------------------
    login: (dataToSend) => {
        return axios.post(`${getServerUrl()}/back-office/back-office-login`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    //?---------------------------------New Application API-------------------------------------------------------------------------
    getAllNewApplications: (page) => {
        return axios.get(`${getServerUrl()}/back-office/get-new-application?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllNewAppliocationsListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-new-application `, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },

    rejectApplication: (dataToSend) => {
        return axios.post(`${getServerUrl()}/back-office/reject-application`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    markIncompleteApplication: (dataToSend) => {
        return axios.post(`${getServerUrl()}/back-office/incomplete-application`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    markApproveApplication: (formData, dataToSend) => {
        return axios.post(`${getServerUrl()}/back-office/approve-application?applicationId=${dataToSend.applicationId}&id=${dataToSend.id}&comment=${dataToSend.comment}`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
                "userId": dataToSend.userId
            }
        });
    },
    //?---------------------------------New grievance API-------------------------------------------------------------------------
    getAllGrievance: (page) => {
        return axios.get(`${getServerUrl()}/back-office/get-grievance?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getGrievanceListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-back-office-grievance`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getGrievanceChatByTicketId: (ticketId) => {
        return axios.post(`${getServerUrl()}/back-office/get-grievance-chat-by-ticket-id`, { ticketId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    replyGrievance: (ticketId, isFileAttached, message, formData) => {
        return axios.post(`${getServerUrl()}/back-office/reply-grievance?ticketId=${ticketId}&isFileAttached=${isFileAttached}&message=${message}`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?---------------------------------State Selected API-------------------------------------------------------------------------
    getSelectedApplications: (page) => {
        return axios.get(`${getServerUrl()}/back-office/get-approved-applications-by-state?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    getStateSelectedApplicationsListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-final-selected-application`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },

    uploadCentralAcknowledgementCertificate: (formData, userId, id) => {
        return axios.post(`${getServerUrl()}/back-office/upload-central-acknowledgment-certificate`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
                "userId": userId,
                "applicationId": id
            }
        });
    },
    //?---------------------------------Final Selected Application API-------------------------------------------------------------------------
    getFinalSelectedApplications: (page) => {
        return axios.get(`${getServerUrl()}/back-office/get-final-selected-application?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    getFinalSelectedApplicationsListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-final-selected-application`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    addReview: (dataToSend, formData) => {
        return axios.post(`${getServerUrl()}/back-office/add-review-comment-to-application?applicationId=${dataToSend.applicationId}&userId=${dataToSend.userId}&reviewName=${dataToSend.reviewName}&comment=${dataToSend.comment}&reviewStatus=${dataToSend.reviewStatus}`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
                "userid": dataToSend.userId
            }
        });
    },
    markProjectAsComplete: (applicationId) => {
        return axios.post(`${getServerUrl()}/back-office/mark-project-as-complete`, { applicationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    getAllReview: (id, applicationId, userId, currentForm) => {
        return axios.post(`${getServerUrl()}/back-office/get-all-review-by-id`, { id, applicationId, userId, currentForm }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateReview: (reviewId, userId, formData) => {
        return axios.post(`${getServerUrl()}/back-office/upload-review-document`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
                "reviewid": reviewId,
                "userid": userId
            }
        });
    },
    //?---------------------------------Remittance API-------------------------------------------------------------------------
    getAllRemittance: (page) => {
        return axios.get(`${getServerUrl()}/back-office/get-backoffice-remittance?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getRemittanceListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-remittance`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    uploadRemittance: (dataToSend) => {
        return axios.post(`${getServerUrl()}/back-office/upload-remittance`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateRemittance: (dataToSend) => {
        return axios.post(`${getServerUrl()}/back-office/update-remittance`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getRemittanceById: (remittanceId) => {
        return axios.post(`${getServerUrl()}/back-office/get-backoffice-remittance-by-id`, { remittanceId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?---------------------------------Notification API-------------------------------------------------------------------------
    getNotifications: (page) => {
        return axios.get(`${getServerUrl()}/back-office/get-back-office-alerts?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getNotificationListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-back-office-alerts`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getNewNotificationsCounts: () => {
        return axios.get(`${getServerUrl()}/back-office/get-back-office-alerts-counts`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //? -------------------------------District Selected API---------------------------------------------
    getDistrictSelectedApplications: (page) => {
        return axios.get(`${getServerUrl()}/back-office/get-district-selected-application?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    getDistrictSelectedApplicationsListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-district-selected-application`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    //? -------------------------------Division Selected API---------------------------------------------
    getDivisionSelectedApplications: (page) => {
        return axios.get(`${getServerUrl()}/back-office/get-division-selected-application?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getDivisionSelectedApplicationsListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-division-selected-application`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },

    markAlertAsSeen: (alertId) => {
        return axios.post(`${getServerUrl()}/back-office/mark-alert-as-seen`, { alertId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
}