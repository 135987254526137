import React from "react";
import './PublicCSS.css';
import { useSelector } from "react-redux";
import H4 from "../text-components/H4";
import H6 from "../text-components/H6";
import H5 from "../text-components/H5";


const OtherSlider = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    return (
        <div style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c", borderBottom: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
            <div className="other_links">
                <div className="container">
                    <div className="row">
                    <div className="success_stories">
                  
                        <div className="col-md-12 pt-3">
                            <div id="storySlider" className="carousel slide" data-bs-ride="carousel">
                                <H4>Our</H4>
                                <H4>Success Stories</H4>

                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#storySlider" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}></button>
                                    <button type="button" data-bs-target="#storySlider" data-bs-slide-to="1" aria-label="Slide 2" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}></button>
                                    <button type="button" data-bs-target="#storySlider" data-bs-slide-to="2" aria-label="Slide 3" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}></button>
                                </div>

                                <div className="carousel-inner mt-3">
                                    <div className="item carousel-item active">
                                        <div className="img-box">
                                            <img src="/images/other_link_img1.png" /></div>
                                        <H6 className="testimonial">उत्तर प्रदेश में बाल स्वास्थ्य एवं पोषण की जो स्थिति है उसे सुधारने के लिये यह जरूरी है कि सम्बन्धित सेवाओं में जन समुदाय की भागीदारी बढ़ायी जाये और विभिन्न विभागों जैसे स्वास्थ्य विभाग, आई.सी.डी.एस., पंचायतीराज संस्थाओं
                                            और ग्राम्य विकास विभाग के प्रयासों को एकीकृत कर साझा प्रयास किया जाये। यह इस कारण भी एक चुनौती है क्योंकि बाल अधिकारों की पूर्ति हमारा दायित्व है। अच्छा स्वास्थ्य और पोषण राज्य में जन्मे हर बच्चे का अधिकार है।</H6>
                                        <H6 className="overview">JOB AIDS Tools For ASHA Under CCSP </H6>
                                    </div>

                                    <div className="item carousel-item">
                                        <div className="img-box">
                                            <img src="/images/other_link_img2.png" />
                                        </div>
                                        <H6 className="testimonial"> राष्ट्रीय ग्रामीण स्वास्थ्य मिशन के अन्तर्गत, प्रदेश में 23 अगस्त 2005 को आशा योजना का शुभारम्भ किया गया था। राष्ट्रीय ग्रामीण स्वास्थ्य मिशन में स्वास्थ्य कार्यक्रमों के सामुदायकीकरण के लिये आशा की महत्वपूर्ण भूमिका है।
                                            प्रदेश में आशा योजना लागू किये जाने के उपलक्ष्य में प्रत्येक वर्ष 23 अगस्त को आशा दिवस के रूप में मनाया जाता है। आशा दिवस/सम्मेलन का आयोजन के उद्देश्य ग्रामीण स्वास्थ्य मिशन का आधार कही जाने वाली आशाओं को उनके द्वारा
                                            समुदाय में किये गये सराहयनीय प्रयासों के लिये उत्सिहत करना, मनोबल बढ़ाना, एवं सम्मानित करना।</H6>
                                        <H6 className="overview"> आशा सम्मेलन 2009-2010 </H6>
                                    </div>

                                    <div className="item carousel-item">
                                        <div className="img-box">
                                            <img src="/images/other_link_img3.png" />
                                        </div>
                                        <H6 className="testimonial">राष्ट्रीय ग्रामीण स्वास्थ्य मिशन के अन्तर्गत, प्रदेश में 23 अगस्त 2005 को आशा योजना का शुभारम्भ किया गया था। राष्ट्रीय ग्रामीण स्वास्थ्य मिशन में स्वास्थ्य कार्यक्रमों के सामुदायकीकरण के लिये आशा की महत्वपूर्ण भूमिका है।
                                            प्रदेश में आशा योजना लागू किये जाने के उपलक्ष्य में प्रत्येक वर्ष 23 अगस्त को आशा दिवस के रूप में मनाया जाता है। आशा दिवस/सम्मेलन का आयोजन के उद्देश्य ग्रामीण स्वास्थ्य मिशन का आधार कही जाने वाली आशाओं को उनके द्वारा
                                            समुदाय में किये गये सराहयनीय प्रयासों के लिये उत्सिहत करना, मनोबल बढ़ाना, एवं सम्मानित करना।</H6>
                                        <H6 className="overview">आशा सम्मेलन 2009-2010 </H6>
                                    </div>

                                </div>
                                <div className="indicators">
                                    <button className="carousel-control-prev" type="button" data-bs-target="#storySlider" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#storySlider" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-3 text-center">
                    <div className="col-md-12">
                        <H4>Other Schemes</H4>
                    </div>
                   
                        <div className="row">
                            <div className="col-md-3 col-lg-3 ">
                            <a href="https://sjsa.maharashtra.gov.in/en/scheme-category/education-training" target="_blank" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>Education & Training</a>
                            </div>
                            <div className="col-md-3 col-lg-3">
                            <a href="https://sjsa.maharashtra.gov.in/en/scheme-category/employment" target="_blank" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>Employment</a>
                            </div>
                            <div className="col-md-3 col-lg-3">
                            <a href="https://sjsa.maharashtra.gov.in/en/scheme-category/disability-welfare" target="_blank" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>Disability Welfare</a>
                            </div>
                            <div className="col-md-3 col-lg-3">
                            <a href="https://sjsa.maharashtra.gov.in/en/scheme-category/social-remedies" target="_blank" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>Social Remedies</a>
                            </div>
                            <div className="col-md-3 col-lg-3">
                            <a href="https://sjsa.maharashtra.gov.in/en/scheme-category/economic-upliftment" target="_blank" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>Economic Unliftment</a>
                            </div>
                            <div className="col-md-3 col-lg-3">
                            <a href="https://sjsa.maharashtra.gov.in/en/scheme-category/special-assistance" target="_blank" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>Special Assistance</a>
                            </div>
                            <div className="col-md-3 col-lg-3">
                            <a href="https://sjsa.maharashtra.gov.in/en/scheme-category/social-integration" target="blank" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>Social Integration</a>
                            </div>
                            <div className="col-md-3 col-lg-3">
                            <a href="https://sjsa.maharashtra.gov.in/en/scheme-category/awards" target="_blank" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>Awards</a>
                            </div>
                        </div>
              


                   
                        


                </div>
            </div>
        </div>
    );
}

export default OtherSlider;
