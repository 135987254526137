import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import H5 from '../text-components/H5';
import H6 from '../text-components/H6';
import useValidator from '../form-validation/useValidatior';
import { userCommunication } from '../communication/userCommunication';
import Loader from '../shared-components/Loader';
import { showLoader, hideLoader } from '../redux-store/loaderReducer';
import TopHeaderPublic from "../Public-portal/TopHearderPublic";
import MainHeader from '../shared-components/MainHeader';
import Footer from '../shared-components/Footer';
import swal from 'sweetalert';

const Registration = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const [validator, showValidationMessage] = useValidator();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({
    institute: "",
    fullName: "",
    address: "",
    mobileNo: "",
    landmark: "",
    city: "",
    pinCode: "",
    email: "",
    password: "",
    accountHolderName: "",
    accountNumber: "",
    ifsc: "",
    branchName: "",
  });

  //getting all inputs value
  const registrationDetail = (event) => {
    setUserDetails({ ...userDetails, [event.target.name]: event.target.value });
  }

  //user registration
  const register = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const dataToSend = {
        institute: userDetails.institute,
        fullName: userDetails.fullName,
        address: userDetails.address,
        mobileNo: Number(userDetails.mobileNo),
        landmark: userDetails.landmark,
        city: userDetails.city,
        pinCode: Number(userDetails.pinCode),
        email: userDetails.email,
        password: userDetails.password,
        bank: userDetails.accountHolderName,
        accountNumber: Number(userDetails.accountNumber),
        ifsc: userDetails.ifsc,
        branchName: userDetails.branchName,
      }
      dispatch(showLoader());
      const serverResponse = await userCommunication.registration(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        navigation("/login");
        swal({text: serverResponse?.data?.message, icon: "success"});
      } else {
        dispatch(hideLoader());
        swal({text: serverResponse?.data?.message, icon: "error"});
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({text: error.message, icon: "error"});
    }
  }

  return (
    <div>
    <TopHeaderPublic/>
    <MainHeader />
    <div className="form_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
    
      {
        loader.showHideLoader === true ?
          <Loader />
          :
         
          <div className="registration_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
            <div className="form_title">
              <H5 whiteThemeColor darkThemeColor>Registration</H5>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <input type="text" name="institute" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter Institute/School Name" />
                {validator.message("institute", userDetails.institute, "required|alpha_space", {
                  messages: {
                    required: "Institute Name is required"
                  }
                })}
              </div>
              <div className="col-6">
                <input type="text" name="fullName" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter Applicant Name" />
                {validator.message("fullName", userDetails.fullName, "required|alpha_space", {
                  messages: {
                    required: "Applicant Name is required"
                  }
                })}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <textarea name="address" onChange={(event) => registrationDetail(event)} className="form-control address" placeholder="Enter Address..."></textarea>
                {validator.message("fullName", userDetails.address, "required", {
                  messages: {
                    required: "Address is required"
                  }
                })}
              </div>
              <div className="col-6">
                <input type="text" name="mobileNo" onChange={(event) => registrationDetail(event)} className="form-control mb-1" placeholder="Enter Mobile Number" />
                {validator.message("mobileNo", userDetails.mobileNo, "required|numeric|min:10|max:10", {
                  messages: {
                    required: "Mobile Number is required"
                  }
                })}
                <input type="text" name="landmark" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter landmark" />
                {validator.message("landmark", userDetails.landmark, "required|alpha_space", {
                  messages: {
                    required: "Landmark is required"
                  }
                })}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <input type="text" name="city" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter City/Village" />
                {validator.message("city", userDetails.city, "required|alpha_space", {
                  messages: {
                    required: "City/Village is required"
                  }
                })}
              </div>
              <div className="col-6">
                <input type="number" name="pinCode" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter Pin Code" />
                {validator.message("pinCode", userDetails.pinCode, "required|min:6|max:6", {
                  messages: {
                    required: "Pin Code is required"
                  }
                })}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <input type="text" name="email" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter Email" />
                {validator.message("email", userDetails.email, "required|email", {
                  messages: {
                    required: "Email is required"
                  }
                })}
              </div>
              <div className="col-6">
                <input type="password" name="password" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter Password" />
                {validator.message("password", userDetails.password, "required|min:8", {
                  messages: {
                    required: "Password is required"
                  }
                })}
              </div>
            </div>

            <H6 whiteThemeColor darkThemeColor>Bank Details :</H6>
            <div className="row mt-2 mb-3">
              <div className="col-6">
                <input type="text" name="accountHolderName" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter Account Holder Name" />
                {validator.message("accountHolderName", userDetails.accountHolderName, "required|alpha_space", {
                  messages: {
                    required: "Account Holder Name is required"
                  }
                })}
              </div>
              <div className="col-6">
                <input type="number" name="accountNumber" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter Account Number" />
                {validator.message("accountNumber", userDetails.accountNumber, "required|min:6|max:18", {
                  messages: {
                    required: "Account Number is required"
                  }
                })}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <input type="text" name="ifsc" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter IFSC Code" />
                {validator.message("ifsc", userDetails.ifsc, "required", {
                  messages: {
                    required: "IFSC Code is required"
                  }
                })}
              </div>
              <div className="col-6">
                <input type="text" name="branchName" onChange={(event) => registrationDetail(event)} className="form-control" placeholder="Enter Branch Name" />
                {validator.message("branchName", userDetails.branchName, "required|alpha_space", {
                  messages: {
                    required: "Branch Name is required"
                  }
                })}
              </div>
            </div>

            <div className="mt-2 button_group text-center">
              <button onClick={() => register()}>Register</button>
            </div>
            <div className="row mt-4">
            <div className="col-6 login_links" style={{ cursor: "pointer" }}>
            <NavLink to="/login">
            <H6 whiteThemeColor darkThemeColor>Already Registered?</H6>
            </NavLink>
            </div>
            <div className="col-6 login_links" style={{ cursor: "pointer",textAlign: "end" }}>
            <NavLink to="/login">
            <H6 whiteThemeColor darkThemeColor> Sign In</H6>
            </NavLink>
            </div>
            
            </div>
          </div>
      }

     
    </div>
    <Footer />
    </div>

  )
}

export default Registration