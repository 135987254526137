import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { removeUnderscoreFromText } from '../../shared-components/removeUnderScore';
import { capitalizeFirstLetter } from '../../shared-components/capitalizeFirstLetter';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const NewApplications = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const limit = process.env.REACT_APP_LIMIT

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
      fetchAllNewListApplicationsbySearch(searchValue, selectedPage + 1);
    } else {
      fetchAllNewApplications(selectedPage + 1);
    }
  }

  //get  New Application list by search
  const fetchAllNewListApplicationsbySearch = async (searchString, page) => {
    try {
      const responseFromServer = await backOfficeCommunication.getAllNewAppliocationsListBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setFilteredData(responseFromServer?.data?.applications);
        setPageCount(responseFromServer?.data?.totalPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/back-office/login");
      } else {
        setFilteredData([]);
        setPageCount(0);
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });

    }
  }



  //filter data according to New Application
  const searchFilter = async (searchString) => {
    if (searchString) {
      fetchAllNewListApplicationsbySearch(searchString, currentPage + 1)
    } else {
      const responseFromServer = await backOfficeCommunication.getAllNewApplications();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setFilteredData(responseFromServer?.data?.applications);
        setPageCount(responseFromServer?.data?.totalPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/back-office/login");
      } else {
        setFilteredData([]);
        setPageCount(0);
      }
    }
  }


  //Fetch all New Applications on initial load
  const fetchAllNewApplications = async () => {
    try {
      dispatch(showLoader());
      const responseFromServer = await backOfficeCommunication.getAllNewApplications();
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setApplications(responseFromServer?.data?.applications);
        setFilteredData(responseFromServer?.data?.applications);
        setPageCount(responseFromServer?.data?.totalPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/back-office/login");
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        setApplications([]);
        setFilteredData([])
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }



  useEffect(() => {
    fetchAllNewApplications();
  }, []);


  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="back_office_section">
            <div className="row mb-2 top_search_header_backoffice">
              <div className="col-lg-3 col-md-4">
                <input Type="text" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search..." />
              </div>
              <div className="col-lg-9 col-md-9 pagination_class">
                <div className="pagination_div ">
                  {
                    pageCount > 1 &&
                    <div className="pagination_main">
                      <ReactPaginate
                        previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                        nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                        breakLabel={"..."} breakClassName={"break-me"} pageCount={pageCount} shape="rounded"
                        marginPagesDisplayed={2} pageRangeDisplayed={3} onPageChange={handlePageClick}
                        containerClassName={"pagination"} subContainerClassName={"pages pagination"}
                        forcePage={currentPage} activeClassName={"active"} />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="back_office_table_main">
            <div className="back_office_table division_selected">
                <div className="back_office_table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                  <div className="back_office_sr_no">
                    <H5>Serial Number</H5>
                  </div>
                  <div className="user_name">
                    <H5>User Name</H5>
                  </div>
                  <div className="application_id">
                    <H5>Application Id</H5>
                  </div>
                  <div className="application_type">
                    <H5>Type</H5>
                  </div>
                  <div className="back_office_status">
                    <H5>Status</H5>
                  </div>
                  <div className="back_office_comment">
                    <H5>Comment</H5>
                  </div>
                  <div className="back_office_date">
                    <H5>Date</H5>
                  </div>
                </div>
                {filteredData.length > 0 ?
                  <>
                    {filteredData?.map((newApplication, index) => (
                      <div className="back_office_table_data" key={index}>
                        <div className="back_office_sr_no">
                          <H6>{(limit * (currentPage) + (index + 1))}</H6>
                        </div>
                        <div className="user_name">
                          <NavLink to={(newApplication?.formType === "NEW APPLICATION" && newApplication?.currentForm !== "FIRST_INSTALLMENT") ? `/back-office/new-applications/second-installment-preview?id=${newApplication?._id}&applicationId=${newApplication?.applicationId}`
                            : (newApplication?.formType === "REPAIR AND MAINTENANCE") ? `/back-office/new-applications/repair-and-maintenance-preview?id=${newApplication?._id}&applicationId=${newApplication?.applicationId}`
                              : `/back-office/new-applications/new-application-preview?id=${newApplication?._id}&applicationId=${newApplication?.applicationId}`}>
                            <H6>{capitalizeFirstLetter(newApplication?.name)}</H6>
                          </NavLink>
                        </div>
                        <div className="application_id">
                          <H6>{newApplication?.applicationId}</H6>
                        </div>
                        <div className="application_type">
                          <H6>{newApplication?.formType === "NEW APPLICATION" ? `New Construction - ${removeUnderscoreFromText(capitalizeFirstLetter(newApplication?.currentForm))}` : capitalizeFirstLetter(newApplication?.formType)}</H6>
                        </div>
                        <div className="back_office_status">
                          {
                            ["Approved_By_District", "Approved_By_Division"].includes(newApplication?.backOfficeStatus) ?
                              <H6>New</H6>
                              :
                              <H6>{removeUnderscoreFromText(capitalizeFirstLetter(newApplication?.backOfficeStatus))}</H6>
                          }
                        </div>
                        <div className="back_office_comment">
                          {newApplication?.backOfficeComment?.length > 0 ?
                            <H6>{!["", null, undefined].includes(newApplication?.backOfficeComment[newApplication?.backOfficeComment.length - 1]) ? newApplication?.backOfficeComment[newApplication?.backOfficeComment.length - 1] : "-"}</H6>
                            :
                            <H6>-</H6>
                          }                        </div>
                        <div className="back_office_date">
                          <H6>{new Date(newApplication?.formSubmittedDate).toLocaleDateString()}</H6>
                        </div>
                      </div>
                    ))}
                  </>
                  :
                  <div className="data_not_available_message">
                    <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Application Not Available</span>
                  </div>
                }
              </div>
            </div>
          </div >
      }
    </>
  )
}

export default NewApplications