import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { darkTheme, whiteTheme } from '../redux-store/darkWhiteThemeToggle';
import { increment, decrement, defaultFont } from '../redux-store/fontSizeMultiplier';
import { AdminLogoutBox } from '../shared-components/LogoutBox';

const TopHeaderadmin = () => {
  const dispatch = useDispatch();
  const [showLoginBox, setShowLoginBox] = useState(false);
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const fullUrl = window.location.href;
  const splitUrl = fullUrl.split("#")

  let load = 0;
  const googleTranslateElementInit = (() => {
    new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: "en,hi,mr" }, 'google_translate_element');
  });
  useEffect(() => {
    if (load === 0) {
      load += 1;
      const script = document.createElement("script");
      script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, [])

  return (
    <>
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 col-12 p-0 text-md-right righthd">
          <div className="top_header" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c" }}>
            <span style={{ color: `${darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff"}` }} onClick={() => dispatch(decrement())}>A-</span>
            <span style={{ color: `${darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff"}` }} onClick={() => dispatch(defaultFont())}>A </span>
            <span style={{ color: `${darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff"}` }} onClick={() => dispatch(increment())}>A+</span>
            <button className="whiteTheme" onClick={() => dispatch(whiteTheme())}>A</button>
            <button className="darkTheme" onClick={() => dispatch(darkTheme())}>A</button>
            <li>
              <div id="google_translate_element" className="google"></div>
            </li>
            {(splitUrl.includes("/admin/login")) ?
              <span></span>
              :
              <div className="user_icon">
                <i className="fa-solid fa-user" onClick={() => setShowLoginBox(true)}></i>
              </div>
            }

          </div>
        </div>
      </div>
      {showLoginBox &&
        <AdminLogoutBox setShowLoginBox={setShowLoginBox} />
      }
    </>
  )
}
export default TopHeaderadmin