import React from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
import SignIn from '../../admin-portal/SignIn';
import DepartmentList from '../../admin-portal/department-maintenance/DepartmentList';
import AddDepartment from '../../admin-portal/department-maintenance/AddDepartment';
import UpdateDepartment from '../../admin-portal/department-maintenance/UpdateDepartment';
import DesignationList from '../../admin-portal/designation-maintenance/DesignationList';
import AddDesignation from '../../admin-portal/designation-maintenance/AddDesignation';
import UpdateDesignation from '../../admin-portal/designation-maintenance/UpdateDesignation';
import UserList from '../../admin-portal/user-maintenance/UserList';
import AddUser from '../../admin-portal/user-maintenance/AddUser';
import UpdateUser from '../../admin-portal/user-maintenance/UpdateUser';
import NotificationList from '../../admin-portal/notification-maintenance/NotificationList';
import AddNotification from '../../admin-portal/notification-maintenance/AddNotification';
import UpdateNotification from '../../admin-portal/notification-maintenance/UpdateNotification';
import UploadDataList from '../../admin-portal/upload-data/UploadDataList';
import AddData from '../../admin-portal/upload-data/AddData';
import UpdateData from '../../admin-portal/upload-data/UpdateData';
import Feedback from '../../admin-portal/feedback/Feedback';
import UpdateStatistics from '../../admin-portal/statistic/UpdateStatistics';
import Dashboard from '../../admin-portal/Dashboard';
import { PrivateRoute } from '../privateRoute';

const AdminRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/login' element={<SignIn />} />
        <Route path='/dashboard' element={<PrivateRoute><Dashboard></Dashboard></PrivateRoute>}>
          <Route path='/dashboard' element={<DesignationList />} />
          <Route path='/dashboard/department-list' element={<DepartmentList />} />
          <Route path='/dashboard/department-list/add-department' element={<AddDepartment />} />
          <Route path='/dashboard/department-list/update-department' element={<UpdateDepartment />} />
          <Route path='/dashboard/designation-list' element={<DesignationList />} />
          <Route path='/dashboard/designation-list/add-designation' element={<AddDesignation />} />
          <Route path='/dashboard/designation-list/update-designation' element={<UpdateDesignation />} />
          <Route path='/dashboard/user-list' element={<UserList />} />
          <Route path='/dashboard/user-list/add-user' element={<AddUser />} />
          <Route path='/dashboard/user-list/update-user' element={<UpdateUser />} />
          <Route path='/dashboard/notification-list' element={<NotificationList />} />
          <Route path='/dashboard/notification-list/add-notification' element={<AddNotification />} />
          <Route path='/dashboard/notification-list/update-notification' element={<UpdateNotification />} />
          <Route path='/dashboard/upload-data-list' element={<UploadDataList />} />
          <Route path='/dashboard/upload-data-list/add-data' element={<AddData />} />
          <Route path='/dashboard/upload-data-list/update-data' element={<UpdateData />} />
          <Route path='/dashboard/feedback' element={<Feedback />} />
          <Route path='/dashboard/statistics' element={<UpdateStatistics />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </>
  )
}

export default AdminRoutes