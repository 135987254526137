import React from 'react'

const divisionArray = [
    {
        division: "Konkan",
        district: ["Mumbai City District", "Mumbai Suburban District", "Thane", "Palghar", "Raigad", "Ratnagiri", "Sindhudurg"]
    },
    {
        division: "Pune",
        district: ["Sangli", "Satara", "Solapur", "Kolhapur", "Pune"]
    },
    {
        division: "Nashik",
        district: ["Dhule", "Jalgaon", "Nandurbar", "Nashik", "Ahmednagar"]
    },
    {
        division: "Aurangabad",
        district: ["Aurangabad", "Beed", "Jalna", "Osmanabad", "Nanded", "Latur", "Parbhani", "Hingoli"]
    },
    {
        division: "Amravati",
        district: ["Akola", "Amravati", "Buldhana", "Yavatmal", "Washim"]
    },
    {
        division: "Nagpur",
        district: ["Bhandara", "Chandrapur", "Gadchiroli", "Gondia", "Nagpur", "Wardha"]
    }
];

export default divisionArray;
