import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import H6 from '../text-components/H6';
import { removeAdimCookies } from '../redux-store/adminAuthenticateReducer';
import { removeUserCookies } from '../redux-store/userAuthenticateReducer';
import { removeBackOfficeCookies } from '../redux-store/backOfficeAuthenticatedReducer';

//Admin logout box
export const AdminLogoutBox = ({ setShowLoginBox }) => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const navigation = useNavigate();
    const adminLoginUserDetails = useSelector((state) => state.adminUserDetails);
    const dispatch = useDispatch();

    //logout admin user
    const adminLogoutBox = () => {
        dispatch(removeAdimCookies());
        setShowLoginBox(false);
        navigation("/admin/login");
    }

    return (
        <div className="login_detail_box" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
            <i className="fa-solid fa-xmark close_btn" onClick={() => setShowLoginBox(false)} style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i>
            <div className="user_info">
                <div className="userName">
                    <H6 whiteThemeColor darkThemeColor>Name : {adminLoginUserDetails?.userName}</H6>
                </div>
                <div className="user_designation">
                    <H6 whiteThemeColor darkThemeColor>Designation : {adminLoginUserDetails?.designation}</H6>
                </div>
            </div>
            <div className="button_group" style={{ textAlign: "left" }}>
                <button onClick={() => adminLogoutBox()}>Logout</button>
            </div>
        </div>
    )
}

//User Logout Box  
export const UserLogoutBox = ({ setShowLoginBox }) => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const userLoginDetails = useSelector((state) => state.userDetails);

    //logout user
    const userLogoutBox = () => {
        dispatch(removeUserCookies());
        setShowLoginBox(false);
        navigation("/login");
    }

    return (
        <div className="login_detail_box" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
            <i className="fa-solid fa-xmark close_btn" onClick={() => setShowLoginBox(false)} style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i>
            <div className="user_info">
                <div className="userName">
                    <H6 whiteThemeColor darkThemeColor>Name : {userLoginDetails?.name}</H6>
                </div>
            </div>
            <div className="button_group" style={{ textAlign: "left" }}>
                <button onClick={() => userLogoutBox()}>Logout</button>
            </div>
        </div>
    )
}


//Back office logout box
export const BackOfficeLogoutBox = ({ setShowLoginBox }) => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const navigation = useNavigate();
    const backOfficeLoginUserDetails = useSelector((state) => state.backOfficeUserDetails);
    const dispatch = useDispatch();

    //logout admin user
    const backOfficeLogoutBox = () => {
        dispatch(removeBackOfficeCookies());
        setShowLoginBox(false);
        navigation("/back-office/login");
    }

    return (
        <div className="back_office_logout_box" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
            <i className="fa-solid fa-xmark close_btn" onClick={() => setShowLoginBox(false)} style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i>
            <div className="user_info">
                <div className="userName">
                    <H6 whiteThemeColor darkThemeColor>Name : {backOfficeLoginUserDetails?.name}</H6>
                </div>
                <div className="user_designation">
                    <H6 whiteThemeColor darkThemeColor>Designation : {backOfficeLoginUserDetails?.designation}</H6>
                </div>
                <div className="user_type">
                    <H6 whiteThemeColor darkThemeColor>Zone : {backOfficeLoginUserDetails?.userType}</H6>
                </div>
            </div>
            <div className="button_group" style={{ textAlign: "left" }}>
                <button onClick={() => backOfficeLogoutBox()}>Logout</button>
            </div>
        </div>
    )
}