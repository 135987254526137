import axios from 'axios';
import swal from 'sweetalert';

const developmentURL = "http://localhost:3012";
const contoboURL = "https://bjrcybackend.mattedemolitions.com";
const machineIp = "http://192.168.29.169:3012";


function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if ("bjrcyUserToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}

const getServerUrl = () => {
    const nodeEnvironment = process.env.REACT_APP_NODE_ENV;
    const serverUrl = process.env.REACT_APP_BACKEND_URL;


    if (nodeEnvironment === "development") {
        return developmentURL;
    }

    if (nodeEnvironment === "contobo_dev") {
        return contoboURL;
    }

    if (nodeEnvironment === "production") {
        return serverUrl;
    }
    if (nodeEnvironment === "machine_ip") {
            return machineIp;
        }
    
 
    
    return serverUrl;
}

export const adminCommunication = {

    //?---------------------------------Login API-------------------------------------------------------------------------
    login: (userId, password) => {
        return axios.post(`${getServerUrl()}/admin/admin-login`, { userId, password }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    //?---------------------------------Designation Maintenance API-------------------------------------------------------------------------
    addDesignation: (designation) => {
        return axios.post(`${getServerUrl()}/admin/add-designation`, { designation }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllDesignations: (page) => {
        try {
            return axios.get(`${getServerUrl()}/admin/get-designation?page=${page}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getDesignationListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-designation`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getDesignationsById: (designationId) => {
        return axios.post(`${getServerUrl()}/admin/get-designation-by-id`, { designationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateDesignation: (designation, designationId) => {
        return axios.post(`${getServerUrl()}/admin/update-designation`, { designation, designationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?---------------------------------Department Maintenance API-------------------------------------------------------------------------
    addDepartment: (department, designation) => {
        return axios.post(`${getServerUrl()}/admin/add-department`, { department, designation }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllDepartments: (page) => {
        try {
            return axios.get(`${getServerUrl()}/admin/get-department?page=${page}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getDepartmentListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-department`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    updateDepartment: (dataToSend) => {
        return axios.post(`${getServerUrl()}/admin/update-department`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getDepartmentById: (departmentId) => {
        return axios.post(`${getServerUrl()}/admin/get-department-by-id`, { departmentId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getDepartmentWiseDesignation: (departmentId) => {
        return axios.post(`${getServerUrl()}/admin/get-department-wise-designation`, { departmentId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?---------------------------------User Maintenance API-------------------------------------------------------------------------
    addUser: (dataToSend) => {
        return axios.post(`${getServerUrl()}/admin/add-office-user`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllUsers: (page) => {
        try {
            return axios.get(`${getServerUrl()}/admin/get-back-office-user?page=${page}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getUserListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-back-office-user`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getBackOfficeUserById: (userId) => {
        return axios.post(`${getServerUrl()}/admin/get-back-office-user-by-id`, { userId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateBackOfficeUser: (dataToSend) => {
        return axios.post(`${getServerUrl()}/admin/update-office-user`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?---------------------------------Notification Maintenance API-------------------------------------------------------------------------
    getAllNotifications: (page) => {
        try {
            return axios.get(`${getServerUrl()}/admin/get-all-notification?page=${page}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getNotificationListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-notification`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getNotificationFile: (fileUrl) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/get-notification-attachment?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                }
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                // resolve(pdfBlob);
            }).catch(error => {
                swal({ text: error.message, icon: "error" });
            });
        })
    },
    getNotificationById: (notificationId) => {
        return axios.post(`${getServerUrl()}/admin/get-notification-by-id`, { notificationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    uploadPublicNotification: (portal, subject, description, fromData) => {
        return axios.post(`${getServerUrl()}/admin/add-public-notification?portal=${portal}&subject=${subject}&description=${description}`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    updatePublicNotification: (notificationId, portal, subject, description, isFileUpdate, fromData) => {
        return axios.post(`${getServerUrl()}/admin/update-public-notification?portal=${portal}&subject=${subject}&description=${description}&isFileUpdate=${isFileUpdate}&notificationId=${notificationId}`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data,boundary=MyBoundary",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    uploadUserNotification: (portal, notificationType, subject, description, fromData, formType, startDate, endDate) => {
        return axios.post(`${getServerUrl()}/admin/add-user-notification?portal=${portal}&notificationType=${notificationType}&subject=${subject}&description=${description}&formType=${formType}&startDate=${startDate}&endDate=${endDate}`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    updateUserNotification: (notificationId, portal, notificationType, subject, description, fromData, formType, isFileUpdate, startDate, endDate) => {
        return axios.post(`${getServerUrl()}/admin/update-user-notification?notificationId=${notificationId}&portal=${portal}&notificationType=${notificationType}&subject=${subject}&description=${description}&isFileUpdate=${isFileUpdate}&formType=${formType}&startDate=${startDate}&endDate=${endDate}`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data,boundary=MyBoundary",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    //? ------------------------------------- Download API------------------------------------------------------------------------------------
    getDownload: () => {
        return axios.get(`${getServerUrl()}/admin/get-upload-data`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    addDownloadDocument: (portal, title, description, fromData) => {
        return axios.post(`${getServerUrl()}/admin/upload-data?portal=${portal}&title=${title}&description=${description}`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    updateDownloadDocument: (isFileUpdate, documentId, portal, title, description, fromData,) => {
        return axios.post(`${getServerUrl()}/admin/update-download-data?isFileUpdate=${isFileUpdate}&portal=${portal}&title=${title}&description=${description}&documentId=${documentId}`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data,boundary=MyBoundary",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    getDownloadDocumentById: (documentId) => {
        return axios.post(`${getServerUrl()}/admin/get-upload-data-by-id`, { documentId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    getDocument: (fileUrl) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/get-uploaded-file?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                // resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
}