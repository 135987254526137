import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import useValidator from '../../form-validation/useValidatior';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import swal from 'sweetalert';

const AddReviewForm = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [validator, showValidationMessage] = useValidator();
  const [uploadReviewDocument, setUploadReviewDocument] = useState("");
  const [comment, setComment] = useState("");
  const [reviewName, setReviewName] = useState("")

  //get id from params
  const params = new URLSearchParams(useLocation().search);
  const applicationId = params.get("applicationId");
  const userId = params.get("userId");
  const currentForm = params.get("currentForm");

  //Add Review
  const addReview = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const formData = new FormData();
      formData.append("uploadReviewDocument", uploadReviewDocument);

      const dataToSend = {
        applicationId,
        userId,
        reviewName,
        comment,
        reviewStatus: "Approved"
      }

      dispatch(showLoader());
      const serverResponse = await backOfficeCommunication.addReview(dataToSend, formData);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "success" });
        navigation("/back-office/final-selected-applications");
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }

  }

  //Mark Under Review
  const markUnderReview = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const formData = new FormData();
      formData.append("uploadReviewDocument", uploadReviewDocument);

      const dataToSend = {
        applicationId,
        userId,
        reviewName,
        comment,
        reviewStatus: "Under_Review"
      }

      dispatch(showLoader());
      const serverResponse = await backOfficeCommunication.addReview(dataToSend, formData);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "success" });
        navigation("/back-office/final-selected-applications");
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }

  }

  //Mark Completed Review
  const markCompleted = async () => {
    try {

      swal({
        title: "Are you sure?",
        text: "Project mark as complete!",
        icon: "warning",
        buttons: [
          'No',
          'Yes'
        ],

      }).then(async function (isConfirm) {
        if (isConfirm) {
          dispatch(showLoader());
          const serverResponse = await backOfficeCommunication.markProjectAsComplete(applicationId);
          if (serverResponse?.data?.status === "SUCCESS") {
            dispatch(hideLoader());
            swal({ text: serverResponse?.data?.message, icon: "success" });
            navigation("/back-office/final-selected-applications");
          } else {
            dispatch(hideLoader());
            swal({ text: serverResponse?.data?.message, icon: "error" });
          }
        }
      })

    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }

  }

  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="add_review_form_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
            <div className="add_review_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div className="form_title">
                <H5 whiteThemeColor darkThemeColor>Add Review</H5>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <H6 whiteThemeColor darkThemeColor>Review Name</H6>
                  <input type="text" name="reviewName" onChange={(event) => setReviewName(event.target.value)} className="form-control mt-1" placeholder="eg: First Review" />
                  {validator.message("reviewName", reviewName, "required", {
                    messages: {
                      required: "Review Name is required"
                    }
                  })}
                </div>
              </div>

              <div className="row mt-3 mb-3">
                <div className="col-12">
                  <H6 whiteThemeColor darkThemeColor>Attach File</H6>
                  <input type="file" name="uploadReviewDocument" onChange={(event) => setUploadReviewDocument(event.target.files[0])} className="form-control mt-1" />
                  {validator.message("uploadReviewDocument", uploadReviewDocument, "required", {
                    messages: {
                      required: "File is required"
                    }
                  })}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <H6 whiteThemeColor darkThemeColor>Comment</H6>
                  <textarea name="comment" onChange={(event) => setComment(event.target.value)} className="form-control mt-1" placeholder="write comment..."></textarea>
                </div>
              </div>
              <div className="back_office_button_group">
                <button className="me-3" onClick={() => markCompleted()}>{currentForm === "REPAIR_AND_MAINTENANCE" ? "Mark Completed" : "Installment Completed"}</button>
                <button className="me-3" onClick={() => markUnderReview()}>Under Review</button>
                <button className="me-3" onClick={() => addReview()}>Approve</button>
                <button onClick={() => navigation("/back-office/final-selected-applications")}>Cancel</button>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default AddReviewForm