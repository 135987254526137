import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import { userCommunication } from '../../communication/userCommunication';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { removeUnderscoreFromText } from '../../shared-components/removeUnderScore';
import { capitalizeFirstLetter } from '../../shared-components/capitalizeFirstLetter';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const StateSelectedApplications = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const backOfficeUser = useSelector((state) => state.backOfficeUserDetails)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stateSelectedApplications, setStateSelectedApplications] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const limit = process.env.REACT_APP_LIMIT

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
      fetchStateSelectedApplicationListbySearch(searchValue, selectedPage + 1);
    } else {
      fetchStateSelectedApplications(selectedPage + 1);
    }
  }
    //filter data according to state selected application
    const searchFilter = async (searchString) => {
      if (searchString) {
        fetchStateSelectedApplicationListbySearch(searchString, currentPage + 1)
      } else {
        const responseFromServer = await backOfficeCommunication.getSelectedApplications()
        if (responseFromServer?.data?.status === "SUCCESS") {
          setFilteredData(responseFromServer?.data?.applications);
          setPageCount(responseFromServer?.data?.totalPage);
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
          swal({ text: responseFromServer?.data?.message, icon: "error" });
          navigate("/back-office/login");
        } else {
          setFilteredData([]);
          setPageCount(0);
        }
      }
    }
  
   //get StateSelectedApplication list by search
  const fetchStateSelectedApplicationListbySearch = async (searchString, page) => {
    try {
      const responseFromServer = await backOfficeCommunication.getStateSelectedApplicationsListBySearch(searchString, page)
      if (responseFromServer?.data?.status === "SUCCESS") {
        setFilteredData(responseFromServer?.data?.applications);
        setPageCount(responseFromServer?.data?.totalPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/back-office/login");
      } else {
        setFilteredData([]);
        setPageCount(0)
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }



  //fetch state selected application on initial load
  const fetchStateSelectedApplications = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await backOfficeCommunication.getSelectedApplications(page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setFilteredData(responseFromServer?.data?.applications);
        //setStateSelectedApplications(responseFromServer?.data?.applications);
        setPageCount(responseFromServer?.data?.totalPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        navigate("/back-office/login");
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        //setStateSelectedApplications([]);
        setFilteredData([]);

      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  //Preview uploaded document
  const previewUploadedDocument = async (application) => {
    let fileUrl;
    if (application.formType === "REPAIR AND MAINTENANCE") {
      fileUrl = application.centralAcknowledgedCertificate
    } else if (application.currentForm === "FIRST_INSTALLMENT") {
      fileUrl = application.firstInstallMentForm.centralAcknowledgedCertificate;
    } else {
      fileUrl = application.secondInstallMentForm.centralAcknowledgedCertificate;
    }

    if (fileUrl) {
      await userCommunication.getUserDocument(fileUrl);
    }
  }


  useEffect(() => {
    fetchStateSelectedApplications();
  }, []);

  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="back_office_section">
            <div className="row mb-2 top_search_header_backoffice">
              <div className="col-lg-3 col-md-4">
                <input Type="text" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }}  className="form-control" placeholder="Search by User Name, Application Id" />
              </div>
              <div className="col-lg-9 col-md-9 pagination_class">
                <div className="pagination_div ">
                  {
                    pageCount>1 &&
                    <div className="pagination_main">
                      <ReactPaginate
                        previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                        nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount} shape="rounded"
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        forcePage={currentPage}
                        activeClassName={"active"} />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="back_office_table_main">
              <div className="back_office_table state_selected">
                <div className="back_office_table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                  <div className="back_office_sr_no">
                    <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                  </div>
                  <div className="user_name">
                    <H5 whiteThemeColor darkThemeColor>User Name</H5>
                  </div>
                  <div className="application_id">
                    <H5 whiteThemeColor darkThemeColor>Application Id</H5>
                  </div>
                  <div className="application_type">
                    <H5 whiteThemeColor darkThemeColor>Type</H5>
                  </div>
                  <div className="back_office_status">
                    <H5 whiteThemeColor darkThemeColor>Status</H5>
                  </div>
                  <div className="back_office_comment">
                    <H5 whiteThemeColor darkThemeColor>Comment</H5>
                  </div>
                  <div className="back_office_date">
                    <H5 whiteThemeColor darkThemeColor>Date</H5>
                  </div>
                  <div className="back_office_action">
                    <H5 whiteThemeColor darkThemeColor>Action</H5>
                  </div>
                </div>
                {filteredData?.length > 0 ?
                  <>
                    {filteredData?.map((application, index) => (
                      <div className="back_office_table_data" key={index}>
                        <div className="back_office_sr_no">
                          <H6 whiteThemeColor darkThemeColor>{(limit * (currentPage) + (index + 1))}</H6>
                        </div>
                        <div className="user_name">
                          <NavLink to={(application?.formType === "NEW APPLICATION" && application?.currentForm === "FIRST_INSTALLMENT") ? `/back-office/state-selected-applications/new-application-preview?id=${application?._id}&applicationId=${application?.applicationId}`
                            : (application?.formType === "REPAIR AND MAINTENANCE") ? `/back-office/state-selected-applications/repair-and-maintenance-preview?id=${application?._id}&applicationId=${application?.applicationId}`
                              : `/back-office/state-selected-applications/second-installment-preview?id=${application?._id}&applicationId=${application?.applicationId}`}>
                            <H6>{capitalizeFirstLetter(application?.name)}</H6>
                          </NavLink>
                        </div>
                        <div className="application_id">
                          <H6 whiteThemeColor darkThemeColor>{application?.applicationId}</H6>
                        </div>
                        <div className="application_type">
                          <H6>{application?.formType === "NEW APPLICATION" ? `New Construction - ${removeUnderscoreFromText(capitalizeFirstLetter(application?.currentForm))}` : capitalizeFirstLetter(application?.formType)}</H6>
                        </div>
                        <div className="back_office_status">
                          <H6 whiteThemeColor darkThemeColor>{removeUnderscoreFromText(capitalizeFirstLetter(application?.backOfficeStatus))}</H6>
                        </div>
                        <div className="back_office_comment">
                          {application?.backOfficeComment?.length > 0 ?
                            <H6>{!["", null, undefined].includes(application?.backOfficeComment[application?.backOfficeComment?.length - 1]) ? application?.backOfficeComment[application?.backOfficeComment.length - 1] : "-"}</H6>
                            :
                            <H6>-</H6>
                          }                        </div>
                        <div className="back_office_date">
                          <H6 whiteThemeColor darkThemeColor>{new Date(application?.formSubmittedDate).toLocaleDateString()}</H6>
                        </div>
                        <div className="back_office_action">
                          {[undefined, null, ""].includes(application?.centralAcknowledgedCertificate || (application?.firstInstallMentForm?.centralAcknowledgedCertificate && application?.currentForm === "FIRST_INSTALLMENT") || (application?.secondInstallMentForm?.centralAcknowledgedCertificate && application?.currentForm === "SECOND_INSTALLMENT")) ?
                            <>
                              {["Division", "District"].includes(backOfficeUser.userType) ?
                                <span>-</span>
                                :
                                <NavLink to={`/back-office/state-selected-applications/cental-acknowledgement?id=${application?._id}&userId=${application?.userId}`}>
                                  <button className="central_acknowledge">Central Acknowledge</button>
                                </NavLink>
                              }
                            </>
                            :
                            <i className="fa-solid fa-file-pdf" style={{ color: (darkWhiteThemeToggle === "WHITE") ? "red" : "rgb(173, 83, 137)", cursor: "pointer" }} onClick={() => previewUploadedDocument(application)} title="Central Acknowledgement Certificate"></i>
                          }
                        </div>
                      </div>
                    ))}
                  </>
                  :
                  <div className="data_not_available_message">
                    <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Application Not Available</span>
                  </div>
                }
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default StateSelectedApplications