import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { backOfficeCommunication } from '../communication/backOfficeCommunication';
import swal from 'sweetalert';

const BackOfficeNavBar = ({ countHandler, setShowLoginBox }) => {
    const userType = useSelector((state) => state.backOfficeUserDetails.userType);
    const [counts, setCounts] = useState(0);
    //get new notification counts
    const getCounts = async () => {
        try {
            const serverResponse = await backOfficeCommunication.getNewNotificationsCounts();
            if (serverResponse.data.status === "SUCCESS") {
                setCounts(serverResponse?.data?.officeAlertCount);
            } else {
                setCounts(0)
            }
        } catch (error) {
            swal(error.message, "error");
        }
    }

    useEffect(() => {
        getCounts();
    }, [countHandler])
    return (
        <ul className="back_office_navbar" onClick={() => setShowLoginBox(false)}>
            <NavLink to="/back-office/new-applications">
                <li><span>New Application</span></li>
            </NavLink>
            {userType === "District" &&
                <NavLink to="/back-office/district-selected-applications">
                    <li><span>District Selected Application</span></li>
                </NavLink>
            }
            {["District", "Division"].includes(userType) &&
                <NavLink to="/back-office/division-selected-applications">
                    <li><span>Division Selected Application</span></li>
                </NavLink>
            }
            <NavLink to="/back-office/state-selected-applications">
                <li><span>State Selected Application</span></li>
            </NavLink>
            <NavLink to="/back-office/final-selected-applications">
                <li><span>Final Selected Application</span></li>
            </NavLink>
            {userType === "State" &&
                <NavLink to="/back-office/remittance">
                    <li><span>Remittance</span></li>
                </NavLink>
            }
            {userType === "State" &&
                <NavLink to="/back-office/grievance">
                    <li><span>Grievance</span></li>
                </NavLink>
            }
            <NavLink to={{ pathname: "/back-office/notification" }} >
                <li><span>Notification</span>
                    {counts ?
                        <div className="badge"><span>{counts}</span></div>
                        :
                        <></>
                    }
                </li>
            </NavLink>
        </ul>
    )
}

export default BackOfficeNavBar