import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import useValidator from '../../form-validation/useValidatior';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import swal from 'sweetalert';

const UpdateReview = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [uploadReviewDocument, setUploadReviewDocument] = useState("");

    //get reviewId and userId
    const params = new URLSearchParams(useLocation().search);
    const reviewId = params.get('reviewId');
    const userId = params.get('userId');

    //Update Review
    const UpdateReview = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const formData = new FormData();
            formData.append("uploadReviewDocument", uploadReviewDocument);

            dispatch(showLoader());
            const serverResponse = await backOfficeCommunication.updateReview(reviewId, userId, formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({text: serverResponse?.data?.message, icon: "success"});
                navigation(-1);
            } else {
                dispatch(hideLoader());
                swal({text: serverResponse?.data?.message, icon: "error"});
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({text: error.message, icon: "error"});
        }

    }

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <CommonLoader />
                    :
                    <div className="add_review_form_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                        <div className="add_review_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
                            <div className="form_title">
                                <H5 whiteThemeColor darkThemeColor>Update Review</H5>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-12">
                                    <H6 whiteThemeColor darkThemeColor>Attach File</H6>
                                    <input type="file" name="uploadReviewDocument" onChange={(event) => setUploadReviewDocument(event.target.files[0])} className="form-control mt-1" />
                                    {validator.message("uploadReviewDocument", uploadReviewDocument, "required", {
                                        messages: {
                                            required: "File is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="back_office_button_group">
                                <button className="me-3" onClick={() => UpdateReview()}>Approve</button>
                                <button onClick={() => navigation(-1)}>Cancel</button>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default UpdateReview