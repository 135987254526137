import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import H5 from "../../text-components/H5";
import { useLocation } from "react-router-dom";
import { adminCommunication } from '../../communication/adminCommunication';
import useValidator from '../../form-validation/useValidatior';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';

const UpdateDesignation = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const [validator, showValidationMessage] = useValidator();
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //getting designation id from query params
  const params = new URLSearchParams(useLocation().search);
  const designationId = params.get("designationId");

  const [designation, setDesignation] = useState();

  //Fetch designation by designation id on initial load
  const fetchDesignationById = async () => {
    try {
      const responseFromServer = await adminCommunication.getDesignationsById(designationId);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setDesignation(responseFromServer?.data?.designation?.designation);
      } else if (responseFromServer.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        dispatch(hideLoader());
      } else {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }

  useEffect(() => {
    fetchDesignationById();
  }, []);

  //Update Designation
  const updateDesignation = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      dispatch(showLoader());
      const serverResponse = await adminCommunication.updateDesignation(designation, designationId);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "success" });
        navigate("/admin/dashboard/designation-list");
      } else if (serverResponse.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "error" });
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="form_section">
            <div className="form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Update Designation</H5>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <input type="text" name="designation" value={designation} onChange={(event) => setDesignation(event.target.value)} className="form-control" placeholder="Enter Designation" />
                    {validator.message("designation", designation, "required|alpha_space", {
                      messages: {
                        required: "Designation is required"
                      }
                    })}
                  </div>
                </div>
                <div className="button_group mt-3">
                  <button onClick={() => updateDesignation()}>Update</button>
                  <NavLink to="/admin/dashboard/designation-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default UpdateDesignation