import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import Label from '../../text-components/Label';
import useValidator from '../../form-validation/useValidatior';
import divisionArray from '../../shared-components/divisionArray';
import { userCommunication } from '../../communication/userCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import swal from 'sweetalert';

const NewApplication = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const [validator, showValidationMessage] = useValidator();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [showFormModal, setShowFormModal] = useState(false);
    const [district, setDistrict] = useState([]);
    const [newApplicationData, setNewApplicationData] = useState(
        {
            submittedTo: "",
            division: "",
            district: "",
            agency: "",
            natureOfAgency: "",
            hostelLevel: "",
            category: "",
            noOfInmates: "",
            general: "",
            SC: "",
            OBC: "",
            ST: "",
            hostelLocation: "",
            isLandAcquiredByAgency: "",
            isLandEncumbranceFree: "",
            area: "",
            completionPeriod: "",
            totalCost: "",
            perInmatesCost: "",
            actualPerInmatesCost: "",
            grandsInAid: "",
            share: "",
            isAgencyAgreeToBearCost: "",
            isAgencyAgreeToShare: "",
            isCostDetermineByPWD: "",
            isDrawingPrepared: "",
            isPlanApproved: "",
            plinthArea: "",
            other: "",
            otherDetails: "",
            cleaning: "",
            bathingAndWashing: "",
            drinkingWater: "",
            gameAndSport: "",
            appointmentOfCook: "",
            medicalCare: "",
            coaching: "",
            facilitiesTiedUp: "",
            sourceOfFundingForCommonFacilities: "",
            fireSafety: "",
            boundaryWall: "",
            appointmentOfWarder: "",
            totalDistrictPopulation: "",
            scDistrictPopulation: "",
            percentageOfSC: "",
            percentageOfSCFemaleMaleLiteracy: "",
            percentageOfSCMaleLiteracy: "",
            scHostelInDistrict: "",
            scHostelInBlock: "",
            operationalSCHostel: "",
            isHostelAttachedToInstitute: "",
            instituteName: "",
            instituteAddress: "",
            sourceOfFundForMaintenance: "",
            isFacilityAvailableForDisableStudent: "",
            facilityDetailsForDisables: "",
            noOfStudentStudyingInAttachedInstitute: "",
            formSubmittedByState: "",
            justification: "",
            annexure: "",
            sitePlanSigned: "",
            costEstimateSigned: "",
            buildingPlanSigned: "",
            isLandOwnershipDocumentAuthenticate: "",
            otherRelevantDetails: ""
        }
    )

    const [documents, setDocuments] = useState({
        form: "",
        justificationCertificate: "",
        annexureCertificate: "",
        sitePlanSignedCertificate: "",
        costEstimateCertificate: "",
        buildingPlanSignedCertificate: "",
        landOwnershipAuthenticateCertificate: "",
    });

    //get notification id from params
    const params = new URLSearchParams(useLocation().search);
    const notificationId = params.get("notificationId");

    //get all inputs value
    const getApplicationDetail = (event, calculatePercentage) => {
        if (calculatePercentage === "calculateScPercentage") {
            //calculate sc percentage
            let percentage = (event.target.value / newApplicationData.totalDistrictPopulation * 100).toFixed(2);
            setNewApplicationData({ ...newApplicationData, "percentageOfSC": percentage, [event.target.name]: event.target.value });
        } else {
            setNewApplicationData({ ...newApplicationData, [event.target.name]: event.target.value });
        }
    }

    //get all files value
    const getDocuments = (event) => {
        setDocuments({ ...documents, [event.target.name]: event.target.files[0] });
    }

    //Submit application
    const submitApplication = () => {
        //check validation
        if (!validator.allValid()) {
            showValidationMessage(true);
            return false;
        }
        setShowFormModal(true);
    }

    //get division wise districts
    const divisionWiseDistricts = (event) => {
        for (let i = 0; i < divisionArray.length; i++) {
            if (divisionArray[i].division === event.target.value) {
                setDistrict(divisionArray[i].district);
            }
        }
    }

    //Final application submission
    const finalApplicationSubmission = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const formData = new FormData();
            formData.append("form", documents.form);
            formData.append("justificationCertificate", documents.justificationCertificate);
            formData.append("annexureCertificate", documents.annexureCertificate);
            formData.append("sitePlanSignedCertificate", documents.sitePlanSignedCertificate);
            formData.append("costEstimateCertificate", documents.costEstimateCertificate);
            formData.append("buildingPlanSignedCertificate", documents.buildingPlanSignedCertificate);
            formData.append("landOwnershipAuthenticateCertificate", documents.landOwnershipAuthenticateCertificate);


            const dataToSend = {
                notificationId,
                submittedTo: newApplicationData.submittedTo,
                division: newApplicationData.division,
                district: newApplicationData.district,
                agency: newApplicationData.agency,
                natureOfAgency: newApplicationData.natureOfAgency,
                hostelLevel: newApplicationData.hostelLevel,
                category: newApplicationData.category,
                noOfInmates: Number(newApplicationData.noOfInmates),
                casteWiseInmates: {
                    general: Number(newApplicationData.general),
                    SC: Number(newApplicationData.SC),
                    OBC: Number(newApplicationData.OBC),
                    ST: Number(newApplicationData.ST)
                },
                hostelLocation: newApplicationData.hostelLocation,
                landDetails: {
                    isLandAcquiredByAgency: newApplicationData.isLandAcquiredByAgency,
                    isLandEncumbranceFree: newApplicationData.isLandEncumbranceFree,
                    area: newApplicationData.area,
                },
                completionPeriod: Number(newApplicationData.completionPeriod),
                costEstimatesDetails: {
                    totalCost: newApplicationData.totalCost,
                    perInmatesCost: newApplicationData.perInmatesCost,
                    actualPerInmatesCost: newApplicationData.actualPerInmatesCost,
                    grandsInAid: newApplicationData.grandsInAid,
                    share: newApplicationData.share,
                    isAgencyAgreeToBearCost: newApplicationData.isAgencyAgreeToBearCost,
                    isAgencyAgreeToShare: newApplicationData.isAgencyAgreeToShare,
                    isCostDetermineByPWD: newApplicationData.isCostDetermineByPWD
                },
                buildingPlan: {
                    isDrawingPrepared: newApplicationData.isDrawingPrepared,
                    isPlanApproved: newApplicationData.isPlanApproved,
                    plinthArea: newApplicationData.plinthArea
                },
                facilities: {
                    other: newApplicationData.other,
                    otherDetails: newApplicationData.otherDetails,
                    cleaning: newApplicationData.cleaning,
                    bathingAndWashing: newApplicationData.bathingAndWashing,
                    drinkingWater: newApplicationData.drinkingWater,
                    gameAndSport: newApplicationData.gameAndSport,
                    appointmentOfCook: newApplicationData.appointmentOfCook,
                    medicalCare: newApplicationData.medicalCare,
                    coaching: newApplicationData.coaching
                },
                facilitiesTiedUp: newApplicationData.facilitiesTiedUp,
                sourceOfFundingForCommonFacilities: newApplicationData.sourceOfFundingForCommonFacilities,
                studentSecurity: {
                    fireSafety: newApplicationData.fireSafety,
                    boundaryWall: newApplicationData.boundaryWall,
                    appointmentOfWarder: newApplicationData.appointmentOfWarder
                },
                populationDetails: {
                    totalDistrictPopulation: Number(newApplicationData.totalDistrictPopulation),
                    scDistrictPopulation: Number(newApplicationData.scDistrictPopulation),
                    percentageOfSC: newApplicationData.percentageOfSC,
                    percentageOfSCFemaleMaleLiteracy: newApplicationData.percentageOfSCFemaleMaleLiteracy,
                    percentageOfSCMaleLiteracy: newApplicationData.percentageOfSCMaleLiteracy
                },
                scHostelDetails: {
                    scHostelInDistrict: Number(newApplicationData.scHostelInDistrict),
                    scHostelInBlock: Number(newApplicationData.scHostelInBlock),
                    operationalSCHostel: Number(newApplicationData.operationalSCHostel)
                },
                isHostelAttachedToInstitute: newApplicationData.isHostelAttachedToInstitute,
                instituteName: newApplicationData.instituteName,
                instituteAddress: newApplicationData.instituteAddress,
                sourceOfFundForMaintenance: newApplicationData.sourceOfFundForMaintenance,
                isFacilityAvailableForDisableStudent: newApplicationData.isFacilityAvailableForDisableStudent,
                facilityDetailsForDisables: newApplicationData.facilityDetailsForDisables,
                noOfStudentStudyingInAttachedInstitute: Number(newApplicationData.noOfStudentStudyingInAttachedInstitute),
                documents: {
                    formSubmittedByState: newApplicationData.formSubmittedByState,
                    justification: newApplicationData.justification,
                    annexure: newApplicationData.annexure,
                    sitePlanSigned: newApplicationData.sitePlanSigned,
                    costEstimateSigned: newApplicationData.costEstimateSigned,
                    buildingPlanSigned: newApplicationData.buildingPlanSigned,
                    isLandOwnershipDocumentAuthenticate: newApplicationData.isLandOwnershipDocumentAuthenticate
                },
                otherRelevantDetails: newApplicationData.otherRelevantDetails
            }

            formData.append("applicationData", JSON.stringify(dataToSend))
            dispatch(showLoader());
            const serverResponse = await userCommunication.SubmitNewApplication(formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                navigation("/user/my-application");
                swal({ text: serverResponse?.data?.message, icon: "success" });
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }
    //close form modal
    const closeFormModal = () => {
        setShowFormModal(false);
    }

    return (
        <>
            {showFormModal === false &&
                < div className="form_main">
                    <div className="form_heading">
                        <H5 whiteThemeColor darkThemeColor>Format for sending new proposal for seeking Center Assistance under the Scheme (BJRCY) </H5>
                    </div>
                    <div className="application_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #eab7f5" : "1px solid #fff" }}>
                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>1. Name of the Implementing Agency</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="agency" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Agency Name" />
                                {validator.message("agency", newApplicationData.agency, "required", {
                                    messages: {
                                        required: "Agency Name is required"
                                    }
                                })}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6>2. Nature of implementing Agencies :</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <select name="natureOfAgency" onChange={(event) => getApplicationDetail(event)} className="form-select"  >
                                    <option value="">Select Implementing Agency</option>
                                    <option value="State Government/UT">State Government/UT</option>
                                    <option value="Central University/Institution">Central University/Institution</option>
                                    <option value="State University/Institution">State University/Institution</option>
                                </select>
                                {validator.message("natureOfAgency", newApplicationData.natureOfAgency, "required", {
                                    messages: {
                                        required: "Agency Nature is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>3. Level for which the hostel is proposed to be constructed :</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Pre-Matric</Label>
                                        <input type="radio" name="hostelLevel" value="Pre-Matric" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Post-Matric</Label>
                                        <input type="radio" name="hostelLevel" value="Post-Matric" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                </div>
                                {validator.message("hostelLevel", newApplicationData.hostelLevel, "required", {
                                    messages: {
                                        required: "Hostel propose to be constructed is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>Category :</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Boys</Label>
                                        <input type="radio" name="category" value="Boys" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Girls</Label>
                                        <input type="radio" name="category" value="Girls" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("category", newApplicationData.category, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>4. Number of inmates for which the hostel is proposed to be constructed :</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="noOfInmates" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Number of inmates" />
                                {validator.message("noOfInmates", newApplicationData.noOfInmates, "required", {
                                    messages: {
                                        required: "Number of inmates is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>4.1 Composition of Inmates Identified :</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4">
                                        <input type="number" name="SC" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="SC" />
                                        {validator.message("SC", newApplicationData.SC, "required", {
                                            messages: {
                                                required: "SC Inmates is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <input type="number" name="ST" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="ST" />
                                        {validator.message("ST", newApplicationData.ST, "required", {
                                            messages: {
                                                required: "ST Inmates is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <input type="number" name="OBC" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="OBC" />
                                        {validator.message("OBC", newApplicationData.OBC, "required", {
                                            messages: {
                                                required: "OBC Inmates is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <input type="number" name="general" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="General" />
                                        {validator.message("general", newApplicationData.general, "required", {
                                            messages: {
                                                required: "general Inmates is required"
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>5. Location of proposed hostel (existing hostel in case of expansion) with detailed
                                    address (please annex site map/plan) :</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <textarea type="text" name="hostelLocation" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Location of proposed hostel"  ></textarea>
                                {validator.message("hostelLocation", newApplicationData.hostelLocation, "required", {
                                    messages: {
                                        required: "Hostel Location is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>6. Land details :</H6>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>i) Whether land has been acquired by the implementing agency for construction/
                                    expansion of the proposed hostel? </H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isLandAcquiredByAgency" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isLandAcquiredByAgency" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                </div>
                                {validator.message("isLandAcquiredByAgency", newApplicationData.isLandAcquiredByAgency, "required", {
                                    messages: {
                                        required: "Agency Aquire Land is required"
                                    }
                                })}
                            </div>
                        </div>

                        {newApplicationData.isLandAcquiredByAgency === "Yes" &&
                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>If yes, whether the land is encumbrance free and in physical possession of the
                                        implementing agency?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isLandEncumbranceFree" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isLandEncumbranceFree" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                        </div>
                                    </div>
                                    {validator.message("isLandEncumbranceFree", newApplicationData.isLandEncumbranceFree, "required", {
                                        messages: {
                                            required: "Land Encumbrance free is required"
                                        }
                                    })}
                                </div>
                            </div>
                        }

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>ii) Area of land</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="area" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Area in sq ft" />
                                {validator.message("area", newApplicationData.area, "required|numeric", {
                                    messages: {
                                        required: "Area of Land is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>7. Time to be taken for completion of construction of the proposed hostel building and
                                    likely date of completion (Period for completion of construction of hostel should not
                                    exceed 2 years from the date of sanction of the project by the Ministry) :</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="number" name="completionPeriod" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Period for completion (in months)" />
                                {validator.message("completionPeriod", newApplicationData.completionPeriod, "required|numeric", {
                                    messages: {
                                        required: "Period of Completion is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>8. Cost estimates details :</H6>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>A. i) Total estimated cost of the proposed hostel</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="totalCost" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Total estimated cost" />
                                {validator.message("totalCost", newApplicationData.totalCost, "required|numeric", {
                                    messages: {
                                        required: "Total Estimated Cost is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor> (ii) Per inmate cost admissible to the implementing agency under the scheme</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="number" name="perInmatesCost" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Per inmate cost" />
                                {validator.message("perInmatesCost", newApplicationData.perInmatesCost, "required|numeric", {
                                    messages: {
                                        required: "Per Inmates Cost is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor> (iii) Actual per inmate proposed</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="actualPerInmatesCost" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Actual per inmate prposed" />
                                {validator.message("actualPerInmatesCost", newApplicationData.actualPerInmatesCost, "required|numeric", {
                                    messages: {
                                        required: "Actual Per Inmates Cost is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor> iv) Grants-in-aid sought under BJRCY</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="grandsInAid" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Grants-in-aid sought" />
                                {validator.message("grandsInAid", newApplicationData.grandsInAid, "required|numeric", {
                                    messages: {
                                        required: "Grands in Aid is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor> v) Share of State/UT Central/State University/Institute for the proposed hostel</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="share" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="State/UT Central/State University/Institute" />
                                {validator.message("share", newApplicationData.share, "required|numeric", {
                                    messages: {
                                        required: "Share is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>B. Whether cost estimates are determined by PWD norms/PAR?</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isCostDetermineByPWD" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isCostDetermineByPWD" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("isCostDetermineByPWD", newApplicationData.isCostDetermineByPWD, "required", {
                                    messages: {
                                        required: "Cost Determined by PWD is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>C. Whether the implementing agency agrees to share the construction cost of hostel
                                    building as per provision under the scheme, BJRCY?</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isAgencyAgreeToShare" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isAgencyAgreeToShare" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("isAgencyAgreeToShare", newApplicationData.isAgencyAgreeToShare, "required", {
                                    messages: {
                                        required: "Agency agree to share is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>D. Whether the implementing agency agrees to bear the cost over and above the estimated
                                    cost of construction, if any?</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isAgencyAgreeToBearCost" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isAgencyAgreeToBearCost" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("isAgencyAgreeToBearCost", newApplicationData.isAgencyAgreeToBearCost, "required", {
                                    messages: {
                                        required: "Agency agree to Bear Cost is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>9. Details of Building Plan :</H6>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>i) Whether building plan/Building Elevation drawing of the proposed hostel has been
                                    prepared?</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isDrawingPrepared" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isDrawingPrepared" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("isDrawingPrepared", newApplicationData.isDrawingPrepared, "required", {
                                    messages: {
                                        required: "Elevation Drawing is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>ii) Whether building plan has been apporoved by PWD?</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isPlanApproved" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isPlanApproved" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("isPlanApproved", newApplicationData.isPlanApproved, "required", {
                                    messages: {
                                        required: "Plan Approved is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iii) Please indicate per inmate plinth area</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="plinthArea" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Plinth area" />
                                {validator.message("plinthArea", newApplicationData.plinthArea, "required|numeric", {
                                    messages: {
                                        required: "Plinth area is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>10. Has the availability of the following common facilities been tied up?( Please tick):</H6>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>i) In-house coaching</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="coaching" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="coaching" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("coaching", newApplicationData.coaching, "required", {
                                    messages: {
                                        required: "In-house Coaching is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>ii) Medical Care</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="medicalCare" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="medicalCare" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("medicalCare", newApplicationData.medicalCare, "required", {
                                    messages: {
                                        required: "Medical Care is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iii) Appointment of cook, helpers, etc.</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="appointmentOfCook" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="appointmentOfCook" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("appointmentOfCook", newApplicationData.appointmentOfCook, "required", {
                                    messages: {
                                        required: "Appointment of cook is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iv) Games & Sports and recreational facilities</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="gameAndSport" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="gameAndSport" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("gameAndSport", newApplicationData.gameAndSport, "required", {
                                    messages: {
                                        required: "Sport Facilities is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>v) Drinking Water</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="drinkingWater" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="drinkingWater" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("drinkingWater", newApplicationData.drinkingWater, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>vi) Bathing & Washing</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="bathingAndWashing" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="bathingAndWashing" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("bathingAndWashing", newApplicationData.bathingAndWashing, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>vii) Sanitation & Cleaning</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="cleaning" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="cleaning" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("cleaning", newApplicationData.cleaning, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>viii) Others</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="other" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="other" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    {newApplicationData.other === "Yes" &&
                                        <div className="col-lg-6 col-md-6">
                                            <input type="text" name="otherDetails" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Others" />
                                            {validator.message("otherDetails", newApplicationData.otherDetails, "required", {
                                                messages: {
                                                    required: "Other Detail is required"
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                                {validator.message("other", newApplicationData.other, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>10. 1 How have availability of these common facilities been tied up?</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="facilitiesTiedUp" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Availability of common facilities" />
                                {validator.message("facilitiesTiedUp", newApplicationData.facilitiesTiedUp, "required", {
                                    messages: {
                                        required: "Facilities Tied Up is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>10. 2 Source of funding for providing above common facilities</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="sourceOfFundingForCommonFacilities" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Source of funding" />
                                {validator.message("sourceOfFundingForCommonFacilities", newApplicationData.sourceOfFundingForCommonFacilities, "required", {
                                    messages: {
                                        required: "Source of Funding is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-12">
                                <H6 whiteThemeColor darkThemeColor>11). Have following provisions been planned for safety/ security of students:</H6>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>i) Fire Safety</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="fireSafety" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="fireSafety" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("fireSafety", newApplicationData.fireSafety, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>ii) Construction of boundary wall</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="boundaryWall" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="boundaryWall" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("boundaryWall", newApplicationData.boundaryWall, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iii) Appointment of Warden & Chowkidar in the hostel </H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="appointmentOfWarder" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="appointmentOfWarder" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" />
                                    </div>
                                </div>
                                {validator.message("appointmentOfWarder", newApplicationData.appointmentOfWarder, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>



                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-12">
                                <H6 whiteThemeColor darkThemeColor>12. Details of Population :</H6>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>i) Total population of the district where hostel is proposed to be constructed</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="totalDistrictPopulation" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Total Population" />
                                {validator.message("totalDistrictPopulation", newApplicationData.totalDistrictPopulation, "required|numeric", {
                                    messages: {
                                        required: "Total Population is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>ii) SC population in the district</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="scDistrictPopulation" onChange={(event) => getApplicationDetail(event, "calculateScPercentage")} className="form-control" placeholder="SC Population" />
                                {validator.message("scDistrictPopulation", newApplicationData.scDistrictPopulation, "required", {
                                    messages: {
                                        required: "Sc District Population is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iii) Percentage(%) SC population in the district</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="percentageOfSC" value={newApplicationData.percentageOfSC} className="form-control" placeholder="% of SC Population" />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iv) Percentage(%) SC literacy in the district</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4">
                                        <input type="text" name="percentageOfSCMaleLiteracy" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Male" />
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <input type="text" name="percentageOfSCFemaleMaleLiteracy" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Female" />
                                    </div>
                                </div>
                                {validator.message("percentageOfSCMaleLiteracy", newApplicationData.percentageOfSCMaleLiteracy, "required|numeric", {
                                    messages: {
                                        required: "Percentage of sc Male in district is required"
                                    }
                                })}
                            </div>

                        </div>

                        {/* <div className="row mb-4">
                    <div className="col-lg-8">
                        <input type="text" name="12" className="form-control" placeholder="Please indicate" />
                    </div>
                </div> */}

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-4">
                                <H6 whiteThemeColor darkThemeColor>13. Details of SC hostels :</H6>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>i) Number of SC hostels available in the district</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="scHostelInDistrict" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Number of SC Hostels in District" />
                                {validator.message("scHostelInDistrict", newApplicationData.scHostelInDistrict, "required|numeric", {
                                    messages: {
                                        required: "Number of SC Hostel in district is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>ii) Number of SC hostels already available in the Block of the proposed hostel</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="scHostelInBlock" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Number of SC Hostels in the Block" />
                                {validator.message("scHostelInBlock", newApplicationData.scHostelInBlock, "required|numeric", {
                                    messages: {
                                        required: "Number of SC Hostels available in block is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iii) Of these, number of SC hostels operational</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="operationalSCHostel" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Number of SC Hostels operational" />
                                {validator.message("operationalSCHostel", newApplicationData.operationalSCHostel, "required|numeric", {
                                    messages: {
                                        required: "Operational sc Hostels is required"
                                    }
                                })}
                            </div>
                        </div>

                        {/* <div className="row mb-4">
                    <div className="col-lg-8">
                        <input type="text" name="13" className="form-control" placeholder="Please indicate" />
                    </div>
                </div> */}


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>14. Whether the proposed hostel is attached to an existing educational institution? If
                                    so, give name and full address of the institution?</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isHostelAttachedToInstitute" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isHostelAttachedToInstitute" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                </div>
                                {validator.message("isHostelAttachedToInstitute", newApplicationData.isHostelAttachedToInstitute, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>
                        {newApplicationData.isHostelAttachedToInstitute === "Yes" &&
                            <>
                                <div className="row mb-4">
                                    <div className="col-lg-4 col-md-5">
                                        <H6 whiteThemeColor darkThemeColor>i) Name of the institution</H6>
                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <input type="text" name="instituteName" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Institution Name" />
                                        {validator.message("instituteName", newApplicationData.instituteName, "required", {
                                            messages: {
                                                required: "Name of Institution is required"
                                            }
                                        })}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-4 col-md-5">
                                        <H6 whiteThemeColor darkThemeColor>ii) Full address of the institution</H6>
                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <textarea name="instituteAddress" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Address"  ></textarea>
                                        {validator.message("instituteAddress", newApplicationData.instituteAddress, "required", {
                                            messages: {
                                                required: "Address of Institution is required"
                                            }
                                        })}
                                    </div>
                                </div>
                            </>
                        }

                        {/* <div className="row mb-4">
                    <div className="col-lg-4 col-md-4">
                        <H6 whiteThemeColor darkThemeColor>If no, give name and distance of educational institutions, to which the proposed
                            hostel will cater to. (list of such institutions may be attached)</H6>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <input type="text" name="14" className="form-control" placeholder="list of such institutions may be attached" />
                    </div>
                </div> */}


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>15. Source(s) of funds for running and maintenance of the proposed hostel, including
                                    expenditure on provision of warden and guard in girls' hostel</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="sourceOfFundForMaintenance" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Source of fund" />
                                {validator.message("sourceOfFundForMaintenance", newApplicationData.sourceOfFundForMaintenance, "required", {
                                    messages: {
                                        required: "Source of Fund for Maintenance is required"
                                    }
                                })}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>16. Whether facilities for student with disabilities like barrier free rooms/blocks,
                                    ramps, etc. have been incorporated in the design of the hostel?</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isFacilityAvailableForDisableStudent" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isFacilityAvailableForDisableStudent" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    {newApplicationData.isFacilityAvailableForDisableStudent === "Yes" &&
                                        <div className="col-lg-4 col-md-6">
                                            <textarea name="facilityDetailsForDisables" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="please specify details"></textarea>
                                            {validator.message("facilityDetailsForDisables", newApplicationData.facilityDetailsForDisables, "required", {
                                                messages: {
                                                    required: "Detail is required"
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                                {validator.message("isFacilityAvailableForDisableStudent", newApplicationData.isFacilityAvailableForDisableStudent, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-4">
                                <H6 whiteThemeColor darkThemeColor>17. Students' availability</H6>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>i) Number of SC students studing in the educational institution to which the hostel
                                    will be attached</H6>
                                <H6 whiteThemeColor darkThemeColor>or</H6>
                                <H6 whiteThemeColor darkThemeColor>Number of SC students studing in the feeder educational institution, if case stand
                                    alone hostel proposed.</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="noOfStudentStudyingInAttachedInstitute" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="No of SC students" />
                                {validator.message("noOfStudentStudyingInAttachedInstitute", newApplicationData.noOfStudentStudyingInAttachedInstitute, "required|numeric", {
                                    messages: {
                                        required: "Number of SC Student is required"
                                    }
                                })}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-lg-12">
                                <H6 whiteThemeColor darkThemeColor>18. Whether following documents/information have been attached with the proposal(Please
                                    tick YES or NO)</H6>
                            </div>

                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>i) Whether submitted through State Govt./Administrative Ministry/Department (in case
                                    of University or Institution)</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="formSubmittedByState" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="formSubmittedByState" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    {newApplicationData.formSubmittedByState === "Yes" &&
                                        <div className="col-lg-5 col-md-6">
                                            <input type="file" name="form" onChange={(event) => getDocuments(event)} className="form-control" />
                                            {validator.message("form", documents.form, "required", {
                                                messages: {
                                                    required: "File is required"
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                                {validator.message("formSubmittedByState", newApplicationData.formSubmittedByState, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>ii) Justification for the proposed hostel</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="justification" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="justification" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    {newApplicationData.justification === "Yes" &&
                                        <div className="col-lg-5 col-md-6">
                                            <input type="file" name="justificationCertificate" onChange={(event) => getDocuments(event)} className="form-control" />
                                            {validator.message("justificationCertificate", documents.justificationCertificate, "required", {
                                                messages: {
                                                    required: "File is required"
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                                {validator.message("justification", newApplicationData.justification, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iii) Annexure-I(duly filled in)</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="annexure" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="annexure" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    {newApplicationData.annexure === "Yes" &&
                                        <div className="col-lg-5 col-md-6">
                                            <input type="file" name="annexureCertificate" onChange={(event) => getDocuments(event)} className="form-control" />
                                            {validator.message("annexureCertificate", documents.annexureCertificate, "required", {
                                                messages: {
                                                    required: "File is required"
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                                {validator.message("annexure", newApplicationData.annexure, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iv) Cost Estimate signed PWD/CPWD/Govt. Construction agency/construction department
                                </H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="costEstimateSigned" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="costEstimateSigned" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    {newApplicationData.costEstimateSigned === "Yes" &&
                                        <div className="col-lg-5 col-md-6">
                                            <input type="file" name="costEstimateCertificate" onChange={(event) => getDocuments(event)} className="form-control" />
                                            {validator.message("costEstimateCertificate", documents.costEstimateCertificate, "required", {
                                                messages: {
                                                    required: "File is required"
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                                {validator.message("costEstimateSigned", newApplicationData.costEstimateSigned, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>v) Site plan signed by PWD/CPWD/Govt. Construction agency/construction department
                                </H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="sitePlanSigned" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="sitePlanSigned" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    {newApplicationData.sitePlanSigned === "Yes" &&
                                        <div className="col-lg-5 col-md-6">
                                            <input type="file" name="sitePlanSignedCertificate" onChange={(event) => getDocuments(event)} className="form-control" />
                                            {validator.message("sitePlanSignedCertificate", documents.sitePlanSignedCertificate, "required", {
                                                messages: {
                                                    required: "File is required"
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                                {validator.message("sitePlanSigned", newApplicationData.sitePlanSigned, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>vi) Building plan signed by PWD/CPWD/Govt. Construction agency/construction
                                    department</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="buildingPlanSigned" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="buildingPlanSigned" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    {newApplicationData.buildingPlanSigned === "Yes" &&
                                        <div className="col-lg-5 col-md-6">
                                            <input type="file" name="buildingPlanSignedCertificate" onChange={(event) => getDocuments(event)} className="form-control" />
                                            {validator.message("buildingPlanSignedCertificate", documents.buildingPlanSignedCertificate, "required", {
                                                messages: {
                                                    required: "File is required"
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                                {validator.message("buildingPlanSigned", newApplicationData.buildingPlanSigned, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>vii) Land ownership document authenticated by State Govt. </H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isLandOwnershipDocumentAuthenticate" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isLandOwnershipDocumentAuthenticate" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" />
                                    </div>
                                    {newApplicationData.isLandOwnershipDocumentAuthenticate === "Yes" &&
                                        <div className="col-lg-5 col-md-6">
                                            <input type="file" name="landOwnershipAuthenticateCertificate" onChange={(event) => getDocuments(event)} className="form-control" />
                                            {validator.message("landOwnershipAuthenticateCertificate", documents.landOwnershipAuthenticateCertificate, "required", {
                                                messages: {
                                                    required: "File is required"
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                                {validator.message("isLandOwnershipDocumentAuthenticate", newApplicationData.isLandOwnershipDocumentAuthenticate, "required", {
                                    messages: {
                                        required: "Choose at least One Option"
                                    }
                                })}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>19. Other relevants details(if any)</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <textarea name="otherRelevantDetails" className="form-control" onChange={(event) => getApplicationDetail(event)} placeholder="other details..." ></textarea>
                            </div>
                        </div>


                        <div className="row mt-2 mb-lg-4 mb-md-5">
                            <div className="col-12 button_group">
                                <button onClick={() => submitApplication()}>Submit</button>
                                <button className="ms-3" onClick={() => navigation("/user/latest-notification")}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showFormModal &&
                <div className="newApplication_modal_main" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                    {
                        loader.showHideLoader === true ?
                            <CommonLoader />
                            :
                            <div className="newApplication_modal" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #eab7f5" : "1px solid #fff" }}>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <H6 whiteThemeColor darkThemeColor>Select Whome You Want to Submit Application</H6>
                                        <select name="submittedTo" onChange={(event) => getApplicationDetail(event)} className="form-select mt-2">
                                            <option value="">Select Application Submit To</option>
                                            <option value="Division">Division</option>
                                            <option value="District">District</option>
                                            <option value="State">State</option>
                                        </select>
                                        {validator.message("submittedTo", newApplicationData.submittedTo, "required", {
                                            messages: {
                                                required: "Select Application Submit To"
                                            }
                                        })}
                                    </div>
                                </div>
                                {((newApplicationData.submittedTo === "Division") || (newApplicationData.submittedTo === "District")) &&
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <select name="division" onChange={(event) => { getApplicationDetail(event); divisionWiseDistricts(event) }} className="form-select">
                                                <option value="">Select Division</option>
                                                {divisionArray?.map((divisionList, index) => (
                                                    <option value={divisionList?.division} key={index}>{divisionList?.division}</option>
                                                ))}
                                            </select>
                                            {validator.message("division", newApplicationData.division, "required", {
                                                messages: {
                                                    required: "Division is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                }
                                {newApplicationData.submittedTo === "District" &&
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <select name="district" onChange={(event) => getApplicationDetail(event)} className="form-select">
                                                <option>Select District</option>
                                                {district?.map((districtList, index) => (
                                                    <option key={index}>{districtList}</option>
                                                ))}
                                            </select>
                                            {validator.message("district", newApplicationData.district, "required", {
                                                messages: {
                                                    required: "District is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-12 button_group">
                                        <button onClick={() => finalApplicationSubmission()}>Submit</button>
                                        <button className="ms-3" onClick={() => closeFormModal()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            }
        </>
    )
}

export default NewApplication