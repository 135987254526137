import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode'

let decodeValue = null;
//----------------------------------Helper Function------------------------------------------------------
(function getCookie() {
    // Split cookie string and get all individual valueName=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if ("bjrcyUserToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            decodeValue = jwtDecode(cookiePair[1]);
            return decodeValue;
        }
    }
    // Return null if not found
    return null;
})();
//----------------------------------Helper Function------------------------------------------------------

const backOfficeUserDetails = createSlice({
    name: "backOfficeUserDetails",
    initialState: {
        name: decodeValue?.name ?? "",
        designation: decodeValue?.designation ?? "",
        userType: decodeValue?.userType ?? ""
    },

    reducers: {
        addCookies: (state, action) => {
            state.name = action.payload.name;
            state.designation = action.payload.designation;
            state.userType = action.payload.userType;
            document.cookie = `bjrcyUserToken=${action.payload.token};path=/`;
        },
        removeBackOfficeCookies: (state, action) => {
            state.name = "";
            state.designation = "";
            state.userType = "";
            document.cookie = "bjrcyUserToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
    }
})
export const { addCookies, removeBackOfficeCookies } = backOfficeUserDetails.actions;
export default backOfficeUserDetails.reducer;