import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode'

let decodeValue = null;
//----------------------------------Helper Function------------------------------------------------------
(function getCookie() {
    // Split cookie string and get all individual valueName=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if ("bjrcyUserToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            decodeValue = jwtDecode(cookiePair[1]);
            return decodeValue;
        }
    }
    // Return null if not found
    return null;
})();
//----------------------------------Helper Function------------------------------------------------------

const userDetails = createSlice({
    name: "UserDetails",
    initialState: {
        name: decodeValue?.name ?? "",
        email: decodeValue?.email ?? ""
    },

    reducers: {
        addCookies: (state, action) => {
            state.name = action.payload.name;
            state.email = action.payload.email;
            document.cookie = `bjrcyUserToken=${action.payload.token};path=/`;
        },
        removeUserCookies: (state, action) => {
            state.name = "";
            state.email = "";
            document.cookie = "bjrcyUserToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
    }
})
export const { addCookies, removeUserCookies } = userDetails.actions;
export default userDetails.reducer;