import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import useValidator from '../../form-validation/useValidatior';
import H5 from '../../text-components/H5';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { userCommunication } from '../../communication/userCommunication';
import swal from 'sweetalert';

const RaiseGrievance = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const userLoginDetails = useSelector((state) => state.userDetails);
    const dispatch = useDispatch();
    const [validator, showValidationMessage] = useValidator();
    const navigation = useNavigate();

    //getting user id from query params
    const params = new URLSearchParams(useLocation().search);
    const userId = params.get("userId");

    const [grievanceData, setGrievanceData] = useState({
        title: "",
        description: ""
    });

    //getting all inputs value
    const getGrievanceDetail = (event) => {
        setGrievanceData({ ...grievanceData, [event.target.name]: event.target.value });
    }

    //user login
    const raise = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const dataToSend = {
                userId,
                name: userLoginDetails.name,
                title: grievanceData.title,
                description: grievanceData.description
            }

            dispatch(showLoader());
            const serverResponse = await userCommunication.raiseGrievance(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                navigation("/user/grievance");
                swal({ text: serverResponse?.data?.message, icon: "success" });
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <CommonLoader />
                    :
                    <div className="grievance_form_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                        <div className="grievance_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
                            <div className="form_title">
                                <H5 whiteThemeColor darkThemeColor>Raise Grievance</H5>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12">
                                    <input type="text" name="title" onChange={(event) => getGrievanceDetail(event)} className="form-control" placeholder="Enter Title" />
                                    {validator.message("title", grievanceData.title, "required", {
                                        messages: {
                                            required: "Title is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12">
                                    <textarea name="description" onChange={(event) => getGrievanceDetail(event)} className="form-control" placeholder="Write your query..."></textarea>
                                    {validator.message("description", grievanceData.description, "required", {
                                        messages: {
                                            required: "Description is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="button_group">
                                <button onClick={() => raise()}>Submit</button>
                                <button className="ms-3" onClick={() => navigation("/user/grievance")}>Cancel</button>
                            </div>

                        </div>
                    </div>
            }
        </>
    )
}

export default RaiseGrievance