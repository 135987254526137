import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import Label from '../../text-components/Label';
import useValidator from '../../form-validation/useValidatior';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { userCommunication } from '../../communication/userCommunication';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import swal from 'sweetalert';

const PreviewRepairAndMaintenance = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const backOfficeUser = useSelector((state) => state.backOfficeUserDetails)
    const [validator, showValidationMessage] = useValidator();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(true);
    const [backOfficeStatus, setBackOfficeStatus] = useState("");
    const [repairMaintenanceData, setRepairMaintenanceData] = useState(
        {
            isHostelConstructedByBJRCY: "",
            sanctionDate: "",
            category: "",
            hostelLocation: "",
            totalNoOfInmates: "",
            admittedInmatesDetails: {
                SC: "",
                other: "",
                total: "",
                percentageOfSC: 0,
            },
            facilities: {
                appointmentOfWarder: "",
                availabilityOfWatchmen: "",
                coaching: "",
                medicalCare: "",
                appointmentOfCook: "",
                gameAndSport: "",
                drinkingWater: "",
                bathingAndWashing: "",
                cleaning: "",
                other: "",
            },
            estimatedCost: "",
            isAgencyAgreeToBearCost: "",
            bearCostDetails: "",
            agency: "",
            remark: "",
        }
    );

    const [documents, setDocuments] = useState({
        studentList: "",
        costEstimateCertificate: "",
        centralAssistCertificate: "",
    });

    const [documentAttached, setDocumentAttached] = useState(
        {
            isStudentListUpdated: false,
            isCostEstimatedCertificateUpdated: false,
            isCentralAssistCertificateUpdated: false
        }
    )

    const [comment, setComment] = useState("");
    const [approveCertificate, setApproveCertificate] = useState();
    //get notification id from params
    const params = new URLSearchParams(useLocation().search);
    const id = params.get("id");
    const applicationId = params.get("applicationId");
    const [userId, setUserId] = useState("");

    //get current url
    const fullUrl = window.location.href;
    const splitUrl = fullUrl.split("/")
    const stateUrl = splitUrl.includes("state-selected-applications")
    const finalSelectedUrl = splitUrl.includes("final-selected-applications");
    const districtSelected = splitUrl.includes("district-selected-applications");
    const divisionSelected = splitUrl.includes("division-selected-applications");

    //get all inputs value
    const getApplicationDetail = (event) => {
        setRepairMaintenanceData({ ...repairMaintenanceData, [event.target.name]: event.target.value });
    }

    //get all files value
    const getDocuments = (event, fileAttached) => {
        if (fileAttached === "studentList") {
            setDocumentAttached({ ...documentAttached, isStudentListUpdated: true });
            setDocuments({ ...documents, [event.target.name]: event.target.files[0] });
        } else if (fileAttached === "costEstimateCertificate") {
            setDocumentAttached({ ...documentAttached, isCostEstimatedCertificateUpdated: true });
            setDocuments({ ...documents, [event.target.name]: event.target.files[0] });
        } else if (fileAttached === "centralAssistCertificate") {
            setDocumentAttached({ ...documentAttached, isCentralAssistCertificateUpdated: true });
            setDocuments({ ...documents, [event.target.name]: event.target.files[0] });
        } else {
            setDocuments({ ...documents, [event.target.name]: event.target.files[0] });
        }
    }

    //Get user Application Detail by id
    const fetchUserApplicationById = async () => {
        try {
            dispatch(showLoader());
            const responseFromServer = await userCommunication.getUserApplicationById(id);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                if (["Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(responseFromServer?.data?.applications?.applicationStatus) && [undefined, null, ""].includes(backOfficeUser.userType)) {
                    setIsSubmitted(false);
                } else {
                    setIsSubmitted(true);
                }
                setUserId(responseFromServer?.data?.applications?.userId);
                setBackOfficeStatus(responseFromServer?.data?.applications?.backOfficeStatus);
                setRepairMaintenanceData(responseFromServer?.data?.applications);
                setDocuments(responseFromServer?.data?.applications);
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }


    //show modal on submit
    const updateRepairAndMaintenance = async () => {

        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const formData = new FormData();
            if (documentAttached.isStudentListUpdated === true) {
                formData.append("studentList", documents.studentList);
            }

            if (documentAttached.isCostEstimatedCertificateUpdated === true) {
                formData.append("costEstimateCertificate", documents.costEstimateCertificate);
            }

            if (documentAttached.isCentralAssistCertificateUpdated === true) {
                formData.append("centralAssistCertificate", documents.centralAssistCertificate);
            }

            const dataToSend = {
                id,
                isHostelConstructedByBJRCY: repairMaintenanceData.isHostelConstructedByBJRCY,
                sanctionDate: repairMaintenanceData.sanctionDate,
                category: repairMaintenanceData.category,
                hostelLocation: repairMaintenanceData.hostelLocation,
                totalNoOfInmates: Number(repairMaintenanceData.totalNoOfInmates),
                admittedInmatesDetails: {
                    SC: Number(repairMaintenanceData.admittedInmatesDetails.SC),
                    other: Number(repairMaintenanceData.admittedInmatesDetails.other),
                    total: Number(repairMaintenanceData.admittedInmatesDetails.total),
                    percentageOfSC: Number(repairMaintenanceData.admittedInmatesDetails.percentageOfSC),
                },
                facilities: {
                    appointmentOfWarder: repairMaintenanceData.facilities.appointmentOfWarder,
                    availabilityOfWatchmen: repairMaintenanceData.facilities.availabilityOfWatchmen,
                    coaching: repairMaintenanceData.facilities.coaching,
                    medicalCare: repairMaintenanceData.facilities.medicalCare,
                    appointmentOfCook: repairMaintenanceData.facilities.appointmentOfCook,
                    gameAndSport: repairMaintenanceData.facilities.gameAndSport,
                    drinkingWater: repairMaintenanceData.facilities.drinkingWater,
                    bathingAndWashing: repairMaintenanceData.facilities.bathingAndWashing,
                    cleaning: repairMaintenanceData.facilities.cleaning,
                    other: repairMaintenanceData.facilities.other,
                },
                estimatedCost: Number(repairMaintenanceData.estimatedCost),
                isAgencyAgreeToBearCost: repairMaintenanceData.isAgencyAgreeToBearCost,
                bearCostDetails: repairMaintenanceData.bearCostDetails,
                agency: repairMaintenanceData.agency,
                remark: repairMaintenanceData.remark,
                isStudentListUpdated: documentAttached.isStudentListUpdated,
                isCostEstimatedCertificateUpdated: documentAttached?.isCostEstimatedCertificateUpdated,
                isCentralAssistCertificateUpdated: documentAttached?.isCentralAssistCertificateUpdated
            }

            formData.append("applicationData", JSON.stringify(dataToSend))
            dispatch(showLoader());
            const serverResponse = await userCommunication.updateRepairAndMaintenance(formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                navigation("/user/my-application");
                swal({ text: serverResponse?.data?.message, icon: "success" });
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }

    }


    //Get document from server
    const previewDocument = async (fileUrl) => {
        await userCommunication.getUserDocument(fileUrl);
    }

    //Reject Application 
    const rejectApplication = async () => {
        try {

            //check validation
            if ([undefined, null, ""].includes(comment)) {
                swal({ text: "Comment is required", icon: "warning" });
                return false;
            }

            const dataToSend = {
                applicationId: Number(applicationId),
                id,
                comment
            }

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.rejectApplication(dataToSend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "success" });
                navigation("/back-office/new-applications");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    //Mark Application Incomplete
    const markApplicationIncomplete = async () => {
        try {

            //check validation
            if ([undefined, null, ""].includes(comment)) {
                swal({ text: "Comment is required", icon: "warning" });
                return false;
            }

            const dataToSend = {
                applicationId: Number(applicationId),
                id,
                comment
            }

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.markIncompleteApplication(dataToSend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "success" });
                navigation("/back-office/new-applications");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    //Mark Application Approved
    const markApplicationApproved = async () => {
        try {

            //check validation
            if ([undefined, null, ""].includes(approveCertificate)) {
                swal({ text: "File is required", icon: "warning" });
                return false;
            }

            const formData = new FormData();
            formData.append("approveCertificate", approveCertificate);

            const dataToSend = {
                applicationId: Number(applicationId),
                id,
                userId,
                comment
            }

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.markApproveApplication(formData, dataToSend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "success" });
                navigation("/back-office/new-applications");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchUserApplicationById();
    }, []);

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <CommonLoader />
                    :
                    < div className="form_main">
                        <div className="form_heading">
                            <H5 whiteThemeColor darkThemeColor>Format for seeking 'Repair and Maintenance cost' under the Scheme (BJRCY)</H5>
                        </div>
                        <div className="application_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #eab7f5" : "1px solid #fff" }}>
                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>1. Whether the hostel has been constructed under the Scheme, BJRCY of Ministry of Social Justice & Empowerment? Please also mention date of sanction of the hostel by the Ministry of SJ&E</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isHostelConstructedByBJRCY" value="Yes" checked={repairMaintenanceData.isHostelConstructedByBJRCY === "Yes" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isHostelConstructedByBJRCY" value="No" checked={repairMaintenanceData.isHostelConstructedByBJRCY === "No" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isHostelConstructedByBJRCY", repairMaintenanceData.isHostelConstructedByBJRCY, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>
                            {repairMaintenanceData.isHostelConstructedByBJRCY === "Yes" &&
                                <div className="row mb-4">
                                    <div className="col-lg-4 col-md-5 col-6">
                                        <H6 whiteThemeColor darkThemeColor>Date of Sanction : </H6>
                                    </div>
                                    <div className="col-lg-4 col-md-5 col-4">
                                        <input type="date" name="sanctionDate" value={repairMaintenanceData.sanctionDate} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Agency Name" disabled={isSubmitted} />
                                        {validator.message("sanctionDate", repairMaintenanceData.sanctionDate, "required", {
                                            messages: {
                                                required: "Sanction Date is required"
                                            }
                                        })}
                                    </div>
                                </div>
                            }

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>2. Category</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Boys</Label>
                                            <input type="radio" name="category" value="Boys" checked={repairMaintenanceData.category === "Boys" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-3" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Girls</Label>
                                            <input type="radio" name="category" value="Girls" checked={repairMaintenanceData.category === "Girls" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-3" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("category", repairMaintenanceData.category, "required", {
                                        messages: {
                                            required: "Choose at least on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>3. Location of the hostel : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <textarea name="hostelLocation" value={repairMaintenanceData.hostelLocation} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Address..." disabled={isSubmitted} ></textarea>
                                    {validator.message("hostelLocation", repairMaintenanceData.hostelLocation, "required", {
                                        messages: {
                                            required: "Hostel Location is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>4. Total number of inmates/seats approved by the Ministry of SJ&E while sanctioning the hostel</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="number" name="totalNoOfInmates" value={repairMaintenanceData.totalNoOfInmates} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Total Number of Inmates" disabled={isSubmitted} />
                                    {validator.message("totalNoOfInmates", repairMaintenanceData.totalNoOfInmates, "required", {
                                        messages: {
                                            required: "Total Number of Inmates is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>5. Total no of student admitted/residing in the hostel at present, including those belonging to other categories</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 mt-lg-0 mt-md-0 mt-3">
                                    <div className="row">
                                        <div className="col-3">
                                            <input type="text" name="SC" value={repairMaintenanceData.admittedInmatesDetails.SC} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, admittedInmatesDetails: { ...repairMaintenanceData.admittedInmatesDetails, SC: event.target.value, total: (Number(repairMaintenanceData.admittedInmatesDetails.other) + Number(event.target.value)), percentageOfSC: (Number(event.target.value) * 100 / (Number(repairMaintenanceData.admittedInmatesDetails.other) + Number(event.target.value)).toFixed()) } })} className="form-control" placeholder="Sc Students" disabled={isSubmitted} />
                                            {validator.message("SC", repairMaintenanceData.admittedInmatesDetails.SC, "required|numeric", {
                                                messages: {
                                                    required: "Number of SC Students is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-3">
                                            <input type="number" name="other" value={repairMaintenanceData.admittedInmatesDetails.other} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, admittedInmatesDetails: { ...repairMaintenanceData.admittedInmatesDetails, other: event.target.value, total: (Number(repairMaintenanceData.admittedInmatesDetails.SC) + Number(event.target.value)), percentageOfSC: (Number(repairMaintenanceData.admittedInmatesDetails.SC) * 100 / (Number(repairMaintenanceData.admittedInmatesDetails.SC) + Number(event.target.value)).toFixed()) } })} className="form-control" placeholder="Other Students" disabled={isSubmitted} />
                                            {validator.message("other", repairMaintenanceData.admittedInmatesDetails.other, "required", {
                                                messages: {
                                                    required: "Number of Other Students is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-3">
                                            <input type="text" name="total" value={repairMaintenanceData.admittedInmatesDetails.total} className="form-control" placeholder="Total" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-3">
                                            <input type="text" name="percentageOfSC" value={repairMaintenanceData.admittedInmatesDetails.percentageOfSC.toFixed(2)} className="form-control" placeholder="Sc Percentage%" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>6. Attach list of student with contact number</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="file" name="studentList" onChange={(event) => getDocuments(event, "studentList")} className="form-control" disabled={isSubmitted} />
                                    {validator.message("studentList", documents.studentList, "required", {
                                        messages: {
                                            required: "File is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-2 col-md-3 col-2 preview_button">
                                    <button onClick={() => previewDocument(documents.studentList)}>Preview</button>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H6 whiteThemeColor darkThemeColor>7. Whether, the State Government/ UT Administration/ University/ Institution have provided following facilities (Please specify clearly for a unit of 100 children) :</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>i) Appointment/Availability of Warden (in case of girl's hostel)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="appointmentOfWarder" value="Yes" checked={repairMaintenanceData.facilities.appointmentOfWarder === "Yes" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, appointmentOfWarder: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="appointmentOfWarder" value="No" checked={repairMaintenanceData.facilities.appointmentOfWarder === "No" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, appointmentOfWarder: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("appointmentOfWarder", repairMaintenanceData.facilities.appointmentOfWarder, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>ii) Availability of Chowkidar/ Watchmen (in case of girl's hostel)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="availabilityOfWatchmen" value="Yes" checked={repairMaintenanceData.facilities.availabilityOfWatchmen === "Yes" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, availabilityOfWatchmen: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="availabilityOfWatchmen" value="No" checked={repairMaintenanceData.facilities.availabilityOfWatchmen === "No" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, availabilityOfWatchmen: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("availabilityOfWatchmen", repairMaintenanceData.facilities.availabilityOfWatchmen, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>iii) In house coaching</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="coaching" value="Yes" checked={repairMaintenanceData.facilities.coaching === "Yes" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, coaching: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="coaching" value="No" checked={repairMaintenanceData.facilities.coaching === "No" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, coaching: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("coaching", repairMaintenanceData.facilities.coaching, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>iv) Medical care</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="medicalCare" value="Yes" checked={repairMaintenanceData.facilities.medicalCare === "Yes" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, medicalCare: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="medicalCare" value="No" checked={repairMaintenanceData.facilities.medicalCare === "No" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, medicalCare: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("medicalCare", repairMaintenanceData.facilities.medicalCare, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>v) Appointment of cook, helper, etc.</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="appointmentOfCook" value="Yes" checked={repairMaintenanceData.facilities.appointmentOfCook === "Yes" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, appointmentOfCook: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="appointmentOfCook" value="No" checked={repairMaintenanceData.facilities.appointmentOfCook === "No" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, appointmentOfCook: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("appointmentOfCook", repairMaintenanceData.facilities.appointmentOfCook, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>vi) Game & Sports and recreational facilities</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="gameAndSport" value="Yes" checked={repairMaintenanceData.facilities.gameAndSport === "Yes" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, gameAndSport: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="gameAndSport" value="No" checked={repairMaintenanceData.facilities.gameAndSport === "No" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, gameAndSport: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("gameAndSport", repairMaintenanceData.facilities.gameAndSport, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>vii) Drinking Water</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="drinkingWater" value="Yes" checked={repairMaintenanceData.facilities.drinkingWater === "Yes" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, drinkingWater: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="drinkingWater" value="No" checked={repairMaintenanceData.facilities.drinkingWater === "No" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, drinkingWater: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("drinkingWater", repairMaintenanceData.facilities.drinkingWater, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>viii) Bathing & Washing</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="bathingAndWashing" value="Yes" checked={repairMaintenanceData.facilities.bathingAndWashing === "Yes" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, bathingAndWashing: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="bathingAndWashing" value="No" checked={repairMaintenanceData.facilities.bathingAndWashing === "No" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, bathingAndWashing: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("bathingAndWashing", repairMaintenanceData.facilities.bathingAndWashing, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>ix) Sanitation & Cleaning</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="cleaning" value="Yes" checked={repairMaintenanceData.facilities.cleaning === "Yes" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, cleaning: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="cleaning" value="No" checked={repairMaintenanceData.facilities.cleaning === "No" ? true : false} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, cleaning: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("cleaning", repairMaintenanceData.facilities.cleaning, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>x) Other : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="other" value={repairMaintenanceData.facilities.other} onChange={(event) => setRepairMaintenanceData({ ...repairMaintenanceData, facilities: { ...repairMaintenanceData.facilities, other: event.target.value } })} className="form-control" placeholder="Other if any.." disabled={isSubmitted} />
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>8. Estimated cost of 'Repair and Maintenance' of hostel(s)</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="estimatedCost" value={repairMaintenanceData.estimatedCost} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Estimated Cost" disabled={isSubmitted} />
                                    {validator.message("estimatedCost", repairMaintenanceData.estimatedCost, "required|numeric", {
                                        messages: {
                                            required: "Estimated Cost is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>Attach details cost estimate determined by PWD/PAR</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="file" name="costEstimateCertificate" onChange={(event) => getDocuments(event, "costEstimateCertificate")} className="form-control" placeholder="Enter Estimated Cost" disabled={isSubmitted} />
                                    {validator.message("costEstimateCertificate", documents.costEstimateCertificate, "required", {
                                        messages: {
                                            required: "File is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-2 col-md-3 col-2 preview_button">
                                    <button onClick={() => previewDocument(documents.costEstimateCertificate)}>Preview</button>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>9. Whether the implementing agency is willing to bear any additional cost incurred on repair and maintenance of the hostel?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isAgencyAgreeToBearCost" value="Yes" checked={repairMaintenanceData.isAgencyAgreeToBearCost === "Yes" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isAgencyAgreeToBearCost" value="No" checked={repairMaintenanceData.isAgencyAgreeToBearCost === "No" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isAgencyAgreeToBearCost", repairMaintenanceData.isAgencyAgreeToBearCost, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>
                            {repairMaintenanceData.isAgencyAgreeToBearCost === "Yes" &&
                                <div className="row mb-4">
                                    <div className="col-lg-4 col-md-5 col-6">
                                        <H6 whiteThemeColor darkThemeColor>Bear Cost Details</H6>
                                    </div>
                                    <div className="col-lg-4 col-md-5 col-4">
                                        <input type="text" name="bearCostDetails" value={repairMaintenanceData.bearCostDetails} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Bear Cost Details" disabled={isSubmitted} />
                                        {validator.message("bearCostDetails", repairMaintenanceData.bearCostDetails, "required", {
                                            messages: {
                                                required: "Bear Cost Details is required"
                                            }
                                        })}
                                    </div>
                                </div>
                            }

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>10. Details of central assistance released earlier for repair and maintenance for the hostel</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="file" name="centralAssistCertificate" onChange={(event) => getDocuments(event, "centralAssistCertificate")} className="form-control" disabled={isSubmitted} />
                                    {validator.message("centralAssistCertificate", documents.centralAssistCertificate, "required", {
                                        messages: {
                                            required: "File is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-2 col-md-3 col-2 preview_button">
                                    <button onClick={() => previewDocument(documents.centralAssistCertificate)}>Preview</button>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>11. Name of Implementing Agency</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="agency" value={repairMaintenanceData.agency} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Name of Agency" disabled={isSubmitted} />
                                    {validator.message("agency", repairMaintenanceData.agency, "required", {
                                        messages: {
                                            required: "Agency Name is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>12. Any Remark</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="remark" value={repairMaintenanceData.remark} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Remark if any" disabled={isSubmitted} />
                                </div>
                            </div>

                            <div className="row mt-2 mb-lg-4 mb-md-5">
                                <div className="col-12 button_group">
                                    {(isSubmitted === false && [undefined, null, ""].includes(backOfficeUser?.userType)) &&
                                        <>
                                            <button onClick={() => updateRepairAndMaintenance()}>Submit</button>
                                        </>
                                    }
                                    {[undefined, null, ""].includes(backOfficeUser?.userType) &&
                                        <button className="ms-3" onClick={() => navigation("/user/my-application")}>Cancel</button>
                                    }
                                </div>
                            </div>

                            {![undefined, null, ""].includes(backOfficeUser.userType) &&
                                <div className="row approve_certificates mb-4">
                                    {![undefined, null, ""].includes(documents.districtApproveCertificate) &&
                                        <div className="col-4 text-center">
                                            <H6>District Approve Certificate</H6>
                                            <div className="col-lg-2 col-md-3 mt-2 preview_button">
                                                <button onClick={() => previewDocument(documents.districtApproveCertificate)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                    {![undefined, null, ""].includes(documents.divisionApproveCertificate) &&
                                        <div className="col-4 text-center">
                                            <H6>Division Approve Certificate</H6>
                                            <div className="col-lg-2 col-md-3 mt-2 preview_button">
                                                <button onClick={() => previewDocument(documents.divisionApproveCertificate)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                    {![undefined, null, ""].includes(documents.stateApproveCertificate) &&
                                        <div className="col-4 text-center">
                                            <H6>State Approve Certificate</H6>
                                            <div className="col-lg-2 col-md-3 mt-2 preview_button">
                                                <button onClick={() => previewDocument(documents.stateApproveCertificate)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            <div className="row mt-2 mb-lg-4 mb-md-5">
                                <div className="col-12 button_group">
                                    {((stateUrl === true) || (finalSelectedUrl === true) || (districtSelected === true) || (divisionSelected === true) || ((["Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["Division"].includes(backOfficeUser.userType))) || ((["Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["State"].includes(backOfficeUser.userType)))) &&
                                        <button className="ms-3" onClick={() => navigation(-1)}>Go back</button>
                                    }
                                </div>
                            </div>
                        </div>

                        {(stateUrl === true) || (finalSelectedUrl === true) || (districtSelected === true) || (divisionSelected === true) || (["Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["Division"].includes(backOfficeUser.userType)) || (["Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["State"].includes(backOfficeUser.userType)) ?
                            <span></span>
                            :
                            <div className="back_office_user_action">
                                {((![undefined, null, ""].includes(backOfficeUser?.userType)) && (["NEW", "Approved_By_District", "Approved_By_Division", "Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus))) &&
                                    <>
                                        <div className="row mb-4">
                                            <div className="col-lg-4 col-md-5">
                                                <H6 whiteThemeColor darkThemeColor>Add Comment</H6>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <textarea name="comment" onChange={(event) => setComment(event.target.value)} className="form-control" placeholder="comment..."></textarea>
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col-lg-4 col-md-5">
                                                <H6 whiteThemeColor darkThemeColor>Upload Document</H6>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <input type="file" name="approveCertificate" onChange={(event) => setApproveCertificate(event.target.files[0])} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="row mt-2 mb-lg-4 mb-md-5">
                                            <div className="col-12 button_group">
                                                <button onClick={() => rejectApplication()}>Reject</button>
                                                <button className="ms-3 me-3" onClick={() => markApplicationIncomplete()}>Incomplete</button>
                                                <button onClick={() => markApplicationApproved()}>Approve</button>
                                                <NavLink to={`/print-repair-and-maintenance?id=${id}`} target="_blank">
                                                    <button className="ms-3">Print</button>
                                                </NavLink>
                                                <button className="ms-3" onClick={() => navigation(-1)}>Cancel</button>
                                            </div>
                                        </div>

                                    </>
                                }

                            </div>
                        }
                    </div>
            }
        </>
    )
}

export default PreviewRepairAndMaintenance