import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userCommunication } from '../../communication/userCommunication';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import swal from 'sweetalert';

const Downloads = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [downloadData, setDownloadData] = useState([]);

  //get all downloads data on initial load
  const fetchDownloads = async () => {
    try {
      dispatch(showLoader());
      const responseFromServer = await userCommunication.getDownloads();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setDownloadData(responseFromServer?.data?.downloads);
        dispatch(hideLoader());
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  const fetchFile = async (fileUrl) => {
    if (fileUrl !== "") {
      await userCommunication.downloadDocument(fileUrl);
    }
  }


  useEffect(() => {
    fetchDownloads();
  }, []);


  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="section">
            <div className="container">
              <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                <div className="row m-0">
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                  </div>
                  <div className="col-3 text_align">
                    <H5 whiteThemeColor darkThemeColor>Title</H5>
                  </div>
                  <div className="col-5 text_align">
                    <H5 whiteThemeColor darkThemeColor>Description</H5>
                  </div>
                  <div className="col-2 align">
                    <H5 whiteThemeColor darkThemeColor>Action</H5>
                  </div>
                </div>
              </div>
              {downloadData?.length > 0 ?
                <>
                  {downloadData?.map((downloadDetail, index) => (
                    <div className="table_data" key={index}>
                      <div className="row m-0">
                        <div className="col-2 text_align">
                          <H6 whiteThemeColor darkThemeColor>{index + 1}</H6>
                        </div>
                        <div className="col-3 text_align">
                          <H6 whiteThemeColor darkThemeColor>{downloadDetail?.title}</H6>
                        </div>
                        <div className="col-5 text_align">
                          <H6 whiteThemeColor darkThemeColor>{downloadDetail?.description}</H6>
                        </div>
                        <div className="col-2 align">
                          <i className="fa-solid fa-download" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#ad5389", cursor: "pointer" }}
                            onClick={() => fetchFile(downloadDetail?.attachment)}></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
                :
                <div className="data_not_available_message">
                  <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Downloads Not Available</span>
                </div>
              }
            </div>
          </div>
      }
    </>
  )
}

export default Downloads