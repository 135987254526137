import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import H5 from '../text-components/H5';
import useValidator from '../form-validation/useValidatior';
import { adminCommunication } from '../communication/adminCommunication';
import { addCookies } from '../redux-store/adminAuthenticateReducer';
import Loader from '../shared-components/Loader';
import { showLoader, hideLoader } from '../redux-store/loaderReducer';
import swal from 'sweetalert';

const SignIn = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validator, showValidationMessage] = useValidator();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const [passwordShow, setPasswordShow] = useState(false);

  //Admin login
  const adminLogin = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      dispatch(showLoader());
      const serverResponse = await adminCommunication.login(userId, password);
      if (serverResponse?.data?.status === "SUCCESS") {
        const dataFromServer = serverResponse?.data?.userData;
        dispatch(addCookies({
          name: dataFromServer?.name,
          designation: dataFromServer?.designation,
          token: serverResponse?.data?.jwtToken
        }));
        dispatch(hideLoader());
        navigate("/admin/dashboard")
        swal({ text: serverResponse?.data?.message, icon: "success" });
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  return (
    <>
      <div className="login_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
        {
          loader.showHideLoader === true ?
            <Loader />
            :
            <div className="login_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Login</H5>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <input type="text" name="userId" onChange={(event) => setUserId(event.target.value)} className="form-control" placeholder="Enter User Id" />
                    {validator.message("userId", userId, "required", {
                      messages: {
                        required: "User id is required"
                      }
                    })}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12 col-md-12">
                    <input type="password" name="password" onChange={(event) => setPassword(event.target.value)} className="form-control" placeholder="Enter Password" />
                    
                   
                  </div>
                </div>
                <div className="button_group mt-3 text-center">
                  <button onClick={() => adminLogin()}>Login</button>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  )
}

export default SignIn