import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import H4 from '../../text-components/H4'
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';

const Feedback = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

  return (
    <div className="tab_content_main">
      <div className="row m-0 mt-2 mb-3">
        <div className="col-12 text-center">
          <H4 whiteThemeColor darkThemColor>Feedback Details List</H4>
        </div>
      </div>

      <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
        <div className="row m-0">
          <div className="col-1 text_align">
            <H5 whiteThemeColor darkThemeColor>Sr. No.</H5>
          </div>
          <div className="col-2 text_align">
            <H5 whiteThemeColor darkThemeColor>Name</H5>
          </div>
          <div className="col-3 text_align">
            <H5 whiteThemeColor darkThemeColor>Email</H5>
          </div>
          <div className="col-4 text_align">
            <H5 whiteThemeColor darkThemeColor>Comments</H5>
          </div>
          <div className="col-2 align">
            <H5 whiteThemeColor darkThemeColor>Posted On</H5>
          </div>
        </div>
      </div>

      <div className="table_data">
        <div className="row m-0">
          <div className="col-1 text_align">
            <H6 whiteThemeColor darkThemeColor>1</H6>
          </div>
          <div className="col-2 text_align">
            <H6 whiteThemeColor darkThemeColor>Anup Thakare</H6>
          </div>
          <div className="col-3 text_align">
            <H6 whiteThemeColor darkThemeColor>anup@getnada.com</H6>
          </div>
          <div className="col-4 text_align">
            <H6 whiteThemeColor darkThemeColor>dgjfdj kfk</H6>
          </div>
          <div className="col-2 align">
            <H6 whiteThemeColor darkThemeColor>13/08/2022</H6>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Feedback