import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import { userCommunication } from '../../communication/userCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { hideLoader, showLoader } from '../../redux-store/loaderReducer';
import { removeUnderscoreFromText } from '../../shared-components/removeUnderScore';
import { capitalizeFirstLetter } from '../../shared-components/capitalizeFirstLetter';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const MyApplication = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const [application, setApplication] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, SetCurrentPage] = useState(0);
  const [pageCount, SetPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const limit = process.env.REACT_APP_LIMIT;

  //onclick set Current page
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    SetCurrentPage(selectedPage);
    if (searchValue) {
      fetchApplicationsBySearch(searchValue, selectedPage + 1);
    } else {
      getApplication(selectedPage + 1);
    }
  }

  //Search Filter
  const searchFilter = async (searchString) => {
    if (searchString) {
      fetchApplicationsBySearch(searchString, currentPage + 1);
    } else {
      const serverResponse = await userCommunication.getUserApplications();
      if (serverResponse?.data?.status === "SUCCESS") {
        setApplication(serverResponse?.data?.applications);
        SetPageCount(serverResponse?.data?.totalPages);
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        swal({ text: serverResponse?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setApplication([]);
        SetPageCount(0);
      }
    }
  }

  //get Applications By Search
  const fetchApplicationsBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await userCommunication.getApplicationsBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setApplication(responseFromServer?.data?.applications);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setApplication([]);
        SetPageCount(0);
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
    }
  }

  //get all applications on initial load
  const getApplication = async (page) => {
    try {
      dispatch(showLoader());
      const serverResponse = await userCommunication.getUserApplications(page);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setApplication(serverResponse?.data?.applications);
        SetPageCount(serverResponse?.data?.totalPages);
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        swal({ text: serverResponse?.data?.message, icon: "warning" });
        navigate("/login");
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        setApplication([]);
        SetPageCount(0);
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });
      dispatch(hideLoader());
    }
  }

  useEffect(() => {
    getApplication();
  }, [])


  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="section">
            <div className="header_wrapper my_application_header_wrapper" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
              <div className="row mb-2 top_search_header_user_portal">
                <div className="col-lg-3 col-md-4">
                  <input Type="text" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search.." />
                </div>
                <div className="col-lg-9 col-md-9 user_portal_pagination">
                  <div className="pagination_div ">
                    {
                      pageCount > 1 &&
                      <div className="pagination_main">
                        <ReactPaginate
                          previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                          nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount} shape="rounded"
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          forcePage={currentPage}
                          activeClassName={"active"} />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="myapplication_main">
              <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                <div className="row m-0">
                  <div className="col-1 text_align">
                    <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                  </div>
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Application Id</H5>
                  </div>
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Application Type</H5>
                  </div>
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Status</H5>
                  </div>
                  <div className="col-3 text_align">
                    <H5 whiteThemeColor darkThemeColor>Comment</H5>
                  </div>
                  <div className="col-2 align">
                    <H5 whiteThemeColor darkThemeColor>Date</H5>
                  </div>
                </div>
              </div>
              {application?.length > 0 ?
                <>
                  {application?.map((ele, index) => {
                    return (
                      <div className="myapplication_data_main">
                        <div className="table_data" key={index}>
                          <div className="row m-0">
                            <div className="col-1 text_align">
                              <H6 whiteThemeColor darkThemeColor>{(limit * (currentPage) + (index + 1))}</H6>
                            </div>
                            <div className="col-2 text_align">
                              <NavLink to={(ele?.formType === "NEW APPLICATION" && ele?.currentForm !== "FIRST_INSTALLMENT") ? `/user/my-application/preview-second-installment?id=${ele?._id}`
                                : (ele?.formType === "REPAIR AND MAINTENANCE") ? `/user/my-application/preview-repair-and-maintenance?id=${ele?._id}`
                                  : `/user/my-application/preview-new-application?id=${ele?._id}`}>
                                <H6 whiteThemeColor darkThemeColor>{ele?.applicationId}</H6>
                              </NavLink>
                            </div>
                            <div className="col-2 text_align">
                              <H6>{ele?.formType === "NEW APPLICATION" ? `New Construction -${removeUnderscoreFromText(capitalizeFirstLetter(ele?.currentForm))}` : capitalizeFirstLetter(ele?.formType)}</H6>
                            </div>
                            <div className="col-2 text_align">
                              <H6 whiteThemeColor darkThemeColor>{removeUnderscoreFromText(capitalizeFirstLetter(ele?.applicationStatus))}</H6>
                            </div>
                            <div className="col-3 text_align">
                              {ele?.comments?.length > 0 ?
                                < H6 whiteThemeColor darkThemeColor>{ele?.comments[ele?.comments.length - 1]}</H6>
                                :
                                < H6 whiteThemeColor darkThemeColor>--</H6>
                              }
                            </div>
                            <div className="col-2 align">
                              <H6 whiteThemeColor darkThemeColor>{new Date(ele?.formSubmittedDate).toLocaleDateString()}</H6>
                            </div>
                          </div>
                        </div>
                        {
                          ele?.formType === "NEW APPLICATION" && (ele?.currentForm === "FIRST_INSTALLMENT" && ele?.isFirstInstallMentComplete === true) &&
                          <div className="second_installment_button">
                            <NavLink to={`/user/my-application/second-installment?applicationId=${ele?.applicationId}`}>
                              <button>2<sup>nd</sup> Installment</button>
                            </NavLink>
                          </div>
                        }
                      </div>
                    )
                  })}
                </>

                :
                <div className="data_not_available_message">
                  <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Application Not Available</span>
                </div>
              }
            </div>
          </div >
      }
    </>
  )
}

export default MyApplication