import React, { useState } from "react";
import { useSelector } from "react-redux";
import './PublicCSS.css';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom"
import H4 from "../text-components/H4";
import H5 from "../text-components/H5";
import H6 from "../text-components/H6";
import { publicCommunication } from "../communication/public-communication";
import useValidator from "../form-validation/useValidatior";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";


const FooterPublic = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [loading, setLoading] = useState(false)
    const [validator, showValidationMessage] = useValidator();
    const location = useLocation();
    const [feedBackData, setFeedBackData] = useState(
        {
            name: "",
            email: "",
            message: ""
        }
    );

    //get all inputs value
    const getFeedbackDetail = (event) => {
        setFeedBackData({ ...feedBackData, [event.target.name]: event.target.value });
    }

    const postuploadFeedback = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            setLoading(true)
            const dataToSend = {
                name: feedBackData.name,
                email: feedBackData.email,
                message: feedBackData.message
            }

            const serverResponse = await publicCommunication.uploadFeedback(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                swal({ text: serverResponse?.data.message, icon: "success" });
                window.location.reload(false);
                setLoading(false)
            } else {
                swal({ text: serverResponse?.data.message, icon: "error" });
                setLoading(false)
            }
        } catch (error) {
            swal({ text: error.message, icon: "error" });
            setLoading(false)
        }

    }


    return (

        <footer style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#000" : "#3c3c3c" }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="widget">
                            <div whiteThemeColor darkThemeColor className="widgetheading">Social Media  </div>
                            <ul className="link-list">
                                <li><a href="https://www.facebook.com/MahaSjsa/" target="_blank"><i className="fa fa-facebook icred"></i></a></li>
                                <li><a href="https://twitter.com/MahaSocialJ" target="_blank"><i className="fa fa-twitter icred"></i></a></li>
                                <li><a href="https://www.instagram.com/mahasjsa/" target="_blank"> <i className="fa fa-instagram"></i></a></li>
                                <li><a href="https://www.youtube.com/channel/UC3rG7F7p_nGZ8aMemOYezwQ" target="_blank"><i className="fa fa-youtube icred"></i> </a></li>
                            </ul>
                            <div className="widgetheading">Contact Us </div>
                            <ul className="contact_detail">
                                <li>
                                    <i className="fa fa-phone icred"></i> 020 - 26127569
                                </li>
                                <li><i className="fa fa-envelope icred"></i> fs.directorsw@gmail.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="widget">
                            <div className="widgetheading">Head Office </div>
                            <p>Babu Jagjivan Ram Chhatrawas Yojna, Government of Maharashtra
                                Director.</p>
                            <p>Social Welfare Department Commissionerate, 3, Church Gate Road
                                Pune - 411001</p>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="widget">
                            <div className="widgetheading">Feedback Form </div>
                            <div className="row">
                                <div className="formgroup col-md-6 col-lg-6">
                                    <input type="text" name="name" onChange={(event) => getFeedbackDetail(event)} className="form-control" placeholder="Enter Full Name" />
                                    {validator.message("name", feedBackData.name, "required", {
                                        messages: {
                                            required: "Name is required"
                                        }
                                    })}
                                </div>
                                <div className="formgroup col-md-6 col-lg-6 mt-2">
                                    <input type="text" name="email" onChange={(event) => getFeedbackDetail(event)} className="form-control" placeholder="Enter Email" />
                                    {validator.message("email", feedBackData.email, "required|email", {
                                        messages: {
                                            required: "Email is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="formgroup col-md-12 mt-2">
                                    <textarea name="message" onChange={(event) => getFeedbackDetail(event)} className="form-control" placeholder="Write your feedback..."></textarea>
                                    {validator.message("message", feedBackData.message, "required", {
                                        messages: {
                                            required: "Feedback is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="btn_footer">

                                <button className="submit_btn" onClick={() => postuploadFeedback()}>
                                    {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {loading && <span></span>}
                                    {!loading && <span>Submit</span>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="websitepolicy">
                    <NavLink to="/Website-Policy" target="_blank">
                        <span>Website Policy</span>
                    </NavLink>
                </div>
            </div>
        </footer>
    );

}

export default FooterPublic;