import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, NavLink } from "react-router-dom";
import { adminCommunication } from '../../communication/adminCommunication';
import { useNavigate } from 'react-router-dom';
import H4 from '../../text-components/H4'
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const UserList = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const limit = process.env.REACT_APP_LIMIT


  //onclick set Current page
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage)
    if (searchValue) {
      fetchUserListBySearch(searchValue, selectedPage + 1);
    } else {
      fetchAllUsers(selectedPage + 1);
    }
  };

  //filter data according to user
  const searchFilter = async (searchString) => {
    if (searchString === "") {
      const serverResponse = await adminCommunication.getAllUsers();
      if (serverResponse?.data?.status === "SUCCESS") {
        setUserDetails(serverResponse?.data?.user);
        setPageCount(serverResponse?.data?.totalPages)
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "warning" });
        dispatch(hideLoader());
      } else {
        setUserDetails([]);
        setPageCount(0);
        swal({ text: serverResponse?.data?.message, icon: "warning" });
      }
    } else {
      fetchUserListBySearch(searchString, currentPage + 1)
    }
  };

  //Fetch users on initial load
  const fetchAllUsers = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await adminCommunication.getAllUsers(page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setUserDetails(responseFromServer?.data?.user);
        setPageCount(responseFromServer?.data?.totalPages);
        dispatch(hideLoader());
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        dispatch(hideLoader());
      } else {
        setUserDetails([]);
        dispatch(hideLoader());
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });
      dispatch(hideLoader());
    }
  };

  //get user list by search
  const fetchUserListBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await adminCommunication.getUserListBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setUserDetails(responseFromServer?.data?.user);
        setPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/admin/login");
      } else {
        setUserDetails([]);
        setPageCount(0);
      }
    } catch (error) {
      swal({ text: error.message, icon: "warning" });
    }
  }

  useEffect(() => {
    fetchAllUsers();
  }, []);


  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="tab_content_main">
            <div className='top_search_header'>
              <div className='title'>
                <H4 whiteThemeColor darkThemColor>User Details List</H4>
              </div>
              <div className="search">
                <input type="text" className="search-input" placeholder="Search .."
                  onChange={(e) => { searchFilter(e.target.value.trim()); setSearchValue(e.target.value.trim()) }} />
                <i className=" search-icon fa-solid fa-magnifying-glass"></i>

              </div>
              <div className='button_group add_button'>
                <NavLink to="/admin/dashboard/user-list/add-user">
                  <button><i className="fa fa-plus me-2"></i>Add New</button>
                </NavLink>
              </div>
              <div className="pagination_div">
                {
                  pageCount>1 &&
                  <div className="pagination_main">
                    <ReactPaginate
                      previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                      nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount} shape="rounded"
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      forcePage={currentPage}
                      activeClassName={"active"} />
                  </div>
                }
              </div>
            </div>
            {/*  <div className="row m-0 ">
              <div className="col-12 ">
              </div>
      </div>*/}
            <div className="table_section">
              <div className="table_main">
                <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                  <div className="sr_no">
                    <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                  </div>
                  <div className="user_id">
                    <H5 whiteThemeColor darkThemeColor>User Id</H5>
                  </div>
                  <div className="full_name">
                    <H5 whiteThemeColor darkThemeColor>Full Name</H5>
                  </div>
                  <div className="zone">
                    <H5 whiteThemeColor darkThemeColor>Zone</H5>
                  </div>
                  <div className="district">
                    <H5 whiteThemeColor darkThemeColor>District</H5>
                  </div>
                  <div className="division">
                    <H5 whiteThemeColor darkThemeColor>Division</H5>
                  </div>
                  <div className="department">
                    <H5 whiteThemeColor darkThemeColor>Department</H5>
                  </div>
                  <div className="designation">
                    <H5 whiteThemeColor darkThemeColor>Designation</H5>
                  </div>
                  <div className="mobile_number">
                    <H5 whiteThemeColor darkThemeColor>Mobile</H5>
                  </div>
                  {/* <div className="state">
                    <H5 whiteThemeColor darkThemeColor>Zone</H5>
                  </div> */}
                  <div className="userAction">
                    <H5 whiteThemeColor darkThemeColor>Action</H5>
                  </div>
                </div>
                {userDetails.length > 0 ? (
                  <>
                    {userDetails?.map((users, index) => (
                      <div className="table_content" key={index}>
                        <div className="sr_no">
                          <H6 whiteThemeColor darkThemeColor>{limit * currentPage + (index + 1)}</H6>
                        </div>
                        <div className="user_id">
                          <H6 whiteThemeColor darkThemeColor>{users?.userId}</H6>
                        </div>
                        <div className="full_name">
                          <H6 whiteThemeColor darkThemeColor>{users?.fullName}</H6>
                        </div>
                        <div className="zone">
                          <H6 whiteThemeColor darkThemeColor>{users?.zone}</H6>
                        </div>
                        <div className="district">
                          <H6 whiteThemeColor darkThemeColor>{[undefined, null, ""].includes(users?.district) ? "-" : users?.district}</H6>
                        </div>
                        <div className="division">
                          <H6 whiteThemeColor darkThemeColor>{[undefined, null, ""].includes(users?.division) ? "-" : users?.division}</H6>
                        </div>
                        <div className="department">
                          <H6 whiteThemeColor darkThemeColor>{users?.department?.department}</H6>
                        </div>
                        <div className="designation">
                          <H6 whiteThemeColor darkThemeColor>{users?.designation?.designation}</H6>
                        </div>
                        <div className="mobile_number">
                          <H6 whiteThemeColor darkThemeColor>{users?.mobileNo}</H6>
                        </div>
                        {/* <div className="state">
                          <H6 whiteThemeColor darkThemeColor>{users?.zone}</H6>
                        </div> */}
                        <div className="userAction">
                          <NavLink to={`/admin/dashboard/user-list/update-user?userId=${users?.userId}`}>
                            <i style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "rgb(173, 83, 137)" }} className="fa-solid fa-pen-to-square" title="Update"></i>
                          </NavLink>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="data_not_available_message">
                    <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>User Not Available</span>
                  </div>
                )}
              </div>
            </div>

          </div>
      }
    </>
  )
}

export default UserList