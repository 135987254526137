import axios from 'axios';
import swal from 'sweetalert';

const developmentURL = "http://localhost:3012";
const contoboURL = "https://bjrcybackend.mattedemolitions.com";
const machineIp = "http://192.168.29.169:3012";


function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if ("bjrcyUserToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}

const getServerUrl = () => {
    const nodeEnvironment = process.env.REACT_APP_NODE_ENV;
    const serverUrl = process.env.REACT_APP_BACKEND_URL;


    if (nodeEnvironment === "development") {
        return developmentURL;
    }

    if (nodeEnvironment === "contobo_dev") {
        return contoboURL;
    }

    if (nodeEnvironment === "production") {
        return serverUrl;
    }

    if (nodeEnvironment === "machine_ip") {
        return machineIp;
    }

    return serverUrl;
}
export const userCommunication = {
    //?---------------------------------Registration API-------------------------------------------------------------------------
    registration: (dataToSend) => {
        return axios.post(`${getServerUrl()}/user/register-user`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    //?---------------------------------Login API-------------------------------------------------------------------------
    login: (dataToSend) => {
        return axios.post(`${getServerUrl()}/user/login`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    forgetPassword: (dataToSend) => {
        return axios.post(`${getServerUrl()}/user/forget-password`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    //?---------------------------------Latest Notification API-------------------------------------------------------------------------
    getLatestNotifications: (page) => {
        return axios.get(`${getServerUrl()}/user/get-latest-notification?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getDocument: (fileUrl) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/user/get-notification-file?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                // resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    isApplicationAvailableToApply: (notificationId) => {
        return axios.post(`${getServerUrl()}/user/is-application-available-to-apply`, { notificationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    SubmitNewApplication: (formData) => {
        return axios.post(`${getServerUrl()}/user/submit-new-application`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    SubmitRepairAndMaintenanceApplication: (formData) => {
        return axios.post(`${getServerUrl()}/user/submit-repair-maintenance`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?---------------------------------My Application API-------------------------------------------------------------------------
    getUserApplications: (page) => {
        return axios.get(`${getServerUrl()}/user/get-applications?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getApplicationsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-user-application`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getUserApplicationById: (id) => {
        return axios.post(`${getServerUrl()}/user/get-user-application-detail-by-id`, { id }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    SubmitSecondInstallmentApplication: (formData) => {
        return axios.post(`${getServerUrl()}/user/submit-second-installment`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getUserDocument: (fileUrl) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/user/user-document-preview?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                // resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    updateNewApplication: (formData) => {
        return axios.post(`${getServerUrl()}/user/update-new-application`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateRepairAndMaintenance: (formData) => {
        return axios.post(`${getServerUrl()}/user/update-repair-maintenance`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateSecondInstallment: (formData) => {
        return axios.post(`${getServerUrl()}/user/update-second-installment`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?---------------------------------Downloads API-------------------------------------------------------------------------
    getDownloads: () => {
        return axios.get(`${getServerUrl()}/user/get-downloads`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    downloadDocument: (fileUrl) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/user/get-download-file?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                // resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    //?-----------------------------------------User Remittance ---------------------------------------------------------------
    getAllRemittance: (page) => {
        return axios.get(`${getServerUrl()}/user/get-user-remittance?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getRemittanceById: (remittanceId) => {
        return axios.post(`${getServerUrl()}/user/get-remittance-by-id`, { remittanceId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    acknowledgeRemittance: (dataToSend) => {
        return axios.post(`${getServerUrl()}/user/acknowledge-remittance`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?---------------------------------Grievance API-------------------------------------------------------------------------
    getGrievance: (page) => {
        return axios.get(`${getServerUrl()}/user/get-grievance?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    raiseGrievance: (dataToSend) => {
        return axios.post(`${getServerUrl()}/user/raise-grievance`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getGrievanceChatByTicketId: (ticketId) => {
        return axios.get(`${getServerUrl()}/user/get-grievance-chat-by-ticket-id?ticketId=${ticketId}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    replyGrievance: (ticketId, isFileAttached, message, formData) => {
        return axios.post(`${getServerUrl()}/user/reply-grievance?ticketId=${ticketId}&isFileAttached=${isFileAttached}&message=${message}`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getGrievanceDocument: (fileUrl) => {
        const splitUrl = fileUrl.split(".");
        const extention = splitUrl[splitUrl.length - 1];
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/user/get-grievance-file?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    // type: "application/pdf"
                    type: extention === "pdf" ? "application/pdf" : "image/jpg"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                // resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    //?-----------------------------------------User Alerts ---------------------------------------------------------------
    getUserAlerts: (page) => {
        return axios.get(`${getServerUrl()}/user/get-user-alerts?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getNewAlertCount: () => {
        return axios.get(`${getServerUrl()}/user/get-user-new-counts`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //Search API'S
    getLatestNotificationsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-latest-notification`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getGrievancesBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-grieveance`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getRemittanceBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-user-remittance`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    },
    getAlertsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-user-alerts`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    }
}