import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

const H6 = ({ whiteThemeColor, darkThemeColor, children }) => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier.fontSize);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <>
            <h6 style={{ fontSize: `${13 * fontSizeMultiplier / 100}px`, color: `${darkWhiteThemeToggle === "WHITE" ? whiteThemeColor="#000" : darkThemeColor="#fff"}` }}>{children}</h6>
        </>
    )
}

export default H6