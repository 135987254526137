import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import H5 from "../../text-components/H5";
import Multiselect from 'multiselect-react-dropdown';
import useValidator from '../../form-validation/useValidatior';
import { adminCommunication } from '../../communication/adminCommunication';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';

const AddDepartment = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validator, showValidationMessage] = useValidator();
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [department, setDeparment] = useState("");
  const [designations, setDesignations] = useState([]);

  //fetch all designations on initial load
  const fetchAllDesignations = async () => {
    try {
      const responseFromServer = await adminCommunication.getAllDesignations();
      if (responseFromServer.data.status === "SUCCESS") {
        setDesignations(responseFromServer?.data?.designation);
      } else if (responseFromServer.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        
      } else {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
      }

    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }

  }
  useEffect(() => {
    fetchAllDesignations();
  }, []);

  //Add designation
  const addDesignation = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      dispatch(showLoader());
      const serverResponse = await adminCommunication.addDepartment(department, selectedDesignations);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "success" });
        navigate("/admin/dashboard/department-list");
      } else if (serverResponse.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "error" });
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="form_section">
            <div className="form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Add Department</H5>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12 col-md-12 mb-4">
                    <input type="text" name="department" onChange={(event) => setDeparment(event.target.value)} className="form-control" placeholder="Enter Department" />
                    {validator.message("department", department, "required|alpha_space", {
                      messages: {
                        required: "Department is required"
                      }
                    })}
                  </div>
                
                <div className="col-lg-12 col-md-12">
               
                <Multiselect options={designations}  displayValue="designation" name="designation" showCheckbox={true}
                  onSelect={(event) => setSelectedDesignations(event)} onRemove={(event) => setSelectedDesignations(event)}
                  placeholder="Select Designation" hidePlaceholder={true}
                  showArrow
                  closeIcon="cancel"
                  // customArrow={<><i className="fa fa-angle-down"></i></>}
                  keepSearchTerm={true}
                />
                </div>
                </div>
                {validator.message("designation", selectedDesignations, "required", {
                  messages: {
                    required: "Designation is required"
                  }
                })}
                <div className="button_group mt-5">
                  <button onClick={() => addDesignation()}>Add</button>
                  <NavLink to="/admin/dashboard/department-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default AddDepartment