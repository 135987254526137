import React from 'react'
import TopHeader from '../shared-components/TopHeader'
import MainHeader from '../shared-components/MainHeader';
import Footer from '../shared-components/Footer';
import AdminRoutes from '../routes/admin-routes/AdminRoutes';
import TopHeaderadmin from './TopHeaderadmin';

const AdminMainComponent = () => {
  return (
   <>
   <TopHeaderadmin />
   <MainHeader />
   <AdminRoutes />
   <Footer />
   </>
  )
}

export default AdminMainComponent