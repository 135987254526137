import { configureStore } from '@reduxjs/toolkit';
import darkWhiteThemeToggle from './darkWhiteThemeToggle';
import fontSizeMultiplier from './fontSizeMultiplier';
import adminUserDetails from './adminAuthenticateReducer';
import loader from './loaderReducer';
import userAuthenticateReducer from './userAuthenticateReducer';
import backOfficeAuthenticatedReducer from './backOfficeAuthenticatedReducer';

const store = configureStore({
  reducer: {
    fontSizeMultiplier: fontSizeMultiplier,
    darkWhiteThemeToggle: darkWhiteThemeToggle,
    loader: loader,
    adminUserDetails: adminUserDetails,
    userDetails: userAuthenticateReducer,
    backOfficeUserDetails: backOfficeAuthenticatedReducer,
  }
});

export default store;
