import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { userCommunication } from '../communication/userCommunication';
import useValidator from '../form-validation/useValidatior';
import { addCookies } from '../redux-store/userAuthenticateReducer';
import H5 from '../text-components/H5';
import H6 from '../text-components/H6';
import Loader from '../shared-components/Loader';
import MainHeader from '../shared-components/MainHeader';
import TopHeaderPublic from '../Public-portal/TopHearderPublic';
import Footer from '../shared-components/Footer';
import { showLoader, hideLoader } from '../redux-store/loaderReducer';
import swal from 'sweetalert';

const SignIn = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const [validator, showValidationMessage] = useValidator();
  const dispatch = useDispatch();


  const navigation = useNavigate();
  const [userData, setUserData] = useState({
    userId: "",
    password: ""
  });

  
  //getting all inputs value
  const getUserDetail = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  }

  //user login
  const userLogin = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const dataToSend = {
        userId: userData.userId.trim(),
        password: userData.password.trim()
      }
      dispatch(showLoader());
      const serverResponse = await userCommunication.login(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        const dataFromServer = serverResponse?.data?.userData;
        dispatch(addCookies({
          name: dataFromServer?.name,
          email: dataFromServer?.email,
          token: serverResponse?.data?.jwtToken
        }));
        if (window.innerWidth <= 767) {
          console.log(window.innerWidth);
          swal({ text: "Please login from desktop", icon: "warning" });
        } else {
          navigation("/user/latest-notification");
          swal({ text: serverResponse?.data?.message, icon: "success" });

        }
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }


  return (
    <>
      <TopHeaderPublic />
      <MainHeader />
      <div className="login_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
        {
          loader.showHideLoader === true ?
            <Loader />
            :
            
            <div className="login_form login_sign" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div className="form_title">
                <H5 whiteThemeColor darkThemeColor>Login</H5>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <input type="text" name="userId" onChange={(event) => getUserDetail(event)} className="form-control" placeholder="Enter User Id" />
                  {validator.message("userId", userData.userId, "required", {
                    messages: {
                      required: "User id is required"
                    }
                  })}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <input type="password" name="password" onChange={(event) => getUserDetail(event)} className="form-control" placeholder="Enter Password" />
                  {validator.message("password", userData.password, "required|min:8", {
                    messages: {
                      required: "Password is required"
                    }
                  })}
                </div>
              </div>

              <div className="button_group text-center">
                <button onClick={() => userLogin()} a>Login</button>
              </div>

              <div className="row mt-4">
                <div className="col-6 login_links" style={{ cursor: "pointer" }}>
                  <NavLink to="/registration">
                    <H6>Create New Account</H6>
                  </NavLink>
                </div>
                <div className="col-6 login_links" style={{ textAlign: "end", cursor: "pointer" }}>
                  <NavLink to="/forgot-password">
                    <H6>Forgot Password?</H6>
                  </NavLink>
                </div>
              </div>
            </div>
        }

      </div>
      <Footer />
    </>
  )
}

export default SignIn