import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import { userCommunication } from '../../communication/userCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { hideLoader, showLoader } from '../../redux-store/loaderReducer';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const Alerts = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const [alerts, setAlerts] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, SetCurrentPage] = useState(0);
  const [pageCount, SetPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const limit = process.env.REACT_APP_LIMIT;

  //onclick set Current page
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    SetCurrentPage(selectedPage);
    if (searchValue) {
      fetchAlertsBySearch(searchValue, selectedPage + 1);
    } else {
      getAlerts(selectedPage + 1);
    }
  }

  //Search Filter
  const searchFilter = async (searchString) => {
    if (searchString) {
      fetchAlertsBySearch(searchString, currentPage + 1);
    } else {
      const serverResponse = await userCommunication.getUserAlerts();
      if (serverResponse?.data?.status === "SUCCESS") {
        setAlerts(serverResponse?.data?.alerts);
        SetPageCount(serverResponse?.data?.totalPages);
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        swal({ text: serverResponse?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setAlerts([]);
        SetPageCount(0);
      }
    }
  }

  //get Alerts By Search
  const fetchAlertsBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await userCommunication.getAlertsBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setAlerts(responseFromServer?.data?.alerts);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setAlerts([]);
        SetPageCount(0);
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
    }
  }

  //Get All alerts on initial load
  const getAlerts = async (page) => {
    try {
      dispatch(showLoader());
      const serverResponse = await userCommunication.getUserAlerts(page);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setAlerts(serverResponse?.data?.alerts);
        SetPageCount(serverResponse?.data?.totalPages);
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        dispatch(hideLoader());
        setAlerts([]);
        SetPageCount(0);
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  useEffect(() => {
    getAlerts();
  }, []);

  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="section">
            <div className="container alert">
              <div className="header_wrapper alert_header_wrapper" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                <div className="row mb-2 top_search_header_user_portal">
                  <div className="col-lg-3 col-md-4">
                    <input Type="text" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search.." />
                  </div>
                  <div className="col-lg-9 col-md-9 user_portal_pagination">
                    <div className="pagination_div ">
                      {
                        pageCount > 1 &&
                        <div className="pagination_main">
                          <ReactPaginate
                            previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount} shape="rounded"
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            forcePage={currentPage}
                            activeClassName={"active"} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                <div className="row m-0">
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                  </div>
                  <div className="col-8 text_align" style={{ justifyContent: "flex-start" }}>
                    <H5 whiteThemeColor darkThemeColor>Description</H5>
                  </div>
                  <div className="col-2 align">
                    <H5 whiteThemeColor darkThemeColor>Date</H5>
                  </div>
                </div>
              </div>
              {alerts.length > 0 ?
                <>
                  {alerts.map((alert, index) => {
                    return (
                      <div className="table_data" key={index}>
                        <div className="row m-0">
                          <div className="col-2 text_align">
                            <H6 whiteThemeColor darkThemeColor>{(limit * (currentPage) + (index + 1))}</H6>
                          </div>
                          <div className="col-8 text_align description" style={{ justifyContent: "flex-start" }}>
                            <H6 whiteThemeColor darkThemeColor>{alert?.description}</H6>
                          </div>
                          <div className="col-2 align">
                            <H6 whiteThemeColor darkThemeColor>{new Date(alert.creationDate).toLocaleDateString()}</H6>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>

                :
                <div className="data_not_available_message">
                  <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Alerts Not Available</span>
                </div>
              }
            </div>
          </div>
      }
    </>
  )
}

export default Alerts