import React from 'react'
import { useSelector } from 'react-redux';
import H6 from "../text-components/H6";

const Footer = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <div className="footer text-center footer_common" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c", borderTop: darkWhiteThemeToggle === "WHITE" ? "1px solid #fff" : "1px solid #fff" }}>
            <H6 whiteThemeColor darkThemeColor className="footer_common">Website Content Managed & Owned by Ministry of <b>Social Justice & Welfare Dept. Government of Maharashtra</b> Designed and Developed by <b>All Indian IT Services LLP.</b></H6>
        </div>
    )
}

export default Footer