import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { userCommunication } from '../communication/userCommunication';

const PrintNewApplication = () => {
    const componentRef = useRef();
    const [newApplicationData, setNewApplicationData] = useState({});
    //get id from params
    const params = new URLSearchParams(useLocation().search);
    const id = params.get("id");

    //Get user Application Detail by id
    const fetchUserApplicationById = async () => {
        try {
            const responseFromServer = await userCommunication.getUserApplicationById(id);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setNewApplicationData(responseFromServer?.data?.applications?.firstInstallMentForm);
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    }   

    useEffect(() => {
        fetchUserApplicationById();
    }, []);

    return (
        <div className="print_table_main">
            <div className="print_table_section" ref={componentRef}>
                {/* <div className="file_number">
                    <h5><b>File No. 11024/1/2016-SCD-I-Vikyne(3)</b></h5>
                </div>
                <div className="annexure_no">
                    <h5>ANNEXURE - II</h5>
                </div> */}
                <div className="annexure_header">
                    <h5>Format for sending proposal for seeking Central Assistance under the Scheme <br /> Babu Jagjivan Ram
                        Chhatrawas Yojna (BJRCY)</h5>
                </div>
                <table className="annexure_table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Items/Documents</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>1</td>
                            <td>Name of the implementing Agency</td>
                            <td>{newApplicationData?.agency}</td>
                        </tr>

                        <tr>
                            <td>2</td>
                            <td>Nature of implementing agencies</td>
                            <td>{newApplicationData?.natureOfAgency}</td>
                        </tr>
                        {/* <tr>
                        <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Central University / Institution</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "left", paddingLeft: "6px" }}>iii) State University / Institution</td>
                    </tr> */}

                        <tr>
                            <td>3</td>
                            <td>Level for which the hostel is proposed to be constructed (please tick)</td>
                            <td>{newApplicationData?.hostelLevel}</td>
                        </tr>
                        {/* <tr>
                        <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Post-Matric</td>
                    </tr> */}

                        <tr>
                            <td>4</td>
                            <td>No. of inmates for which the hostel is proposed to be constructed</td>
                            <td>{newApplicationData?.noOfInmates}</td>
                        </tr>

                        <tr>
                            <td rowspan="4">4.1</td>
                            <td rowspan="4">Composition of inmates identified (please indicate number)</td>
                            <td>i) SC : {newApplicationData?.casteWiseInmates?.SC}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) ST : {newApplicationData?.casteWiseInmates?.ST}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) OBC: {newApplicationData?.casteWiseInmates?.OBC}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) General : {newApplicationData?.casteWiseInmates?.general}</td>
                        </tr>

                        <tr>
                            <td>5</td>
                            <td>Location of proposed hostel (existing hostel in case of expansion) with detailed address</td>
                            <td>{newApplicationData?.hostelLocation}</td>
                        </tr>

                        <tr>
                            <td rowspan="5">6</td>
                            <td colspan="2">Land details :</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>i) Whether land has been acquired by the
                                Implementing Agency for construction / expansion of the proposed hostel? <br />
                                If yes, whether the land is encumbrance free and in physical possession of the Implementing
                                Agency?</td>
                            <td>{newApplicationData?.landDetails?.isLandAcquiredByAgency}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Land is encumbrance free </td>
                            <td>{newApplicationData?.landDetails?.isLandEncumbranceFree}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iii) Area of land</td>
                            <td>{newApplicationData?.landDetails?.area}</td>
                        </tr>

                        <tr>
                            <td colspan="2" style={{ textAlign: "left", paddingLeft: "6px" }}>(Please annex valid prooof/documents of
                                ownership & occupancy of land)</td>
                        </tr>

                        <tr>
                            <td>7</td>
                            <td> Time to be taken for completion of construction of the proposed hostel building and Likely date
                                of completion <br />
                                (Period for completion of construction of hostel should not exceed 2 years from the date of
                                sanction of the project by the Ministry)</td>
                            <td>{newApplicationData?.completionPeriod + "(months)"}</td>
                        </tr>

                        <tr>
                            <td rowspan="9">8</td>
                            <td colspan="2">Cost Estimates details :</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}><b>A.</b> i) Total estimated cost of the proposed
                                hostel</td>
                            <td>{newApplicationData?.costEstimatesDetails?.totalCost}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Per inmate cost <b>admissible</b>to the
                                implementing Agency under the scheme</td>
                            <td>{newApplicationData?.costEstimatesDetails?.perInmatesCost}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iii) <b>Grants-inaid sought under BJRCY</b></td>
                            <td>{newApplicationData?.costEstimatesDetails?.grandsInAid}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iV) Share of State/UT Central / State
                                University/Institute for the proposed hostel</td>
                            <td>{newApplicationData?.costEstimatesDetails?.share}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}><b>B.</b> Whether cost estimates are
                                determined by
                                PWD norms/PAR?</td>
                            <td>{newApplicationData?.costEstimatesDetails?.isCostDetermineByPWD}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}><b>C.</b> Whether the Implementing Agency agrees to
                                share the construction cost of hostel building as per provisions under the Scheme, BJRCY?</td>
                            <td>{newApplicationData?.costEstimatesDetails?.isAgencyAgreeToShare}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}><b>D.</b>Whether the implementing agency agrees to bear the cost over and above the estimated cost of construction, if any?</td>
                            <td>{newApplicationData?.costEstimatesDetails?.isAgencyAgreeToShare}</td>
                        </tr>

                        <tr>
                            <td colspan="2" style={{ textAlign: "left", paddingLeft: "6px" }}>(Plsease annex detailed cost estimates
                                duly approved/certified by competent PWD authority)</td>
                        </tr>

                        <tr>
                            <td rowspan="5">9</td>
                            <td colspan="2">Details of Building Plan :</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>i) Whether building plan/ Building
                                Elevation drawing of the proposed hostel has been prepared? (Please tick)</td>
                            <td>{newApplicationData?.buildingPlan?.isDrawingPrepared}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Whether building plan has been
                                approved by PWD? (Please tick)</td>
                            <td>{newApplicationData?.buildingPlan?.isPlanApproved}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iii) Please indicate per inmate plinth area</td>
                            <td>{newApplicationData?.buildingPlan?.plinthArea}</td>
                        </tr>

                        <tr>
                            <td colspan="2" style={{ textAlign: "left", paddingLeft: "6px" }}>(Please annex plan/ drawings of the
                                proposed hostel building signed by PWD Authority)</td>
                        </tr>

                        <tr>
                            <td rowspan="10">10</td>
                            <td colspan="2">Has the availability of the following common facilities been tied up?</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>i) In-house coaching</td>
                            <td>{newApplicationData?.facilities?.coaching}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Medical care</td>
                            <td>{newApplicationData?.facilities?.medicalCare}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iii) Appointment of cook, helpers, etc.</td>
                            <td>{newApplicationData?.facilities?.appointmentOfCook}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iv) Games & Sports and recreational facilities</td>
                            <td>{newApplicationData?.facilities?.gameAndSport}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>v) Drinking Water</td>
                            <td>{newApplicationData?.facilities?.drinkingWater}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>vi) Bathing & Washing</td>
                            <td>{newApplicationData?.facilities?.bathingAndWashing}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>vii) Sanitation & Cleaning</td>
                            <td>{newApplicationData?.facilities?.cleaning}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>viii) Other</td>
                            <td>{newApplicationData?.facilities?.other}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iX) Other Detail</td>
                            <td>{(newApplicationData?.facilities?.otherDetails === "") ? "-" : newApplicationData?.facilities?.otherDetails}</td>
                        </tr>

                        <tr>
                            <td>10.1</td>
                            <td>How have availability of these common facilities been tied up?</td>
                            <td>{newApplicationData?.facilitiesTiedUp}</td>
                        </tr>

                        <tr>
                            <td>10.2</td>
                            <td>Source of funding for providing above common facilities</td>
                            <td>{newApplicationData?.sourceOfFundingForCommonFacilities}</td>
                        </tr>

                        <tr>
                            <td rowspan="4">11</td>
                            <td colspan="2">Have following provisions been planned for safety/ security of students : </td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>i) Fire Safety</td>
                            <td>{newApplicationData?.studentSecurity?.fireSafety}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Construction of boundary wall</td>
                            <td>{newApplicationData?.studentSecurity?.boundaryWall}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iii) Appointment of Warden & Chowkidar in the
                                hostel</td>
                            <td>{newApplicationData?.studentSecurity?.appointmentOfWarder}</td>
                        </tr>


                        <tr>
                            <td rowspan="6">12</td>
                            <td colspan="2">Detail of population : </td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>i) Total population of the district where hostel is
                                proposed to be constructed</td>
                            <td>{newApplicationData?.populationDetails?.totalDistrictPopulation}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) SC population in the district</td>
                            <td>{newApplicationData?.populationDetails?.scDistrictPopulation}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iii) Percentage (%) of SC population in the
                                district</td>
                            <td>{newApplicationData?.populationDetails?.percentageOfSC}</td>
                        </tr>

                        <tr>
                            <td rowspan="2" style={{ textAlign: "left", paddingLeft: "6px" }}>iv) Percentage (%) of SC literacy in
                                the district</td>
                            <td>Male : {newApplicationData?.populationDetails?.percentageOfSCMaleLiteracy}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Female : {newApplicationData?.populationDetails?.percentageOfSCFemaleMaleLiteracy}</td>
                        </tr>

                        <tr>
                            <td rowspan="4">13</td>
                            <td colspan="2">Detail of SC hostels : </td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>i) Number of SC hostels available in the district
                            </td>
                            <td>{newApplicationData?.scHostelDetails?.scHostelInDistrict}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Number of SC hostels already available in the
                                Block of the proposed hostel</td>
                            <td>{newApplicationData?.scHostelDetails?.scHostelInBlock}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iii) Of these, number of SC hostels operational
                            </td>
                            <td>{newApplicationData?.scHostelDetails?.operationalSCHostel}</td>
                        </tr>

                        <tr>
                            <td rowSpan={3}>14</td>
                            <td>Wheather the proposed hostel is attached to an existing educational institution? <b>If
                                so,</b>give name and full address of the institution?<br />
                                <b>If no,</b>give name and distance of educational institutions, to which the proposed hostel
                                will cater to.<br />
                                (list of such institutions may be attached)
                            </td>
                            <td>{newApplicationData?.isHostelAttachedToInstitute}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>i) Name of attached Institution</td>
                            <td>{(newApplicationData?.instituteName === "" ? "-" : newApplicationData?.instituteName)}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Address of attached Institution</td>
                            <td>{(newApplicationData?.instituteAddress === "") ? "-" : newApplicationData?.instituteAddress}</td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td>Source(s) of funds for running and maintenance of the proposed hostel, including expenditure on
                                provision of warden and guard in girls' hostel</td>
                            <td>{newApplicationData?.sourceOfFundForMaintenance}</td>
                        </tr>

                        <tr>
                            <td rowspan={2}>16</td>
                            <td>Whether facilities for students with disabilities like barrier free rooms/blocks, ramps, etc.
                                have been incorporated in the design of the hostel?</td>
                            <td>{newApplicationData?.isFacilityAvailableForDisableStudent}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "12px" }}> Facility Details for Disabilities student</td>
                            <td>{(newApplicationData?.facilityDetailsForDisables === "") ? "-" : newApplicationData.facilityDetailsForDisables}</td>
                        </tr>

                        <tr>
                            <td rowspan="2">17</td>
                            <td colspan="2">Students' availability : </td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>i) Number of SC students studying in the
                                educational institution to which the hostel will be
                                attached<br />or<br />
                                Number of SC students studying in the feeder educational istitutions, if case stand alone hostel
                                proposed.</td>
                            <td>{newApplicationData?.noOfStudentStudyingInAttachedInstitute}</td>
                        </tr>

                        <tr>
                            <td rowspan="8">18</td>
                            <td colSpan={2}>Whether following documents/information have been attached with the proposal </td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>i) Wheather submitted through State Govt./
                                Administrative Ministry/Department (in case of University of Institution)</td>
                            <td>{newApplicationData?.documents?.formSubmittedByState}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>ii) Justification for the proposed hostel</td>
                            <td>{newApplicationData?.documents?.justification}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iii) Annexure - I (duly filled in)</td>
                            <td>{newApplicationData?.documents?.annexure}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>iv) Cost Estimate signed PWD/CPWD/Gpvt.
                                Construction agency/construction department</td>
                            <td>{newApplicationData?.documents?.costEstimateSigned}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>v) Site plan signed by PWD/CPWD/Gpvt. Construction
                                agency/construction department</td>
                            <td>{newApplicationData?.documents?.sitePlanSigned}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>vi) Building plan signed by PWD/CPWD/Govt.
                                Construction agency/construction department</td>
                            <td>{newApplicationData?.documents?.buildingPlanSigned}</td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>vii) Land ownership document (in Hindi or Eng.)
                                authenticated by State Govt.</td>
                            <td>{newApplicationData?.documents?.isLandOwnershipDocumentAuthenticate}</td>
                        </tr>

                        <tr>
                            <td>19</td>
                            <td style={{ textAlign: "left", paddingLeft: "6px" }}>Other relevant details (if any)</td>
                            <td>{(newApplicationData?.otherRelevantDetails === "") ? "-" : newApplicationData?.otherRelevantDetails}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="row mt-4">
                    <div className="col-6 mt-5">
                        <h6 className="mb-3">Place:.........................</h6>
                        <h6>Date:..........................</h6>
                    </div>
                    <div className="col-6 Authorization_detail">
                        <h6>Signature of the Authorized Representative of the Implementing Agency</h6>
                        <div className="mt-3" style={{ marginLeft: "50%", textAlign: "left" }}>
                            <h6>Name:..................................</h6>
                            <h6 className="mt-3 mb-3">Designation:...........................</h6>
                            <h6>Full address:..........................</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="print_icon">
                <ReactToPrint
                    trigger={() => <i className="fa fa-print" aria-hidden="true" title="Print Document"></i>}
                    content={() => componentRef.current}
                />
            </div>
        </div>
    )
}

export default PrintNewApplication