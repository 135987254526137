import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { capitalizeFirstLetter } from '../../shared-components/capitalizeFirstLetter';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const Grievance = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [grievanceData, setGrievanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const limit = process.env.REACT_APP_LIMIT


    
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
      fetchGrievanceListbySearch(searchValue, selectedPage + 1);
    } else {
      fetchAllGrievance(selectedPage + 1);
    }
  }

//filter data according to grievance
const searchFilter = async (searchString)=>{
  if (searchString){
    fetchGrievanceListbySearch(searchString, currentPage + 1)
  }else{
    const responseFromServer = await backOfficeCommunication.getAllGrievance();
    if(responseFromServer?.data?.status === "SUCCESS"){
      setGrievanceData(responseFromServer?.data?.grievance);
      setPageCount(responseFromServer?.data?.totalPage);
    } else if (responseFromServer?.data?.status === "JWT_INVALID"){
      swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/back-office/login")
    }else{
      setGrievanceData([])
      setPageCount(0)
    }
  }
}

  //get grievance list by search
const fetchGrievanceListbySearch = async (searchString, page) => {
  try {
    const responseFromServer = await backOfficeCommunication.getGrievanceListBySearch(searchString, page);
    if(responseFromServer?.data?.status === "SUCCESS"){
      setGrievanceData(responseFromServer?.data?.grievance);
      setPageCount(responseFromServer?.data?.totalPage);
    } else if (responseFromServer?.data?.status === "JWT_INVALID"){
      swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/back-office/login")
    }else{
      setGrievanceData([]);
      setPageCount([]);
    }
    
  } catch (error) {
    swal({text: error.message, icon: "error"})
    
  }
}
  //fetch all grievance on initial load
  const fetchAllGrievance = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await backOfficeCommunication.getAllGrievance(page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setGrievanceData(responseFromServer?.data?.grievance);
        setFilteredData(responseFromServer?.data?.grievance);
        setPageCount(responseFromServer?.data?.totalPages)
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        navigate("/back-office/login");
        dispatch(hideLoader());
      } else {
        setGrievanceData([]);
        setFilteredData([]);
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }


  useEffect(() => {
    fetchAllGrievance();
  }, []);

  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="back_office_section">
          <div className="row mb-2 top_search_header_backoffice">
          <div className="col-lg-3 col-md-4">
                <input Type="text" onChange={(e) => { searchFilter(e.target.value.trim()); setSearchValue(e.target.value.trim()) }} className="form-control" placeholder="Search by User Name, Ticket Id" />
              </div>
              <div className="col-lg-9 col-md-9 pagination_class">
                <div className="pagination_div ">
                  {
                    pageCount>1 &&
                    <div className="pagination_main">
                      <ReactPaginate
                        previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                        nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount} shape="rounded"
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        forcePage={currentPage}
                        activeClassName={"active"} />
                    </div>
                  }
                </div>
              </div>

            </div>
            <div className="back_office_table_main">
              <div className="back_office_table">
                <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                  <div className="row m-0">
                    <div className="col-2 text_align">
                      <H5>Serial Number</H5>
                    </div>
                    <div className="col-2 text_align">
                      <H5>User Name</H5>
                    </div>
                    <div className="col-1 text_align">
                      <H5>Ticket Id</H5>
                    </div>
                    <div className="col-3 text_align">
                      <H5>Title</H5>
                    </div>
                    <div className="col-2 text_align">
                      <H5>Status</H5>
                    </div>
                    <div className="col-2 align">
                      <H5>Date</H5>
                    </div>
                  </div>
                </div>
                {filteredData?.length > 0 ?
                  <>
                    {grievanceData?.map((grievanceDetail, index) => (
                      <div className="table_data" key={index}>
                        <div className="row m-0">
                          <div className="col-2 text_align">
                            <H6>{(limit * (currentPage) + (index + 1))}</H6>
                          </div>
                          <div className="col-2 text_align">
                            <NavLink to={`/back-office/grievance/chatbot?ticketId=${grievanceDetail?.ticketId}`}>
                              <H6>{capitalizeFirstLetter(grievanceDetail?.name)}</H6>
                            </NavLink>
                          </div>
                          <div className="col-1 text_align">
                            <NavLink to={`/back-office/grievance/chatbot?ticketId=${grievanceDetail?.ticketId}`}>
                              <H6>{grievanceDetail?.ticketId}</H6>
                            </NavLink>
                          </div>
                          <div className="col-3 text_align">
                            <H6>{grievanceDetail?.title}</H6>
                          </div>
                          <div className="col-2 text_align">
                            <H6>{capitalizeFirstLetter(grievanceDetail?.backOfficeStatus)}</H6>
                          </div>
                          <div className="col-2 align">
                            <H6>{new Date(grievanceDetail?.lastTransaction).toLocaleDateString()}</H6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                  :
                  <div className="data_not_available_message">
                    <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Grievance Not Available</span>
                  </div>
                }
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default Grievance