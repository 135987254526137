import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import Label from '../../text-components/Label';
import useValidator from '../../form-validation/useValidatior';
import divisionArray from '../../shared-components/divisionArray';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { userCommunication } from '../../communication/userCommunication';
import swal from 'sweetalert';

const RepairAndMaintenance = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const [validator, showValidationMessage] = useValidator();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [district, setDistrict] = useState([]);
    const [repairMaintenanceData, setRepairMaintenanceData] = useState(
        {
            isHostelConstructedByBJRCY: "",
            sanctionDate: "",
            category: "",
            hostelLocation: "",
            totalNoOfInmates: "",
            SC: "",
            otherCasteStudents: "",
            total: "",
            percentageOfSC: 0,
            appointmentOfWarder: "",
            availabilityOfWatchmen: "",
            coaching: "",
            medicalCare: "",
            appointmentOfCook: "",
            gameAndSport: "",
            drinkingWater: "",
            bathingAndWashing: "",
            cleaning: "",
            other: "",
            estimatedCost: "",
            isAgencyAgreeToBearCost: "",
            bearCostDetails: "",
            agency: "",
            remark: "",
            submittedTo: "",
            division: "",
            district: ""
        }
    );

    const [documents, setDocuments] = useState({
        studentList: "",
        costEstimateCertificate: "",
        centralAssistCertificate: "",
    });

    //get notification id from params
    const params = new URLSearchParams(useLocation().search);
    const notificationId = params.get("notificationId");

    //get all inputs value
    const getApplicationDetail = (event, calculatePercentage) => {
        if (calculatePercentage === "typeSC") {
            //calculate total students onChange
            let totalStudents = Number(repairMaintenanceData.other) + Number(event.target.value);
            setRepairMaintenanceData({ ...repairMaintenanceData, total: totalStudents, [event.target.name]: event.target.value });

        } else if (calculatePercentage === "typeOther") {
            //calculate total students onChange
            let totalStudents = Number(repairMaintenanceData.SC) + Number(event.target.value);
            setRepairMaintenanceData({ ...repairMaintenanceData, total: totalStudents, [event.target.name]: event.target.value });
        } else {
            setRepairMaintenanceData({ ...repairMaintenanceData, [event.target.name]: event.target.value });
        }
    }

    //get all files value
    const getDocuments = (event) => {
        setDocuments({ ...documents, [event.target.name]: event.target.files[0] });
    }


    //get division wise districts
    const divisionWiseDistricts = (event) => {
        for (let i = 0; i < divisionArray.length; i++) {
            if (divisionArray[i].division === event.target.value) {
                setDistrict(divisionArray[i].district);
            }
        }
    }


    //show modal on submit
    const submitApplication = () => {

        //check validation
        if (!validator.allValid()) {
            showValidationMessage(true);
            return false;
        }

        setShowFormModal(true);
    }

    //Final application submission
    const finalApplicationSubmission = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const formData = new FormData();
            formData.append("studentList", documents.studentList);
            formData.append("costEstimateCertificate", documents.costEstimateCertificate);
            formData.append("centralAssistCertificate", documents.centralAssistCertificate);

            const dataToSend = {
                notificationId,
                submittedTo: repairMaintenanceData.submittedTo,
                division: repairMaintenanceData.division,
                district: repairMaintenanceData.district,
                isHostelConstructedByBJRCY: repairMaintenanceData.isHostelConstructedByBJRCY,
                sanctionDate: repairMaintenanceData.sanctionDate,
                category: repairMaintenanceData.category,
                hostelLocation: repairMaintenanceData.hostelLocation,
                totalNoOfInmates: Number(repairMaintenanceData.totalNoOfInmates),
                admittedInmatesDetails: {
                    SC: Number(repairMaintenanceData.SC),
                    other: Number(repairMaintenanceData.otherCasteStudents),
                    total: Number(repairMaintenanceData.total),
                    percentageOfSC: Number(repairMaintenanceData.percentageOfSC),
                },
                facilities: {
                    appointmentOfWarder: repairMaintenanceData.appointmentOfWarder,
                    availabilityOfWatchmen: repairMaintenanceData.availabilityOfWatchmen,
                    coaching: repairMaintenanceData.coaching,
                    medicalCare: repairMaintenanceData.medicalCare,
                    appointmentOfCook: repairMaintenanceData.appointmentOfCook,
                    appointmentOfWarder: repairMaintenanceData.appointmentOfWarder,
                    gameAndSport: repairMaintenanceData.gameAndSport,
                    drinkingWater: repairMaintenanceData.drinkingWater,
                    bathingAndWashing: repairMaintenanceData.bathingAndWashing,
                    cleaning: repairMaintenanceData.cleaning,
                    other: repairMaintenanceData.other,
                },
                estimatedCost: Number(repairMaintenanceData.estimatedCost),
                isAgencyAgreeToBearCost: repairMaintenanceData.isAgencyAgreeToBearCost,
                bearCostDetails: repairMaintenanceData.bearCostDetails,
                agency: repairMaintenanceData.agency,
                remark: repairMaintenanceData.remark,
            }

            formData.append("applicationData", JSON.stringify(dataToSend))
            dispatch(showLoader());
            const serverResponse = await userCommunication.SubmitRepairAndMaintenanceApplication(formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                navigation("/user/my-application");
                swal({text: serverResponse?.data?.message, icon: "success"});
            } else {
                dispatch(hideLoader());
                swal({text: serverResponse?.data?.message, icon: "error"});
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({text: error.message, icon: "error"});
        }
    }
    //close form modal
    const closeFormModal = () => {
        setShowFormModal(false);
    }

    useEffect(() => {
        let totolscPercentage = (Number(repairMaintenanceData.SC) * 100 / Number(repairMaintenanceData.total)).toFixed(2);
        setRepairMaintenanceData({ ...repairMaintenanceData, percentageOfSC: totolscPercentage });
    }, [repairMaintenanceData.total]);

    return (
        <>
            {showFormModal === false &&
                < div className="form_main">
                    <div className="form_heading">
                        <H5 whiteThemeColor darkThemeColor>Format for seeking 'Repair and Maintenance cost' under the Scheme (BJRCY)</H5>
                    </div>
                    <div className="application_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #eab7f5" : "1px solid #fff" }}>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>1. Whether the hostel has been constructed under the Scheme, BJRCY of Ministry of Social Justice & Empowerment? Please also mention date of sanction of the hostel by the Ministry of SJ&E</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isHostelConstructedByBJRCY" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isHostelConstructedByBJRCY" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("isHostelConstructedByBJRCY", repairMaintenanceData.isHostelConstructedByBJRCY, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>
                        {repairMaintenanceData.isHostelConstructedByBJRCY === "Yes" &&
                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>Date of Sanction : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="date" name="sanctionDate" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Agency Name" disabled={isSubmitted} />
                                    {validator.message("sanctionDate", repairMaintenanceData.sanctionDate, "required", {
                                        messages: {
                                            required: "Sanction Date is required"
                                        }
                                    })}
                                </div>
                            </div>
                        }

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>2. Category</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Boys</Label>
                                        <input type="radio" name="category" value="Boys" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-3" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Girls</Label>
                                        <input type="radio" name="category" value="Girls" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-3" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("category", repairMaintenanceData.category, "required", {
                                    messages: {
                                        required: "Choose at least on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>3. Location of the hostel : </H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <textarea name="hostelLocation" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Address..." disabled={isSubmitted} ></textarea>
                                {validator.message("hostelLocation", repairMaintenanceData.hostelLocation, "required", {
                                    messages: {
                                        required: "Hostel Location is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>4. Total number of inmates/seats approved by the Ministry of SJ&E while sanctioning the hostel</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="number" name="totalNoOfInmates" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Total Number of Inmates" disabled={isSubmitted} />
                                {validator.message("totalNoOfInmates", repairMaintenanceData.totalNoOfInmates, "required", {
                                    messages: {
                                        required: "Total Number of Inmates is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>5. Total no of student admitted/residing in the hostel at present, including those belonging to other categories</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-3">
                                        <input type="text" name="SC" onChange={(event) => getApplicationDetail(event, "typeSC")} className="form-control" placeholder="Sc Students" disabled={isSubmitted} />
                                        {validator.message("SC", repairMaintenanceData.SC, "required|numeric", {
                                            messages: {
                                                required: "Number of SC Students is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-3">
                                        <input type="number" name="otherCasteStudents" onChange={(event) => getApplicationDetail(event, "typeOther")} className="form-control" placeholder="Other Students" disabled={isSubmitted} />
                                        {validator.message("otherCasteStudents", repairMaintenanceData.otherCasteStudents, "required", {
                                            messages: {
                                                required: "Number of Other Students is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-3">
                                        <input type="text" name="total" value={repairMaintenanceData.total} className="form-control" placeholder="Total" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-3">
                                        <input type="number" name="percentageOfSC" value={repairMaintenanceData.percentageOfSC} className="form-control" placeholder="Sc Percentage%" disabled={isSubmitted} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>6. Attach list of student with contact number</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="file" name="studentList" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                {validator.message("studentList", documents.studentList, "required", {
                                    messages: {
                                        required: "File is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-12">
                                <H6 whiteThemeColor darkThemeColor>7. Whether, the State Government/ UT Administration/ University/ Institution have provided following facilities (Please specify clearly for a unit of 100 children) :</H6>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>i) Appointment/Availability of Warden (in case of girl's hostel)</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="appointmentOfWarder" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="appointmentOfWarder" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("appointmentOfWarder", repairMaintenanceData.appointmentOfWarder, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>ii) Availability of Chowkidar/ Watchmen (in case of girl's hostel)</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="availabilityOfWatchmen" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="availabilityOfWatchmen" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("availabilityOfWatchmen", repairMaintenanceData.availabilityOfWatchmen, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iii) In house coaching</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="coaching" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="coaching" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("coaching", repairMaintenanceData.coaching, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>iv) Medical care</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="medicalCare" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="medicalCare" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("medicalCare", repairMaintenanceData.medicalCare, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>v) Appointment of cook, helper, etc.</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="appointmentOfCook" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="appointmentOfCook" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("appointmentOfCook", repairMaintenanceData.appointmentOfCook, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>vi) Game & Sports and recreational facilities</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="gameAndSport" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="gameAndSport" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("gameAndSport", repairMaintenanceData.gameAndSport, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>vii) Drinking Water</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="drinkingWater" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="drinkingWater" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("drinkingWater", repairMaintenanceData.drinkingWater, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>viii) Bathing & Washing</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="bathingAndWashing" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="bathingAndWashing" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("bathingAndWashing", repairMaintenanceData.bathingAndWashing, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>ix) Sanitation & Cleaning</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="cleaning" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="cleaning" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("cleaning", repairMaintenanceData.cleaning, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>x) Other : </H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="other" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Other if any.." />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>8. Estimated cost of 'Repair and Maintenance' of hostel(s)</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="estimatedCost" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Estimated Cost" disabled={isSubmitted} />
                                {validator.message("estimatedCost", repairMaintenanceData.estimatedCost, "required|numeric", {
                                    messages: {
                                        required: "Estimated Cost is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>Attach details cost estimate determined by PWD/PAR</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="file" name="costEstimateCertificate" onChange={(event) => getDocuments(event)} className="form-control" placeholder="Enter Estimated Cost" disabled={isSubmitted} />
                                {validator.message("costEstimateCertificate", documents.costEstimateCertificate, "required", {
                                    messages: {
                                        required: "File is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>9. Whether the implementing agency is willing to bear any additional cost incurred on repair and maintenance of the hostel?</H6>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>Yes</Label>
                                        <input type="radio" name="isAgencyAgreeToBearCost" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <Label whiteThemeColor darkThemeColor>No</Label>
                                        <input type="radio" name="isAgencyAgreeToBearCost" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                    </div>
                                </div>
                                {validator.message("isAgencyAgreeToBearCost", repairMaintenanceData.isAgencyAgreeToBearCost, "required", {
                                    messages: {
                                        required: "Choose at lease on Option"
                                    }
                                })}
                            </div>
                        </div>
                        {repairMaintenanceData.isAgencyAgreeToBearCost === "Yes" &&
                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>Bear Cost Details</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="bearCostDetails" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Bear Cost Details" disabled={isSubmitted} />
                                    {validator.message("bearCostDetails", repairMaintenanceData.bearCostDetails, "required", {
                                        messages: {
                                            required: "Bear Cost Details is required"
                                        }
                                    })}
                                </div>
                            </div>
                        }

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>10. Details of central assistance released earlier for repair and maintenance for the hostel</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="file" name="centralAssistCertificate" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                {validator.message("centralAssistCertificate", documents.centralAssistCertificate, "required", {
                                    messages: {
                                        required: "File is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>11. Name of Implementing Agency</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="agency" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Name of Agency" disabled={isSubmitted} />
                                {validator.message("agency", repairMaintenanceData.agency, "required", {
                                    messages: {
                                        required: "Agency Name is required"
                                    }
                                })}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-5">
                                <H6 whiteThemeColor darkThemeColor>12. Any Remark</H6>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <input type="text" name="remark" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Remark if any" disabled={isSubmitted} />
                            </div>
                        </div>

                        <div className="row mt-2 mb-lg-4 mb-md-5">
                            <div className="col-12 button_group">
                                {isSubmitted === false &&
                                    <button onClick={() => submitApplication()}>Submit</button>
                                }
                                <button className="ms-3" onClick={() => navigation("/user/latest-notification")}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showFormModal &&
                <div className="newApplication_modal_main" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                    {
                        loader.showHideLoader === true ?
                            <CommonLoader />
                            :
                            <div className="newApplication_modal" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #eab7f5" : "1px solid #fff" }}>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <H6 whiteThemeColor darkThemeColor>Select Whome You Want to Submit Application</H6>
                                        <select name="submittedTo" onChange={(event) => getApplicationDetail(event)} className="form-select mt-2">
                                            <option value="">Select Application Submit To</option>
                                            <option value="Division">Division</option>
                                            <option value="District">District</option>
                                            <option value="State">State</option>
                                        </select>
                                        {validator.message("submittedTo", repairMaintenanceData.submittedTo, "required", {
                                            messages: {
                                                required: "Select Application Submit To"
                                            }
                                        })}
                                    </div>
                                </div>
                                {((repairMaintenanceData.submittedTo === "Division") || (repairMaintenanceData.submittedTo === "District")) &&
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <select name="division" onChange={(event) => { getApplicationDetail(event); divisionWiseDistricts(event) }} className="form-select">
                                                <option value="">Select Division</option>
                                                {divisionArray?.map((divisionList, index) => (
                                                    <option value={divisionList?.division} key={index}>{divisionList?.division}</option>
                                                ))}
                                            </select>
                                            {validator.message("division", repairMaintenanceData.division, "required", {
                                                messages: {
                                                    required: "Division is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                }
                                {repairMaintenanceData.submittedTo === "District" &&
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <select name="district" onChange={(event) => getApplicationDetail(event)} className="form-select">
                                                <option>Select District</option>
                                                {district?.map((districtList, index) => (
                                                    <option key={index}>{districtList}</option>
                                                ))}
                                            </select>
                                            {validator.message("district", repairMaintenanceData.district, "required", {
                                                messages: {
                                                    required: "District is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-12 button_group">
                                        <button onClick={() => finalApplicationSubmission()}>Submit</button>
                                        <button className="ms-3" onClick={() => closeFormModal()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            }
        </>
    )
}

export default RepairAndMaintenance