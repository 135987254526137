import { createSlice } from '@reduxjs/toolkit';

const fontSizeMultiplier = createSlice({
    name: "fontSizeMultiplierSlice",
    initialState: {
        fontSize: 100
    },

    reducers: {
        increment: (state, action) => {
            if (state.fontSize < 105) {
                state.fontSize = state.fontSize + 5
                return state
            } else {
                return state;
            }
        },
        defaultFont: (state, action) => {
            state.fontSize = state.fontSize = 100
            return state
        },
        decrement: (state, action) => {
            if (state.fontSize > 95 && state.fontSize <= 105) {
                state.fontSize = state.fontSize - 5
                return state
            } else {
                return state;
            }
        },
    }
})
export const { increment, defaultFont, decrement } = fontSizeMultiplier.actions;
export default fontSizeMultiplier.reducer;