import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import useValidator from '../form-validation/useValidatior';
import TopHeaderPublic from '../Public-portal/TopHearderPublic';
import Footer from '../shared-components/Footer';
import MainHeader from '../shared-components/MainHeader';
import H5 from '../text-components/H5';
import { userCommunication } from '../communication/userCommunication';
import Loader from '../shared-components/Loader';
import { showLoader, hideLoader } from '../redux-store/loaderReducer';
import swal from 'sweetalert';

const ForgotPassword = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const [validator, showValidationMessage] = useValidator();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    userId: "",
    newPassword: "",
    confirmPassword: ""
  })

  //getting all inputs value
  const getUserDetail = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  }

  //forgot password
  const forgotPassword = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }
      //show loader
      dispatch(showLoader());

      const dataToSend = {
        userId: userData.userId,
        password: userData.newPassword
      }

      //call api
      const serverResponse = await userCommunication.forgetPassword(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        navigation("/login");
        swal({text: serverResponse?.data?.message, icon: "success"});
        dispatch(hideLoader());
      } else {
        swal({text: serverResponse?.data?.message, icon: "error"});
        dispatch(hideLoader());
      }
    } catch (error) {
      swal({text: error.message, icon: "error"});
      dispatch(hideLoader());
    }
  }

  return (
    <div>
      <TopHeaderPublic />
      <MainHeader />
      <div className="login_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
        {
          loader.showHideLoader === true ?
            <Loader /> :
            <div className="login_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div style={{ textAlign: "end", cursor: "pointer" }}>
                <NavLink to="/login">
                  <i className="fa-solid fa-arrow-left-long" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff", fontSize: "18px" }} title="Click to go back"></i>
                </NavLink>
              </div>
              <div className="form_title">
                <H5 whiteThemeColor darkThemeColor>Forget Password</H5>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <input type="text" name="userId" onChange={(event) => getUserDetail(event)} className="form-control" placeholder="Enter userId" />
                  {validator.message("userId", userData.userId, "required", {
                    messages: {
                      required: "userId is required"
                    }
                  })}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <input type="password" name="newPassword" onChange={(event) => getUserDetail(event)} className="form-control" placeholder="Enter New Password" />
                  {validator.message("newPassword", userData.newPassword, "required|min:8", {
                    messages: {
                      required: "Password is required"
                    }
                  })}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <input type="password" name="confirmPassword" onChange={(event) => getUserDetail(event)} className="form-control" placeholder="Enter Confirm Password" />
                  {validator.message("confirmPassword", userData.confirmPassword, `required|in:${userData.newPassword}`, {
                    messages: {
                      required: "Confirm Password is required",
                      in: "Password must Match"
                    }
                  })}
                </div>
              </div>

              <div className="button_group text-center">
                <button onClick={() => forgotPassword()}>Send</button>
              </div>

            </div>
        }
      </div>

      <Footer />

    </div>
  )
}

export default ForgotPassword