import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import Label from '../../text-components/Label';
import useValidator from '../../form-validation/useValidatior';
import { userCommunication } from '../../communication/userCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import swal from 'sweetalert';

const SecondInstallment = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [validator, showValidationMessage] = useValidator();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [year, setYear] = useState([]);
    const [secondInstallMentData, setSecondInstallmentData] = useState(
        {
            sanctionAmount: "",
            stateShare: "",
            universityShare: "",
            sanctionNo: "",
            sanctionDate: "",
            amount: "",
            utilizedCentralShare: "",
            utilizedUniversityShare: "",
            unspentCentralShare: "",
            unspentUniversityShare: "",
            isFundMadeForSalary: "",
            year: "",
            isConstructionCompleted: "",
            dateOfCompletion: "",
            constructionStatus: "",
            expectedDateOfCompletion: "",
            nonCompletionReason: "",
            isUtilizationCertificateAttached: "",
            isAuditReportAttached: "",
            isBalanceSheetAttached: "",
            isIncomeExpenditureAttached: "",
            isPaymentReceiptAttached: "",
            isBankStatementAttached: "",
            isInterestDetailsAttached: "",
            isStatusOfUtilizationAttached: "",
            isSiteInspectionReportAttached: "",
            isProjectPhotoAttached: ""
        }
    );

    const [documents, setDocuments] = useState(
        {
            hostelSanctionCertificate: "",
            utilizationCertificate: "",
            auditReport: "",
            balanceSheet: "",
            incomeExpenditureCertificate: "",
            paymentReceipt: "",
            bankStatement: "",
            interestCertificate: "",
            utilizationStatusCertificate: "",
            siteInspectionReport: "",
            projectPhoto: ""
        }
    );

    //get Application id from params
    const params = new URLSearchParams(useLocation().search);
    const applicationId = params.get("applicationId");

    //get all inputs value
    const getApplicationDetail = (event) => {
        setSecondInstallmentData({ ...secondInstallMentData, [event.target.name]: event.target.value });
    }

    //get all files value
    const getDocuments = (event) => {
        setDocuments({ ...documents, [event.target.name]: event.target.files[0] });
    }

    // Calculate Year
    const calculateYear = () => {
        let date = new Date();
        let years = [];
        let fullYear = date.getFullYear()
        for (let i = 2022; i <= fullYear; i++) {
            years.push(i);
        }
        setYear(years)
    }

    //show modal on submit
    const submitApplication = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const formData = new FormData();
            formData.append("hostelSanctionCertificate", documents.hostelSanctionCertificate);
            formData.append("utilizationCertificate", documents.utilizationCertificate);
            formData.append("auditReport", documents.auditReport);
            formData.append("balanceSheet", documents.balanceSheet);
            formData.append("incomeExpenditureCertificate", documents.incomeExpenditureCertificate);
            formData.append("paymentReceipt", documents.paymentReceipt);
            formData.append("bankStatement", documents.bankStatement);
            formData.append("interestCertificate", documents.interestCertificate);
            formData.append("utilizationStatusCertificate", documents.utilizationStatusCertificate);
            formData.append("siteInspectionReport", documents.siteInspectionReport);
            formData.append("projectPhoto", documents.projectPhoto);

            const dataToSend = {
                applicationId,
                sanctionAmount: Number(secondInstallMentData.sanctionAmount),
                stateShare: Number(secondInstallMentData.stateShare),
                universityShare: Number(secondInstallMentData.universityShare),
                centralAssistance: {
                    sanctionNo: secondInstallMentData.sanctionNo,
                    sanctionDate: secondInstallMentData.sanctionDate,
                    amount: Number(secondInstallMentData.amount)
                },
                amountUtilized: {
                    centralShare: Number(secondInstallMentData.utilizedCentralShare),
                    universityShare: Number(secondInstallMentData.utilizedUniversityShare)
                },
                unspentBalance: {
                    centralShare: Number(secondInstallMentData.unspentCentralShare),
                    universityShare: Number(secondInstallMentData.unspentUniversityShare)
                },
                isFundMadeForSalary: secondInstallMentData.isFundMadeForSalary,
                year: secondInstallMentData.year,
                isConstructionCompleted: secondInstallMentData.isConstructionCompleted,
                constructionDetails: {
                    dateOfCompletion: secondInstallMentData.dateOfCompletion,
                    constructionStatus: secondInstallMentData.constructionStatus,
                    expectedDateOfCompletion: secondInstallMentData.expectedDateOfCompletion,
                    reasonOfNonCompletion: secondInstallMentData.nonCompletionReason
                },
                document: {
                    isUtilizationCertificateAttached: secondInstallMentData.isUtilizationCertificateAttached,
                    isAuditReportAttached: secondInstallMentData.isAuditReportAttached,
                    isBalanceSheetAttached: secondInstallMentData.isBalanceSheetAttached,
                    isIncomeExpenditureAttached: secondInstallMentData.isIncomeExpenditureAttached,
                    isPaymentReceiptAttached: secondInstallMentData.isPaymentReceiptAttached,
                    isBankStatementAttached: secondInstallMentData.isBankStatementAttached,
                    isInterestDetailsAttached: secondInstallMentData.isInterestDetailsAttached,
                    isStatusOfUtilizationAttached: secondInstallMentData.isStatusOfUtilizationAttached,
                    isSiteInspectionReportAttached: secondInstallMentData.isSiteInspectionReportAttached,
                    isProjectPhotoAttached: secondInstallMentData.isProjectPhotoAttached
                }
            }

            formData.append("applicationData", JSON.stringify(dataToSend))
            dispatch(showLoader());
            const serverResponse = await userCommunication.SubmitSecondInstallmentApplication(formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                navigation("/user/my-application");
                swal({ text: serverResponse?.data?.message, icon: "success" });
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }

    }

    useEffect(() => {
        calculateYear();
    }, []);

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <CommonLoader />
                    :
                    < div className="form_main">
                        <div className="form_heading">
                            <H5 whiteThemeColor darkThemeColor>Proposal For Release Of 2nd InstallMent For SC Girls/Boys Hostels Sanctioned By Minsitry Of Social Justice & Empowerment</H5>
                        </div>
                        <div className="application_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #eab7f5" : "1px solid #fff" }}>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <H5 whiteThemeColor darkThemeColor>Financial Progress Report</H5>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>1. Amount sanctioned by the Ministry of Social Justice & Empowerment : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="sanctionAmount" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Sanctioned Amount" disabled={isSubmitted} />
                                    {validator.message("sanctionAmount", secondInstallMentData.sanctionAmount, "required|numeric", {
                                        messages: {
                                            required: "Sanctioned Amount is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>2. State/UT share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="stateShare" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="State Share" disabled={isSubmitted} />
                                    {validator.message("stateShare", secondInstallMentData.stateShare, "required|numeric", {
                                        messages: {
                                            required: "State Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>3. University/Institution Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="universityShare" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="University Share" disabled={isSubmitted} />
                                    {validator.message("universityShare", secondInstallMentData.universityShare, "required|numeric", {
                                        messages: {
                                            required: "University Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H6 whiteThemeColor darkThemeColor>4. Central assistance released by Ministry of Social Justice & Empowerment</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Sanction Number : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="sanctionNo" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Sanctioned Number" disabled={isSubmitted} />
                                    {validator.message("sanctionNo", secondInstallMentData.sanctionNo, "required", {
                                        messages: {
                                            required: "Sanctioned Number is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) Sanction Date : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="date" name="sanctionDate" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Sanctioned Date" disabled={isSubmitted} />
                                    {validator.message("sanctionDate", secondInstallMentData.sanctionDate, "required", {
                                        messages: {
                                            required: "Sanctioned Date is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iii) Amount : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="amount" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Amount" disabled={isSubmitted} />
                                    {validator.message("amount", secondInstallMentData.amount, "required|numeric", {
                                        messages: {
                                            required: "Amount is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H6 whiteThemeColor darkThemeColor>5. Actual amount utilized</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Central Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="utilizedCentralShare" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Utilized Central Share" disabled={isSubmitted} />
                                    {validator.message("utilizedCentralShare", secondInstallMentData.utilizedCentralShare, "required|numeric", {
                                        messages: {
                                            required: "Central Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) State/University Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="utilizedUniversityShare" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Utilized State/University Share" disabled={isSubmitted} />
                                    {validator.message("utilizedUniversityShare", secondInstallMentData.utilizedUniversityShare, "required|numeric", {
                                        messages: {
                                            required: "University Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H6 whiteThemeColor darkThemeColor>6. Unspent balance of central assistance up to 31<sup>st</sup> March, i.e. year ending</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Central Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="unspentCentralShare" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Unspent Central Share" disabled={isSubmitted} />
                                    {validator.message("unspentCentralShare", secondInstallMentData.unspentCentralShare, "required|numeric", {
                                        messages: {
                                            required: "Unspent Central Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) State/University Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="unspentUniversityShare" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Unspent State/University Share" disabled={isSubmitted} />
                                    {validator.message("unspentUniversityShare", secondInstallMentData.unspentUniversityShare, "required|numeric", {
                                        messages: {
                                            required: "Unspent University Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>7. Whether neccessary provision of fund has been made for salary and other expenses related to deployment of warden and guards for the hostel in case of girls' hostel?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isFundMadeForSalary" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isFundMadeForSalary" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isFundMadeForSalary", secondInstallMentData.isFundMadeForSalary, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H5 whiteThemeColor darkThemeColor>Physical Progress Report</H5>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>1. Year : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <select name="year" onChange={(event) => getApplicationDetail(event)} className="form-select" disabled={isSubmitted}>
                                        <option value="">Select Year</option>
                                        {year?.map((yearList, index) => (
                                            <option key={index}>{yearList}</option>
                                        ))}
                                    </select>
                                    {validator.message("year", secondInstallMentData.year, "required", {
                                        messages: {
                                            required: "Year is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>2. Hostels sanctioned by Ministry of Social Justice & Empowerment along with location : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="file" name="hostelSanctionCertificate" onChange={(event) => getDocuments(event)} className="form-control" />
                                    {validator.message("hostelSanctionCertificate", documents.hostelSanctionCertificate, "required", {
                                        messages: {
                                            required: "File is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>3. Whether completed</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isConstructionCompleted" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isConstructionCompleted" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isConstructionCompleted", secondInstallMentData.isConstructionCompleted, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>
                            {secondInstallMentData.isConstructionCompleted === "Yes" &&
                                <div className="row mb-4">
                                    <div className="col-lg-4 col-md-5">
                                        <H6 whiteThemeColor darkThemeColor>Date of completion : </H6>
                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <input type="date" name="dateOfCompletion" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Date of Completion" disabled={isSubmitted} />
                                        {validator.message("dateOfCompletion", secondInstallMentData.dateOfCompletion, "required", {
                                            messages: {
                                                required: "Date of Completion is required"
                                            }
                                        })}
                                    </div>
                                </div>
                            }
                            {secondInstallMentData.isConstructionCompleted === "No" &&
                                <>
                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-5">
                                            <H6 whiteThemeColor darkThemeColor>Status of construction : </H6>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <input type="text" name="constructionStatus" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Construction Status" disabled={isSubmitted} />
                                            {validator.message("constructionStatus", secondInstallMentData.constructionStatus, "required", {
                                                messages: {
                                                    required: "Construction Status is required"
                                                }
                                            })}
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-5">
                                            <H6 whiteThemeColor darkThemeColor>Expected date of completion : </H6>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <input type="date" name="expectedDateOfCompletion" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Expected Date of Completion" disabled={isSubmitted} />
                                            {validator.message("expectedDateOfCompletion", secondInstallMentData.expectedDateOfCompletion, "required", {
                                                messages: {
                                                    required: "Expected Date of Completion is required"
                                                }
                                            })}
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-5">
                                            <H6 whiteThemeColor darkThemeColor>Reason for non-completion : </H6>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <input type="text" name="nonCompletionReason" onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Non Completion Reason" disabled={isSubmitted} />
                                            {validator.message("nonCompletionReason", secondInstallMentData.nonCompletionReason, "required", {
                                                messages: {
                                                    required: "Non Completion Reason is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H5 whiteThemeColor darkThemeColor>List of Documents to be submitted along with the proposal for release of 2nd installment</H5>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>1. Utilization Certificate as per format mentioned in sanction letter</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isUtilizationCertificateAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isUtilizationCertificateAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isUtilizationCertificateAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="utilizationCertificate" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("utilizationCertificate", documents.utilizationCertificate, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isUtilizationCertificateAttached", secondInstallMentData.isUtilizationCertificateAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>2. Audit report since 1<sup>st</sup> installment released (in case of Central/State University/Institution)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isAuditReportAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isAuditReportAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isAuditReportAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="auditReport" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("auditReport", documents.auditReport, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isAuditReportAttached", secondInstallMentData.isAuditReportAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>3. Balance sheet since 1<sup>st</sup> installment released (in case of Central/State University/Institution)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isBalanceSheetAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isBalanceSheetAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isBalanceSheetAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="balanceSheet" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("balanceSheet", documents.balanceSheet, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isBalanceSheetAttached", secondInstallMentData.isBalanceSheetAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>4. Income expenditure statement since 1<sup>st</sup> installment released (in case of Central/StateUniversity/Institution)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isIncomeExpenditureAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isIncomeExpenditureAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isIncomeExpenditureAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="incomeExpenditureCertificate" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("incomeExpenditureCertificate", documents.incomeExpenditureCertificate, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isIncomeExpenditureAttached", secondInstallMentData.isIncomeExpenditureAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>5. Receipt of payment since 1<sup>st</sup> installment released (in case of Central/State University/Institution)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isPaymentReceiptAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isPaymentReceiptAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isPaymentReceiptAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="paymentReceipt" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("paymentReceipt", documents.paymentReceipt, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isPaymentReceiptAttached", secondInstallMentData.isPaymentReceiptAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>6. Bank Statement since 1<sup>st</sup> installment</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isBankStatementAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isBankStatementAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isBankStatementAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="bankStatement" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("bankStatement", documents.bankStatement, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isBankStatementAttached", secondInstallMentData.isBankStatementAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>7. Details of interest earned on 1<sup>st</sup> installment released for the project</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isInterestDetailsAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isInterestDetailsAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isInterestDetailsAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="interestCertificate" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("interestCertificate", documents.interestCertificate, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isInterestDetailsAttached", secondInstallMentData.isInterestDetailsAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>8. Status of utilization of matching share by the implementing agency, if any</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isStatusOfUtilizationAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isStatusOfUtilizationAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isStatusOfUtilizationAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="utilizationStatusCertificate" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("utilizationStatusCertificate", documents.utilizationStatusCertificate, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isStatusOfUtilizationAttached", secondInstallMentData.isStatusOfUtilizationAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>9. Site inspection report</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isSiteInspectionReportAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isSiteInspectionReportAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isSiteInspectionReportAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="siteInspectionReport" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("siteInspectionReport", documents.siteInspectionReport, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isSiteInspectionReportAttached", secondInstallMentData.isSiteInspectionReportAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>10. Colour Photographs of project site</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isProjectPhotoAttached" value="Yes" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isProjectPhotoAttached" value="No" onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.isProjectPhotoAttached === "Yes" &&
                                            <div className="col-lg-5 col-md-5">
                                                <input type="file" name="projectPhoto" onChange={(event) => getDocuments(event)} className="form-control" disabled={isSubmitted} />
                                                {validator.message("projectPhoto", documents.projectPhoto, "required", {
                                                    messages: {
                                                        required: "File is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isProjectPhotoAttached", secondInstallMentData.isProjectPhotoAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mt-2 mb-lg-4 mb-md-5">
                                <div className="col-12 button_group">
                                    <button onClick={() => submitApplication()}>Submit</button>
                                    <button className="ms-3" onClick={() => navigation("/user/my-application")}>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
            }
        </>
    )
}

export default SecondInstallment