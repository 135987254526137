import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import H5 from '../../text-components/H5';
import useValidator from '../../form-validation/useValidatior';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { removeUnderscoreFromText } from '../../shared-components/removeUnderScore';
import swal from 'sweetalert';

const RemittanceForm = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [isDisabled, setIsDisabled] = useState(true);
    const [remittanceData, setRemittanceData] = useState(
        {
            totalApprovedAmount: "",
            paymentMode: "",
            stateReceivedAmount: "",
            centralReceivedAmount: "",
            narration: ""
        }
    );

    //get id from params
    const params = new URLSearchParams(useLocation().search);
    const remittanceId = params.get("remittanceId");

    //get all inputs value
    const getRemittanceDetail = (event) => {
        setRemittanceData({ ...remittanceData, [event.target.name]: event.target.value });
    }

    //Fetch Remittance By Id on initial load
    const fetchRemittanceById = async () => {
        try {
            const responseFromServer = await backOfficeCommunication.getRemittanceById(remittanceId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                if (["NEW", "Rejected"].includes(responseFromServer?.data?.remittance?.backOfficeStatus)) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
                setRemittanceData(responseFromServer?.data?.remittance);
            } else {
                swal({text: responseFromServer?.data?.message, icon: "error"});
            }
        } catch (error) {
            swal({text: error.message, icon: "error"});
        }
    }


    //Upload Remittance
    const SubmitRemittance = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const dataToSend = {
                remittanceId,
                paymentMode: remittanceData.paymentMode,
                totalApprovedAmount: Number(remittanceData.totalApprovedAmount),
                stateReceivedAmount: Number(remittanceData.stateReceivedAmount),
                centralReceivedAmount: Number(remittanceData.centralReceivedAmount),
                narration: remittanceData.narration
            }

            dispatch(showLoader());
            const serverResponse = await backOfficeCommunication.uploadRemittance(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({text: serverResponse?.data?.message, icon: "success"});
                navigation("/back-office/remittance");
            } else {
                dispatch(hideLoader());
                swal({text: serverResponse?.data?.message, icon: "error"});
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({text: error.message, icon: "error"});
        }
    }

    //Update remittance after reject
    const updateRemittance = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const dataToSend = {
                remittanceId,
                paymentMode: remittanceData.paymentMode,
                totalApprovedAmount: Number(remittanceData.totalApprovedAmount),
                stateReceivedAmount: Number(remittanceData.stateReceivedAmount),
                centralReceivedAmount: Number(remittanceData.centralReceivedAmount),
                narration: remittanceData.narration
            }

            dispatch(showLoader());
            const serverResponse = await backOfficeCommunication.updateRemittance(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({text: serverResponse?.data?.message, icon: "success"});
                navigation("/back-office/remittance");
            } else {
                dispatch(hideLoader());
                swal({text: serverResponse?.data?.message, icon: "error"});
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({text: error.message, icon: "error"});
        }
    }


    useEffect(() => {
        fetchRemittanceById();
    }, []);

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <CommonLoader />
                    :
                    <div className="remittance_form_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                        <div className="add_remittance_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
                            {/* <div className="row mb-4">
                    <div className="col-6">
                        <H5>Application Id : ADF121</H5>
                    </div>
                    <div className="col-6">
                        <H5>Application Type : 2nd Installment</H5>
                    </div>
                </div> */}
                            <div className="row mb-4">
                                <div className="col-12">
                                    <H5>{removeUnderscoreFromText(remittanceData?.applicationType)}</H5>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <input type="text" name="totalApprovedAmount" value={remittanceData.totalApprovedAmount} onChange={(event) => getRemittanceDetail(event)} className="form-control" placeholder="Total Approved Amount" disabled={isDisabled} />
                                    {validator.message("totalApprovedAmount", remittanceData.totalApprovedAmount, "required|numeric", {
                                        messages: {
                                            required: "Approved Amount is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6">
                                    <select name="paymentMode" onChange={(event) => getRemittanceDetail(event)} className="form-select" disabled={isDisabled}>
                                        <option value="">Select Payment Mode</option>
                                        <option value="Cash" selected={remittanceData.paymentMode === "Cash" ? true : false}>Cash</option>
                                        <option value="Bank" selected={remittanceData.paymentMode === "Bank" ? true : false}>Bank</option>
                                        <option value="Cheque" selected={remittanceData.paymentMode === "Cheque" ? true : false}>Cheque</option>
                                        <option value="Other" selected={remittanceData.paymentMode === "Other" ? true : false}>Other</option>
                                    </select>
                                    {validator.message("paymentMode", remittanceData.paymentMode, "required", {
                                        messages: {
                                            required: "Payment Mode is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-6">
                                    <input type="text" name="stateReceivedAmount" value={remittanceData.stateReceivedAmount} onChange={(event) => getRemittanceDetail(event)} className="form-control" placeholder="Amount Received from State" disabled={isDisabled} />
                                    {validator.message("stateReceivedAmount", remittanceData.stateReceivedAmount, "required|numeric", {
                                        messages: {
                                            required: "State Amount is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6">
                                    <input type="text" name="centralReceivedAmount" value={remittanceData.centralReceivedAmount} onChange={(event) => getRemittanceDetail(event)} className="form-control" placeholder="Amount Received from Central" disabled={isDisabled} />
                                    {validator.message("centralReceivedAmount", remittanceData.centralReceivedAmount, "required|numeric", {
                                        messages: {
                                            required: "Central Amount is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <textarea name="narration" value={remittanceData.narration} onChange={(event) => getRemittanceDetail(event)} className="form-control" placeholder="Enter Narration..." disabled={isDisabled}></textarea>
                                </div>
                            </div>
                            <div className="back_office_button_group">
                                {remittanceData?.backOfficeStatus === "NEW" &&
                                    <button className="me-3" onClick={() => SubmitRemittance()}>Submit</button>
                                }
                                {remittanceData?.backOfficeStatus === "Rejected" &&
                                    <button className="me-3" onClick={() => updateRemittance()}>Update</button>
                                }
                                <button onClick={() => navigation("/back-office/remittance")}>Cancel</button>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default RemittanceForm