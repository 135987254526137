import React from 'react'
import { useSelector } from 'react-redux';

const Label = ({ whiteThemeColor, darkThemeColor, children }) => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier.fontSize);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <>
            <label style={{ fontSize: `${14 * fontSizeMultiplier / 100}px`, color: `${darkWhiteThemeToggle === "WHITE" ? whiteThemeColor = "#000" : darkThemeColor = "#fff"}` }}>{children}</label>
        </>
    )
}

export default Label