import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useValidator from '../../form-validation/useValidatior';
import H5 from "../../text-components/H5";
import { adminCommunication } from '../../communication/adminCommunication';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';

const UpdateNotification = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [validator, showValidationMessage] = useValidator();
  const location = useLocation();
  const [isFileUpdate, setIsFileUpdate] = useState(false);
  const [notification, setNotification] = useState({});

  const queryParams = new URLSearchParams(location.search);
  const notificationId = queryParams.get('notificationId');

  //Get all inputs value
  const getNotificationDetails = (event) => {
    setNotification({ ...notification, [event.target.name]: event.target.value });
  }

  //file preview
  const fetchFile = async (fileUrl) => {
    if (fileUrl !== "") {
      await adminCommunication.getNotificationFile(fileUrl);
    }
  }

  //update notification
  const updateNotification = async () => {
    try {
      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }


      dispatch(showLoader()); //show loader
      //call api according to portal

      //public portal notification
      if (notification.portal === "PUBLIC") {
        const serverResponse = await adminCommunication.updatePublicNotification(notificationId, notification.portal, notification.subject, notification.description, isFileUpdate, notification.attachment);
        if (serverResponse.data.status === "SUCCESS") {
          dispatch(hideLoader());
          swal({ text: serverResponse?.data?.message, icon: "success" });
          navigate("/admin/dashboard/notification-list");
        } else if (serverResponse.data.status === "JWT_INVALID") {
          navigate("/admin/login");
          swal({ text: serverResponse?.data?.message, icon: "error" });
          dispatch(hideLoader());
        } else {
          swal({ text: serverResponse?.data?.message, icon: "error" });
          dispatch(hideLoader());
        }
      }

      //user portal notification
      if (notification.portal === "USER") {
        const serverResponse = await adminCommunication.updateUserNotification(notificationId, notification.portal, notification.notificationType, notification.subject, notification.description, notification.attachment, notification.formType, isFileUpdate, notification.startDate, notification.endDate);
        if (serverResponse.data.status === "SUCCESS") {
          dispatch(hideLoader());
          swal({ text: serverResponse?.data?.message, icon: "success" });
          navigate("/admin/dashboard/notification-list");
        } else if (serverResponse.data.status === "JWT_INVALID") {
          navigate("/admin/login");
          swal({ text: serverResponse?.data?.message, icon: "error" });
          dispatch(hideLoader());
        } else {
          swal({ text: serverResponse?.data?.message, icon: "error" });
          dispatch(hideLoader());
        }
      }

    } catch (error) {
      swal({ text: error?.message, icon: "error" });
      dispatch(hideLoader());
    }

  }

  //get notification by id on initialLoad
  const getNotification = async () => {
    try {
      dispatch(showLoader());
      const responseFromServer = await adminCommunication.getNotificationById(notificationId);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setNotification(responseFromServer?.data?.notification);
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
      }

    } catch (error) {
      swal({ text: error?.message, icon: "error" });
      dispatch(hideLoader());
    }
  }


  //set file
  const onFileSelect = (event) => {
    //convert file into fromData
    const formData = new FormData();
    formData.append('attachment', event.target.files[0], event.target.files[0].name);
    setNotification({ ...notification, attachment: formData })
    setIsFileUpdate(true);
  }

  useEffect(() => {
    getNotification();
  }, []);


  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="form_section">
            <div className="add_notification_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <form>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Update Notification</H5>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <select className="form-select" name="portal" onChange={(event) => getNotificationDetails(event)} >
                      <option value="">Select Portal</option>
                      <option value="PUBLIC" selected={notification?.portal === "PUBLIC" ? true : false} >Public</option>
                      <option value="USER" selected={notification?.portal === "USER" ? true : false} >User</option>
                    </select>
                  </div>
                  {notification.portal === "PUBLIC" ?
                    <span></span>
                    :
                    <div className="col-lg-6 col-md-6">
                      <select className="form-select" name="notificationType" onChange={(event) => getNotificationDetails(event)}>
                        <option value="">Select Notification Type</option>
                        <option value="GENERAL" selected={notification?.notificationType === "GENERAL" ? true : false}>General</option>
                        <option value="APPLY" selected={notification?.notificationType === "APPLY" ? true : false}>Apply</option>
                      </select>
                    </div>
                  }
                </div>
                {(notification.portal === "PUBLIC") || (notification.portal === "USER" && notification.notificationType === "GENERAL") ?
                  <span></span>
                  :
                  <div className="row mb-3">
                    <div className="col-lg-6 col-md-6">
                      <input type="date" name="startDate" value={notification?.startDate} className="form-control" placeholder="Start Date" onChange={(event) => getNotificationDetails(event)} />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <input type="date" name="endDate" value={notification?.endDate} className="form-control" placeholder="End Date" onChange={(event) => getNotificationDetails(event)} />
                    </div>
                  </div>
                }
                <div className="row mb-3">
                  {(notification.portal === "PUBLIC") || (notification.portal === "USER" && notification.notificationType === "GENERAL") ?
                    <span></span>
                    :
                    <div className="col-lg-6 col-md-6">
                      <select className="form-select" name="formType" onChange={(event) => getNotificationDetails(event)}>
                        <option value="">Select Form Type</option>
                        <option value="NEW APPLICATION" selected={notification?.formType === "NEW APPLICATION" ? true : false} >New Application</option>
                        <option value="REPAIR AND MAINTENANCE" selected={notification?.formType === "REPAIR AND MAINTENANCE" ? true : false}>Repair and Maintenance</option>
                      </select>
                    </div>
                  }
                  <div className="col-lg-6 col-md-6">
                    <input type="text" name="subject" value={notification.subject} className="form-control" placeholder="Enter Subject" onChange={(event) => getNotificationDetails(event)} />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <textarea className="form-control" name='description' value={notification.description} placeholder="Enter Description" onChange={(event) => getNotificationDetails(event)} ></textarea>
                  </div>
                  {(notification.portal === "USER" && notification.notificationType === "APPLY") ?
                    <span></span>
                    :
                    < div className="col-lg-6 col-md-6">
                      <div>
                        <input type="file" name="file" className="form-control" onChange={(event) => onFileSelect(event)} />
                        <div style={{ display: "flex", alignItems: "center", marginTop: "3px", fontSize: "15px" }}>
                          <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff", marginRight: "3px" }}>Document Preview : </span>
                          <i className="fa-solid fa-file-pdf" style={{ color: darkWhiteThemeToggle === "WHITE" ? "red" : "#fff", cursor: "pointer" }} title='click to preview document' onClick={() => fetchFile(notification?.attachment)} ></i>
                        </div>
                      </div>
                      <div>
                        <span className="document_attachement_note" style={{ marginTop: "4px" }}>*Only pdf is allowed</span>
                      </div>
                    </div>
                  }
                </div>

                <div className="button_group mt-3">
                  <button onClick={() => updateNotification()} >Update</button>
                  <NavLink to="/admin/dashboard/notification-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </form>
            </div >
          </div >
      }
    </>
  )
}

export default UpdateNotification