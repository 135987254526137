import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";
import H5 from '../../text-components/H5';
import { useLocation } from 'react-router-dom';
import { adminCommunication } from '../../communication/adminCommunication';
import { useNavigate } from 'react-router-dom';
import useValidator from '../../form-validation/useValidatior';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';

const UpdateData = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const [validator, showValidationMessage] = useValidator();
  const location = useLocation();
  const navigate = useNavigate();
  const [document, setDocument] = useState({});

  const [dataToUpdate, setDataToUpdate] = useState({
    portal: "USER",
    title: "",
    description: "",
    attachment: "",
    isFileAttached: false
  })

  const queryParams = new URLSearchParams(location.search);
  const documentId = queryParams.get('documentId');

  //get document details by Id on initial load
  const getDocumentDetails = async () => {
    try {
      dispatch(showLoader());
      const serverResponse = await adminCommunication.getDownloadDocumentById(documentId);
      if (serverResponse?.data?.status === "SUCCESS") {
        setDocument(serverResponse?.data?.document);
        setDataToUpdate({ ...dataToUpdate, title: serverResponse?.data?.document?.title, description: serverResponse?.data?.document?.description, attachment: serverResponse?.data?.document?.attachment })
        dispatch(hideLoader());
      } else if (serverResponse.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "error" });
        dispatch(hideLoader());
      } else {
        swal({ text: serverResponse?.data?.message, icon: "error" });
        dispatch(hideLoader());
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
      dispatch(hideLoader());
    }
  }

  //get file 
  const fetchFile = async (fileUrl) => {
    if (fileUrl !== "") {
      await adminCommunication.getDocument(fileUrl);
    }
  }

  //set file
  const onFileSelect = (event) => {
    //convert file into fromData
    const formData = new FormData();
    formData.append('attachment', event.target.files[0], event.target.files[0].name);
    setDataToUpdate({ ...dataToUpdate, attachment: formData, isFileAttached: true })
  }

  //update document
  const updateDocument = async () => {
    try {
      dispatch(showLoader());
      const serverResponse = await adminCommunication.updateDownloadDocument(dataToUpdate.isFileAttached, document._id, dataToUpdate.portal, dataToUpdate.title, dataToUpdate.description, dataToUpdate.attachment);
      if (serverResponse?.data?.status === "SUCCESS") {
        swal({ text: serverResponse?.data?.message, icon: "success" });
        navigate("/admin/dashboard/upload-data-list");
        dispatch(hideLoader());
      } else if (serverResponse.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "warning" });
        dispatch(hideLoader());
      } else {
        swal({ text: serverResponse?.data?.message, icon: "error" });
        dispatch(hideLoader());
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
      dispatch(hideLoader());
    }
  }

  useEffect(() => {
    getDocumentDetails();
  }, [])


  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="form_section">
            <div className="form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Update Data</H5>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 col-md-12">
                    <input type="text" name="portal" value="USER" className="form-control" placeholder="Enter portal" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 col-md-12">
                    <input type="text" name="title" value={dataToUpdate.title} className="form-control" onChange={(e) => { setDataToUpdate({ ...dataToUpdate, title: e.target.value }) }} placeholder="Enter Title" />
                    {validator.message("Title", dataToUpdate.title, "required|alpha_space", {
                      messages: {
                        required: "Title is required"
                      }
                    })}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 col-md-12">
                    <textarea name="description" value={dataToUpdate.description} className="form-control" placeholder="Enter Description" onChange={(e) => { setDataToUpdate({ ...dataToUpdate, description: e.target.value }) }} ></textarea>
                    {validator.message("Description", dataToUpdate.description, "required|alpha_num_dash_space", {
                      messages: {
                        required: "Description is required"
                      }
                    })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <input type="file" name="file" className="form-control" onChange={(e) => { onFileSelect(e) }} />
                    {validator.message("file", dataToUpdate.attachment, "required", {
                      messages: {
                        required: "file is required"
                      }
                    })}
                    <div style={{ display: "flex", alignItems: "center", marginTop: "3px" }}>
                      <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff", marginRight: "3px", fontSize: "15px" }}>Document Preview : </span>
                      <i className="fa-solid fa-file-pdf" style={{ color: darkWhiteThemeToggle === "WHITE" ? "red" : "rgb(173, 83, 137)", cursor: "pointer" }} title='click to preview document' onClick={() => fetchFile(document.attachment)} ></i>
                    </div>
                    <span className="document_attachement_note" style={{color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "rgb(173, 83, 137)", marginTop: "4px" }}>*Only pdf is allowed</span>
                  </div>
                </div>
                <div className="button_group mt-3">
                  <button onClick={() => { updateDocument() }} >Update</button>
                  <NavLink to="/admin/dashboard/upload-data-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default UpdateData