import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import UserRoutes from '../routes/user-routes/UserRoutes';
import Footer from '../shared-components/Footer';
import MainHeader from '../shared-components/MainHeader';
import UserNavBar from './UserNavBar';
import TopHeaderuser from './TopHeaderuser';
import { userCommunication } from '../communication/userCommunication';
import swal from 'sweetalert';

const UserDashboard = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [counts, setCounts] = useState(0);
    const [showLoginBox, setShowLoginBox] = useState(false);

    const getAlertCounts = async () => {
        try {
            const serverResponse = await userCommunication.getNewAlertCount();
            if (serverResponse.data.status === "SUCCESS") {

                setCounts(serverResponse?.data?.alertCount);
            } else {
                setCounts(0)
            }
        } catch (error) {
            swal(error.message);
        }
    }

    useEffect(() => {
        getAlertCounts();
    }, [])
    return (
        <div className="dashboard_main">
            <TopHeaderuser setShowLoginBox={setShowLoginBox} showLoginBox={showLoginBox} />
            <MainHeader />
            <UserNavBar counts={counts} getAlertCounts={getAlertCounts} setShowLoginBox={setShowLoginBox} />
            <div className="dashboard" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }} onClick={() => setShowLoginBox(false)}>
                {/* <Outlet></Outlet> */}
                <UserRoutes />
            </div>
            <Footer />
        </div>
    )
}

export default UserDashboard