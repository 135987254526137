import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { publicCommunication } from "../communication/public-communication";
import swal from "sweetalert";

const ChartComponent = () => {
  const [yearlyCourseCompletion, setYearlyCourseCompletion] = useState([]);
  const [yearlyApplicationReceived, setYearlyApplicationReceived] = useState([]);
  const [applicationReceivedForGirl, setApplicationReceivedForGirl] = useState([]);
  const [applicationReceivedForBoys, setApplicationReceivedForBoys] = useState([]);
  const [yearlyAmountDisbursedForGirl, setYearlyAmountDisbursedForGirl] = useState([]);
  const [yearlyAmountDisbursedForBoys, setYearlyAmountDisbursedForBoys] = useState([]);
  const [districtWiseGirlApplication, setDistrictWiseGirlApplication] = useState([]);
  const [districtWiseBoysApplication, setDistrictWiseBoysApplication] = useState([]);



  const getData = async () => {
    try {
      const serverResponse = await publicCommunication.getGraphData();
      if (serverResponse.data.status === "SUCCESS") {
        setYearlyApplicationReceived(serverResponse?.data?.data?.yearlyApplicationReceived);
        setYearlyCourseCompletion(serverResponse?.data?.data?.yearlyCompletedApplicationReceived)
        setApplicationReceivedForGirl(serverResponse?.data?.data?.totalNumberOfApplicationReceivedForGirls)
        setApplicationReceivedForBoys(serverResponse?.data?.data?.totalNumberOfApplicationReceivedForBoys)
        setYearlyAmountDisbursedForGirl(serverResponse?.data?.data?.totalAmountReceivedByGirls)
        setYearlyAmountDisbursedForBoys(serverResponse?.data?.data?.totalAmountReceivedByBoysGraph)
        setDistrictWiseBoysApplication(serverResponse?.data?.data?.totalNumberOfApplicationReceivedForBoysDistrictWise)
        setDistrictWiseGirlApplication(serverResponse?.data?.data?.totalNumberOfApplicationReceivedForGirlsDistrictWise)
      } else {

      }
    } catch (error) {
      swal(error.message);
    }
  }
  useEffect(() => {
    getData();
  }, [])
  return (
    <div style={{ width: "80%", margin: "auto", marginTop: "40px", marginBottom: "20px" }}>
      <div className="text-center" style={{ marginTop: "40px" }}>
        <h3 style={{ color: "#790a81", marginBottom: "15px" }}>Yearly Applications Received</h3>
        <div >
          <Bar
            data={{
              labels: yearlyApplicationReceived.map((data) => {
                return data.year
              }),
              datasets: [
                {
                  label: "Yearly New Construction Applications Received",
                  data: yearlyApplicationReceived.map((data) => {
                    return data.newApplicationCount
                  }),
                  backgroundColor: "#C3F8FF",
                },
                {
                  label: "Yearly Repair and Maintenance Applications Received",
                  data: yearlyApplicationReceived.map((data) => {
                    return data.repairMaintenanceCount
                  }),
                  backgroundColor: "#ABD9FF",

                },
              ],
            }}
            height={400}
            width={600}
            options={{
              maintainAspectRatio: false,
            }}
          />

        </div>
      </div>

      <div className="text-center" style={{ marginTop: "40px" }}>
        <h3 style={{ color: "#790a81", marginBottom: "15px" }}>Yearly Applications Completed</h3>
        <div >
          <Bar data={{
            labels: yearlyCourseCompletion.map((data) => {
              return data.year
            }),
            datasets: [
              {
                label: "Yearly New Construction Completed",
                data: yearlyCourseCompletion.map((data) => {
                  return data.newApplicationCount
                }),
                backgroundColor: "#C3F8FF",
              },
              {
                label: "Yearly Repair and Maintenance Completed",
                data: yearlyCourseCompletion.map((data) => {
                  return data.repairMaintenanceCount
                }),
                backgroundColor: "#ABD9FF",
              },
            ],
          }}
            height={300}
            width={600}
            options={{
              maintainAspectRatio: false,
            }}></Bar>
        </div>
      </div>

      <div className="text-center" style={{ marginTop: "40px" }}>
        <h3 style={{ color: "#790a81", marginBottom: "15px" }}>Districtwise Boys Application</h3>
        <div className="mt-5">
          <Pie data={{
            labels: districtWiseBoysApplication.map((data) => {
              return data.district
            }),
            datasets: [
              {
                label: "District wise Application for Boys Hostel ",
                data: districtWiseBoysApplication.map((data) => {
                  return data.count
                }),
                backgroundColor: [
                  "#a2a2d0",
                  "#6699cc",
                  "#0d98ba",
                  "#8a2be2",
                  "#8a2be2",
                  "#de5d83",
                  "#79443b",
                  "#0095b6",
                  "#e3dac9",
                  "#cc0000",
                  "#006a4e",
                  "#873260",
                  "#0070ff",
                  "#b5a642",
                  "#cb4154",
                  "#1dacd6",
                  "#66ff00",
                  "#bf94e4",
                  "#c32148",
                  "#ff007f",
                  "#08e8de",
                  "#d19fe8",
                  "#f4bbff",
                  "#ff55a3",
                  "#fb607f",
                  "#004225",
                  "#cd7f32",
                  "#a52a2a",
                  "#ffc1cc",
                  "#e7feff",
                  "#f0dc82",
                  "#480607",
                  "#800020",
                  "#deb887",
                  "#cc5500"
                ],
                // borderWidth: 4
              },
            ],
          }}
            height={400}
            width={600}
            options={{
              maintainAspectRatio: false,
            }}>

          </Pie>
        </div>
      </div>

      <div className="text-center" style={{ marginTop: "40px" }}>
        <h3 style={{ color: "#790a81", marginBottom: "15px" }}>Districtwise Girls Application</h3>
        <div className="mt-5">
          <Pie data={{
            labels: districtWiseGirlApplication.map((data) => {
              return data.district
            }),
            datasets: [
              {
                label: "District wise Application for Girls Hostel ",
                data: districtWiseGirlApplication.map((data) => {
                  return data.count
                }),
                backgroundColor: [
                  "#a2a2d0",
                  "#6699cc",
                  "#0d98ba",
                  "#8a2be2",
                  "#8a2be2",
                  "#de5d83",
                  "#79443b",
                  "#0095b6",
                  "#e3dac9",
                  "#cc0000",
                  "#006a4e",
                  "#873260",
                  "#0070ff",
                  "#b5a642",
                  "#cb4154",
                  "#1dacd6",
                  "#66ff00",
                  "#bf94e4",
                  "#c32148",
                  "#ff007f",
                  "#08e8de",
                  "#d19fe8",
                  "#f4bbff",
                  "#ff55a3",
                  "#fb607f",
                  "#004225",
                  "#cd7f32",
                  "#a52a2a",
                  "#ffc1cc",
                  "#e7feff",
                  "#f0dc82",
                  "#480607",
                  "#800020",
                  "#deb887",
                  "#cc5500"
                ],
                // borderWidth: 2
              },
            ],

          }}
            height={400}
            width={600}
            options={{
              maintainAspectRatio: false,
            }}>

          </Pie>

        </div>
      </div>

      <div className="text-center" style={{ marginTop: "40px" }}>
        <h3 style={{ color: "#790a81", marginBottom: "15px" }}>Category wise Applications Received</h3>
        <div className="mt-5">
          <Bar data={{
            labels: applicationReceivedForGirl.map((data) => {
              return data.year
            }),
            datasets: [
              {
                label: "Total No of Application Received for Girls",
                data: applicationReceivedForGirl.map((data) => {
                  return data.count
                }),
                backgroundColor: "#B1B2FF",


              },
              {
                label: "Total No of Application Received for Boys",
                data: applicationReceivedForBoys.map((data) => {
                  return data.count
                }),
                backgroundColor: "#7FBCD2",

              },
            ],
          }}
            height={400}
            width={600}
            options={{
              maintainAspectRatio: false,
            }}></Bar>
        </div>
      </div>

      <div className="text-center" style={{ marginTop: "40px" }}>
        <h3 style={{ color: "#790a81", marginBottom: "15px" }}>Yearly Amount Disbursed </h3>
        <div className="mt-5">
          <Bar data={{
            labels: yearlyAmountDisbursedForBoys.map((data) => {
              return data.year
            }),
            datasets: [
              {
                label: "Amount disbursed for Girls",
                data: yearlyAmountDisbursedForGirl.map((data) => {
                  return data.totalAmount
                }),
                backgroundColor: "#B1B2FF",


              },
              {
                label: "Amount disbursed for Boys",
                data: yearlyAmountDisbursedForBoys.map((data) => {
                  return data.totalAmount
                }),
                backgroundColor: "#7FBCD2",

              },
            ],
          }}
            height={400}
            width={600}
            options={{
              maintainAspectRatio: false,
            }}></Bar>
        </div>
      </div>


    </div>

  );
};

export default ChartComponent;
