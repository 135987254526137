import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import H5 from "../../text-components/H5";
import useValidator from '../../form-validation/useValidatior';
import { adminCommunication } from '../../communication/adminCommunication';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';

const AddDesignation = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validator, showValidationMessage] = useValidator();
  const [designation, setDesignation] = useState("");

  //Add Designation
  const addDesignation = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }
      dispatch(showLoader());
      const serverResponse = await adminCommunication.addDesignation(designation);
      if (serverResponse.data.status === "SUCCESS") {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "success" });
        navigate("/admin/dashboard/designation-list");
      } else if (serverResponse.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "error" });
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }

    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }

  }


  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="form_section">
            <div className="form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Add Designation</H5>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12 col-md-12 mb-4"> 
                    <input type="text" name="designation" onChange={(event) => setDesignation(event.target.value)} className="form-control" placeholder="Enter Designation" />
                    {validator.message("designation", designation, "required|alpha_space", {
                      messages: {
                        required: "Designation is required"
                      }
                    })}
                  </div>
                </div>


               
                
               
                    
                  
                    
                    
                  
                  
                  
        
        




                <div className="button_group mt-3">
                  <button onClick={() => addDesignation()}>Add</button>
                  <NavLink to="/admin/dashboard/designation-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

        
      }
      
    </>
  )
}

export default AddDesignation