import React from "react";
import './PublicCSS.css'
import H4 from "../text-components/H4";
import { useSelector } from "react-redux";

const About = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <div>
            <div className="about_section">
                <div className="layer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-9 col-12 ">
                                <h4>About Us</h4>
                                <p>This scheme for construction of hostels is one of the means to enable and encourage students belonging to Scheduled Castes (SC) to attain quality education. Such hostels are immensely beneficial to the students hailing from rural and remote areas of the country. While the scheme of construction of hostels for SC girls is in operation from the Third Five Year Plan (1961-66), the same for boys was started with effect from the year 1989-90.
                                    <br></br>
                                    The primary objective of the revised scheme is to attract implementing agencies for undertaking construction of hostels, especially for SC girls, towards the broader vision of containment and reduction of their dropout rate. The other objectives are:(i) having a girls/ boys hostel with a capacity of 100 seats, in every block headquarters of low literacy districts not having one as of now, by way of priority, (ii) repairing and proper maintenance of hostels, and (iii) having an effective mechanism for monitoring, review, etc.
                                </p>
                            </div>
                            <div className="col-lg-3 col-md-3  ab_right  about_img">
                                <img src="/images/girl-students-l.jpg" width="100%" height="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About