import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import H4 from '../../text-components/H4'
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import { adminCommunication } from '../../communication/adminCommunication';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const UploadDataList = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const [download, setDownload] = useState([]);
    const navigate = useNavigate();
    const [currentPage, SetCurrentPage] = useState(0);
  // const [searchValue, SetSearchValue] =useState("");
    const [pageCount, SetPageCount] = useState(1);
    const pageLImit = useSelector((state) => state.limitReducer?.limit)
    const limit = process.env.REACT_APP_LIMIT


    //get download data on initial load
    const getDownloadData = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await adminCommunication?.getDownload();
            if (serverResponse?.data?.status === "SUCCESS") {
                setDownload(serverResponse?.data?.uploadedData)
                dispatch(hideLoader());
            } else if (serverResponse.data.status === "JWT_INVALID") {
                navigate("/admin/login");
                swal({ text: serverResponse?.data?.message, icon: "error" });
                dispatch(hideLoader());
            } else {
                dispatch(hideLoader());
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
            dispatch(hideLoader());
        }
    }

   //get file 
    const fetchFile = async (fileUrl) => {
        if (fileUrl !== "") {
            await adminCommunication.getDocument(fileUrl);
        }
    }

    
  //onclick set Current page
    const handlePageClick = (e) => {
    const selectedPage = e.selected;
    SetCurrentPage(selectedPage);
    // if(searchValue)
}

    useEffect(() => {
        getDownloadData();
    }, [])


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :

                    <div className="tab_content_main">
                    <div className='top_search_header'>
                    <div className='title'>
                                <H4 whiteThemeColor darkThemColor>Document List</H4>
                            </div>
                        { /*<div className="search">
                                <input type="text" className="search-input" placeholder="Search .." onChange="" />
                                <i className=" search-icon fa-solid fa-magnifying-glass"></i>
                                 </div> */}
                                
                                <div className='button_group add_button'>
                                    <NavLink to="/admin/dashboard/upload-data-list/add-data">
                                    <button><i className="fa fa-plus me-2"></i>Add New</button>
                                </NavLink>
                            </div>
                            <div className="pagination_div">
                            {
                            pageCount>1 &&
                                <div className="pagination_main">
                                <ReactPaginate
                                previousLabel={currentPage === 0 ?  "First" : <i className="fa-sharp fa-solid fa-backward"></i> }
                                nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}shape="rounded"
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                forcePage={currentPage}
                                activeClassName={"active"} />
                            </div>
                            }
                            </div>

                        </div>
                    <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                            <div className="row m-0">
                                <div className="col-lg-2 col-md-2 text_align">
                                    <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                                </div>
                                <div className="col-lg-7 col-md-7 text_align">
                                    <H5 whiteThemeColor darkThemeColor>Title</H5>
                                </div>
                                <div className="col-2 col-md-2 text_align">
                                    <H5 whiteThemeColor darkThemeColor>Document</H5>
                                </div>
                                <div className="col-1 col-md-1 align">
                                    <H5 whiteThemeColor darkThemeColor>Action</H5>
                                </div>
                            </div>
                        </div>
                        {
                            download?.length > 0 ?
                                <>
                                    {
                                        download.map((data, index) => {
                                            return (
                                                <div className="table_data" key={index}>
                                                    <div className="row m-0">
                                                        <div className="col-lg-2 col-md-2 text_align">
                                                            <H6 whiteThemeColor darkThemeColor>{limit * currentPage + (index + 1)}</H6>
                                                        </div>
                                                        <div className="col-lg-7 col-md-7 text_align">
                                                            <H6 whiteThemeColor darkThemeColor>{data.title}</H6>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 text_align action"  >
                                                            <i style={{color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "rgb(173, 83, 137)"}} className="fa-solid fa-download" title="Download" onClick={() => { fetchFile(data.attachment) }} ></i>
                                                        </div>
                                                        <div className="col-lg-1 col-md-1 align p-0 action">
                                                            <NavLink to={`/admin/dashboard/upload-data-list/update-data?documentId=${data?._id}`}>
                                                                <i style={{color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "rgb(173, 83, 137)"}} className="fa-solid fa-pen-to-square" title="Update"></i>
                                                            </NavLink>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })}
                                </>

                                :
                                <div className="data_not_available_message">
                                    <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Download Not Available</span>
                                </div>
                        }

                    </div>
            }
        </>
    )
}

export default UploadDataList