import React, { useEffect, useState } from "react";
import './PublicCSS.css';
import { NavLink } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { publicCommunication } from "../communication/public-communication";
import swal from "sweetalert";


function myFunction() {
  var x = document.getElementById("myLinks");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}
const Navbar = () => {
  const [yearlyCourseCompletion, setYearlyCourseCompletion] = useState([]);
  const [yearlyApplicationReceived, setYearlyApplicationReceived] = useState([]);
  const [applicationReceivedForGirl, setApplicationReceivedForGirl] = useState([]);
  const [applicationReceivedForBoys, setApplicationReceivedForBoys] = useState([]);
  const [yearlyAmountDisbursedForGirl, setYearlyAmountDisbursedForGirl] = useState([]);
  const [yearlyAmountDisbursedForBoys, setYearlyAmountDisbursedForBoys] = useState([]);
  const [districtWiseGirlApplication, setDistrictWiseGirlApplication] = useState([]);
  const [districtWiseBoysApplication, setDistrictWiseBoysApplication] = useState([]);



  const getData = async () => {
    try {
      const serverResponse = await publicCommunication.getGraphData();
      if (serverResponse.data.status === "SUCCESS") {
        setYearlyApplicationReceived(serverResponse?.data?.data?.yearlyApplicationReceived);
        setYearlyCourseCompletion(serverResponse?.data?.data?.yearlyCompletedApplicationReceived)
        setApplicationReceivedForGirl(serverResponse?.data?.data?.totalNumberOfApplicationReceivedForGirls)
        setApplicationReceivedForBoys(serverResponse?.data?.data?.totalNumberOfApplicationReceivedForBoys)
        setYearlyAmountDisbursedForGirl(serverResponse?.data?.data?.totalAmountReceivedByGirls)
        setYearlyAmountDisbursedForBoys(serverResponse?.data?.data?.totalAmountReceivedByBoysGraph)
        setDistrictWiseBoysApplication(serverResponse?.data?.data?.totalNumberOfApplicationReceivedForBoysDistrictWise)
        setDistrictWiseGirlApplication(serverResponse?.data?.data?.totalNumberOfApplicationReceivedForGirlsDistrictWise)
      } else {

      }
    } catch (error) {
      swal(error.message);
    }
  }
  useEffect(() => {
    getData();
  }, [])

  return (

 
    
    <nav className="navbar navbar-dark bg-dark navbar-expand-md">
    
    <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
        <span className="navbar-toggler-icon"></span>
    </button>
    <div className="navbar-collapse collapse" id="navbar">
        <ul className="navbar-nav">

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Statistic
                </a>
                <NavLink to="/statistics" target="_blank">
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                      <span className="dropdown-item">
                        <p className="dropdown_text">

                          <div>
                            <Bar
                              data={{
                                labels: yearlyApplicationReceived.map((data) => {
                                  return data.year
                                }),
                                datasets: [
                                  {
                                    label: "Yearly New Construction Applications Received",
                                    data: yearlyApplicationReceived.map((data) => {
                                      return data.newApplicationCount
                                    }),
                                    backgroundColor: "#C3F8FF",
                                  },
                                  {
                                    label: "Yearly Repair and Maintenance Applications Received",
                                    data: yearlyApplicationReceived.map((data) => {
                                      return data.repairMaintenanceCount
                                    }),
                                    backgroundColor: "#ABD9FF",

                                  },
                                ],
                              }}
                              height={400}
                              width={600}
                              options={{
                                maintainAspectRatio: false,
                              }}
                            />

                          </div>

                        </p>
                      </span>
                    </li>
                    <li>
                      <span className="dropdown-item">
                        <p className="dropdown_text">

                          <div>
                            <Bar data={{
                              labels: yearlyCourseCompletion.map((data) => {
                                return data.year
                              }),
                              datasets: [
                                {
                                  label: "Yearly New Construction Completed",
                                  data: yearlyCourseCompletion.map((data) => {
                                    return data.newApplicationCount
                                  }),
                                  backgroundColor: "#C3F8FF",
                                },
                                {
                                  label: "Yearly Repair and Maintenance Completed",
                                  data: yearlyCourseCompletion.map((data) => {
                                    return data.repairMaintenanceCount
                                  }),
                                  backgroundColor: "#ABD9FF",
                                },
                              ],
                            }}
                              height={300}
                              width={600}
                              options={{
                                maintainAspectRatio: false,
                              }}></Bar>
                          </div>
                        </p>
                      </span>
                    </li>

                    <li>
                      <span className="dropdown-item">
                        <p className="dropdown_text">
                          <div className="mt-5">
                            <Pie data={{
                              labels: districtWiseBoysApplication.map((data) => {
                                return data.district
                              }),
                              datasets: [
                                {
                                  label: "District wise Application for Boys Hostel ",
                                  data: districtWiseBoysApplication.map((data) => {
                                    return data.count
                                  }),
                                  backgroundColor: [
                                    "#a2a2d0",
                                    "#6699cc",
                                    "#0d98ba",
                                    "#8a2be2",
                                    "#8a2be2",
                                    "#de5d83",
                                    "#79443b",
                                    "#0095b6",
                                    "#e3dac9",
                                    "#cc0000",
                                    "#006a4e",
                                    "#873260",
                                    "#0070ff",
                                    "#b5a642",
                                    "#cb4154",
                                    "#1dacd6",
                                    "#66ff00",
                                    "#bf94e4",
                                    "#c32148",
                                    "#ff007f",
                                    "#08e8de",
                                    "#d19fe8",
                                    "#f4bbff",
                                    "#ff55a3",
                                    "#fb607f",
                                    "#004225",
                                    "#cd7f32",
                                    "#a52a2a",
                                    "#ffc1cc",
                                    "#e7feff",
                                    "#f0dc82",
                                    "#480607",
                                    "#800020",
                                    "#deb887",
                                    "#cc5500"
                                  ],
                                  // borderWidth: 4
                                },
                              ],
                            }}
                              height={400}
                              width={600}
                              options={{
                                maintainAspectRatio: false,
                              }}>

                            </Pie>

                          </div>
                        </p>
                      </span>
                    </li>

                    <li>
                      <span className="dropdown-item">
                        <p className="dropdown_text">

                          <div className="mt-5">
                            <Pie data={{
                              labels: districtWiseGirlApplication.map((data) => {
                                return data.district
                              }),
                              datasets: [
                                {
                                  label: "District wise Application for Girls Hostel ",
                                  data: districtWiseGirlApplication.map((data) => {
                                    return data.count
                                  }),
                                  backgroundColor: [
                                    "#a2a2d0",
                                    "#6699cc",
                                    "#0d98ba",
                                    "#8a2be2",
                                    "#8a2be2",
                                    "#de5d83",
                                    "#79443b",
                                    "#0095b6",
                                    "#e3dac9",
                                    "#cc0000",
                                    "#006a4e",
                                    "#873260",
                                    "#0070ff",
                                    "#b5a642",
                                    "#cb4154",
                                    "#1dacd6",
                                    "#66ff00",
                                    "#bf94e4",
                                    "#c32148",
                                    "#ff007f",
                                    "#08e8de",
                                    "#d19fe8",
                                    "#f4bbff",
                                    "#ff55a3",
                                    "#fb607f",
                                    "#004225",
                                    "#cd7f32",
                                    "#a52a2a",
                                    "#ffc1cc",
                                    "#e7feff",
                                    "#f0dc82",
                                    "#480607",
                                    "#800020",
                                    "#deb887",
                                    "#cc5500"
                                  ],
                                  // borderWidth: 2
                                },
                              ],

                            }}
                              height={400}
                              width={600}
                              options={{
                                maintainAspectRatio: false,
                              }}>

                            </Pie>

                          </div>
                        </p>
                      </span>
                    </li>

                    <li>
                      <span className="dropdown-item">
                        <p className="dropdown_text">
                          <div className="mt-5">
                            <Bar data={{
                              labels: applicationReceivedForGirl.map((data) => {
                                return data.year
                              }),
                              datasets: [
                                {
                                  label: "Total No of Application Received for Girls",
                                  data: applicationReceivedForGirl.map((data) => {
                                    return data.count
                                  }),
                                  backgroundColor: "#B1B2FF",


                                },
                                {
                                  label: "Total No of Application Received for Boys",
                                  data: applicationReceivedForBoys.map((data) => {
                                    return data.count
                                  }),
                                  backgroundColor: "#7FBCD2",

                                },
                              ],
                            }}
                              height={400}
                              width={600}
                              options={{
                                maintainAspectRatio: false,
                              }}></Bar>
                          </div>
                        </p>
                      </span>
                    </li>

                    <li>
                      <span className="dropdown-item">
                        <p className="dropdown_text">
                          <div className="mt-5">
                            <Bar data={{
                              labels: yearlyAmountDisbursedForBoys.map((data) => {
                                return data.year
                              }),
                              datasets: [
                                {
                                  label: "Amount disbursed for Girls",
                                  data: yearlyAmountDisbursedForGirl.map((data) => {
                                    return data.totalAmount
                                  }),
                                  backgroundColor: "#B1B2FF",


                                },
                                {
                                  label: "Amount disbursed for Boys",
                                  data: yearlyAmountDisbursedForBoys.map((data) => {
                                    return data.totalAmount
                                  }),
                                  backgroundColor: "#7FBCD2",

                                },
                              ],
                            }}
                              height={400}
                              width={600}
                              options={{
                                maintainAspectRatio: false,
                              }}></Bar>
                          </div>
                        </p>
                      </span>
                    </li>
                  </ul>
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Eligibility Criteria
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        <b> IMPLEMENTING AGENCIES AND THEIR ELIGIBILITY</b>
                      </p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        The scheme is implemented through the State Governments, Union Territory
                        Administrations and the Central &amp; State Universities/ Institutions. These implementing
                        agencies are provided eligible central assistance as per provisions of the scheme, for fresh
                        construction of hostel buildings, for expansion of the existing hostel facilities and for
                        periodic repair and maintenance of the hostels constructed under this Scheme.
                      </p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        The proposal for construction/ expansion/ repair &maintenance of hostels by State
                        Universities/ Institutions shall be submitted to the respective State Government/ UT
                        Administration who, in turn, would forward the same along with their recommendations to
                        the Department of Social Justice and Empowerment, Government of India for release of
                        grants-in-aid as per provisions of the Scheme.
                      </p>
                    </span>

                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        Likewise, the proposal of Central Universities/ Institutions shall be submitted to the
                        Ministry through their Administrative Ministries/Departments along with their
                        recommendations for release of grants-in-aid under the Scheme.
                      </p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item" href="#">
                      <p className="dropdown_text">
                        <b>LOCATION AND SCOPE OF HOSTELS</b>
                      </p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        While sanctioning hostels, priority maybe given to areas having concentration of
                        SC population of 15% and more, and without adequate hostel facilities for SC students.
                        Preference will be given to integrated hostels (part of established educational institutions)
                        over stand-alone hostels.
                      </p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        State Governments, while selecting schools for construction of hostels will give
                        priority to top performing Government schools not having any hostel at present.
                      </p>
                    </span>
                  </li>

                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        In case of girls, the hostels will be located in areas having low SC female literacy.
                        The girls hostels will be constructed in close vicinity of the educational institution.
                      </p>
                    </span>
                  </li>

                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        Focus will be given on construction of hostels for middle and higher secondary
                        levels of education. However, hostels can also be constructed under the scheme for college
                        and university levels of education.
                      </p>
                    </span>
                  </li>

                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        The construction of boundary walls, two rooms set
                        quarter for hostel wardens and
                        one room set for Chowkidars/ Lady Guards would be an integral part of the scheme.</p>
                    </span>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Construction Norms
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        <b>STRENGTH OF THE HOSTELS</b></p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        The capacity per hostel should not normally exceed 100 students. In exceptional
                        cases, hostels with larger capacities (maximum upto 250 students) can be considered. Each
                        hostel room should accommodate at least 2-3 students. No single room accommodation
                        would be provided in the hostels constructed under the scheme.</p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        The capacity per hostel should not normally exceed 100 students. In exceptional
                        cases, hostels with larger capacities (maximum upto 250 students) can be considered. Each
                        hostel room should accommodate at least 2-3 students. No single room accommodation
                        would be provided in the hostels constructed under the scheme.</p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        <b>COST NORMS</b></p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        For Construction</p>
                    </span>
                  </li>

                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        (a) The cost norms for construction/expansion of girls and boys hostels will be as
                        under:
                      </p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        (I) &nbsp; North Eastern Region &nbsp;&nbsp; &nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : Rs.3.50 lakh per inmate <br />
                        (II)&nbsp; Northern Himalayan Regions  &nbsp;&nbsp;: Rs.3.25 lakh per inmate<br />
                        (III)&nbsp;Gangetic Plains &Low Himalayan Region &nbsp;&nbsp;: Rs.3.00 lakh per inmate
                      </p>
                    </span>
                  </li>

                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        (b) In addition to the admissible central assistance under the Scheme, a one-time grant
                        of Rs.5000/- per student would also be provided for making provisions of a cot, a table and
                        a chair for each student and for common facilities like Television, Computer, Kitchen
                        equipment, etc. This one-time grant shall be released after utilization of central assistance
                        provided by the Government alongwith matching share of implementing agency, if any, for
                        construction, and completion of construction of the hostel in all respects   </p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        <b>Infrastructure Maintenance:</b></p>
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item">
                      <p className="dropdown_text">
                        Central assistance for repair and maintenance of the operational hostels will be
                        provided to implementing agencies, once in every five years after operationalization of the
                        hostel. It will be limited to a maximum of Rs.5.00 lakh for one hostel of 50 inmates,
                        Rs.10.00 lakh for 100 inmates, Rs.15.00lakh for 150 inmates and so on. Central assistance
                        for repair and maintenance shall be provided for the SC hostels constructed under BJRCY
                        by the State/ UT Governments and Central & State Universities/ Institutions. </p>
                    </span>
                  </li>

                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Application Guideline
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                  <span className="dropdown-item">
                      <p className="dropdown_text">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni deleniti officiis et doloremque eveniet. Nesciunt aliquam dolores, sapiente iusto blanditiis mollitia assumenda, voluptate quia nemo omnis nulla consectetur neque repudiandae.   </p>
                    </span>
                  </li>
                  <li>
                  <span className="dropdown-item">
                      <p className="dropdown_text">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit, quaerat.</p>
                    </span>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Application Flow
              </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                  <span className="dropdown-item">
                      <p className="dropdown_text">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni deleniti officiis et doloremque eveniet. Nesciunt aliquam dolores, sapiente iusto blanditiis mollitia assumenda, voluptate quia nemo omnis nulla consectetur neque repudiandae.   </p>
                    </span></li>
                  <li>
                  <span className="dropdown-item">
                      <p className="dropdown_text">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni deleniti officiis et doloremque eveniet. Nesciunt aliquam dolores, sapiente iusto blanditiis mollitia assumenda, voluptate quia nemo omnis nulla consectetur neque repudiandae.   </p>
                    </span></li>
                  <li>
                  <span className="dropdown-item">
                      <p className="dropdown_text">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni deleniti officiis et doloremque eveniet. Nesciunt aliquam dolores, sapiente iusto blanditiis mollitia assumenda, voluptate quia nemo omnis nulla consectetur neque repudiandae.   </p>
                    </span></li>
                </ul>
              </li>
            </ul>
          </div>
        
      </nav>


    
  );
}

export default Navbar;