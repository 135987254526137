import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { darkTheme, whiteTheme } from '../redux-store/darkWhiteThemeToggle';
import { increment, decrement, defaultFont } from '../redux-store/fontSizeMultiplier';

const TopHeader = ({setShowLoginBox}) => {
    const dispatch = useDispatch();
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    
    return (
        <div className="row m-0">
            <div className="col-lg-12 col-md-12 col-12 p-0 text-md-right righthd">
                <div className="top_header" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c" }}>
                    <span style={{ color: `${darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff"}` }} onClick={() => dispatch(decrement())}>A-</span>
                    <span style={{ color: `${darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff"}` }} whiteThemeColor darkThemeColor onClick={() => dispatch(defaultFont())}>A </span>
                    <span style={{ color: `${darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff"}` }} whiteThemeColor darkThemeColor onClick={() => dispatch(increment())}>A+</span>
                    <button className="whiteTheme" onClick={() => dispatch(whiteTheme())}>A</button>
                    <button className="darkTheme" onClick={() => dispatch(darkTheme())}>A</button>
                    <li>
                        <div id="google_element" className="google"></div>
                    </li>
                    <div className="user_icon">
                        <i className="fa-solid fa-user" onClick={() => setShowLoginBox(true)}></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeader