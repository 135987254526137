import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { userCommunication } from '../communication/userCommunication';
import swal from 'sweetalert';

const PrintSecondInstallment = () => {
    const componentRef = useRef();
    const [secondInstallmentData, setSecondInstallmentData] = useState({});

    //get Application id from params
    const params = new URLSearchParams(useLocation().search);
    const id = params.get("id");

    //Get user Application Detail by id
    const fetchUserApplicationById = async () => {
        try {
            const responseFromServer = await userCommunication.getUserApplicationById(id);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setSecondInstallmentData(responseFromServer?.data?.applications?.secondInstallMentForm);
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchUserApplicationById();
    }, []);


    return (
        <div className="print_table_main">
            <div className="print_table_section" ref={componentRef}>
                {/* <div className="file_number">
                    <h5><b>File No. 11024/1/2016-SCD-I-Vikyne(3)</b></h5>
                </div>
                <div className="annexure_no">
                    <h5>ANNEXURE - III</h5>
                </div> */}
                <div className="annexure_header">
                    <h5>PROPOSAL FOR RELEASE OF 2ND INSTALLMENT FOR SC GIRLS/BOYS HOSTELS SANCTIONED BY MINISTRY OF SOCIAL
                        JUSTICE &
                        EMPOWERMENT UNDER 'BJRCY'</h5>
                </div>

                <div>

                    <h5>Financial Progress Report:</h5>

                    <table className="second_installment_table">

                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Amount Sanctioned by the Ministry of Social Justice & Empowerment</th>
                                <th>State/UT share</th>
                                <th>University/Institute Share</th>
                                <th colSpan="2">Central assistance released by Ministry of Social Justice & Empowerment </th>
                                <th colSpan="2">Actual Amount utilized</th>
                                <th colSpan="2">Unspent balance of central assistance up to 31st March, i.e. year ending</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>Sanction No & Date</th>
                                <th>Amount</th>
                                <th>Central Share</th>
                                <th>State/University share</th>
                                <th>Central share</th>
                                <th>State/University share</th>

                            </tr>
                        </tbody>

                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center" }}>1</td>
                                <td>{secondInstallmentData?.sanctionAmount}</td>
                                <td>{secondInstallmentData?.stateShare}</td>
                                <td>{secondInstallmentData?.universityShare}</td>
                                <td>{secondInstallmentData?.centralAssistance?.sanctionNo} / {secondInstallmentData?.centralAssistance?.sanctionDate}</td>
                                <td>{secondInstallmentData?.centralAssistance?.amount}</td>
                                <td>{secondInstallmentData?.amountUtilized?.centralShare}</td>
                                <td>{secondInstallmentData?.amountUtilized?.universityShare}</td>
                                <td>{secondInstallmentData?.unspentBalance?.centralShare}</td>
                                <td>{secondInstallmentData?.unspentBalance?.universityShare}</td>

                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td colSpan="10">Whether necessary provision of fund has been made for
                                    salary and other expenses
                                    related to
                                    deployment of wardens and guards for the hostel in case of girl's hostel? (Yes or No):-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <h5 className="mt-3 mb-3">Physical Progress Report:</h5>

                    <table className="second_installment_table">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Year</th>
                                <th>Hostels Sanctioned by Ministry of Social Justice & Empowerment along with location </th>
                                <th>Whether Completed(Yes/No)</th>
                                <th>If completed, Date of completion </th>
                                <th>If not completed, Status of construction</th>
                                <th>Expected date of completion</th>
                                <th>Reasons for non-completion</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center" }}>1</td>
                                <td>{secondInstallmentData?.year}</td>
                                <td>Yes</td>
                                <td>{secondInstallmentData?.isConstructionCompleted}</td>
                                <td>{(secondInstallmentData?.constructionDetails?.dateOfCompletion === "") ? "-" : secondInstallmentData?.constructionDetails?.dateOfCompletion}</td>
                                <td>{(secondInstallmentData?.constructionDetails?.constructionStatus === "") ? "-" : secondInstallmentData?.constructionDetails?.constructionStatus}</td>
                                <td>{(secondInstallmentData?.constructionDetails?.expectedDateOfCompletion === "") ? "-" : secondInstallmentData?.constructionDetails?.expectedDateOfCompletion}</td>
                                <td>{(secondInstallmentData?.constructionDetails?.reasonOfNonCompletion === "") ? "-" : secondInstallmentData?.constructionDetails?.reasonOfNonCompletion}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <h5 className="mt-3 mb-3">List of Documents to be submitted along with the proposal for release of 2nd
                        installment :</h5>
                    <table className="document_list">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Documents/Information</th>
                                <th> Whether enclosed or not (Indicate Yes/No) </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center" }}>1</td>
                                <td>Utilization Certificate as per format mentioned in sanction letter</td>
                                <td>{secondInstallmentData?.document?.isUtilizationCertificateAttached}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>2</td>
                                <td>Audit reports since 1st installment released (in case of Central/State
                                    University/Institution
                                    ) </td>
                                <td>{secondInstallmentData?.document?.isAuditReportAttached}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>3</td>
                                <td>Balance sheet since 1st installment released (in case of Central/State
                                    University/Institution)</td>
                                <td>{secondInstallmentData?.document?.isBalanceSheetAttached}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>4</td>
                                <td>Income expenditure statement since 1st installment released (in case of Central/State
                                    University/Institution)</td>
                                <td>{secondInstallmentData?.document?.isIncomeExpenditureAttached}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>5</td>
                                <td>Receipt of payment since 1st installment released (in case of Central/State
                                    University/Institution)</td>
                                <td>{secondInstallmentData?.document?.isPaymentReceiptAttached}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>6</td>
                                <td>Bank Statement since release of 1st installment</td>
                                <td>{secondInstallmentData?.document?.isBankStatementAttached}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>7</td>
                                <td>Details of interest earned on 1st installment released for the project</td>
                                <td>{secondInstallmentData?.document?.isInterestDetailsAttached}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>8</td>
                                <td>Status of Utilization of matching share by the implementing agency, if any</td>
                                <td>{secondInstallmentData?.document?.isStatusOfUtilizationAttached}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>9</td>
                                <td>Site inspection report</td>
                                <td>{secondInstallmentData?.document?.isSiteInspectionReportAttached}</td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center" }}>10</td>
                                <td>Colour Photographs of project site</td>
                                <td>{secondInstallmentData?.document?.isProjectPhotoAttached}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="row mt-4">
                    <div className="col-6 mt-2">
                        <p><b> Note: </b>Separate report may be submitted for SC Girls and Boys hostels.</p>
                    </div>
                    <div className="col-6 Authorization_detail">
                        <div className="mt-3" style={{ marginLeft: "50%", textAlign: "left" }}>
                            <h6>Signature</h6>
                            <h6 className="mt-3 mb-3">Name:..................................</h6>
                            <h6>Designation:...........................</h6>
                            <h6 className="mt-3">Full address:..........................</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="print_icon">
                <ReactToPrint
                    trigger={() => <i className="fa fa-print" aria-hidden="true" title="Print Document"></i>}
                    content={() => componentRef.current}
                />
            </div>
        </div>
    )
}

export default PrintSecondInstallment