export function capitalizeFirstLetter(string) {
 
    const splitArray = string?.toLowerCase()?.split("_");

    const splitBySpace = string?.toLowerCase()?.split(" ");
    if (splitArray?.length > 1) {
        let newString = "";
        splitArray.forEach((ele) => {
            newString += " " + ele?.charAt(0)?.toUpperCase() + ele?.slice(1);
        })
        return newString;
    } else if (splitBySpace?.length > 1) {
        let newString = "";
        splitBySpace.forEach((ele) => {
            newString += " " + ele?.charAt(0)?.toUpperCase() + ele?.slice(1);
        })
        return newString;
    } else {
        return string?.toLowerCase()?.charAt(0)?.toUpperCase() + string?.toLowerCase()?.slice(1);
    }
}