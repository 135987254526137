import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { userCommunication } from '../../communication/userCommunication';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { capitalizeFirstLetter } from '../../shared-components/capitalizeFirstLetter';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const Grievance = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [grievanceList, setGrievanceList] = useState([]);
  const [currentPage, SetCurrentPage] = useState(0);
  const [pageCount, SetPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const limit = process.env.REACT_APP_LIMIT


  //onclick set Current page
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    SetCurrentPage(selectedPage);
    if (searchValue) {
      fetchGrievanceBySearch(searchValue, selectedPage + 1);
    } else {
      fetchGrievance(selectedPage + 1);
    }
  }

  //Search Filter
  const searchFilter = async (searchString) => {
    if (searchString) {
      fetchGrievanceBySearch(searchString, currentPage + 1);
    } else {
      const responseFromServer = await userCommunication.getGrievance();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setGrievanceList(responseFromServer?.data?.grievance);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setGrievanceList([]);
        SetPageCount(0);
      }
    }
  }

  //get grievance by search
  const fetchGrievanceBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await userCommunication.getGrievancesBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setGrievanceList(responseFromServer?.data?.grievance);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setGrievanceList([]);
        SetPageCount(0);
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
    }
  }
  //fetch grievance list on initial load
  const fetchGrievance = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await userCommunication.getGrievance(page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setGrievanceList(responseFromServer?.data?.grievance);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        dispatch(hideLoader());
        setGrievanceList([]);
        SetPageCount(0);
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  //get user id
  let userId = "";
  grievanceList?.filter((ele) => {
    userId = ele.userId;
  })

  useEffect(() => {
    fetchGrievance();
  }, []);


  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="section">
            <div className="container mt-2">
              <div className="header_wrapper alert_header_wrapper" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                <div className="row mb-2 top_search_header_user_portal">
                  <div className="col-lg-3 col-md-4">
                    <input Type="text" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search.." />
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="raise_grievance_btn">
                      <NavLink to={`/user/grievance/raise-grievance?userId=${userId}`}>
                        <button>Raise Grievance</button>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-5 user_portal_pagination">
                    <div className="pagination_div ">
                      {
                        pageCount > 1 &&
                        <div className="pagination_main">
                          <ReactPaginate
                            previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount} shape="rounded"
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            forcePage={currentPage}
                            activeClassName={"active"} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                <div className="row m-0">
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                  </div>
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Grievance Id</H5>
                  </div>
                  <div className="col-4 text_align">
                    <H5 whiteThemeColor darkThemeColor>Title</H5>
                  </div>
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Status</H5>
                  </div>
                  <div className="col-2 align">
                    <H5 whiteThemeColor darkThemeColor>Date</H5>
                  </div>
                </div>
              </div>
              {grievanceList?.length > 0 ?
                <>
                  {grievanceList?.map((grievance, index) => (
                    <div className="table_data" key={index}>
                      <div className="row m-0">
                        <div className="col-2 text_align">
                          <H6 whiteThemeColor darkThemeColor>{(limit * (currentPage) + (index + 1))}</H6>
                        </div>
                        <div className="col-2 text_align">
                          <NavLink to={`/user/grievance/chatbot?ticketId=${grievance?.ticketId}`}>
                            <H6 whiteThemeColor darkThemeColor>{grievance?.ticketId}</H6>
                          </NavLink>
                        </div>
                        <div className="col-4 text_align">
                          <H6 whiteThemeColor darkThemeColor>{grievance?.title}</H6>
                        </div>
                        <div className="col-2 text_align">
                          <H6 whiteThemeColor darkThemeColor>{capitalizeFirstLetter(grievance?.userStatus)}</H6>
                        </div>
                        <div className="col-2 align">
                          <H6 whiteThemeColor darkThemeColor>{new Date(grievance?.creationDate).toLocaleDateString()}</H6>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
                :
                <div className="data_not_available_message">
                  <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Grievance Not Available</span>
                </div>
              }
            </div>
          </div>
      }
    </>
  )
}

export default Grievance