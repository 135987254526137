import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import Label from '../../text-components/Label';
import useValidator from '../../form-validation/useValidatior';
import { userCommunication } from '../../communication/userCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import swal from 'sweetalert';

const PreviewNewApplication = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const backOfficeUser = useSelector((state) => state.backOfficeUserDetails)
    const [validator, showValidationMessage] = useValidator();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(true);
    const [backOfficeStatus, setBackOfficeStatus] = useState("");
    const [newApplicationData, setNewApplicationData] = useState(
        {
            submittedTo: "",
            division: "",
            district: "",
            agency: "",
            natureOfAgency: "",
            hostelLevel: "",
            category: "",
            noOfInmates: "",
            casteWiseInmates: {
                general: "",
                SC: "",
                OBC: "",
                ST: "",
            },
            hostelLocation: "",
            landDetails: {
                isLandAcquiredByAgency: "",
                isLandEncumbranceFree: "",
                area: "",
            },
            completionPeriod: "",
            costEstimatesDetails: {
                totalCost: "",
                perInmatesCost: "",
                actualPerInmatesCost: "",
                grandsInAid: "",
                share: "",
                isAgencyAgreeToBearCost: "",
                isAgencyAgreeToShare: "",
                isCostDetermineByPWD: "",
            },
            buildingPlan: {
                isDrawingPrepared: "",
                isPlanApproved: "",
                plinthArea: "",
            },
            facilities: {
                other: "",
                otherDetails: "",
                cleaning: "",
                bathingAndWashing: "",
                drinkingWater: "",
                gameAndSport: "",
                appointmentOfCook: "",
                medicalCare: "",
                coaching: "",
            },
            facilitiesTiedUp: "",
            sourceOfFundingForCommonFacilities: "",
            studentSecurity: {
                fireSafety: "",
                boundaryWall: "",
                appointmentOfWarder: "",
            },
            populationDetails: {
                totalDistrictPopulation: "",
                scDistrictPopulation: "",
                percentageOfSC: "",
                percentageOfSCFemaleMaleLiteracy: "",
                percentageOfSCMaleLiteracy: "",
            },
            scHostelDetails: {
                scHostelInDistrict: "",
                scHostelInBlock: "",
                operationalSCHostel: "",
            },
            isHostelAttachedToInstitute: "",
            instituteName: "",
            instituteAddress: "",
            sourceOfFundForMaintenance: "",
            isFacilityAvailableForDisableStudent: "",
            facilityDetailsForDisables: "",
            noOfStudentStudyingInAttachedInstitute: "",
            documents: {
                formSubmittedByState: "",
                justification: "",
                annexure: "",
                sitePlanSigned: "",
                costEstimateSigned: "",
                buildingPlanSigned: "",
                isLandOwnershipDocumentAuthenticate: "",
            },
            otherRelevantDetails: ""
        }
    )

    const [documents, setDocuments] = useState({
        documents: {
            form: "",
            justificationCertificate: "",
            annexureCertificate: "",
            sitePlanSignedCertificate: "",
            costEstimateCertificate: "",
            buildingPlanSignedCertificate: "",
            landOwnershipAuthenticateCertificate: "",
        }
    });

    const [documentAttached, setDocumentAttached] = useState(
        {
            isFormUpdated: false,
            isJustificationCertificateUpdated: false,
            isAnnexureCertificateUpdated: false,
            isSitePlanSignedCertificateUpdated: false,
            isCostEstimateCertificateUpdated: false,
            isBuildingPlanSignedCertificateUpdated: false,
            isLandOwnershipAuthenticateCertificateUpdated: false,
        }
    )

    const [comment, setComment] = useState("");
    const [approveCertificate, setApproveCertificate] = useState();
    //get id from params
    const params = new URLSearchParams(useLocation().search);
    const id = params.get("id");
    const applicationId = params.get("applicationId");
    const [userId, setUserId] = useState("");

    //get current url
    const fullUrl = window.location.href;
    const splitUrl = fullUrl.split("/")
    const stateUrl = splitUrl.includes("state-selected-applications")
    const finalSelectedUrl = splitUrl.includes("final-selected-applications")
    const districtSelected = splitUrl.includes("district-selected-applications");
    const divisionSelected = splitUrl.includes("division-selected-applications");

    //get all inputs value
    const getApplicationDetail = (event) => {
        setNewApplicationData({ ...newApplicationData, [event.target.name]: event.target.value });
    }


    //Get user Application Detail by id
    const fetchUserApplicationById = async () => {
        try {
            dispatch(showLoader());
            const responseFromServer = await userCommunication.getUserApplicationById(id);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                if (["Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(responseFromServer?.data?.applications?.applicationStatus) && [undefined, null, ""].includes(backOfficeUser.userType)) {
                    setIsSubmitted(false);
                } else {
                    setIsSubmitted(true);
                }

                setUserId(responseFromServer?.data?.applications?.userId);
                setBackOfficeStatus(responseFromServer?.data?.applications?.backOfficeStatus);
                setNewApplicationData(responseFromServer?.data?.applications?.firstInstallMentForm);
                setDocuments(responseFromServer?.data?.applications?.firstInstallMentForm);
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    }

    //Submit application
    const updateApplication = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const formData = new FormData();
            if (documentAttached.isFormUpdated === true) {
                formData.append("form", documents.documents.form);
            }
            if (documentAttached.isJustificationCertificateUpdated === true) {
                formData.append("justificationCertificate", documents.documents.justificationCertificate);
            }
            if (documentAttached.isAnnexureCertificateUpdated === true) {
                formData.append("annexureCertificate", documents.documents.annexureCertificate);
            }
            if (documentAttached.isSitePlanSignedCertificateUpdated === true) {
                formData.append("sitePlanSignedCertificate", documents.documents.sitePlanSignedCertificate);
            }
            if (documentAttached.isCostEstimateCertificateUpdated === true) {
                formData.append("costEstimateCertificate", documents.documents.costEstimateCertificate);
            }
            if (documentAttached.isBuildingPlanSignedCertificateUpdated === true) {
                formData.append("buildingPlanSignedCertificate", documents.documents.buildingPlanSignedCertificate);
            }
            if (documentAttached.isLandOwnershipAuthenticateCertificateUpdated === true) {
                formData.append("landOwnershipAuthenticateCertificate", documents.documents.landOwnershipAuthenticateCertificate);
            }


            const dataToSend = {
                id,
                agency: newApplicationData.agency,
                natureOfAgency: newApplicationData.natureOfAgency,
                hostelLevel: newApplicationData.hostelLevel,
                category: newApplicationData.category,
                noOfInmates: Number(newApplicationData.noOfInmates),
                casteWiseInmates: {
                    general: Number(newApplicationData.casteWiseInmates.general),
                    SC: Number(newApplicationData.casteWiseInmates.SC),
                    OBC: Number(newApplicationData.casteWiseInmates.OBC),
                    ST: Number(newApplicationData.casteWiseInmates.ST)
                },
                hostelLocation: newApplicationData.hostelLocation,
                landDetails: {
                    isLandAcquiredByAgency: newApplicationData.landDetails.isLandAcquiredByAgency,
                    isLandEncumbranceFree: newApplicationData.landDetails.isLandEncumbranceFree,
                    area: newApplicationData.landDetails.area,
                },
                completionPeriod: Number(newApplicationData.completionPeriod),
                costEstimatesDetails: {
                    totalCost: newApplicationData.costEstimatesDetails.totalCost,
                    perInmatesCost: newApplicationData.costEstimatesDetails.perInmatesCost,
                    actualPerInmatesCost: newApplicationData.costEstimatesDetails.actualPerInmatesCost,
                    grandsInAid: newApplicationData.costEstimatesDetails.grandsInAid,
                    share: newApplicationData.costEstimatesDetails.share,
                    isAgencyAgreeToBearCost: newApplicationData.costEstimatesDetails.isAgencyAgreeToBearCost,
                    isAgencyAgreeToShare: newApplicationData.costEstimatesDetails.isAgencyAgreeToShare,
                    isCostDetermineByPWD: newApplicationData.costEstimatesDetails.isCostDetermineByPWD
                },
                buildingPlan: {
                    isDrawingPrepared: newApplicationData.buildingPlan.isDrawingPrepared,
                    isPlanApproved: newApplicationData.buildingPlan.isPlanApproved,
                    plinthArea: newApplicationData.buildingPlan.plinthArea
                },
                facilities: {
                    other: newApplicationData.facilities.other,
                    otherDetails: newApplicationData.facilities.otherDetails,
                    cleaning: newApplicationData.facilities.cleaning,
                    bathingAndWashing: newApplicationData.facilities.bathingAndWashing,
                    drinkingWater: newApplicationData.facilities.drinkingWater,
                    gameAndSport: newApplicationData.facilities.gameAndSport,
                    appointmentOfCook: newApplicationData.facilities.appointmentOfCook,
                    medicalCare: newApplicationData.facilities.medicalCare,
                    coaching: newApplicationData.facilities.coaching
                },
                facilitiesTiedUp: newApplicationData.facilitiesTiedUp,
                sourceOfFundingForCommonFacilities: newApplicationData.sourceOfFundingForCommonFacilities,
                studentSecurity: {
                    fireSafety: newApplicationData.studentSecurity.fireSafety,
                    boundaryWall: newApplicationData.studentSecurity.boundaryWall,
                    appointmentOfWarder: newApplicationData.studentSecurity.appointmentOfWarder
                },
                populationDetails: {
                    totalDistrictPopulation: Number(newApplicationData.populationDetails.totalDistrictPopulation),
                    scDistrictPopulation: Number(newApplicationData.populationDetails.scDistrictPopulation),
                    percentageOfSC: newApplicationData.populationDetails.percentageOfSC,
                    percentageOfSCFemaleMaleLiteracy: newApplicationData.populationDetails.percentageOfSCFemaleMaleLiteracy,
                    percentageOfSCMaleLiteracy: newApplicationData.populationDetails.percentageOfSCMaleLiteracy
                },
                scHostelDetails: {
                    scHostelInDistrict: Number(newApplicationData.scHostelDetails.scHostelInDistrict),
                    scHostelInBlock: Number(newApplicationData.scHostelDetails.scHostelInBlock),
                    operationalSCHostel: Number(newApplicationData.scHostelDetails.operationalSCHostel)
                },
                isHostelAttachedToInstitute: newApplicationData.isHostelAttachedToInstitute,
                instituteName: newApplicationData.instituteName,
                instituteAddress: newApplicationData.instituteAddress,
                sourceOfFundForMaintenance: newApplicationData.sourceOfFundForMaintenance,
                isFacilityAvailableForDisableStudent: newApplicationData.isFacilityAvailableForDisableStudent,
                facilityDetailsForDisables: newApplicationData.facilityDetailsForDisables,
                noOfStudentStudyingInAttachedInstitute: Number(newApplicationData.noOfStudentStudyingInAttachedInstitute),
                documents: {
                    formSubmittedByState: newApplicationData.documents.formSubmittedByState,
                    justification: newApplicationData.documents.justification,
                    annexure: newApplicationData.documents.annexure,
                    sitePlanSigned: newApplicationData.documents.sitePlanSigned,
                    costEstimateSigned: newApplicationData.documents.costEstimateSigned,
                    buildingPlanSigned: newApplicationData.documents.buildingPlanSigned,
                    isLandOwnershipDocumentAuthenticate: newApplicationData.documents.isLandOwnershipDocumentAuthenticate
                },
                otherRelevantDetails: newApplicationData.otherRelevantDetails,
                isFormUpdated: documentAttached.isFormUpdated,
                isJustificationCertificateUpdated: documentAttached.isJustificationCertificateUpdated,
                isAnnexureCertificateUpdated: documentAttached.isAnnexureCertificateUpdated,
                isCostEstimateCertificateUpdated: documentAttached.isCostEstimateCertificateUpdated,
                isSitePlanSignedCertificateUpdated: documentAttached.isSitePlanSignedCertificateUpdated,
                isBuildingPlanSignedCertificateUpdated: documentAttached.isBuildingPlanSignedCertificateUpdated,
                isLandOwnershipAuthenticateCertificateUpdated: documentAttached.isLandOwnershipAuthenticateCertificateUpdated
            }

            formData.append("applicationData", JSON.stringify(dataToSend))
            dispatch(showLoader());
            const serverResponse = await userCommunication.updateNewApplication(formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                navigation("/user/my-application");
                swal({ text: serverResponse?.data?.message, icon: "success" });
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }

    }

    //Get document from server
    const previewDocument = async (fileUrl) => {
        await userCommunication.getUserDocument(fileUrl);
    }

    //Reject Application 
    const rejectApplication = async () => {
        try {

            //check validation
            if ([undefined, null, ""].includes(comment)) {
                swal({ text: "Comment is required", icon: "warning" });
                return false;
            }

            const dataToSend = {
                applicationId: Number(applicationId),
                id,
                comment
            }

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.rejectApplication(dataToSend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "success" });
                navigation("/back-office/new-applications");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    //Mark Application Incomplete
    const markApplicationIncomplete = async () => {
        try {

            //check validation
            if ([undefined, null, ""].includes(comment)) {
                swal({ text: "Comment is required", icon: "warning" });
                return false;
            }

            const dataToSend = {
                applicationId: Number(applicationId),
                id,
                comment
            }

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.markIncompleteApplication(dataToSend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "success" });
                navigation("/back-office/new-applications");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    //Mark Application Approved
    const markApplicationApproved = async () => {
        try {

            //check validation
            if ([undefined, null, ""].includes(approveCertificate)) {
                swal({ text: "File is required", icon: "error" });
                return false;
            }

            const formData = new FormData();
            formData.append("approveCertificate", approveCertificate);

            const dataToSend = {
                applicationId: Number(applicationId),
                id,
                userId,
                comment
            }

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.markApproveApplication(formData, dataToSend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "success" });
                navigation("/back-office/new-applications");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchUserApplicationById();
    }, []);

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <CommonLoader />
                    :
                    < div className="form_main">
                        <div className="form_heading">
                            <H5 whiteThemeColor darkThemeColor>Format for sending new proposal for seeking Center Assistance under the Scheme (BJRCY) </H5>
                        </div>
                        <div className="application_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #eab7f5" : "1px solid #fff" }}>
                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>1. Name of the Implementing Agency</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="agency" value={newApplicationData.agency} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Agency Name" disabled={isSubmitted} />
                                    {validator.message("agency", newApplicationData.agency, "required", {
                                        messages: {
                                            required: "Agency Name is required"
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6>2. Nature of implementing Agencies :</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <select name="natureOfAgency" onChange={(event) => getApplicationDetail(event)} className="form-select" disabled={isSubmitted} >
                                        <option value="">Select Implementing Agency</option>
                                        <option value="State Government/UT" selected={newApplicationData.natureOfAgency === "State Government/UT" ? true : false}>State Government/UT</option>
                                        <option value="Central University/Institution" selected={newApplicationData.natureOfAgency === "Central University/Institution" ? true : false}>Central University/Institution</option>
                                        <option value="State University/Institution" selected={newApplicationData.natureOfAgency === "State University/Institution" ? true : false}>State University/Institution</option>
                                    </select>
                                    {validator.message("natureOfAgency", newApplicationData.natureOfAgency, "required", {
                                        messages: {
                                            required: "Agency Nature is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>3. Level for which the hostel is proposed to be constructed :</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Pre-Matric</Label>
                                            <input type="radio" name="hostelLevel" value="Pre-Matric" checked={newApplicationData.hostelLevel === "Pre-Matric" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Post-Matric</Label>
                                            <input type="radio" name="hostelLevel" value="Post-Matric" checked={newApplicationData.hostelLevel === "Post-Matric" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("hostelLevel", newApplicationData.hostelLevel, "required", {
                                        messages: {
                                            required: "Hostel propose to be constructed is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>Category :</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Boys</Label>
                                            <input type="radio" name="category" value="Boys" checked={newApplicationData.category === "Boys" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Girls</Label>
                                            <input type="radio" name="category" value="Girls" checked={newApplicationData.category === "Girls" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("category", newApplicationData.category, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>4. Number of inmates for which the hostel is proposed to be constructed :</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="noOfInmates" value={newApplicationData.noOfInmates} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Enter Number of inmates" disabled={isSubmitted} />
                                    {validator.message("noOfInmates", newApplicationData.noOfInmates, "required", {
                                        messages: {
                                            required: "Number of inmates is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>4.1 Composition of Inmates Identified :</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-3">
                                            <input type="number" name="SC" value={newApplicationData.casteWiseInmates.SC} onChange={(event) => setNewApplicationData({ ...newApplicationData, casteWiseInmates: { ...newApplicationData.casteWiseInmates, SC: event.target.value } })} className="form-control" placeholder="SC" disabled={isSubmitted} />
                                            {validator.message("SC", newApplicationData.casteWiseInmates.SC, "required", {
                                                messages: {
                                                    required: "SC Inmates is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-3">
                                            <input type="number" name="ST" value={newApplicationData.casteWiseInmates.ST} onChange={(event) => setNewApplicationData({ ...newApplicationData, casteWiseInmates: { ...newApplicationData.casteWiseInmates, ST: event.target.value } })} className="form-control" placeholder="ST" disabled={isSubmitted} />
                                            {validator.message("ST", newApplicationData.casteWiseInmates.ST, "required", {
                                                messages: {
                                                    required: "ST Inmates is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-3">
                                            <input type="number" name="OBC" value={newApplicationData.casteWiseInmates.OBC} onChange={(event) => setNewApplicationData({ ...newApplicationData, casteWiseInmates: { ...newApplicationData.casteWiseInmates, OBC: event.target.value } })} className="form-control" placeholder="OBC" disabled={isSubmitted} />
                                            {validator.message("OBC", newApplicationData.casteWiseInmates.OBC, "required", {
                                                messages: {
                                                    required: "OBC Inmates is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-3">
                                            <input type="number" name="general" value={newApplicationData.casteWiseInmates.general} onChange={(event) => setNewApplicationData({ ...newApplicationData, casteWiseInmates: { ...newApplicationData.casteWiseInmates, general: event.target.value } })} className="form-control" placeholder="General" disabled={isSubmitted} />
                                            {validator.message("general", newApplicationData.casteWiseInmates.general, "required", {
                                                messages: {
                                                    required: "general Inmates is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>5. Location of proposed hostel (existing hostel in case of expansion) with detailed
                                        address (please annex site map/plan) :</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <textarea type="text" name="hostelLocation" value={newApplicationData.hostelLocation} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Location of proposed hostel" disabled={isSubmitted} ></textarea>
                                    {validator.message("hostelLocation", newApplicationData.hostelLocation, "required", {
                                        messages: {
                                            required: "Hostel Location is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>6. Land details :</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>i) Whether land has been acquired by the implementing agency for construction/
                                        expansion of the proposed hostel? </H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isLandAcquiredByAgency" value="Yes" checked={newApplicationData.landDetails.isLandAcquiredByAgency === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, landDetails: { ...newApplicationData.landDetails, isLandAcquiredByAgency: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isLandAcquiredByAgency" value="No" checked={newApplicationData.landDetails.isLandAcquiredByAgency === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, landDetails: { ...newApplicationData.landDetails, isLandAcquiredByAgency: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isLandAcquiredByAgency", newApplicationData.landDetails.isLandAcquiredByAgency, "required", {
                                        messages: {
                                            required: "Agency Aquire Land is required"
                                        }
                                    })}
                                </div>
                            </div>

                            {newApplicationData.landDetails.isLandAcquiredByAgency === "Yes" &&
                                <div className="row mb-4">
                                    <div className="col-lg-4 col-md-5 col-6">
                                        <H6 whiteThemeColor darkThemeColor>If yes, whether the land is encumbrance free and in physical possession of the
                                            implementing agency?</H6>
                                    </div>
                                    <div className="col-lg-8 col-md-7 col-6">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 col-4">
                                                <Label whiteThemeColor darkThemeColor>Yes</Label>
                                                <input type="radio" name="isLandEncumbranceFree" value="Yes" checked={newApplicationData.landDetails.isLandEncumbranceFree === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, landDetails: { ...newApplicationData.landDetails, isLandEncumbranceFree: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-4">
                                                <Label whiteThemeColor darkThemeColor>No</Label>
                                                <input type="radio" name="isLandEncumbranceFree" value="No" onChange={(event) => setNewApplicationData({ ...newApplicationData, landDetails: { ...newApplicationData.landDetails, isLandEncumbranceFree: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                            </div>
                                        </div>
                                        {validator.message("isLandEncumbranceFree", newApplicationData.landDetails.isLandEncumbranceFree, "required", {
                                            messages: {
                                                required: "Land Encumbrance free is required"
                                            }
                                        })}
                                    </div>
                                </div>
                            }

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>ii) Area of land</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="area" value={newApplicationData.landDetails.area} onChange={(event) => setNewApplicationData({ ...newApplicationData, landDetails: { ...newApplicationData.landDetails, area: event.target.value } })} className="form-control" placeholder="Area in sq ft" disabled={isSubmitted} />
                                    {validator.message("area", newApplicationData.landDetails.area, "required|numeric", {
                                        messages: {
                                            required: "Area of Land is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>7. Time to be taken for completion of construction of the proposed hostel building and
                                        likely date of completion (Period for completion of construction of hostel should not
                                        exceed 2 years from the date of sanction of the project by the Ministry) :</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="number" name="completionPeriod" value={newApplicationData.completionPeriod} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Period for completion (in months)" disabled={isSubmitted} />
                                    {validator.message("completionPeriod", newApplicationData.completionPeriod, "required|numeric", {
                                        messages: {
                                            required: "Period of Completion is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>8. Cost estimates details :</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>A. i) Total estimated cost of the proposed hostel</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="totalCost" value={newApplicationData.costEstimatesDetails.totalCost} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, totalCost: event.target.value } })} className="form-control" placeholder="Total estimated cost" disabled={isSubmitted} />
                                    {validator.message("totalCost", newApplicationData.costEstimatesDetails.totalCost, "required|numeric", {
                                        messages: {
                                            required: "Total Estimated Cost is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor> (ii) Per inmate cost admissible to the implementing agency under the scheme</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="number" name="perInmatesCost" value={newApplicationData.costEstimatesDetails.perInmatesCost} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, perInmatesCost: event.target.value } })} className="form-control" placeholder="Per inmate cost" disabled={isSubmitted} />
                                    {validator.message("perInmatesCost", newApplicationData.costEstimatesDetails.perInmatesCost, "required|numeric", {
                                        messages: {
                                            required: "Per Inmates Cost is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor> (iii) Actual per inmate proposed</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="actualPerInmatesCost" value={newApplicationData.costEstimatesDetails.actualPerInmatesCost} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, actualPerInmatesCost: event.target.value } })} className="form-control" placeholder="Actual per inmate prposed" disabled={isSubmitted} />
                                    {validator.message("actualPerInmatesCost", newApplicationData.costEstimatesDetails.actualPerInmatesCost, "required|numeric", {
                                        messages: {
                                            required: "Actual Per Inmates Cost is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor> iv) Grants-in-aid sought under BJRCY</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="grandsInAid" value={newApplicationData.costEstimatesDetails.grandsInAid} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, grandsInAid: event.target.value } })} className="form-control" placeholder="Grants-in-aid sought" disabled={isSubmitted} />
                                    {validator.message("grandsInAid", newApplicationData.costEstimatesDetails.grandsInAid, "required|numeric", {
                                        messages: {
                                            required: "Grands in Aid is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor> v) Share of State/UT Central/State University/Institute for the proposed hostel</H6>
                                </div>
                                <div className="col-lg-4 col-md-5 col-4">
                                    <input type="text" name="share" value={newApplicationData.costEstimatesDetails.share} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, share: event.target.value } })} className="form-control" placeholder="State/UT Central/State University/Institute" disabled={isSubmitted} />
                                    {validator.message("share", newApplicationData.costEstimatesDetails.share, "required|numeric", {
                                        messages: {
                                            required: "Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>B. Whether cost estimates are determined by PWD norms/PAR?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isCostDetermineByPWD" value="Yes" checked={newApplicationData.costEstimatesDetails.isCostDetermineByPWD === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, isCostDetermineByPWD: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isCostDetermineByPWD" value="No" checked={newApplicationData.costEstimatesDetails.isCostDetermineByPWD === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, isCostDetermineByPWD: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isCostDetermineByPWD", newApplicationData.costEstimatesDetails.isCostDetermineByPWD, "required", {
                                        messages: {
                                            required: "Cost Determined by PWD is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5 col-6">
                                    <H6 whiteThemeColor darkThemeColor>C. Whether the implementing agency agrees to share the construction cost of hostel
                                        building as per provision under the scheme, BJRCY?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7 col-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isAgencyAgreeToShare" value="Yes" checked={newApplicationData.costEstimatesDetails.isAgencyAgreeToShare === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, isAgencyAgreeToShare: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-4">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isAgencyAgreeToShare" value="No" checked={newApplicationData.costEstimatesDetails.isAgencyAgreeToShare === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, isAgencyAgreeToShare: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isAgencyAgreeToShare", newApplicationData.costEstimatesDetails.isAgencyAgreeToShare, "required", {
                                        messages: {
                                            required: "Agency agree to share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>D. Whether the implementing agency agrees to bear the cost over and above the estimated
                                        cost of construction, if any?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isAgencyAgreeToBearCost" value="Yes" checked={newApplicationData.costEstimatesDetails.isAgencyAgreeToBearCost === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, isAgencyAgreeToBearCost: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isAgencyAgreeToBearCost" value="No" checked={newApplicationData.costEstimatesDetails.isAgencyAgreeToBearCost === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, costEstimatesDetails: { ...newApplicationData.costEstimatesDetails, isAgencyAgreeToBearCost: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isAgencyAgreeToBearCost", newApplicationData.costEstimatesDetails.isAgencyAgreeToBearCost, "required", {
                                        messages: {
                                            required: "Agency agree to Bear Cost is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>9. Details of Building Plan :</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Whether building plan/Building Elevation drawing of the proposed hostel has been
                                        prepared?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isDrawingPrepared" value="Yes" checked={newApplicationData.buildingPlan.isDrawingPrepared === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, buildingPlan: { ...newApplicationData.buildingPlan, isDrawingPrepared: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isDrawingPrepared" value="No" checked={newApplicationData.buildingPlan.isDrawingPrepared === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, buildingPlan: { ...newApplicationData.buildingPlan, isDrawingPrepared: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isDrawingPrepared", newApplicationData.buildingPlan.isDrawingPrepared, "required", {
                                        messages: {
                                            required: "Elevation Drawing is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) Whether building plan has been apporoved by PWD?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isPlanApproved" value="Yes" checked={newApplicationData.buildingPlan.isPlanApproved === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, buildingPlan: { ...newApplicationData.buildingPlan, isPlanApproved: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isPlanApproved" value="No" checked={newApplicationData.buildingPlan.isPlanApproved === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, buildingPlan: { ...newApplicationData.buildingPlan, isPlanApproved: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isPlanApproved", newApplicationData.buildingPlan.isPlanApproved, "required", {
                                        messages: {
                                            required: "Plan Approved is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iii) Please indicate per inmate plinth area</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="plinthArea" value={newApplicationData.buildingPlan.plinthArea} onChange={(event) => setNewApplicationData({ ...newApplicationData, buildingPlan: { ...newApplicationData.buildingPlan, plinthArea: event.target.value } })} className="form-control" placeholder="Plinth area" disabled={isSubmitted} />
                                    {validator.message("plinthArea", newApplicationData.buildingPlan.plinthArea, "required|numeric", {
                                        messages: {
                                            required: "Plinth area is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>10. Has the availability of the following common facilities been tied up?( Please tick):</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) In-house coaching</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="coaching" value="Yes" checked={newApplicationData.facilities.coaching === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, coaching: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="coaching" value="No" checked={newApplicationData.facilities.coaching === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, coaching: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("coaching", newApplicationData.facilities.coaching, "required", {
                                        messages: {
                                            required: "In-house Coaching is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) Medical Care</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="medicalCare" value="Yes" checked={newApplicationData.facilities.medicalCare === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, medicalCare: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="medicalCare" value="No" checked={newApplicationData.facilities.medicalCare === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, medicalCare: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("medicalCare", newApplicationData.facilities.medicalCare, "required", {
                                        messages: {
                                            required: "Medical Care is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iii) Appointment of cook, helpers, etc.</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="appointmentOfCook" value="Yes" checked={newApplicationData.facilities.appointmentOfCook === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, appointmentOfCook: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="appointmentOfCook" value="No" checked={newApplicationData.facilities.appointmentOfCook === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, appointmentOfCook: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("appointmentOfCook", newApplicationData.facilities.appointmentOfCook, "required", {
                                        messages: {
                                            required: "Appointment of cook is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iv) Games & Sports and recreational facilities</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="gameAndSport" value="Yes" checked={newApplicationData.facilities.gameAndSport === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, gameAndSport: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="gameAndSport" value="No" checked={newApplicationData.facilities.gameAndSport === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, gameAndSport: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("gameAndSport", newApplicationData.facilities.gameAndSport, "required", {
                                        messages: {
                                            required: "Sport Facilities is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>v) Drinking Water</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="drinkingWater" value="Yes" checked={newApplicationData.facilities.drinkingWater === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, drinkingWater: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="drinkingWater" value="No" checked={newApplicationData.facilities.drinkingWater === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, drinkingWater: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("drinkingWater", newApplicationData.facilities.drinkingWater, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>vi) Bathing & Washing</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="bathingAndWashing" value="Yes" checked={newApplicationData.facilities.bathingAndWashing === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, bathingAndWashing: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="bathingAndWashing" value="No" checked={newApplicationData.facilities.bathingAndWashing === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, bathingAndWashing: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("bathingAndWashing", newApplicationData.facilities.bathingAndWashing, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>vii) Sanitation & Cleaning</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="cleaning" value="Yes" checked={newApplicationData.facilities.cleaning === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, cleaning: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="cleaning" value="No" checked={newApplicationData.facilities.cleaning === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, cleaning: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("cleaning", newApplicationData.facilities.cleaning, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>viii) Others</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="other" value="Yes" checked={newApplicationData.facilities.other === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, other: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="other" value="No" checked={newApplicationData.facilities.other === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, other: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        {newApplicationData.facilities.other === "Yes" &&
                                            <div className="col-lg-6 col-md-6">
                                                <input type="text" name="otherDetails" value={newApplicationData.facilities.otherDetails} onChange={(event) => setNewApplicationData({ ...newApplicationData, facilities: { ...newApplicationData.facilities, otherDetails: event.target.value } })} className="form-control" placeholder="Others" disabled={isSubmitted} />
                                                {validator.message("otherDetails", newApplicationData.facilities.otherDetails, "required", {
                                                    messages: {
                                                        required: "Other Detail is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("other", newApplicationData.facilities.other, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>10. 1 How have availability of these common facilities been tied up?</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="facilitiesTiedUp" value={newApplicationData.facilitiesTiedUp} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Availability of common facilities" disabled={isSubmitted} />
                                    {validator.message("facilitiesTiedUp", newApplicationData.facilitiesTiedUp, "required", {
                                        messages: {
                                            required: "Facilities Tied Up is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>10. 2 Source of funding for providing above common facilities</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="sourceOfFundingForCommonFacilities" value={newApplicationData.sourceOfFundingForCommonFacilities} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Source of funding" disabled={isSubmitted} />
                                    {validator.message("sourceOfFundingForCommonFacilities", newApplicationData.sourceOfFundingForCommonFacilities, "required", {
                                        messages: {
                                            required: "Source of Funding is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-12">
                                    <H6 whiteThemeColor darkThemeColor>11). Have following provisions been planned for safety/ security of students:</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Fire Safety</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="fireSafety" value="Yes" checked={newApplicationData.studentSecurity.fireSafety === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, studentSecurity: { ...newApplicationData.studentSecurity, fireSafety: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="fireSafety" value="No" checked={newApplicationData.studentSecurity.fireSafety === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, studentSecurity: { ...newApplicationData.studentSecurity, fireSafety: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("fireSafety", newApplicationData.studentSecurity.fireSafety, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) Construction of boundary wall</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="boundaryWall" value="Yes" checked={newApplicationData.studentSecurity.boundaryWall === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, studentSecurity: { ...newApplicationData.studentSecurity, boundaryWall: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="boundaryWall" value="No" checked={newApplicationData.studentSecurity.boundaryWall === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, studentSecurity: { ...newApplicationData.studentSecurity, boundaryWall: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("boundaryWall", newApplicationData.studentSecurity.boundaryWall, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iii) Appointment of Warden & Chowkidar in the hostel </H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="appointmentOfWarder" value="Yes" checked={newApplicationData.studentSecurity.appointmentOfWarder === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, studentSecurity: { ...newApplicationData.studentSecurity, appointmentOfWarder: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="appointmentOfWarder" value="No" checked={newApplicationData.studentSecurity.appointmentOfWarder === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, studentSecurity: { ...newApplicationData.studentSecurity, appointmentOfWarder: event.target.value } })} className="form-check-input ms-5" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("appointmentOfWarder", newApplicationData.studentSecurity.appointmentOfWarder, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>



                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-12">
                                    <H6 whiteThemeColor darkThemeColor>12. Details of Population :</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Total population of the district where hostel is proposed to be constructed</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="totalDistrictPopulation" value={newApplicationData.populationDetails.totalDistrictPopulation} onChange={(event) => setNewApplicationData({ ...newApplicationData, populationDetails: { ...newApplicationData.populationDetails, totalDistrictPopulation: event.target.value } })} className="form-control" placeholder="Total Populatin" disabled={isSubmitted} />
                                    {validator.message("totalDistrictPopulation", newApplicationData.populationDetails.totalDistrictPopulation, "required|numeric", {
                                        messages: {
                                            required: "Total Population is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) SC population in the district</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="scDistrictPopulation" value={newApplicationData.populationDetails.scDistrictPopulation} onChange={(event) => setNewApplicationData({ ...newApplicationData, populationDetails: { ...newApplicationData.populationDetails, scDistrictPopulation: event.target.value, percentageOfSC: (event.target.value / newApplicationData.populationDetails.totalDistrictPopulation * 100).toFixed(2) } })} className="form-control" placeholder="SC Population" disabled={isSubmitted} />
                                    {validator.message("scDistrictPopulation", newApplicationData.populationDetails.scDistrictPopulation, "required", {
                                        messages: {
                                            required: "Sc District Population is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iii) Percentage(%) SC population in the district</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="percentageOfSC" value={newApplicationData.populationDetails.percentageOfSC} className="form-control" placeholder="% of SC Population" disabled={isSubmitted} />
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iv) Percentage(%) SC literacy in the district</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4">
                                            <input type="text" name="percentageOfSCMaleLiteracy" value={newApplicationData.populationDetails.percentageOfSCMaleLiteracy} onChange={(event) => setNewApplicationData({ ...newApplicationData, populationDetails: { ...newApplicationData.populationDetails, percentageOfSCMaleLiteracy: event.target.value } })} className="form-control" placeholder="Male" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <input type="text" name="percentageOfSCFemaleMaleLiteracy" value={newApplicationData.populationDetails.percentageOfSCFemaleMaleLiteracy} onChange={(event) => setNewApplicationData({ ...newApplicationData, populationDetails: { ...newApplicationData.populationDetails, percentageOfSCFemaleMaleLiteracy: event.target.value } })} className="form-control" placeholder="Female" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("percentageOfSCMaleLiteracy", newApplicationData.populationDetails.percentageOfSCMaleLiteracy, "required|numeric", {
                                        messages: {
                                            required: "Percentage of sc Male in district is required"
                                        }
                                    })}
                                    {validator.message("percentageOfSCFemaleMaleLiteracy", newApplicationData.populationDetails.percentageOfSCFemaleMaleLiteracy, "required|numeric", {
                                        messages: {
                                            required: "Percentage of sc Female in district is required"
                                        }
                                    })}
                                </div>

                            </div>

                            {/* <div className="row mb-4">
                    <div className="col-lg-8">
                        <input type="text" name="12" className="form-control" placeholder="Please indicate" />
                    </div>
                </div> */}

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-4">
                                    <H6 whiteThemeColor darkThemeColor>13. Details of SC hostels :</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Number of SC hostels available in the district</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="scHostelInDistrict" value={newApplicationData.scHostelDetails.scHostelInDistrict} onChange={(event) => setNewApplicationData({ ...newApplicationData, scHostelDetails: { ...newApplicationData.scHostelDetails, scHostelInDistrict: event.target.value } })} className="form-control" placeholder="Number of SC Hostels in District" disabled={isSubmitted} />
                                    {validator.message("scHostelInDistrict", newApplicationData.scHostelDetails.scHostelInDistrict, "required|numeric", {
                                        messages: {
                                            required: "Number of SC Hostel in district is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) Number of SC hostels already available in the Block of the proposed hostel</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="scHostelInBlock" value={newApplicationData.scHostelDetails.scHostelInBlock} onChange={(event) => setNewApplicationData({ ...newApplicationData, scHostelDetails: { ...newApplicationData.scHostelDetails, scHostelInBlock: event.target.value } })} className="form-control" placeholder="Number of SC Hostels in the Block" disabled={isSubmitted} />
                                    {validator.message("scHostelInBlock", newApplicationData.scHostelDetails.scHostelInBlock, "required|numeric", {
                                        messages: {
                                            required: "Number of SC Hostels available in block is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iii) Of these, number of SC hostels operational</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="operationalSCHostel" value={newApplicationData.scHostelDetails.operationalSCHostel} onChange={(event) => setNewApplicationData({ ...newApplicationData, scHostelDetails: { ...newApplicationData.scHostelDetails, operationalSCHostel: event.target.value } })} className="form-control" placeholder="Number of SC Hostels operational" disabled={isSubmitted} />
                                    {validator.message("operationalSCHostel", newApplicationData.scHostelDetails.operationalSCHostel, "required|numeric", {
                                        messages: {
                                            required: "Operational sc Hostels is required"
                                        }
                                    })}
                                </div>
                            </div>

                            {/* <div className="row mb-4">
                    <div className="col-lg-8">
                        <input type="text" name="13" className="form-control" placeholder="Please indicate" />
                    </div>
                </div> */}


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>14. Whether the proposed hostel is attached to an existing educational institution? If
                                        so, give name and full address of the institution?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isHostelAttachedToInstitute" value="Yes" checked={newApplicationData.isHostelAttachedToInstitute === "Yes" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isHostelAttachedToInstitute" value="No" checked={newApplicationData.isHostelAttachedToInstitute === "No" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isHostelAttachedToInstitute", newApplicationData.isHostelAttachedToInstitute, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>
                            {newApplicationData.isHostelAttachedToInstitute === "Yes" &&
                                <>
                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-5">
                                            <H6 whiteThemeColor darkThemeColor>i) Name of the institution</H6>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <input type="text" name="instituteName" value={newApplicationData.instituteName} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Institution Name" disabled={isSubmitted} />
                                            {validator.message("instituteName", newApplicationData.instituteName, "required", {
                                                messages: {
                                                    required: "Name of Institution is required"
                                                }
                                            })}
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-5">
                                            <H6 whiteThemeColor darkThemeColor>ii) Full address of the institution</H6>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <textarea name="instituteAddress" value={newApplicationData.instituteAddress} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Address" disabled={isSubmitted} ></textarea>
                                            {validator.message("instituteAddress", newApplicationData.instituteAddress, "required", {
                                                messages: {
                                                    required: "Address of Institution is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                            {/* <div className="row mb-4">
                    <div className="col-lg-4 col-md-4">
                        <H6 whiteThemeColor darkThemeColor>If no, give name and distance of educational institutions, to which the proposed
                            hostel will cater to. (list of such institutions may be attached)</H6>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <input type="text" name="14" className="form-control" placeholder="list of such institutions may be attached" />
                    </div>
                </div> */}


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>15. Source(s) of funds for running and maintenance of the proposed hostel, including
                                        expenditure on provision of warden and guard in girls' hostel</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="sourceOfFundForMaintenance" value={newApplicationData.sourceOfFundForMaintenance} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Source of fund" disabled={isSubmitted} />
                                    {validator.message("sourceOfFundForMaintenance", newApplicationData.sourceOfFundForMaintenance, "required", {
                                        messages: {
                                            required: "Source of Fund for Maintenance is required"
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>16. Whether facilities for student with disabilities like barrier free rooms/blocks,
                                        ramps, etc. have been incorporated in the design of the hostel?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isFacilityAvailableForDisableStudent" value="Yes" checked={newApplicationData.isFacilityAvailableForDisableStudent === "Yes" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isFacilityAvailableForDisableStudent" value="No" checked={newApplicationData.isFacilityAvailableForDisableStudent === "No" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {newApplicationData.isFacilityAvailableForDisableStudent === "Yes" &&
                                            <div className="col-lg-4 col-md-6">
                                                <textarea name="facilityDetailsForDisables" value={newApplicationData.facilityDetailsForDisables} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="please specify details" disabled={isSubmitted}></textarea>
                                                {validator.message("facilityDetailsForDisables", newApplicationData.facilityDetailsForDisables, "required", {
                                                    messages: {
                                                        required: "Detail is required"
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {validator.message("isFacilityAvailableForDisableStudent", newApplicationData.isFacilityAvailableForDisableStudent, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-4">
                                    <H6 whiteThemeColor darkThemeColor>17. Students' availability</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Number of SC students studing in the educational institution to which the hostel
                                        will be attached</H6>
                                    <H6 whiteThemeColor darkThemeColor>or</H6>
                                    <H6 whiteThemeColor darkThemeColor>Number of SC students studing in the feeder educational institution, if case stand
                                        alone hostel proposed.</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="noOfStudentStudyingInAttachedInstitute" value={newApplicationData.noOfStudentStudyingInAttachedInstitute} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="No of SC students" disabled={isSubmitted} />
                                    {validator.message("noOfStudentStudyingInAttachedInstitute", newApplicationData.noOfStudentStudyingInAttachedInstitute, "required|numeric", {
                                        messages: {
                                            required: "Number of SC Student is required"
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-lg-12">
                                    <H6 whiteThemeColor darkThemeColor>18. Whether following documents/information have been attached with the proposal(Please
                                        tick YES or NO)</H6>
                                </div>

                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Whether submitted through State Govt./Administrative Ministry/Department (in case
                                        of University or Institution)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="formSubmittedByState" value="Yes" checked={newApplicationData.documents.formSubmittedByState === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, formSubmittedByState: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="formSubmittedByState" value="No" checked={newApplicationData.documents.formSubmittedByState === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, formSubmittedByState: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {newApplicationData.documents.formSubmittedByState === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-6">
                                                    <input type="file" name="form" onChange={(event) => { setDocuments({ ...documents, documents: { ...documents.documents, form: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isFormUpdated: true }); }} className="form-control" disabled={isSubmitted} />
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.documents.form)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("formSubmittedByState", newApplicationData.documents.formSubmittedByState, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) Justification for the proposed hostel</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="justification" value="Yes" checked={newApplicationData.documents.justification === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, justification: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="justification" value="No" checked={newApplicationData.documents.justification === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, justification: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {newApplicationData.documents.justification === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-6">
                                                    <input type="file" name="justificationCertificate" onChange={(event) => { setDocuments({ ...documents, documents: { ...documents.documents, justificationCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isJustificationCertificateUpdated: true }); }} className="form-control" disabled={isSubmitted} />
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.documents.justificationCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("justification", newApplicationData.documents.justification, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iii) Annexure-I(duly filled in)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="annexure" value="Yes" checked={newApplicationData.documents.annexure === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, annexure: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="annexure" value="No" checked={newApplicationData.documents.annexure === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, annexure: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {newApplicationData.documents.annexure === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-6">
                                                    <input type="file" name="annexureCertificate" onChange={(event) => { setDocuments({ ...documents, documents: { ...documents.documents, annexureCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isAnnexureCertificateUpdated: true }); }} className="form-control" disabled={isSubmitted} />
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.documents.annexureCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("annexure", newApplicationData.documents.annexure, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iv) Cost Estimate signed PWD/CPWD/Govt. Construction agency/construction department
                                    </H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="costEstimateSigned" value="Yes" checked={newApplicationData.documents.costEstimateSigned === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, costEstimateSigned: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="costEstimateSigned" value="No" checked={newApplicationData.documents.costEstimateSigned === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, costEstimateSigned: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {newApplicationData.documents.costEstimateSigned === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-6">
                                                    <input type="file" name="costEstimateCertificate" onChange={(event) => { setDocuments({ ...documents, documents: { ...documents.documents, costEstimateCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isCostEstimateCertificateUpdated: true }); }} className="form-control" disabled={isSubmitted} />
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.documents.costEstimateCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("costEstimateSigned", newApplicationData.documents.costEstimateSigned, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>v) Site plan signed by PWD/CPWD/Govt. Construction agency/construction department
                                    </H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="sitePlanSigned" value="Yes" checked={newApplicationData.documents.sitePlanSigned === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, sitePlanSigned: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="sitePlanSigned" value="No" checked={newApplicationData.documents.sitePlanSigned === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, sitePlanSigned: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {newApplicationData.documents.sitePlanSigned === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-6">
                                                    <input type="file" name="sitePlanSignedCertificate" onChange={(event) => { setDocuments({ ...documents, documents: { ...documents.documents, sitePlanSignedCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isSitePlanSignedCertificateUpdated: true }); }} className="form-control" disabled={isSubmitted} />
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.documents.sitePlanSignedCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("sitePlanSigned", newApplicationData.documents.sitePlanSigned, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>vi) Building plan signed by PWD/CPWD/Govt. Construction agency/construction
                                        department</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="buildingPlanSigned" value="Yes" checked={newApplicationData.documents.buildingPlanSigned === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, buildingPlanSigned: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="buildingPlanSigned" value="No" checked={newApplicationData.documents.buildingPlanSigned === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, buildingPlanSigned: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {newApplicationData.documents.buildingPlanSigned === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-6">
                                                    <input type="file" name="buildingPlanSignedCertificate" onChange={(event) => { setDocuments({ ...documents, documents: { ...documents.documents, buildingPlanSignedCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isBuildingPlanSignedCertificateUpdated: true }); }} className="form-control" disabled={isSubmitted} />
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.documents.buildingPlanSignedCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("buildingPlanSigned", newApplicationData.documents.buildingPlanSigned, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>vii) Land ownership document authenticated by State Govt. </H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isLandOwnershipDocumentAuthenticate" value="Yes" checked={newApplicationData.documents.isLandOwnershipDocumentAuthenticate === "Yes" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, isLandOwnershipDocumentAuthenticate: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isLandOwnershipDocumentAuthenticate" value="No" checked={newApplicationData.documents.isLandOwnershipDocumentAuthenticate === "No" ? true : false} onChange={(event) => setNewApplicationData({ ...newApplicationData, documents: { ...newApplicationData.documents, isLandOwnershipDocumentAuthenticate: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {newApplicationData.documents.isLandOwnershipDocumentAuthenticate === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-6">
                                                    <input type="file" name="landOwnershipAuthenticateCertificate" onChange={(event) => { setDocuments({ ...documents, documents: { ...documents.documents, landOwnershipAuthenticateCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isLandOwnershipAuthenticateCertificateUpdated: true }); }} className="form-control" disabled={isSubmitted} />
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.documents.landOwnershipAuthenticateCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isLandOwnershipDocumentAuthenticate", newApplicationData.documents.isLandOwnershipDocumentAuthenticate, "required", {
                                        messages: {
                                            required: "Choose at least One Option"
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>19. Other relevants details(if any)</H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <textarea name="otherRelevantDetails" value={newApplicationData.otherRelevantDetails} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="other details..." disabled={isSubmitted}></textarea>
                                </div>
                            </div>


                            <div className="row mt-2 mb-lg-4 mb-md-5">
                                <div className="col-12 button_group">
                                    {(isSubmitted === false && [undefined, null, ""].includes(backOfficeUser?.userType)) &&
                                        <>
                                            <button onClick={() => updateApplication()}>Submit</button>
                                        </>
                                    }
                                    {[undefined, null, ""].includes(backOfficeUser?.userType) &&
                                        <button className="ms-3" onClick={() => navigation("/user/my-application")}>Cancel</button>
                                    }
                                </div>
                            </div>

                            {![undefined, null, ""].includes(backOfficeUser.userType) &&
                                <div className="row approve_certificates mb-4">
                                    {![undefined, null, ""].includes(newApplicationData.districtApproveCertificate) &&
                                        <div className="col-4 text-center">
                                            <H6>District Approve Certificate</H6>
                                            <div className="col-lg-2 col-md-3 mt-2 preview_button">
                                                <button onClick={() => previewDocument(newApplicationData.districtApproveCertificate)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                    {![undefined, null, ""].includes(newApplicationData.divisionApproveCertificate) &&
                                        <div className="col-4 text-center">
                                            <H6>Division Approve Certificate</H6>
                                            <div className="col-lg-2 col-md-3 mt-2 preview_button">
                                                <button onClick={() => previewDocument(newApplicationData.divisionApproveCertificate)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                    {![undefined, null, ""].includes(newApplicationData.stateApproveCertificate) &&
                                        <div className="col-4 text-center">
                                            <H6>State Approve Certificate</H6>
                                            <div className="col-lg-2 col-md-3 mt-2 preview_button">
                                                <button onClick={() => previewDocument(newApplicationData.stateApproveCertificate)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <div className="row mt-2 mb-lg-4 mb-md-5">
                                <div className="col-12 button_group">
                                    {((stateUrl === true) || (finalSelectedUrl === true) || (districtSelected === true) || (divisionSelected === true) || (["Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["Division"].includes(backOfficeUser.userType)) || (["Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["State"].includes(backOfficeUser.userType))) &&
                                        <button className="ms-3" onClick={() => navigation(-1)}>Go back</button>
                                    }

                                </div>
                            </div>

                        </div>
                        {(stateUrl === true) || (finalSelectedUrl === true) || (districtSelected === true) || (divisionSelected === true) || (["Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["Division"].includes(backOfficeUser.userType)) || (["Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["State"].includes(backOfficeUser.userType)) ?
                            <div>
                            </div>
                            :
                            <div className="back_office_user_action">
                                {((![undefined, null, ""].includes(backOfficeUser?.userType)) && (["NEW", "Approved_By_District", "Approved_By_Division", "Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus))) &&
                                    <>
                                        <div className="row mb-4">
                                            <div className="col-lg-4 col-md-5">
                                                <H6 whiteThemeColor darkThemeColor>Add Comment</H6>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <textarea name="comment" onChange={(event) => setComment(event.target.value)} className="form-control" placeholder="comment..."></textarea>
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col-lg-4 col-md-5">
                                                <H6 whiteThemeColor darkThemeColor>Upload Document</H6>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <input type="file" name="approveCertificate" onChange={(event) => setApproveCertificate(event.target.files[0])} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="row mt-2 mb-4">
                                            <div className="col-12 button_group">
                                                <button onClick={() => rejectApplication()}>Reject</button>
                                                <button className="ms-3 me-3" onClick={() => markApplicationIncomplete()}>Incomplete</button>
                                                <button onClick={() => markApplicationApproved()}>Approve</button>
                                                <NavLink to={`/print-new-application?id=${id}`} target="_blank">
                                                    <button className="ms-3">Print</button>
                                                </NavLink>
                                                <button className="ms-3" onClick={() => navigation(-1)}>Cancel</button>
                                            </div>
                                        </div>

                                    </>
                                }

                            </div>
                        }
                    </div>
            }
        </>
    )
}

export default PreviewNewApplication