import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import H5 from "../../text-components/H5";
import Multiselect from 'multiselect-react-dropdown';
import { adminCommunication } from '../../communication/adminCommunication';
import useValidator from '../../form-validation/useValidatior';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import swal from 'sweetalert';

const UpdateDepartment = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validator, showValidationMessage] = useValidator();

  //getting designation id from query params
  const params = new URLSearchParams(useLocation().search);
  const departmentId = params.get("departmentId");

  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [department, setDepartment] = useState({});
  const [designations, setDesignations] = useState([]);

  //Fetch departments on initial load
  const fetchDepartments = async () => {
    try {
      const responseFromServer = await adminCommunication.getDepartmentById(departmentId);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setDepartment(responseFromServer?.data?.department);
      } else if (responseFromServer.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: responseFromServer?.data?.message, icon: "error" });
      } else {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }


  //Fetch designation on initial load
  const fetchDesignations = async () => {
    try {
      const getDesignations = await adminCommunication.getAllDesignations();
      if (getDesignations?.data.status === "SUCCESS") {
        setDesignations(getDesignations?.data?.designation);
      } else if (getDesignations.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: getDesignations?.data?.message, icon: "error" });
      } else {
        swal({ text: getDesignations?.data?.message, icon: "error" });
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
    }
  }
  useEffect(() => {
    fetchDepartments();
    fetchDesignations();
  }, []);

  //Update department
  const updateDepartment = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const dataToSend = {
        departmentId,
        department: department.department,
        designation: selectedDesignations
      }

      dispatch(hideLoader());
      const serverResponse = await adminCommunication.updateDepartment(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        navigate("/admin/dashboard/department-list");
        swal({ text: serverResponse?.data?.message, icon: "success" });
      } else if (serverResponse.data.status === "JWT_INVALID") {
        navigate("/admin/login");
        swal({ text: serverResponse?.data?.message, icon: "error" });
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error?.message, icon: "error" });
    }
  }

  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="form_section">
            <div className="form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Update Department</H5>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 col-md-12">
                    <input type="text" name="department" value={department?.department} onChange={(event) => setDepartment({ ...department, "department": event.target.value })} className="form-control" placeholder="Enter Department" />
                    {validator.message("department", department?.department, "required|alpha_space", {
                      messages: {
                        required: "Department is required"
                      }
                    })}
                  </div>
                </div>
                <Multiselect
                  options={designations}
                  displayValue="designation"
                  name="designation"
                  showCheckbox={true}
                  onSelect={(event) => setSelectedDesignations(event)}
                  onRemove={(event) => setSelectedDesignations(event)}
                  selectedValues={department?.designation}
                  placeholder="Select Designation"
                  hidePlaceholder={true}
                  showArrow
                  closeIcon="cancel"
                  // customArrow={<><i className="fa fa-angle-down"></i></>}
                  keepSearchTerm={true}
                />
                {validator.message("designation", selectedDesignations, "required", {
                  messages: {
                    required: "Designation is required"
                  }
                })}
                <div className="button_group mt-3">
                  <button onClick={() => updateDepartment()}>Update</button>
                  <NavLink to="/admin/dashboard/department-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default UpdateDepartment