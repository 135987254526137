import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import H5 from '../text-components/H5';
import { darkTheme, whiteTheme } from '../redux-store/darkWhiteThemeToggle';
import { increment, decrement, defaultFont } from '../redux-store/fontSizeMultiplier';
import { NavLink, useLocation } from 'react-router-dom';

const TopHeaderPublic = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    let load = 0
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier.fontSize);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);


    const googleTranslateElementInit = (() => {
        new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: "en,hi,mr" }, 'google_translate_element');
    });

    useEffect(() => {
        if (load === 0) {
            load += 1;
            const script = document.createElement("script");
            script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            script.async = true;
            document.body.appendChild(script);
            window.googleTranslateElementInit = googleTranslateElementInit;
        }
    }, []);

    return (
        <div className="row m-0">
            <div className="col-lg-12 col-md-12 col-12 p-0 text-md-right righthd">
                <div className="top_header" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c" }}>
                    <span style={{ color: `${darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff"}` }} onClick={() => dispatch(decrement())}>A-</span>
                    <span style={{ color: `${darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff"}` }} whiteThemeColor darkThemeColor onClick={() => dispatch(defaultFont())}>A </span>
                    <span style={{ color: `${darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff"}` }} whiteThemeColor darkThemeColor onClick={() => dispatch(increment())}>A+</span>
                    <button className="whiteTheme" onClick={() => dispatch(whiteTheme())}>A</button>
                    <button className="darkTheme" onClick={() => dispatch(darkTheme())}>A</button>
                    <li>
                        <div id="google_translate_element" className='google'></div>
                    </li>
                    <div className="">
                        {location.pathname === "/login" ?
                            <NavLink to="/public">
                                <button className='submit_btn' >
                                    Back </button>
                            </NavLink>
                            :
                            <NavLink to="/login">
                                <button className='submit_btn' >
                                    Sign In</button>
                            </NavLink>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeaderPublic