import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import Label from '../../text-components/Label';
import useValidator from '../../form-validation/useValidatior';
import { userCommunication } from '../../communication/userCommunication';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import swal from 'sweetalert';

const PreviewSecondInstallment = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const backOfficeUser = useSelector((state) => state.backOfficeUserDetails)
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const componentRef = useRef();
    const [validator, showValidationMessage] = useValidator();
    const [isSubmitted, setIsSubmitted] = useState(true);
    const [backOfficeStatus, setBackOfficeStatus] = useState("");
    const [year, setYear] = useState([]);
    const [approveCertificates, setApproveCertificates] = useState([]);
    const [secondInstallMentData, setSecondInstallmentData] = useState(
        {
            sanctionAmount: "",
            stateShare: "",
            universityShare: "",
            centralAssistance: {
                sanctionNo: "",
                sanctionDate: "",
                amount: ""
            },
            amountUtilized: {
                centralShare: "",
                universityShare: ""
            },
            unspentBalance: {
                centralShare: "",
                universityShare: ""
            },
            isFundMadeForSalary: "",
            year: "",
            isConstructionCompleted: "",
            constructionDetails: {
                dateOfCompletion: "",
                constructionStatus: "",
                expectedDateOfCompletion: "",
                reasonOfNonCompletion: ""
            },
            isUtilizationCertificateAttached: "",
            document: {
                isAuditReportAttached: "",
                isBalanceSheetAttached: "",
                isIncomeExpenditureAttached: "",
                isPaymentReceiptAttached: "",
                isBankStatementAttached: "",
                isInterestDetailsAttached: "",
                isStatusOfUtilizationAttached: "",
                isSiteInspectionReportAttached: "",
                isProjectPhotoAttached: ""
            }
        }
    );

    const [documents, setDocuments] = useState(
        {
            hostelSanctionCertificate: "",
            document: {
                utilizationCertificate: "",
                auditReport: "",
                balanceSheet: "",
                incomeExpenditureCertificate: "",
                paymentReceipt: "",
                bankStatement: "",
                interestCertificate: "",
                utilizationStatusCertificate: "",
                siteInspectionReport: "",
                projectPhoto: ""
            }
        }
    );

    const [documentAttached, setDocumentAttached] = useState(
        {
            isHostelSanctionCertificateUpdated: false,
            isUtilizationCertificateUpdated: false,
            isAuditReportUpdated: false,
            isBalanceSheetUpdated: false,
            isIncomeExpenditureCertificateUpdated: false,
            isPaymentReceiptUpdated: false,
            isBankStatementUpdated: false,
            isInterestCertificateUpdated: false,
            isUtilizationStatusCertificateUpdated: false,
            isSiteInspectionReportUpdated: false,
            isProjectPhotoUpdated: false
        }
    )

    const [comment, setComment] = useState("");
    const [approveCertificate, setApproveCertificate] = useState();

    //get Application id from params
    const params = new URLSearchParams(useLocation().search);
    const id = params.get("id");
    const applicationId = params.get("applicationId");
    const [userId, setUserId] = useState("");

    //get current url
    const fullUrl = window.location.href;
    const splitUrl = fullUrl.split("/")
    const stateUrl = splitUrl.includes("state-selected-applications")
    const finalSelectedUrl = splitUrl.includes("final-selected-applications")
    const districtSelected = splitUrl.includes("district-selected-applications");
    const divisionSelected = splitUrl.includes("division-selected-applications");


    //get all inputs value
    const getApplicationDetail = (event) => {
        setSecondInstallmentData({ ...secondInstallMentData, [event.target.name]: event.target.value });
    }

    //get all files value
    const getDocuments = (event) => {
        setDocuments({ ...documents, [event.target.name]: event.target.files[0] });
    }


    // Calculate Year
    const calculateYear = () => {
        let date = new Date();
        let years = [];
        let fullYear = date.getFullYear()
        for (let i = 2022; i <= fullYear; i++) {
            years.push(i);
        }
        setYear(years)
    }

    //Get user Application Detail by id
    const fetchUserApplicationById = async () => {
        try {
            dispatch(showLoader());
            const responseFromServer = await userCommunication.getUserApplicationById(id);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                if (["Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(responseFromServer?.data?.applications?.applicationStatus) && [undefined, null, ""].includes(backOfficeUser.userType)) {
                    setIsSubmitted(false);
                } else {
                    setIsSubmitted(true);
                }

                setApproveCertificates(responseFromServer?.data?.applications?.secondInstallMentForm);
                setUserId(responseFromServer?.data?.applications?.userId);
                setBackOfficeStatus(responseFromServer?.data?.applications?.backOfficeStatus);
                setSecondInstallmentData(responseFromServer?.data?.applications?.secondInstallMentForm);
                setDocuments(responseFromServer?.data?.applications?.secondInstallMentForm);
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }
    //show modal on submit
    const updateApplication = async () => {
        try {

            //check validation
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const formData = new FormData();
            if (documentAttached.isUtilizationCertificateUpdated === true) {
                formData.append("utilizationCertificate", documents.document.utilizationCertificate);
            }
            if (documentAttached.isAuditReportUpdated === true) {
                formData.append("auditReport", documents.document.auditReport);
            }
            if (documentAttached.isBalanceSheetUpdated === true) {
                formData.append("balanceSheet", documents.document.balanceSheet);
            }
            if (documentAttached.isIncomeExpenditureCertificateUpdated === true) {
                formData.append("incomeExpenditureCertificate", documents.document.incomeExpenditureCertificate);
            }
            if (documentAttached.isPaymentReceiptUpdated === true) {
                formData.append("paymentReceipt", documents.document.paymentReceipt);
            }
            if (documentAttached.isBankStatementUpdated === true) {
                formData.append("bankStatement", documents.document.bankStatement);
            }
            if (documentAttached.isInterestCertificateUpdated === true) {
                formData.append("interestCertificate", documents.document.interestCertificate);
            }
            if (documentAttached.isUtilizationStatusCertificateUpdated === true) {
                formData.append("utilizationStatusCertificate", documents.document.utilizationStatusCertificate);
            }
            if (documentAttached.isSiteInspectionReportUpdated === true) {
                formData.append("siteInspectionReport", documents.document.siteInspectionReport);
            }
            if (documentAttached.isProjectPhotoUpdated === true) {
                formData.append("projectPhoto", documents.document.projectPhoto);
            }
            if (documentAttached.isHostelSanctionCertificateUpdated === true) {
                formData.append("hostelSanctionCertificate", documents.hostelSanctionCertificate);
            }


            const dataToSend = {
                id,
                sanctionAmount: Number(secondInstallMentData.sanctionAmount),
                stateShare: Number(secondInstallMentData.stateShare),
                universityShare: Number(secondInstallMentData.universityShare),
                centralAssistance: {
                    sanctionNo: secondInstallMentData.centralAssistance.sanctionNo,
                    sanctionDate: secondInstallMentData.centralAssistance.sanctionDate,
                    amount: Number(secondInstallMentData.centralAssistance.amount)
                },
                amountUtilized: {
                    centralShare: Number(secondInstallMentData.amountUtilized.centralShare),
                    universityShare: Number(secondInstallMentData.amountUtilized.universityShare)
                },
                unspentBalance: {
                    centralShare: Number(secondInstallMentData.unspentBalance.centralShare),
                    universityShare: Number(secondInstallMentData.unspentBalance.universityShare)
                },
                isFundMadeForSalary: secondInstallMentData.isFundMadeForSalary,
                year: secondInstallMentData.year,
                isConstructionCompleted: secondInstallMentData.isConstructionCompleted,
                constructionDetails: {
                    dateOfCompletion: secondInstallMentData.constructionDetails.dateOfCompletion,
                    constructionStatus: secondInstallMentData.constructionDetails.constructionStatus,
                    expectedDateOfCompletion: secondInstallMentData.constructionDetails.expectedDateOfCompletion,
                    reasonOfNonCompletion: secondInstallMentData.constructionDetails.reasonOfNonCompletion
                },
                document: {
                    isUtilizationCertificateAttached: secondInstallMentData.document.isUtilizationCertificateAttached,
                    isAuditReportAttached: secondInstallMentData.document.isAuditReportAttached,
                    isBalanceSheetAttached: secondInstallMentData.document.isBalanceSheetAttached,
                    isIncomeExpenditureAttached: secondInstallMentData.document.isIncomeExpenditureAttached,
                    isPaymentReceiptAttached: secondInstallMentData.document.isPaymentReceiptAttached,
                    isBankStatementAttached: secondInstallMentData.document.isBankStatementAttached,
                    isInterestDetailsAttached: secondInstallMentData.document.isInterestDetailsAttached,
                    isStatusOfUtilizationAttached: secondInstallMentData.document.isStatusOfUtilizationAttached,
                    isSiteInspectionReportAttached: secondInstallMentData.document.isSiteInspectionReportAttached,
                    isProjectPhotoAttached: secondInstallMentData.document.isProjectPhotoAttached
                },
                isHostelSanctionCertificateUpdated: documentAttached.isHostelSanctionCertificateUpdated,
                isUtilizationCertificateUpdated: documentAttached.isUtilizationCertificateUpdated,
                isAuditReportUpdated: documentAttached.isAuditReportUpdated,
                isBalanceSheetUpdated: documentAttached.isBalanceSheetUpdated,
                isIncomeExpenditureCertificateUpdated: documentAttached.isIncomeExpenditureCertificateUpdated,
                isPaymentReceiptUpdated: documentAttached.isPaymentReceiptUpdated,
                isBankStatementUpdated: documentAttached.isBankStatementUpdated,
                isInterestCertificateUpdated: documentAttached.isInterestCertificateUpdated,
                isUtilizationStatusCertificateUpdated: documentAttached.isUtilizationStatusCertificateUpdated,
                isSiteInspectionReportUpdated: documentAttached.isSiteInspectionReportUpdated,
                isProjectPhotoUpdated: documentAttached.isProjectPhotoUpdated

            }

            formData.append("applicationData", JSON.stringify(dataToSend))
            dispatch(showLoader());
            const serverResponse = await userCommunication.updateSecondInstallment(formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                navigation("/user/my-application");
                swal({ text: serverResponse?.data?.message, icon: "success" });
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }

    }

    //Reject Application 
    const rejectApplication = async () => {
        try {

            //check validation
            if ([undefined, null, ""].includes(comment)) {
                swal({ text: "Comment is required", icon: "warning" });
                return false;
            }

            const dataToSend = {
                applicationId: Number(applicationId),
                id,
                comment
            }

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.rejectApplication(dataToSend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "success" });
                navigation("/back-office/new-applications");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    //Mark Application Incomplete
    const markApplicationIncomplete = async () => {
        try {

            //check validation
            if ([undefined, null, ""].includes(comment)) {
                swal({ text: "Comment is required", icon: "warning" });
                return false;
            }

            const dataToSend = {
                applicationId: Number(applicationId),
                id,
                comment
            }

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.markIncompleteApplication(dataToSend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "success" });
                navigation("/back-office/new-applications");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    //Mark Application Approved
    const markApplicationApproved = async () => {
        try {

            //check validation
            if ([undefined, null, ""].includes(approveCertificate)) {
                swal({ text: "File is required", icon: "warning" });
                return false;
            }

            const formData = new FormData();
            formData.append("approveCertificate", approveCertificate);

            const dataToSend = {
                applicationId: Number(applicationId),
                id,
                userId,
                comment
            }

            dispatch(showLoader());
            const responseFromServer = await backOfficeCommunication.markApproveApplication(formData, dataToSend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "success" });
                navigation("/back-office/new-applications");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }

    //Get document from server
    const previewDocument = async (fileUrl) => {
        await userCommunication.getUserDocument(fileUrl);
    }

    useEffect(() => {
        fetchUserApplicationById();
        calculateYear();
    }, []);


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <CommonLoader />
                    :
                    < div className="form_main">
                        <div className="form_heading">
                            <H5 whiteThemeColor darkThemeColor>Proposal For Release Of 2nd InstallMent For SC Girls/Boys Hostels Sanctioned By Minsitry Of Social Justice & Empowerment</H5>
                        </div>
                        <div className="application_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #eab7f5" : "1px solid #fff" }} ref={componentRef}>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <H5 whiteThemeColor darkThemeColor>Financial Progress Report</H5>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>1. Amount sanctioned by the Ministry of Social Justice & Empowerment : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="sanctionAmount" value={secondInstallMentData.sanctionAmount} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="Sanctioned Amount" disabled={isSubmitted} />
                                    {validator.message("sanctionAmount", secondInstallMentData.sanctionAmount, "required|numeric", {
                                        messages: {
                                            required: "Sanctioned Amount is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>2. State/UT share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="stateShare" value={secondInstallMentData.stateShare} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="State Share" disabled={isSubmitted} />
                                    {validator.message("stateShare", secondInstallMentData.stateShare, "required|numeric", {
                                        messages: {
                                            required: "State Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>3. University/Institution Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="universityShare" value={secondInstallMentData.universityShare} onChange={(event) => getApplicationDetail(event)} className="form-control" placeholder="University Share" disabled={isSubmitted} />
                                    {validator.message("universityShare", secondInstallMentData.universityShare, "required|numeric", {
                                        messages: {
                                            required: "University Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H6 whiteThemeColor darkThemeColor>4. Central assistance released by Ministry of Social Justice & Empowerment</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Sanction Number : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="sanctionNo" value={secondInstallMentData.centralAssistance.sanctionNo} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, centralAssistance: { ...secondInstallMentData.centralAssistance, sanctionNo: event.target.value } })} className="form-control" placeholder="Sanctioned Number" disabled={isSubmitted} />
                                    {validator.message("sanctionNo", secondInstallMentData.centralAssistance.sanctionNo, "required", {
                                        messages: {
                                            required: "Sanctioned Number is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) Sanction Date : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="date" name="sanctionDate" value={secondInstallMentData.centralAssistance.sanctionDate} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, centralAssistance: { ...secondInstallMentData.centralAssistance, sanctionDate: event.target.value } })} className="form-control" placeholder="Sanctioned Date" disabled={isSubmitted} />
                                    {validator.message("sanctionDate", secondInstallMentData.centralAssistance.sanctionDate, "required", {
                                        messages: {
                                            required: "Sanctioned Date is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>iii) Amount : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="amount" value={secondInstallMentData.centralAssistance.amount} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, centralAssistance: { ...secondInstallMentData.centralAssistance, amount: event.target.value } })} className="form-control" placeholder="Amount" disabled={isSubmitted} />
                                    {validator.message("amount", secondInstallMentData.centralAssistance.amount, "required|numeric", {
                                        messages: {
                                            required: "Amount is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H6 whiteThemeColor darkThemeColor>5. Actual amount utilized</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Central Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="centralShare" value={secondInstallMentData.amountUtilized.centralShare} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, amountUtilized: { ...secondInstallMentData.amountUtilized, centralShare: event.target.value } })} className="form-control" placeholder="Utilized Central Share" disabled={isSubmitted} />
                                    {validator.message("centralShare", secondInstallMentData.amountUtilized.centralShare, "required|numeric", {
                                        messages: {
                                            required: "Central Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) State/University Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="universityShare" value={secondInstallMentData.amountUtilized.universityShare} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, amountUtilized: { ...secondInstallMentData.amountUtilized, universityShare: event.target.value } })} className="form-control" placeholder="Utilized State/University Share" disabled={isSubmitted} />
                                    {validator.message("universityShare", secondInstallMentData.amountUtilized.universityShare, "required|numeric", {
                                        messages: {
                                            required: "University Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H6 whiteThemeColor darkThemeColor>6. Unspent balance of central assistance up to 31<sup>st</sup> March, i.e. year ending</H6>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>i) Central Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="centralShare" value={secondInstallMentData.unspentBalance.centralShare} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, unspentBalance: { ...secondInstallMentData.unspentBalance, centralShare: event.target.value } })} className="form-control" placeholder="Unspent Central Share" disabled={isSubmitted} />
                                    {validator.message("centralShare", secondInstallMentData.unspentBalance.centralShare, "required|numeric", {
                                        messages: {
                                            required: "Unspent Central Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>ii) State/University Share : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="text" name="universityShare" value={secondInstallMentData.unspentBalance.universityShare} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, unspentBalance: { ...secondInstallMentData.unspentBalance, universityShare: event.target.value } })} className="form-control" placeholder="Unspent State/University Share" disabled={isSubmitted} />
                                    {validator.message("universityShare", secondInstallMentData.unspentBalance.universityShare, "required|numeric", {
                                        messages: {
                                            required: "Unspent University Share is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>7. Whether neccessary provision of fund has been made for salary and other expenses related to deployment of warden and guards for the hostel in case of girls' hostel?</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isFundMadeForSalary" value="Yes" checked={secondInstallMentData.isFundMadeForSalary === "Yes" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isFundMadeForSalary" value="No" checked={secondInstallMentData.isFundMadeForSalary === "No" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isFundMadeForSalary", secondInstallMentData.isFundMadeForSalary, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H5 whiteThemeColor darkThemeColor>Physical Progress Report</H5>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>1. Year : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <select name="year" onChange={(event) => getApplicationDetail(event)} className="form-select" disabled={isSubmitted}>
                                        <option value="">Select Year</option>
                                        {year?.map((yearList, index) => (
                                            <option key={index} selected={secondInstallMentData.year.toString() === yearList.toString() ? true : false}>{yearList}</option>
                                        ))}
                                    </select>
                                    {validator.message("year", secondInstallMentData.year, "required", {
                                        messages: {
                                            required: "Year is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>2. Hostels sanctioned by Ministry of Social Justice & Empowerment along with location : </H6>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <input type="file" name="hostelSanctionCertificate" onChange={(event) => { getDocuments(event); setDocumentAttached({ ...documentAttached, isHostelSanctionCertificateUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                    {validator.message("hostelSanctionCertificate", documents.hostelSanctionCertificate, "required", {
                                        messages: {
                                            required: "File is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-2 col-md-3 button_group">
                                    <button onClick={() => previewDocument(documents.hostelSanctionCertificate)}>Preview</button>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>3. Whether completed</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isConstructionCompleted" value="Yes" checked={secondInstallMentData.isConstructionCompleted === "Yes" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isConstructionCompleted" value="No" checked={secondInstallMentData.isConstructionCompleted === "No" ? true : false} onChange={(event) => getApplicationDetail(event)} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                    </div>
                                    {validator.message("isConstructionCompleted", secondInstallMentData.isConstructionCompleted, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>
                            {secondInstallMentData.isConstructionCompleted === "Yes" &&
                                <div className="row mb-4">
                                    <div className="col-lg-4 col-md-5">
                                        <H6 whiteThemeColor darkThemeColor>Date of completion : </H6>
                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <input type="date" name="dateOfCompletion" value={secondInstallMentData.constructionDetails.dateOfCompletion} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, constructionDetails: { ...secondInstallMentData.constructionDetails, dateOfCompletion: event.target.value } })} className="form-control" placeholder="Date of Completion" disabled={isSubmitted} />
                                        {validator.message("dateOfCompletion", secondInstallMentData.constructionDetails.dateOfCompletion, "required", {
                                            messages: {
                                                required: "Date of Completion is required"
                                            }
                                        })}
                                    </div>
                                </div>
                            }
                            {secondInstallMentData.isConstructionCompleted === "No" &&
                                <>
                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-5">
                                            <H6 whiteThemeColor darkThemeColor>Status of construction : </H6>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <input type="text" name="constructionStatus" value={secondInstallMentData.constructionDetails.constructionStatus} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, constructionDetails: { ...secondInstallMentData.constructionDetails, constructionStatus: event.target.value } })} className="form-control" placeholder="Construction Status" disabled={isSubmitted} />
                                            {validator.message("constructionStatus", secondInstallMentData.constructionDetails.constructionStatus, "required", {
                                                messages: {
                                                    required: "Construction Status is required"
                                                }
                                            })}
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-5">
                                            <H6 whiteThemeColor darkThemeColor>Expected date of completion : </H6>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <input type="date" name="expectedDateOfCompletion" value={secondInstallMentData.constructionDetails.expectedDateOfCompletion} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, constructionDetails: { ...secondInstallMentData.constructionDetails, expectedDateOfCompletion: event.target.value } })} className="form-control" placeholder="Expected Date of Completion" disabled={isSubmitted} />
                                            {validator.message("expectedDateOfCompletion", secondInstallMentData.constructionDetails.expectedDateOfCompletion, "required", {
                                                messages: {
                                                    required: "Expected Date of Completion is required"
                                                }
                                            })}
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-5">
                                            <H6 whiteThemeColor darkThemeColor>Reason for non-completion : </H6>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <input type="text" name="reasonOfNonCompletion" value={secondInstallMentData.constructionDetails.reasonOfNonCompletion} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, constructionDetails: { ...secondInstallMentData.constructionDetails, reasonOfNonCompletion: event.target.value } })} className="form-control" placeholder="Non Completion Reason" disabled={isSubmitted} />
                                            {validator.message("reasonOfNonCompletion", secondInstallMentData.constructionDetails.reasonOfNonCompletion, "required", {
                                                messages: {
                                                    required: "Non Completion Reason is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="row mb-4">
                                <div className="col-12">
                                    <H5 whiteThemeColor darkThemeColor>List of Documents to be submitted along with the proposal for release of 2nd installment</H5>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>1. Utilization Certificate as per format mentioned in sanction letter</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isUtilizationCertificateAttached" value="Yes" checked={secondInstallMentData.document.isUtilizationCertificateAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isUtilizationCertificateAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isUtilizationCertificateAttached" value="No" checked={secondInstallMentData.document.isUtilizationCertificateAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isUtilizationCertificateAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isUtilizationCertificateAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="utilizationCertificate" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, utilizationCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isUtilizationCertificateUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("utilizationCertificate", documents.document.utilizationCertificate, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.utilizationCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isUtilizationCertificateAttached", secondInstallMentData.document.isUtilizationCertificateAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>2. Audit report since 1<sup>st</sup> installment released (in case of Central/State University/Institution)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isAuditReportAttached" value="Yes" checked={secondInstallMentData.document.isAuditReportAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isAuditReportAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isAuditReportAttached" value="No" checked={secondInstallMentData.document.isAuditReportAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isAuditReportAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isAuditReportAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="auditReport" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, auditReport: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isAuditReportUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("auditReport", documents.document.auditReport, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.auditReport)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isAuditReportAttached", secondInstallMentData.document.isAuditReportAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>3. Balance sheet since 1<sup>st</sup> installment released (in case of Central/State University/Institution)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isBalanceSheetAttached" value="Yes" checked={secondInstallMentData.document.isBalanceSheetAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isBalanceSheetAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isBalanceSheetAttached" value="No" checked={secondInstallMentData.document.isBalanceSheetAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isBalanceSheetAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isBalanceSheetAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="balanceSheet" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, balanceSheet: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isBalanceSheetUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("balanceSheet", documents.document.balanceSheet, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.balanceSheet)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isBalanceSheetAttached", secondInstallMentData.document.isBalanceSheetAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>4. Income expenditure statement since 1<sup>st</sup> installment released (in case of Central/StateUniversity/Institution)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isIncomeExpenditureAttached" value="Yes" checked={secondInstallMentData.document.isIncomeExpenditureAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isIncomeExpenditureAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isIncomeExpenditureAttached" value="No" checked={secondInstallMentData.document.isIncomeExpenditureAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isIncomeExpenditureAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isIncomeExpenditureAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="incomeExpenditureCertificate" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, incomeExpenditureCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isIncomeExpenditureCertificateUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("incomeExpenditureCertificate", documents.document.incomeExpenditureCertificate, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.incomeExpenditureCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isIncomeExpenditureAttached", secondInstallMentData.document.isIncomeExpenditureAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>5. Receipt of payment since 1<sup>st</sup> installment released (in case of Central/State University/Institution)</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isPaymentReceiptAttached" value="Yes" checked={secondInstallMentData.document.isPaymentReceiptAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isPaymentReceiptAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isPaymentReceiptAttached" value="No" checked={secondInstallMentData.document.isPaymentReceiptAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isPaymentReceiptAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isPaymentReceiptAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="paymentReceipt" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, paymentReceipt: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isPaymentReceiptUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("paymentReceipt", documents.document.paymentReceipt, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.paymentReceipt)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isPaymentReceiptAttached", secondInstallMentData.document.isPaymentReceiptAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>6. Bank Statement since 1<sup>st</sup> installment</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isBankStatementAttached" value="Yes" checked={secondInstallMentData.document.isBankStatementAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isBankStatementAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isBankStatementAttached" value="No" checked={secondInstallMentData.document.isBankStatementAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isBankStatementAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isBankStatementAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="bankStatement" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, bankStatement: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isBankStatementUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("bankStatement", documents.document.bankStatement, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.bankStatement)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isBankStatementAttached", secondInstallMentData.document.isBankStatementAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>7. Details of interest earned on 1<sup>st</sup> installment released for the project</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isInterestDetailsAttached" value="Yes" checked={secondInstallMentData.document.isInterestDetailsAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isInterestDetailsAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isInterestDetailsAttached" value="No" checked={secondInstallMentData.document.isInterestDetailsAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isInterestDetailsAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isInterestDetailsAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="interestCertificate" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, interestCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isInterestCertificateUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("interestCertificate", documents.document.interestCertificate, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.interestCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isInterestDetailsAttached", secondInstallMentData.document.isInterestDetailsAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>8. Status of utilization of matching share by the implementing agency, if any</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isStatusOfUtilizationAttached" value="Yes" checked={secondInstallMentData.document.isStatusOfUtilizationAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isStatusOfUtilizationAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isStatusOfUtilizationAttached" value="No" checked={secondInstallMentData.document.isStatusOfUtilizationAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isStatusOfUtilizationAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isStatusOfUtilizationAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="utilizationStatusCertificate" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, utilizationStatusCertificate: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isUtilizationStatusCertificateUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("utilizationStatusCertificate", documents.document.utilizationStatusCertificate, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.utilizationStatusCertificate)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isStatusOfUtilizationAttached", secondInstallMentData.document.isStatusOfUtilizationAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>9. Site inspection report</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isSiteInspectionReportAttached" value="Yes" checked={secondInstallMentData.document.isSiteInspectionReportAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isSiteInspectionReportAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isSiteInspectionReportAttached" value="No" checked={secondInstallMentData.document.isSiteInspectionReportAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isSiteInspectionReportAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isSiteInspectionReportAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="siteInspectionReport" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, siteInspectionReport: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isSiteInspectionReportUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("siteInspectionReport", documents.document.siteInspectionReport, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.siteInspectionReport)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isSiteInspectionReportAttached", secondInstallMentData.document.isSiteInspectionReportAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-5">
                                    <H6 whiteThemeColor darkThemeColor>10. Colour Photographs of project site</H6>
                                </div>
                                <div className="col-lg-8 col-md-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>Yes</Label>
                                            <input type="radio" name="isProjectPhotoAttached" value="Yes" checked={secondInstallMentData.document.isProjectPhotoAttached === "Yes" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isProjectPhotoAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <Label whiteThemeColor darkThemeColor>No</Label>
                                            <input type="radio" name="isProjectPhotoAttached" value="No" checked={secondInstallMentData.document.isProjectPhotoAttached === "No" ? true : false} onChange={(event) => setSecondInstallmentData({ ...secondInstallMentData, document: { ...secondInstallMentData.document, isProjectPhotoAttached: event.target.value } })} className="form-check-input ms-4" disabled={isSubmitted} />
                                        </div>
                                        {secondInstallMentData.document.isProjectPhotoAttached === "Yes" &&
                                            <>
                                                <div className="col-lg-4 col-md-5">
                                                    <input type="file" name="projectPhoto" onChange={(event) => { setDocuments({ ...documents, document: { ...documents.document, projectPhoto: event.target.files[0] } }); setDocumentAttached({ ...documentAttached, isProjectPhotoUpdated: true }) }} className="form-control" disabled={isSubmitted} />
                                                    {validator.message("projectPhoto", documents.document.projectPhoto, "required", {
                                                        messages: {
                                                            required: "File is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-lg-2 col-md-3 button_group">
                                                    <button onClick={() => previewDocument(documents.document.projectPhoto)}>Preview</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {validator.message("isProjectPhotoAttached", secondInstallMentData.document.isProjectPhotoAttached, "required", {
                                        messages: {
                                            required: "Choose at lease on Option"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row mt-2 mb-lg-4 mb-md-5">
                                <div className="col-12 button_group">
                                    {(isSubmitted === false && [undefined, null, ""].includes(backOfficeUser?.userType)) &&
                                        <>
                                            <button onClick={() => updateApplication()}>Submit</button>
                                        </>
                                    }
                                    {[undefined, null, ""].includes(backOfficeUser?.userType) &&
                                        <button className="ms-3" onClick={() => navigation("/user/my-application")}>Cancel</button>
                                    }
                                </div>
                            </div>

                            {![undefined, null, ""].includes(backOfficeUser.userType) &&
                                <div className="row approve_certificates mb-4">
                                    {![undefined, null, ""].includes(approveCertificates.districtApproveCertificate) &&
                                        <div className="col-4 text-center">
                                            <H6>District Approve Certificate</H6>
                                            <div className="col-lg-2 col-md-3 mt-2 preview_button">
                                                <button onClick={() => previewDocument(approveCertificates.districtApproveCertificate)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                    {![undefined, null, ""].includes(approveCertificates.divisionApproveCertificate) &&
                                        <div className="col-4 text-center">
                                            <H6>Division Approve Certificate</H6>
                                            <div className="col-lg-2 col-md-3 mt-2 preview_button">
                                                <button onClick={() => previewDocument(approveCertificates.divisionApproveCertificate)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                    {![undefined, null, ""].includes(approveCertificates.stateApproveCertificate) &&
                                        <div className="col-4 text-center">
                                            <H6>State Approve Certificate</H6>
                                            <div className="col-lg-2 col-md-3 mt-2 preview_button">
                                                <button onClick={() => previewDocument(approveCertificates.stateApproveCertificate)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }


                            <div className="row mt-2 mb-lg-4 mb-md-5">
                                <div className="col-12 button_group">
                                    {((stateUrl === true) || (finalSelectedUrl === true) || (districtSelected === true) || (divisionSelected === true) || (["Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["Division"].includes(backOfficeUser.userType)) || (["Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["State"].includes(backOfficeUser.userType))) &&
                                        <button className="ms-3" onClick={() => navigation(-1)}>Go back</button>
                                    }

                                </div>
                            </div>
                        </div>
                        {(stateUrl === true) || (finalSelectedUrl === true) || (districtSelected === true) || (divisionSelected === true) || (["Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["Division"].includes(backOfficeUser.userType)) || (["Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus) && ["State"].includes(backOfficeUser.userType)) ?
                            <div>
                            </div>
                            :
                            <div className="back_office_user_action">
                                {((![undefined, null, ""].includes(backOfficeUser?.userType)) && (["NEW", "Approved_By_District", "Approved_By_Division", "Incomplete_By_State", "Incomplete_By_Division", "Incomplete_By_District"].includes(backOfficeStatus))) &&
                                    <>
                                        <div className="row mb-4">
                                            <div className="col-lg-4 col-md-5">
                                                <H6 whiteThemeColor darkThemeColor>Add Comment</H6>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <textarea name="comment" onChange={(event) => setComment(event.target.value)} className="form-control" placeholder="comment..."></textarea>
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col-lg-4 col-md-5">
                                                <H6 whiteThemeColor darkThemeColor>Upload Document</H6>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <input type="file" name="approveCertificate" onChange={(event) => setApproveCertificate(event.target.files[0])} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="row mt-2 mb-4">
                                            <div className="col-12 button_group">
                                                <button onClick={() => rejectApplication()}>Reject</button>
                                                <button className="ms-3 me-3" onClick={() => markApplicationIncomplete()}>Incomplete</button>
                                                <button onClick={() => markApplicationApproved()}>Approve</button>
                                                <NavLink to={`/print-second-installment?id=${id}`} target="_blank">
                                                    <button className="ms-3">Print</button>
                                                </NavLink>
                                                <button className="ms-3" onClick={() => navigation(-1)}>Cancel</button>
                                            </div>
                                        </div>

                                    </>
                                }

                            </div>
                        }
                    </div>
            }
        </>
    )
}

export default PreviewSecondInstallment