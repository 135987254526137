import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { adminCommunication } from '../../communication/adminCommunication';
import H5 from "../../text-components/H5";
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import Loader from '../../shared-components/Loader';
import useValidator from '../../form-validation/useValidatior';
import divisionArray from '../../shared-components/divisionArray';
import swal from 'sweetalert';

const UpdateUser = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const [validator, showValidationMessage] = useValidator();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  //getting designation id from query params
  const params = new URLSearchParams(useLocation().search);
  const userId = params.get("userId");

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [district, setDistrict] = useState([]);
  const [userDetail, setUserDetail] = useState({
    fullName: "",
    email: "",
    mobileNo: "",
    alternateMobileNo: "",
    department: "",
    designation: "",
    zone: "",
    division: "",
    district: ""
  });

  //Get all inputs value
  const getUserDetails = (event) => {
    if (event.department) {
      setUserDetail({ ...userDetail, "department": event });
    } else if (event.designation) {
      setUserDetail({ ...userDetail, "designation": event });
    } else {
      setUserDetail({ ...userDetail, [event.target.name]: event.target.value });
    }
  }

  const fetchBackOfficeUsersById = async () => {
    try {
      dispatch(showLoader());
      const responseFromServer = await adminCommunication.getBackOfficeUserById(userId);
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setUserDetail(responseFromServer?.data?.user);
        setDesignations([responseFromServer?.data?.user.designation]);
        setDistrict([responseFromServer?.data?.user?.district]);
      } else {
        dispatch(hideLoader());
        swal({ text: responseFromServer?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  //fetch all departments on initial load
  const fetchAllDepartments = async () => {
    try {
      const responseFromServer = await adminCommunication.getAllDepartments();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setDepartments(responseFromServer?.data?.department);
      } else {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }

  //Department wise designations
  const departmentWiseDesignations = async (department) => {
    try {

      const getDesignations = await adminCommunication.getDepartmentWiseDesignation(department?._id);
      if (getDesignations?.data?.status === "SUCCESS") {
        setDesignations(getDesignations?.data?.designation)
      } else {
        swal({ text: getDesignations?.data?.message, icon: "error" });
      }

    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }

  //get division wise districts
  const divisionWiseDistricts = (event) => {
    for (let i = 0; i < divisionArray.length; i++) {
      if (divisionArray[i].division === event.target.value) {
        setDistrict(divisionArray[i].district);
      }
    }
  }

  useEffect(() => {
    fetchBackOfficeUsersById();
    fetchAllDepartments();
  }, []);

  //update user
  const updateUser = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const dataToSend = {
        id: userDetail._id,
        userId,
        fullName: userDetail.fullName,
        email: userDetail.email,
        mobileNo: userDetail.mobileNo,
        alternateMobileNo: userDetail.alternateMobileNo,
        department: userDetail.department,
        designation: userDetail.designation,
        zone: userDetail.zone,
        division: userDetail.division,
        district: userDetail.district
      }

      dispatch(showLoader());
      const serverResponse = await adminCommunication.updateBackOfficeUser(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        navigation("/admin/dashboard/user-list");
        swal({ text: serverResponse?.data?.message, icon: "success" });
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "error" });
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  return (
    <>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="form_section">
            <div className="add_user_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
              <div>
                <div className="form_title">
                  <H5 whiteThemeColor darkThemeColor>Update User</H5>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <input type="text" name="fullName" value={userDetail?.fullName} onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Enter Full Name" />
                    {validator.message("fullName", userDetail.fullName, "required|alpha_space", {
                      messages: {
                        required: "Full Name is required"
                      }
                    })}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <input type="text" name="email" value={userDetail?.email} onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Enter Email" />
                    {validator.message("email", userDetail.email, "required|email", {
                      messages: {
                        required: "Email is required"
                      }
                    })}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <input type="number" name="mobileNo" value={userDetail?.mobileNo} onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Enter Mobile Number" />
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <input type="number" name="alternateMobileNo" value={userDetail?.alternateMobileNo} onChange={(event) => getUserDetails(event)} className="form-control" placeholder="Enter Alternate Number" />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <select name="department" onChange={(event) => { getUserDetails(JSON.parse(event.target.value)); departmentWiseDesignations(JSON.parse(event.target.value)) }} className="form-select">
                      <option value="">Select Department</option>
                      {departments?.map((departmentDetail, index) => (
                        <option value={JSON.stringify(departmentDetail)} selected={userDetail?.department?.department === departmentDetail?.department ? true : false} key={index}>{departmentDetail?.department}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <select name="designation" onChange={(event) => getUserDetails(JSON.parse(event.target.value))} className="form-select">
                      <option value="">Select Designation</option>
                      {designations?.map((designationDetail, index) => (
                        <option value={JSON.stringify(designationDetail)} selected={userDetail?.designation?.designation === designationDetail?.designation ? true : false} key={index}>{designationDetail?.designation}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <select className="form-select" name="zone" onChange={(event) => getUserDetails(event)}>
                      <option value="">Select Zone</option>
                      <option selected={userDetail.zone === "District" ? true : false} value="District">District</option>
                      <option selected={userDetail.zone === "Division" ? true : false} value="Division">Division</option>
                      <option selected={userDetail.zone === "State" ? true : false} value="State">State</option>
                    </select>
                    {validator.message("zone", userDetail.zone, "required", {
                      messages: {
                        required: "Zone is required"
                      }
                    })}
                  </div>
                  {userDetail.zone === "State" ?
                    <span></span>
                    :
                    <div className="col-lg-6 col-md-6">
                      <select name="division" onChange={(event) => { getUserDetails(event); divisionWiseDistricts(event) }} className="form-select">
                        <option value="">Select Division</option>
                        {divisionArray?.map((divisionList, index) => (
                          <option selected={userDetail.division === divisionList?.division ? true : false} key={index}>{divisionList?.division}</option>
                        ))}
                        {/* <option selected={userDetail.district === "Satara" ? true : false} value="Satara">Satara</option> */}
                      </select>
                      {validator.message("division", userDetail.division, "required", {
                        messages: {
                          required: "Division is required"
                        }
                      })}
                    </div>
                  }
                </div>
                {userDetail.zone === "Division" || userDetail.zone === "State" ?
                  <span></span>
                  :
                  <div className="row mb-3">
                    <div className="col-lg-6 col-md-6">
                      <select name="district" onChange={(event) => getUserDetails(event)} className="form-select">
                        <option value="">Select District</option>
                        {district?.map((districtList, index) => (
                          <option selected={districtList === userDetail.district ? true : false} key={index}>{districtList}</option>
                        ))}
                        {/* <option selected={userDetail.division === "Gondia" ? true : false} value="Gondia">Gondia</option>
                  <option selected={userDetail.division === "Nagpur" ? true : false} value="Nagpur">Nagpur</option> */}
                      </select>
                      {validator.message("district", userDetail.district, "required", {
                        messages: {
                          required: "District is required"
                        }
                      })}
                    </div>
                  </div>
                }
                <div className="button_group mt-3">
                  <button onClick={() => updateUser()}>Update</button>
                  <NavLink to="/admin/dashboard/user-list">
                    <button className="ms-2">Cancel</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default UpdateUser