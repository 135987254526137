
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import H5 from '../../text-components/H5';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { removeUnderscoreFromText } from '../../shared-components/removeUnderScore';
import { userCommunication } from '../../communication/userCommunication';
import swal from 'sweetalert';

const RemittancePreview = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [remittanceData, setRemittanceData] = useState({});

    //get id from params
    const params = new URLSearchParams(useLocation().search);
    const remittanceId = params.get('remittanceId');

    //Fetch Remittance By Id on initial load
    const fetchRemittanceById = async () => {
        try {

            dispatch(showLoader());
            const responseFromServer = await userCommunication.getRemittanceById(remittanceId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setRemittanceData(responseFromServer?.data?.remittance);
            } else {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({text: error.message, icon: "error"});
        }
    }

    //Confirm Remittance
    const confirmRemittance = async () => {
        try {

            const dataToSend = {
                remittanceId,
                status: "Confirmed"
            }
            dispatch(showLoader());
            const serverResoponse = await userCommunication.acknowledgeRemittance(dataToSend);
            if (serverResoponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({text: serverResoponse?.data?.message, icon: "success"});
                navigation("/user/remittance");
            } else {
                dispatch(hideLoader());
                swal({text: serverResoponse?.data?.message, icon: "error"});
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({text: error.message, icon: "error"});
        }
    }

    //Reject Remittance
    const rejectRemittance = async () => {
        try {

            const dataToSend = {
                remittanceId,
                status: "Rejected"
            }
            dispatch(showLoader());
            const serverResoponse = await userCommunication.acknowledgeRemittance(dataToSend);
            if (serverResoponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({text: serverResoponse?.data?.message, icon: "success"});
                navigation("/user/remittance");
            } else {
                dispatch(hideLoader());
                swal({text: serverResoponse?.data?.message, icon: "error"});
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({text: error.message, icon: "error"});
        }
    }

    useEffect(() => {
        fetchRemittanceById();
    }, []);


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <CommonLoader />
                    :
                    <div className="remittance_form_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                        <div className="add_remittance_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <H5>{removeUnderscoreFromText(remittanceData?.applicationType)}</H5>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <input type="text" name="totalApprovedAmount" value={remittanceData.totalApprovedAmount} className="form-control" placeholder="Total Approved Amount" disabled />
                                </div>
                                <div className="col-6">
                                    <select name="paymentMode" className="form-select" disabled>
                                        <option value="">Select Payment Mode</option>
                                        <option value="Cash" selected={remittanceData.paymentMode === "Cash" ? true : false}>Cash</option>
                                        <option value="Bank" selected={remittanceData.paymentMode === "Bank" ? true : false}>Bank</option>
                                        <option value="Cheque" selected={remittanceData.paymentMode === "Cheque" ? true : false}>Cheque</option>
                                        <option value="Other" selected={remittanceData.paymentMode === "Other" ? true : false}>Other</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-6">
                                    <input type="text" name="stateReceivedAmount" value={remittanceData.stateReceivedAmount} className="form-control" placeholder="Amount Received from State" disabled />
                                </div>
                                <div className="col-6">
                                    <input type="text" name="centralReceivedAmount" value={remittanceData.centralReceivedAmount} className="form-control" placeholder="Amount Received from Central" disabled />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <textarea name="narration" value={remittanceData.narration} className="form-control" placeholder="Enter Narration..." disabled></textarea>
                                </div>
                            </div>
                            <div className="back_office_button_group">
                                {!["Confirmed", "Rejected"].includes(remittanceData?.userStatus) &&
                                    <>
                                        <button onClick={() => confirmRemittance()}>Confirm</button>
                                        <button className="ms-3 me-3" onClick={() => rejectRemittance()}>Reject</button>
                                    </>
                                }
                                <button onClick={() => navigation("/user/remittance")}>Cancel</button>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default RemittancePreview