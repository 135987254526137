import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userCommunication } from '../../communication/userCommunication';
import H6 from '../../text-components/H6';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const LatestNotifications = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [currentPage, SetCurrentPage] = useState(0);
  const [pageCount, SetPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  //onclick set Current page
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    SetCurrentPage(selectedPage);
    if (searchValue) {
      fetchLatestNotificationsBySearch(searchValue, selectedPage + 1);
    } else {
      fetchAllNotifications(selectedPage + 1);
    }
  }


  //Search Filter
  const searchFilter = async (searchString) => {
    if (searchString) {
      fetchLatestNotificationsBySearch(searchString, currentPage + 1);
    } else {
      const responseFromServer = await userCommunication.getLatestNotifications();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setNotifications(responseFromServer?.data?.notifications);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigation("/login");
      } else {
        setNotifications([]);
        SetPageCount(0);
      }
    }
  }

  //Get Latest Notifications by search
  const fetchLatestNotificationsBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await userCommunication.getLatestNotificationsBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setNotifications(responseFromServer?.data?.notifications);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigation("/login");
      } else {
        setNotifications([]);
        SetPageCount(0);
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
    }
  }

  //get All Notifications on initial load
  const fetchAllNotifications = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await userCommunication.getLatestNotifications(page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setNotifications(responseFromServer?.data?.notifications);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigation("/login");
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        setNotifications([]);
        SetPageCount(0);
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  //get Document from server
  const fetchFile = async (fileUrl) => {
    if (fileUrl !== "") {
      await userCommunication.getDocument(fileUrl);
    }
  }

  //Apply for scheme
  const applyForScheme = async (notificationId) => {
    try {
      dispatch(showLoader());
      const serverResponse = await userCommunication.isApplicationAvailableToApply(notificationId);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        const formType = serverResponse?.data?.notification;
        if (formType?.formType === "NEW APPLICATION") {
          navigation(`/user/latest-notification/new-application?notificationId=${notificationId}`);
        } else {
          navigation(`/user/latest-notification/repair-and-maintenance?notificationId=${notificationId}`);
        }
      } else {
        swal({ text: serverResponse?.data?.message, icon: "warning" })
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }


  useEffect(() => {
    fetchAllNotifications();
  }, []);

  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />

          :

          <div className="section">
            <div className="container mt-2">
              <div className="header_wrapper" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                <div className="row mb-2 top_search_header_user_portal">
                  <div className="col-lg-3 col-md-4">
                    <input Type="text" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search.." />
                  </div>
                  <div className="col-lg-9 col-md-9 user_portal_pagination">
                    <div className="pagination_div ">
                      {
                        pageCount > 1 &&
                        <div className="pagination_main">
                          <ReactPaginate
                            previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount} shape="rounded"
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            forcePage={currentPage}
                            activeClassName={"active"} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {notifications.length > 0 ?
                <>
                  {notifications?.map((userNotification, index) => (
                    <div className="row notification_card" style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #eab7f5" : "1px solid #fff" }} key={index}>
                      <div className="col-10 notification_card_description">
                        <div className="subject">
                          <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#ad5389" }}>{userNotification?.subject}</span>
                        </div>
                        <H6>{userNotification?.description}</H6>

                        {userNotification?.notificationType === "APPLY" ?
                          < div className="notification_date">
                            <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#ad5389" }}>Start Date : </span>
                            <p style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(userNotification?.startDate).toLocaleDateString()}</p>
                            <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#ad5389" }}>End Date : </span>
                            <p style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}>{new Date(userNotification?.endDate).toLocaleDateString()}</p>
                          </div>
                          :
                          <span></span>
                        }

                      </div>
                      {(userNotification?.notificationType === "GENERAL") && ([undefined, null, "", "-"].includes(userNotification?.attachment)) ?
                        <span></span>
                        :
                        <div className="col-2 notification_card_button button_group">
                          {userNotification?.notificationType === "APPLY" ?
                            <button onClick={() => applyForScheme(userNotification?._id)}>Apply</button>
                            :
                            <i className="fa-solid fa-download" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#ad5389", cursor: "pointer" }}
                              onClick={() => fetchFile(userNotification?.attachment)}></i>
                          }
                        </div>
                      }
                    </div>
                  ))}
                </>
                :
                <div className="data_not_available_message">
                  <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Notification Not Generated Yet</span>
                </div>
              }
            </div>
          </div>
      }
    </>
  )
}

export default LatestNotifications