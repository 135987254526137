import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import SignIn from "../user-portal/SignIn"
import Registration from '../user-portal/Registration';
import ForgotPassword from '../user-portal/ForgotPassword';
import UserDashboard from '../user-portal/UserDashboard';
import AdminRoutes from './admin-routes/AdminRoutes';
import BackOfficeDashboard from '../backOffice-portal/BackOfficeDashboard';
import BackOfficeSignIn from '../backOffice-portal/BackOfficeSignIn';
import PublicPortal from "../Public-portal/PublicPortal"
import ChartComponent from "../Public-portal/ChartComponent";
import WebsitePolicy from "../Public-portal/WebsitePolicy";
import AdminMainComponent from '../admin-portal/AdminMainComponent';
import PrintNewApplication from '../shared-components/PrintNewApplication';
import PrintSecondInstallment from '../shared-components/PrintSecondInstallment';
import PrintRepairAndMaintenance from '../shared-components/PrintRepairAndMaintenance';

const MainRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/public' element={<PublicPortal />} />
                <Route path='/statistics' element={<ChartComponent />} />
                <Route path='/Website-Policy' element={<WebsitePolicy />} />
                <Route path='/login' element={<SignIn />} />
                <Route path='/registration' element={<Registration />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/print-new-application' element={<PrintNewApplication />} />
                <Route path='/print-repair-and-maintenance' element={<PrintRepairAndMaintenance />} />
                <Route path='/print-second-installment' element={<PrintSecondInstallment />} />
                <Route path="/user/*" element={<UserDashboard />} ></Route>
                <Route path="/admin/*" element={<AdminMainComponent />} ></Route>
                <Route path="/back-office/*" element={<BackOfficeDashboard />} ></Route>
                <Route path="*" element={<Navigate to="/public" replace />} />
            </Routes>
        </>
    )
}

export default MainRoutes