import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useValidator from '../form-validation/useValidatior';
import H5 from '../text-components/H5';
import Loader from '../shared-components/Loader';
import { showLoader, hideLoader } from '../redux-store/loaderReducer';
import { backOfficeCommunication } from '../communication/backOfficeCommunication';
import { addCookies } from '../redux-store/backOfficeAuthenticatedReducer';
import swal from 'sweetalert';

const BackOfficeSignIn = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [validator, showValidationMessage] = useValidator();
  const [userData, setUserData] = useState({
    userId: "",
    password: ""
  });

  //getting all inputs value
  const getUserDetail = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  }

 
  //Login Back office user
  const userLogin = async () => {
    try {

      //check validation
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const dataToSend = {
        userId: userData.userId,
        password: userData.password
      }

      dispatch(showLoader());
      const serverResponse = await backOfficeCommunication.login(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        const dataFromServer = serverResponse?.data?.userData;
        dispatch(addCookies({
          name: dataFromServer?.name,
          designation: dataFromServer?.designation,
          userType: dataFromServer?.userType,
          token: serverResponse?.data?.jwtToken
        }));
        dispatch(hideLoader());
        navigation("/back-office/new-applications");
        swal({text: serverResponse?.data?.message, icon: "success"});
      } else {
        dispatch(hideLoader());
        swal({text: serverResponse?.data?.message, icon: "error"});
      }

    } catch (error) {
      dispatch(hideLoader());
      swal({text: error.message, icon: "error"});
    }
  }

  return (
    <div className="login_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
      {
        loader.showHideLoader === true ?
          <Loader />
          :
          <div className="login_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#d47ae83b" : "#3c3c3c", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff" }}>
            <div className="form_title">
              <H5 whiteThemeColor darkThemeColor>Login</H5>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <input type="text" name="userId" onChange={(event) => getUserDetail(event)} className="form-control" placeholder="Enter User Id" />
                {validator.message("userId", userData.userId, "required", {
                  messages: {
                    required: "User id is required"
                  }
                })}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <input type="password" name="password" onChange={(event) => getUserDetail(event)} className="form-control" placeholder="Enter Password" />
                {validator.message("password", userData.password, "required|min:8", {
                  messages: {
                    required: "Password is required"
                  }
                })}
              </div>
            </div>

            <div className="button_group text-center">
              <button onClick={() => userLogin()}>Login</button>
            </div>
          </div>
      }
    </div>
  )
}

export default BackOfficeSignIn