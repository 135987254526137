import axios from 'axios';



const developmentURL = "http://localhost:3012";
const contoboURL = "https://bjrcybackend.mattedemolitions.com";
const machineIp = "http://192.168.29.169:3012";



const getServerUrl = () => {
    const nodeEnvironment = process.env.REACT_APP_NODE_ENV;
    const serverUrl = process.env.REACT_APP_BACKEND_URL;


    if (nodeEnvironment === "development") {
        return developmentURL;
    }

    if (nodeEnvironment === "contobo_dev") {
        return contoboURL;
    }

    if (nodeEnvironment === "production") {
        return serverUrl;
    }

    if (nodeEnvironment === "machine_ip") {
        return machineIp;
    }

    return machineIp;
}

export const publicCommunication = {
    getPublicNotification: () => {
        return axios.get(`${getServerUrl()}/public/get-public-notification`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    getGraphData: () => {
        return axios.get(`${getServerUrl()}/public/get-yearly-received-applications-data-for-graph`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    getDocument: (fileUrl) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/public/get-file?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                // resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    uploadFeedback: (dataToSend) => {
        return axios.post(`${getServerUrl()}/public/upload-feedback`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
}