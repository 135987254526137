import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import H3 from '../text-components/H3';
import H4 from '../text-components/H4';



const MainHeader = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

  return (
    <div className="main_header" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c", borderTop: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff", borderBottom: darkWhiteThemeToggle === "WHITE" ? "1px solid #d47ae83b" : "1px solid #fff"}}>
      <div className="row m-0">
        <div className="col-lg-3 col-md-3 text-center main_header_left">
          <img src='/images/Maharashtra_Emblem_transparent.png' />
          <img src='/images/favicon 1-01.png' />
        </div>
        <div className="col-lg-6 col-md-6 main_header_middle">
          <div className="right_box text-center">
            <H3 whitThemeColor darkThemeColor>Babu Jagjivan Ram Chhatrawas Yojna(BJRCY)</H3>
            <H4 whitThemeColor darkThemeColor>Government of Maharashtra</H4>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 main_header_right">
          <img src="../images/mainheaderrightimg.png" />
        </div>
      </div>
    </div>
  )
}

export default MainHeader