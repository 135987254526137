import React from 'react'
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
    const adminLoginUserDetails = useSelector((state) => state.adminUserDetails);
    if ([null, undefined, ""].includes(adminLoginUserDetails.userName)) {
        return <Navigate to="/admin/login" replace />;
    }
    return children;
};

export const UserPrivateRoute = ({ children }) => {
    const userLoginDetails = useSelector((state) => state.userDetails);

    if ([null, undefined, ""].includes(userLoginDetails.name)) {
        return <Navigate to="/login" replace />;
    }
    return children;
};