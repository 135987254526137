import React from "react";
import Footer from "../shared-components/Footer";
import './PublicCSS.css'
import Navbar from "./Navbar";
import Slider from "./Slider";
import FooterPublic from "./FooterPublic";
import MainHeader from "../shared-components/MainHeader";
import Notification from "./Notification";
import About from "./About";
import OtherSlider from "./OtherSlider";
import TopHeaderPublic from "./TopHearderPublic";

const PublicPortal = () => {

    return (
        <div>
           <TopHeaderPublic />
            <MainHeader />
            <Navbar />
            <Slider />
            <Notification />
            <About />
            <OtherSlider />
            <FooterPublic />
            <Footer />
        </div>
    );
};


export default PublicPortal;
