import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import { removeUnderscoreFromText } from '../../shared-components/removeUnderScore';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { capitalizeFirstLetter } from '../../shared-components/capitalizeFirstLetter';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const Remittance = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [remittance, setRemittance] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const limit = process.env.REACT_APP_LIMIT
  const [filterRemittance, setFilterRemitance] = useState([]);

  //onclick set Current page
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
      fetchRemittanceListBySearch(searchValue, selectedPage + 1);
    } else {
      fetchAllRemittance(selectedPage + 1);
    }
  }

  //filter data according to remittance
  const searchFilter = async (searchString) => {
    if (searchString) {
      fetchRemittanceListBySearch(searchString, currentPage + 1)
    } else {
      const serverResponse = await backOfficeCommunication.getAllRemittance();
      if (serverResponse?.data?.status === "SUCCESS") {
        setRemittance(serverResponse?.data?.remittance);
        setPageCount(serverResponse?.data?.totalPages);
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        swal({ text: serverResponse?.data?.message, icon: "warning" });
        navigate("/back-office/login");
      } else {
        setRemittance([]);
        setPageCount(0);
      }
    }
  }

  //get remittance list by search
  const fetchRemittanceListBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await backOfficeCommunication.getRemittanceListBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        // console.log(responseFromServer?.data);
        setRemittance(responseFromServer?.data?.remittance);
        setPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer.data.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/back-office/login");
      } else {
        setRemittance([]);
        setPageCount(0);
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }

  //Fetch All Remittance on initial load
  const fetchAllRemittance = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await backOfficeCommunication.getAllRemittance(page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setRemittance(responseFromServer?.data?.remittance);
        setPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        navigate("/back-office/login");
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        setRemittance([]);
        setPageCount(0);
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }


  useEffect(() => {
    fetchAllRemittance();
  }, []);


  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="back_office_section">
            <div className="row mb-2 top_search_header_backoffice">
              <div className="col-lg-3 col-md-4">
                <input Type="text" onChange={(e) => { searchFilter(e.target.value.trim()); setSearchValue(e.target.value.trim()) }} className="form-control" placeholder="Search by User Name, Application Id" />
              </div>

              <div className="col-lg-9 col-md-9 pagination_class">
                <div className="pagination_div ">
                  {
                    pageCount > 1 &&
                    <div className="pagination_main">
                      <ReactPaginate
                        previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                        nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount} shape="rounded"
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        forcePage={currentPage}
                        activeClassName={"active"} />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="back_office_table_main">
              <div className="back_office_table remittance">
                <div className="back_office_table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                  <div className="back_office_sr_no">
                    <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                  </div>

                  <div className="user_name">
                    <H5 whiteThemeColor darkThemeColor>User Name</H5>
                  </div>
                  <div className="application_id">
                    <H5 whiteThemeColor darkThemeColor>Application Id</H5>
                  </div>
                  <div className="application_type">
                    <H5 whiteThemeColor darkThemeColor>Type</H5>
                  </div>
                  <div className="back_office_status">
                    <H5 whiteThemeColor darkThemeColor>Status</H5>
                  </div>
                  <div className="back_office_comment">
                    <H5 whiteThemeColor darkThemeColor>Comment</H5>
                  </div>
                  <div className="back_office_date">
                    <H5 whiteThemeColor darkThemeColor>Date</H5>
                  </div>
                </div>
                {remittance?.length > 0 ?
                  <>
                    {remittance?.map((remittanceDetail, index) => (
                      <div className="back_office_table_data" key={index}>
                        <div className="back_office_sr_no">
                          <H6 whiteThemeColor darkThemeColor>{(limit * (currentPage) + (index + 1))}</H6>
                        </div>
                        <div className="user_name">
                          <NavLink to={`/back-office/remittance/remittance-form?remittanceId=${remittanceDetail._id}`}>
                            <H6 whiteThemeColor darkThemeColor>{capitalizeFirstLetter(remittanceDetail?.name)}</H6>
                          </NavLink>
                        </div>
                        <div className="application_id">
                          <H6 whiteThemeColor darkThemeColor>{remittanceDetail?.applicationId}</H6>
                        </div>
                        <div className="application_type">
                          <H6 whiteThemeColor darkThemeColor>{removeUnderscoreFromText(capitalizeFirstLetter(remittanceDetail?.applicationType))}</H6>
                        </div>
                        <div className="back_office_status">
                          <H6 whiteThemeColor darkThemeColor>{removeUnderscoreFromText(capitalizeFirstLetter(remittanceDetail?.backOfficeStatus))}</H6>
                        </div>
                        <div className="back_office_comment">
                          {
                            remittanceDetail?.narration.length > 0 ?
                              <H6 whiteThemeColor darkThemeColor>{remittanceDetail?.narration}</H6>
                              :
                              <H6 whiteThemeColor darkThemeColor>-</H6>
                          }
                        </div>
                        <div className="back_office_date">
                          <H6 whiteThemeColor darkThemeColor>{new Date(remittanceDetail?.creationDate).toLocaleDateString()}</H6>
                        </div>
                      </div>
                    ))}
                  </>
                  :
                  <div className="data_not_available_message">
                    <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Remittance Not Available</span>
                  </div>
                }
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default Remittance