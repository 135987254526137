import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { backOfficeCommunication } from '../../communication/backOfficeCommunication';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import CommonLoader from '../../shared-components/CommonLoader';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { capitalizeFirstLetter } from '../../shared-components/capitalizeFirstLetter';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const Notification = ({ setCountHandler }) => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [filterNotification, setFilterNotification] = useState([]);
  const limit = process.env.REACT_APP_LIMIT

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
      fetchNotificationListbySearch(searchValue, selectedPage + 1);
    } else {
      fetchNotifications(selectedPage + 1);
    }
  }

  //filter data according to Notification
  const searchFilter = async (searchString) => {
    if (searchString) {
      fetchNotificationListbySearch(searchString, currentPage + 1)
    } else {
      const responseFromServer = await backOfficeCommunication.getNotifications();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setNotifications(responseFromServer?.data?.alert);
        setPageCount(responseFromServer?.data?.totalPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/back-office/login")
      } else {
        setNotifications([]);
        setPageCount([]);
      }
    }
  }

  //get Notification list by search
  const fetchNotificationListbySearch = async (searchString, page) => {
    try {
      const responseFromServer = await backOfficeCommunication.getNotificationListBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setFilteredData(responseFromServer?.data?.alert);
        setPageCount(responseFromServer?.data?.totalPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/back-office/login")
      } else {
        setFilteredData([]);
        setPageCount(0);
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" })
    }
  }

  //fetch all Notifications on initial load
  const fetchNotifications = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await backOfficeCommunication.getNotifications(page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setNotifications(responseFromServer?.data?.alert);
        setFilteredData(responseFromServer?.data?.alert);
        setPageCount(responseFromServer?.data?.totalPages)
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        navigate("/back-office/login");
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        setNotifications([]);
        setFilteredData([])
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }


  const marKAsSeen = async (alertId) => {
    try {
      const serverResponse = await backOfficeCommunication.markAlertAsSeen(alertId);
      if (serverResponse.data.status === "FAILED") {
        swal({ text: serverResponse.data.message, icon: "error" });
      } else {
        fetchNotifications();
        setCountHandler(prevState => !prevState);
      }
    } catch (error) {
      swal({ text: error.message, icon: "error" });
    }
  }


  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="back_office_section">
            <div className="row mb-2 top_search_header_backoffice">
              <div className="col-lg-3 col-md-4">
                <input Type="text" onChange={(e) => { searchFilter(e.target.value.trim()); setSearchValue(e.target.value.trim()) }} className="form-control" placeholder="Search by Name/Message" />
              </div>
              <div className="col-lg-9 col-md-9 pagination_class">
                <div className="pagination_div ">
                  {
                    pageCount > 1 &&
                    <div className="pagination_main">
                      <ReactPaginate
                        previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                        nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount} shape="rounded"
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        forcePage={currentPage}
                        activeClassName={"active"} />
                    </div>
                  }
                </div>
              </div>

            </div>
            <div className="back_office_table_main">
              <div className="back_office_notification_table">
                <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                  <div className="row m-0">
                    <div className="col-2 text_align">
                      <H5>Serial Number</H5>
                    </div>
                    <div className="col-3 text_align">
                      <H5>User Name</H5>
                    </div>
                    <div className="col-7 align" style={{ justifyContent: "flex-start" }}>
                      <H5>Description</H5>
                    </div>
                  </div>
                </div>
                {filteredData?.length > 0 ?
                  <>
                    {filteredData?.map((notificationDetail, index) => (
                      <div className="table_data" key={index} style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                        <div className="row m-0">
                          <div className="col-2 text_align">
                            <H6>{(limit * (currentPage) + (index + 1))}</H6>
                          </div>
                          <div className="col-3 text_align">
                            <H6>{capitalizeFirstLetter(notificationDetail?.name)}</H6>
                          </div>
                          <div className="col-7 align pe-5" style={{ justifyContent: "flex-start" }}>
                            <H6>{notificationDetail?.message}</H6>
                          </div>
                        </div>
                        {notificationDetail?.isReviewed === false &&
                          <div className="notification_review_button" onClick={() => { marKAsSeen(notificationDetail._id) }} >
                            <i className="fa-solid fa-eye" title="Mark as Seen" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "rgb(173, 83, 137)" }}></i>
                          </div>
                        }
                      </div>
                    ))}
                  </>
                  :
                  <div className="data_not_available_message">
                    <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Notification Not Available</span>
                  </div>
                }
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default Notification