import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import H4 from '../../text-components/H4'
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';

const UpdateStatistics = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

  return (
    <div className="tab_content_main">
      <div className="row m-0 mt-2 mb-3">
        <div className="col-lg-12 col-md-12 text-center">
          <H4 whiteThemeColor darkThemColor>Statistics Details List</H4>
        </div>
      </div>

      <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 text_align">
            <H5 whiteThemeColor darkThemeColor>Sr. No.</H5>
          </div>
          <div className="col-lg-2 col-md-2 text_align">
            <H5 whiteThemeColor darkThemeColor>File Name</H5>
          </div>
          <div className="col-lg-2 col-md-2 align">
            <H5 whiteThemeColor darkThemeColor>Action</H5>
          </div>
        </div>
      </div>

      <div className="table_data">
        <div className="row m-0">
          <div className="col-2 text_align">
            <H6 whiteThemeColor darkThemeColor>1</H6>
          </div>
          <div className="col-6 text_align">
            <H6 whiteThemeColor darkThemeColor>Document Review</H6>
          </div>
          <div className="col-2 text_align">
            <select name="year" className="form-select update_statistic_select">
              <option value="">Select Year</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
            </select>
          </div>
          <div className="col-2 align p-0 action button_group">
            {/* <NavLink to="/admin/upload-data-list/update-data"> */}
            {/* <i className="fa-solid fa-pen-to-square"></i> */}
            <button>Update</button>
            {/* </NavLink> */}
          </div>
        </div>

      </div>
    </div>
  )
}

export default UpdateStatistics