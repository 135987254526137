import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { userCommunication } from '../../communication/userCommunication';
import H5 from '../../text-components/H5';
import H6 from '../../text-components/H6';
import CommonLoader from '../../shared-components/CommonLoader';
import { hideLoader, showLoader } from '../../redux-store/loaderReducer';
import { removeUnderscoreFromText } from '../../shared-components/removeUnderScore';
import { capitalizeFirstLetter } from '../../shared-components/capitalizeFirstLetter';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

const Remittance = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [remittance, setRemittance] = useState([]);
  const [currentPage, SetCurrentPage] = useState(0);
  const [pageCount, SetPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const limit = process.env.REACT_APP_LIMIT;

  //onclick set Current page
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    SetCurrentPage(selectedPage);
    if (searchValue) {
      fetchRemittanceBySearch(searchValue, selectedPage + 1);
    } else {
      fetchAllRemittance(selectedPage + 1);
    }
  }

  //Search Filter
  const searchFilter = async (searchString) => {
    if (searchString) {
      fetchRemittanceBySearch(searchString, currentPage + 1);
    } else {
      const responseFromServer = await userCommunication.getAllRemittance();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setRemittance(responseFromServer?.data?.remittance);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        navigate("/login");
      } else {
        setRemittance([]);
        SetPageCount(0);
      }
    }
  }

  //get Remittance By Search
  const fetchRemittanceBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await userCommunication.getRemittanceBySearch(searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setRemittance(responseFromServer?.data?.remittance);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        navigate("/login");
      } else {
        setRemittance([]);
        SetPageCount(0);
      }
    } catch (error) {
      swal({ text: error?.message, icon: "error" });
    }
  }

  //fetch All Remittance on initial load
  const fetchAllRemittance = async (page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await userCommunication.getAllRemittance(page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setRemittance(responseFromServer?.data?.remittance);
        SetPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "error" });
        navigate("/login");
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        setRemittance([]);
        SetPageCount(0);
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "error" });
    }
  }


  useEffect(() => {
    fetchAllRemittance();
  }, []);

  return (
    <>
      {
        loader.showHideLoader === true ?
          <CommonLoader />
          :
          <div className="section">
            <div className="container">
              <div className="header_wrapper alert_header_wrapper" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#3c3c3c" }}>
                <div className="row mb-2 top_search_header_user_portal">
                  <div className="col-lg-3 col-md-4">
                    <input Type="text" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search.." />
                  </div>
                  <div className="col-lg-9 col-md-8 user_portal_pagination">
                    <div className="pagination_div ">
                      {
                        pageCount > 1 &&
                        <div className="pagination_main">
                          <ReactPaginate
                            previousLabel={currentPage === 0 ? "First" : <i className="fa-sharp fa-solid fa-backward"></i>}
                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa-sharp fa-solid fa-forward"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount} shape="rounded"
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            forcePage={currentPage}
                            activeClassName={"active"} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="table_heading" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#fff" : "#790a81" }}>
                <div className="row m-0">
                  <div className="col-1 text_align">
                    <H5 whiteThemeColor darkThemeColor>Serial Number</H5>
                  </div>
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Application Id</H5>
                  </div>
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Application Type</H5>
                  </div>
                  <div className="col-2 text_align">
                    <H5 whiteThemeColor darkThemeColor>Status</H5>
                  </div>
                  <div className="col-3 text_align">
                    <H5 whiteThemeColor darkThemeColor>Comment</H5>
                  </div>
                  <div className="col-2 align">
                    <H5 whiteThemeColor darkThemeColor>Date</H5>
                  </div>
                </div>
              </div>
              {remittance.length > 0 ?
                <>
                  {remittance?.map((remittanceDetails, index) => (
                    <div className="table_data" key={index}>
                      <div className="row m-0">
                        <div className="col-1 text_align">
                          <H6>{(limit * (currentPage) + (index + 1))}</H6>
                        </div>
                        <div className="col-2 text_align">
                          <NavLink to={`/user/remittance/remittance-preview?remittanceId=${remittanceDetails?._id}`}>
                            <H6>{remittanceDetails?.applicationId}</H6>
                          </NavLink>
                        </div>
                        <div className="col-2 text_align">
                          <H6>{removeUnderscoreFromText(capitalizeFirstLetter(remittanceDetails?.applicationType))}</H6>
                        </div>
                        <div className="col-2 text_align">
                          <H6>{removeUnderscoreFromText(capitalizeFirstLetter(remittanceDetails?.userStatus))}</H6>
                        </div>
                        <div className="col-3 text_align">
                          {remittanceDetails?.comment?.length > 0 ?
                            < H6>{remittanceDetails?.comment[remittanceDetails?.comment.length - 1]}</H6>
                            :
                            < H6>--</H6>
                          }
                        </div>
                        <div className="col-2 align">
                          <H6>{new Date(remittanceDetails?.creationDate).toLocaleDateString()}</H6>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
                :
                <div className="data_not_available_message">
                  <span style={{ color: darkWhiteThemeToggle === "WHITE" ? "#790a81" : "#fff" }}>Remittance Not Available</span>
                </div>
              }
            </div>
          </div>
      }
    </>
  )
}

export default Remittance